import { useEffect } from 'react';
import { ReactComponent as BrainSvg } from '../../assets/boosters/brain.svg';
import { ReactComponent as RecoverSvg } from '../../assets/boosters/recover.svg';
import { ReactComponent as RobotSvg } from '../../assets/boosters/robot.svg';
import { ReactComponent as RocketSvg } from '../../assets/boosters/rocket.svg';
import { ReactComponent as UfoSvg } from '../../assets/boosters/ufo.svg';
import { useDateProgress } from '../../hooks/useDateProgress';
import { useAppDispatch } from '../../store';
import { getPurchases } from '../../store/reducers/store/asyncActions';
import { Purchase } from '../../types';

type ActiveBoosterCardProps = {
    purchase: Purchase
    onBoosterClick: () => void
}
function ActiveBoosterCard({ purchase, onBoosterClick }: ActiveBoosterCardProps) {
  const dispatch = useAppDispatch();

  const { remain, isFinished, start } = useDateProgress();

  const handleClick = () => {
    onBoosterClick();
  };

  const handleTimerEnd = () => {
    dispatch(getPurchases());
  };

  const getItemIcon = (icon: string) => {
    switch (icon) {
      case 'brain-bot':
        return <BrainSvg width={45} height={45} />;
      case 'recover':
        return <RecoverSvg width={45} height={45} />;
      case 'ufo':
        return <UfoSvg width={45} height={45} />;
      case 'crash-guard':
        return <RobotSvg width={45} height={45} />;
      default:
        return <RocketSvg width={45} height={45} />;
    }
  };

  useEffect(() => {
    start(new Date().getTime(), new Date(purchase?.expires_at ?? 0).getTime());
  }, []);

  useEffect(() => {
    if (isFinished) {
      handleTimerEnd();
    }
  }, [isFinished]);

  if (isFinished) {
    return null;
  }

  return (
    <div className="flex flex-col items-center max-w-[60px]" onClick={handleClick}>
      {getItemIcon(purchase.store_item_level.store_item.icon)}
      <div className="font-Bangers text-xs uppercase leading-[12.77px]">
        {purchase.store_item_level.store_item.name}
      </div>
      <div className="font-Barlow text-xs uppercase leading-[12px]">
        {(purchase.store_item_level.store_item.type === 'temporary' && purchase.expires_at)
        && <Timer formatedMinutes={remain.formatedMinutes} formatedSecond={remain.formatedSecond} />}
      </div>
    </div>
  );
}

export default ActiveBoosterCard;

type TimerProps = {
  formatedSecond: string, formatedMinutes: string;
}
function Timer({ formatedSecond, formatedMinutes }: TimerProps) {
  return (
    <div>
      <span>
        {formatedMinutes}
      </span>
      :
      <span>
        {formatedSecond}
      </span>
    </div>
  );
}
