import { memo } from 'react';

type LoaderProps = {
  progress: number
}

const Loader = memo(({ progress }: LoaderProps) => (
  <div
    className="absolute h-screen bg-cover w-full z-[2] flex justify-center items-center"
    style={{
      backgroundImage: 'url(/images/loaderBG.png)',
    }}
  >
    <div className="mt-[-120px] flex justify-center items-center flex-col max-w-[232px] w-full">
      <img src="/images/logo2x.png" alt="logo" className="w-full" />
      <div className="font-Bangers loader-text mt-4">
        Loading..
      </div>
      <div className={`loader-progress before:w-[${Math.floor(progress)}%] mt-4`} />
    </div>
  </div>
));
Loader.displayName = 'Loader';
export default Loader;
