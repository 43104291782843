import { CountDownT } from '../../../hooks/useCoundown';
import { UseUserEnergyT } from '../../../hooks/useUserEnergy';
import EnergyBottleIcon from '../../../icons/EnergyBottle';

type MiddleScreenProps = {
  countdown: CountDownT;
  userEnergy: UseUserEnergyT['userEnergy'];
  maxEnergy: UseUserEnergyT['maxEnergy'];
  energyForSpin: UseUserEnergyT['energyForSpin'];
};
function MiddleScreen({
  countdown,
  userEnergy,
  maxEnergy,
  energyForSpin,
}: MiddleScreenProps) {
  const maxSpins = maxEnergy / energyForSpin;
  const spinsLeft = userEnergy / energyForSpin;
  return (
    <div className="font-Russo text-[9px]" id="slot-middle-screen">
      <div className="flex items-center justify-center gap-[2px]">
        <EnergyBottleIcon size={12} />
        <div className="text-[12px]">
          {Math.floor(spinsLeft)}
          /
          {maxSpins}
        </div>
      </div>
      <div className={userEnergy >= maxEnergy ? 'invisible' : 'visible'}>
        1
        {' '}
        <span className="text-[#83F3F1]">Spin in</span>
        {' '}
        <span>
          {countdown.formatedMinutes}
          :
          {countdown.formatedSecond}
        </span>
      </div>
    </div>
  );
}
export default MiddleScreen;
