export const getColorByMultiplier = (multiplier: number) => {
  if (multiplier >= 10) {
    return 'orange-400';
  }

  if (multiplier >= 8) {
    return 'green-400';
  }

  if (multiplier >= 6) {
    return 'pink-400';
  }

  if (multiplier >= 4) {
    return 'cyan-400';
  }

  if (multiplier >= 2) {
    return 'blue-400';
  }

  return 'navy-600';
};
