import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Coin } from '../../../assets/images/coin-size.svg';
import { formatNumbers, getNextLeagueCoins } from '../../../helpers';
import Pages from '../../../Router/pages';
import { useAppSelector } from '../../../store';
import LeagueIcon from '../../LeagueIcon';

function UserLeague() {
  const navigate = useNavigate();

  const { userData: user } = useAppSelector(({ profile }) => profile);

  const { settings } = useAppSelector(
    (state) => state.settings,
  );

  const nextLeagueCoins: number = useMemo(() => {
    if (!user || !settings) {
      return 0;
    }

    return getNextLeagueCoins((settings).leagues, (user).league.name);
  }, [settings, user]);

  if (!user) {
    return null;
  }

  return (
    <div
      className={`header-league-card ${user.league.name} gradient-border before:rounded-8`}
      onClick={() => navigate(Pages.leaderbord)}
    >
      <div className="w-[34px] h-[34px] absolute left-0 top-0 transform -translate-x-1/2 header-league-icon-bg">
        <LeagueIcon league={user.league.name} />
      </div>
      <div className="flex flex-col items-start">
        <span className="font-Bangers text-xs leading-[13px]">{user.league.name}</span>
        <div className="flex items-center gap-0.5 leading-[10px] font-bold text-[8px]">
          <span>
            {formatNumbers(user.coins, 2)}
            {' '}
            <span className={`header-separator ${user.league.name}`}>/</span>
            {' '}
            {formatNumbers(nextLeagueCoins)}
          </span>
          <Coin width={10} height={10} />
        </div>
      </div>
    </div>
  );
}

export default UserLeague;
