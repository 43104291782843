import { useEffect, useState } from 'react';
import { slotMachinePixiApp } from '../../../apps/slotMachine';
import { useEnergyContext } from '../../../providers/EnergyProvider';
import SlotMachinePixi from '../SlotMachinePixi';
import AllWinsBanner from './AllWinsBanner';
import BottomPart from './BottomPart';
import BottomScreen from './BottomScreen';
import MachineScreenBgSVG from './MachineScreenBgSVG';
import MachineSvgDefs from './MachineSvgDefs';
import MultiplierBtn from './MultiplierBtn';
import SecondLayer from './SecondLayer';
import { MultiplierT, MultiplierValues } from './slotConstants';
import SpinButton from './SpinButton';
import SpinResultBlock from './SpinResultBlock';
import SpinsScreen from './SpinsScreen';

type MachineProps = {
  onSpin: () => void
  isSpinning: boolean
  multiplier: MultiplierT
  setMultiplier: (val: MultiplierT) => void
}
export const getMachineFrameScale = () => {
  const parentSvg = document.getElementById('machine-frame-svg');
  if (!parentSvg) {
    return null;
  }
  const actualWidth = parentSvg.clientWidth;
  const actualHeight = parentSvg.clientHeight;
  const viewBox = parentSvg.getAttribute('viewBox');

  if (!viewBox) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = viewBox.split(' ').map(Number);

  const scaleX = actualWidth / viewBoxWidth;
  const scaleY = actualHeight / viewBoxHeight;

  return {
    scaleX, scaleY,
  };
};

function Machine({
  onSpin, isSpinning, multiplier, setMultiplier,
}: MachineProps) {
  const { userEnergy, energyForSpin } = useEnergyContext().userEnergy;
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [scale, setScale] = useState({ scaleX: 1, scaleY: 1 });

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const resize = () => {
    const foreignMachine = document.getElementById('machine-foreign');
    const parentSvg = document.getElementById('machine-frame-svg');
    const screenSvg = document.getElementById('svg_63');
    const divInside = document.getElementById('machine-content');
    if (foreignMachine && parentSvg && screenSvg && divInside) {
      const screenRect = screenSvg.getBoundingClientRect();
      const scaleData = getMachineFrameScale();
      if (scaleData) {
        const { scaleX, scaleY } = scaleData;
        setScale({ scaleX, scaleY });

        foreignMachine.setAttribute('width', (screenRect.width / scaleX).toString());
        foreignMachine.setAttribute('height', (screenRect.height / scaleY).toString());
        // set position to div inside foreignObject
        divInside.style.width = `${screenRect.width / scaleX}px`;
        divInside.style.height = `${screenRect.height / scaleY}px`;
        divInside.style.position = 'fixed';
        divInside.style.top = '0';
        divInside.style.left = '0';

        if (isSafari) {
          const { height: resizeHeight, width: resizeWidth } = screenSvg.getBoundingClientRect();

          if (resizeHeight && resizeWidth) {
            slotMachinePixiApp.renderer.resize(resizeWidth, resizeHeight);
          }
          if (scaleX && scaleY) {
            slotMachinePixiApp.stage.scale.set(scaleX, scaleY);
          }
        }
      }
    }
  };
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
  }, []);
  const handleSpinClick = () => {
    onSpin();
  };

  const handleChangeMultiplier = () => {
    setIsAnimating(true);
    const indexOfCurrentMultiplier = MultiplierValues.indexOf(multiplier);
    const hasEnergyForMultipleSpins = energyForSpin * MultiplierValues[indexOfCurrentMultiplier + 1] <= userEnergy;
    if (indexOfCurrentMultiplier + 1 === MultiplierValues.length || !hasEnergyForMultipleSpins) {
      setMultiplier(MultiplierValues[0]);
    } else {
      setMultiplier(MultiplierValues[indexOfCurrentMultiplier + 1]);
    }
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
  };

  useEffect(() => {
    const indexOfCurrentMultiplier = MultiplierValues.indexOf(multiplier);
    const hasEnergyForMultipleSpins = energyForSpin * MultiplierValues[indexOfCurrentMultiplier] <= userEnergy;
    if (!hasEnergyForMultipleSpins) {
      const valueWithEnoughEnergy = [...MultiplierValues].reverse().find((val) => val * energyForSpin <= userEnergy);
      setMultiplier(valueWithEnoughEnergy || MultiplierValues[0]);
    }
  }, [userEnergy, energyForSpin]);
  return (
    <>
      <AllWinsBanner multiplier={multiplier} scale={scale} isSafari={isSafari} isAnimating={isAnimating} />
      <svg
        id="machine-frame-svg"
        className="w-full h-auto max-w-full"
        // viewBox="0 0 374 486"
        viewBox="0 0 374 566"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        style={{
          overflow: 'hidden',
        }}
      >
        <foreignObject
          x="-5"
          y={(window?.Telegram.WebApp.platform === 'android' || window?.Telegram.WebApp.platform === 'unknown') ? '-10' : '-25'}
          width="100%"
          height="100%"
          className="pointer-events-none"
        >
          <MachineScreenBgSVG />
        </foreignObject>
        <foreignObject x="20" y="35" id="machine-foreign">
          <div
            id="machine-content"
            className={`z-[2] relative overflow-hidden ${isSafari ? 'machine-content-safari' : ''}`}
          >
            <SlotMachinePixi />
          </div>
        </foreignObject>
        <g>
          <title>Layer 2</title>
          <SecondLayer />
          <svg y="363" x="69.45621" id="svg_220" fill="none" viewBox="0 0 216 104" height="104" width="236" xmlns="http://www.w3.org/2000/svg">
            <foreignObject x={0} y={0} height="100%" width="100%">
              <SpinButton onClick={handleSpinClick} isSpinning={isSpinning} />
            </foreignObject>
          </svg>
        </g>
        <g
          id="svg_164"
        >
          <svg width="136" height="24" viewBox="0 0 140 24" fill="none" id="svg_4" x="119.18385" y="305.41553" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#svg_9)" id="svg_147">
              <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="#0C056B" id="svg_152" />
              <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="url(#svg_3)" id="svg_151" />
              <path d="m132.542,22.3534l-129.39484,0c-0.84609,0 -1.41014,-0.9041 -1.0153,-1.6387l9.30694,-18.64664c0.1692,-0.39553 0.5641,-0.62155 1.0153,-0.62155l111.1189,0c0.452,0 0.846,0.22602 1.016,0.62155l8.968,18.64664c0.339,0.7911 -0.169,1.6387 -1.015,1.6387zm-8.235,-22.03699l-112.5298,0c-0.4512,0 -0.846,0.22602 -1.0153,0.62155l-10.09659,20.11584c-0.56406,1.1301 0.28203,2.4297 1.52295,2.4297l131.25574,0c1.241,0 2.087,-1.2996 1.523,-2.4297l-9.645,-20.05933c-0.169,-0.39554 -0.621,-0.67806 -1.015,-0.67806z" fill="url(#svg_2)" id="svg_148" />
            </g>
            <defs>
              <clipPath id="svg_9">
                <rect width="134.697" height="23.2236" fill="white" y="0.3169" x="0.49609" id="svg_12" />
              </clipPath>
            </defs>
            <foreignObject
              x="0"
              y="0"
              width="100%"
              height="100%"
            >
              <BottomScreen multiplier={multiplier} isClickable onMultiplierClick={handleChangeMultiplier} isAnimating={isAnimating} isSafari={isSafari} />
            </foreignObject>
          </svg>
        </g>
      </svg>
      <svg
        id="machine-frame-svg-second"
        className="w-full h-auto max-w-full"
        viewBox="0 0 374 566"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        style={{
          overflow: 'hidden',
        }}
      >
        <defs>
          <MachineSvgDefs />
        </defs>
        <g>
          <title>Layer 1</title>
          <g id="svg_194">
            <g stroke="null" id="svg_136">
              <path d="M12.0303 260.6V286.737L68.2096 340.111H65.3399H116.442L113.407 322.393L86.0898 322.558L12.0303 260.6Z" fill="url(#paint0_radial_4581_194638)" />
              <path d="M118.65 33.015C118.705 33.125 116.111 34.0054 115.946 34.0604C114.4 34.5006 112.91 35.1059 111.42 35.6562C108.33 36.8117 105.35 38.2974 102.204 39.2328C97.9551 40.4984 93.1539 41.4889 83.6067 46.166C81.8408 47.0464 79.5781 48.1469 77.8122 49.1374C67.2165 55.0801 57.283 61.7382 48.8947 70.5423C44.0384 75.5496 38.0231 85.3441 37.4712 96.019C37.0298 104.988 37.0849 241.616 37.0849 241.616L29.2485 243.762V91.3418C29.2485 82.4277 34.8223 74.7242 38.1335 70.3772C43.5969 62.9488 49.557 58.2166 57.3382 52.714C64.5124 47.6517 73.2317 42.5894 81.0682 38.5175C91.4983 33.125 112.414 25.8066 112.414 25.8066C112.414 25.8066 118.595 32.9599 118.65 33.015Z" fill="url(#paint1_linear_4581_194638)" />
              <path d="M109.158 24.4311L108.275 11.0049C72.7901 19.2037 63.5741 25.0914 43.8727 38.1324C17.2179 56.4008 14.1274 81.6574 14.1274 81.6574L16.6108 118.745L21.9638 133.436L33.6633 117.369C33.6633 117.369 28.9725 81.7675 46.4664 63.1689C63.9604 44.5704 92.2708 32.6298 92.2708 32.6298L109.158 24.4311Z" fill="url(#paint2_linear_4581_194638)" />
              <path d="M109.158 24.4311L108.275 11.0049C72.7901 19.2037 63.5741 25.0914 43.8727 38.1324C17.2179 56.4008 14.1274 81.6574 14.1274 81.6574L16.6108 118.745L21.9638 133.436L33.6633 117.369C33.6633 117.369 28.9725 81.7675 46.4664 63.1689C63.9604 44.5704 92.2708 32.6298 92.2708 32.6298L109.158 24.4311Z" fill="url(#paint3_radial_4581_194638)" />
              <path d="M36.4777 241.176L84.4895 281.784L95.0301 282.17L95.1956 313.149L84.5999 312.819H75.5494L8.88477 251.851L36.4777 241.176Z" fill="url(#paint4_radial_4581_194638)" />
              <path d="M29.8003 88.9759L13.686 84.4639V249.98L30.5729 243.487L29.8003 88.9759Z" fill="url(#paint5_radial_4581_194638)" />
              <path d="M29.8001 89.4163L25.4404 88.2607L26.5441 244.973L30.5727 243.487L29.8001 89.4163Z" fill="url(#paint6_linear_4581_194638)" />
              <path d="M112.248 21.625C107.613 22.1753 103.363 23.4408 99.0036 24.9815C82.3927 30.8142 67.2717 40.3336 54.0271 51.4487C46.9081 57.4465 42.9899 60.4729 38.1887 67.296C33.222 74.3393 28.9727 83.9137 28.9727 92.6627L29.1382 244.533L35.6502 242.332C35.6502 238.975 35.7605 155.997 35.7053 145.047C35.6502 134.922 35.6502 124.743 35.8157 114.673C35.9261 107.41 36.0365 100.201 36.5883 92.9929C37.3609 82.8132 43.3762 72.9637 50.5504 65.7553C56.1242 60.1427 62.6913 55.5206 69.4792 51.3937C84.5449 41.9844 102.039 35.6014 118.981 30.1539C116.718 27.3476 114.511 24.4863 112.248 21.625Z" fill="url(#paint7_linear_4581_194638)" />
              <path d="M26.5444 244.973L80.7922 285.967L83.9378 280.959L29.3589 243.212L26.5444 244.973Z" fill="url(#paint8_linear_4581_194638)" />
              <path d="M29.3589 243.212L37.2505 240.405L91.719 277.217L87.4145 287.012L29.3589 243.212Z" fill="url(#paint9_radial_4581_194638)" />
              <path d="M8.88477 251.851L73.2316 308.582H125.382L124.003 326.025H80.2402L8.88477 261.535V254.712" fill="url(#paint10_radial_4581_194638)" />
              <path d="M116.221 29.8232L118.705 34.6654C103.142 38.022 87.7454 42.0938 68.9822 54.3645C62.3047 58.7115 56.1238 63.3886 50.3845 68.8912C43.1551 75.8244 38.5195 85.6189 37.8573 93.4875C37.4158 99.0451 37.6365 108.124 37.5262 115.333C37.3606 125.457 22.7915 90.8463 22.7915 90.7913C22.7915 82.0422 26.213 74.2837 31.1798 67.2404C35.981 60.4173 42.9896 53.4291 49.8326 48.7519C64.5673 38.6272 79.633 30.5935 96.1888 24.7608C100.549 23.2201 104.798 21.9546 109.433 21.4043C111.806 24.2106 114.014 27.0169 116.221 29.8232Z" fill="url(#paint11_radial_4581_194638)" />
              <path opacity="0.69" d="M91.3324 29.6034C91.3324 29.6034 74.3903 36.1514 57.4482 48.6972C48.784 55.1352 39.1816 65.8101 39.1816 65.8101C39.1816 65.8101 48.4529 53.5394 63.3531 42.8095C79.5778 32.0795 89.8424 27.2373 89.8424 27.2373L91.3324 29.6034Z" fill="url(#paint12_linear_4581_194638)" />
              <path d="M62.084 290.81L46.356 277.934C45.0315 276.833 45.3074 274.742 46.8527 274.137L53.0887 271.441C53.8613 271.111 54.7994 271.221 55.4065 271.716L70.3067 283.601C71.5208 284.592 71.4656 286.408 70.1963 287.288L64.7881 290.92C63.9603 291.47 62.9118 291.415 62.084 290.81Z" fill="url(#paint13_radial_4581_194638)" />
              <path opacity="0.3" d="M46.853 274.632L53.089 271.936C53.8616 271.606 54.7998 271.716 55.4069 272.211L70.3071 284.097C70.8589 284.482 71.0797 285.087 71.1349 285.692C71.2452 284.922 70.9693 284.097 70.3623 283.546L55.462 271.661C54.855 271.166 53.9168 271 53.1442 271.386L46.9082 274.082C45.9701 274.467 45.4734 275.458 45.5286 276.338C45.6389 275.623 46.0804 274.962 46.853 274.632Z" fill="#2B0691" />
              <path opacity="0.6" d="M44.9212 263.957L30.5177 252.347C29.8003 251.796 28.8621 251.631 27.9792 251.961L22.5157 254.327C20.9705 255.153 20.7498 257.299 22.0743 258.399L36.4778 270.01C37.1952 270.56 38.1334 270.725 39.0163 270.395L44.4798 268.029C46.025 267.258 46.3009 265.057 44.9212 263.957Z" fill="url(#paint14_radial_4581_194638)" />
              <path opacity="0.5" d="M22.5159 254.878L27.9793 252.512C28.8071 252.181 29.8004 252.292 30.5179 252.897L44.9214 264.507C45.5285 264.947 45.8044 265.608 45.8596 266.213C46.0251 265.388 45.694 264.507 44.9766 263.957L30.5731 252.347C29.8556 251.796 28.9175 251.631 28.0345 251.961L22.5711 254.327C21.6329 254.768 21.1363 255.758 21.2466 256.694C21.3018 255.923 21.7433 255.263 22.5159 254.878Z" fill="#2B0691" />
              <path d="M29.0832 262.25L23.8405 258.069C23.1231 257.463 23.4542 255.923 23.4542 255.923L28.3658 254.052C28.8073 253.832 29.3591 253.942 29.6902 254.217L35.3744 258.454C35.3744 258.454 35.6503 260.049 34.7673 260.435L30.4076 262.36C29.9662 262.581 29.5247 262.526 29.0832 262.25Z" fill="#2B0691" />
              <path d="M29.1384 261.866L23.8957 257.684C23.1783 257.078 23.5094 255.538 23.5094 255.538L28.4209 253.667C28.8624 253.447 29.4143 253.557 29.7454 253.832L35.4296 258.069C35.4296 258.069 35.7055 259.665 34.8225 260.05L30.4628 261.976C30.0213 262.196 29.5247 262.141 29.1384 261.866Z" fill="#B70870" />
              <path d="M29.1936 260.93L24.0061 256.803C23.2887 256.198 23.399 254.987 24.282 254.602L28.4209 252.731C28.8624 252.511 29.4143 252.621 29.7454 252.896L34.9329 257.023C35.6503 257.629 35.5399 258.839 34.657 259.224L30.518 261.095C30.0213 261.315 29.5247 261.205 29.1936 260.93Z" fill="url(#paint15_linear_4581_194638)" />
              <path d="M37.6921 269.239L32.5046 265.112C31.7872 264.506 32.1183 263.021 32.1183 263.021L36.9746 261.205C37.4161 260.985 37.9128 261.095 38.2991 261.37L43.9281 265.607C43.9281 265.607 44.204 267.203 43.321 267.588L39.0165 269.514C38.6302 269.569 38.0784 269.514 37.6921 269.239Z" fill="#2B0691" />
              <path d="M37.7472 268.854L32.5598 264.672C31.8423 264.067 32.1735 262.581 32.1735 262.581L36.9746 260.82C37.4161 260.6 37.9128 260.71 38.2991 260.985L43.9281 265.222C43.9281 265.222 44.204 266.818 43.321 267.203L39.0165 269.129C38.6302 269.239 38.1335 269.184 37.7472 268.854Z" fill="#D88705" />
              <path d="M37.7473 267.973L32.5598 263.902C31.8424 263.296 31.9527 262.086 32.8357 261.701L36.9747 259.885C37.4161 259.665 37.9128 259.775 38.2991 260.05L43.4866 264.122C44.204 264.727 44.0936 265.937 43.2107 266.323L39.0717 268.138C38.6854 268.304 38.1887 268.249 37.7473 267.973Z" fill="url(#paint16_linear_4581_194638)" />
              <path opacity="0.6" d="M53.6955 282.06L48.2321 277.713C47.5147 277.108 47.8458 275.567 47.8458 275.567L52.7022 273.696C53.1437 273.476 53.6955 273.586 54.0266 273.861L59.7108 278.098C59.7108 278.098 59.9867 279.639 59.1038 280.079C58.1104 280.629 55.0752 282.225 55.0752 282.225C54.5233 282.445 54.0818 282.39 53.6955 282.06Z" fill="#2B0691" />
              <path d="M53.6955 281.73L48.2321 277.383C47.5147 276.778 47.8458 275.237 47.8458 275.237L52.7022 273.366C53.1437 273.146 53.6955 273.256 54.0266 273.531L59.7108 277.768C59.7108 277.768 59.9867 279.309 59.1038 279.749C58.1104 280.299 55.0752 281.895 55.0752 281.895C54.6337 282.06 54.0818 282.005 53.6955 281.73Z" fill="#0694BA" />
              <path d="M53.7507 280.794L48.3425 276.502C47.6251 275.897 47.7354 274.687 48.6184 274.301L52.7574 272.431C53.1989 272.21 53.7507 272.321 54.0818 272.596L59.2693 276.668C59.9867 277.273 59.8212 278.373 58.9934 278.869C58.0552 279.419 55.1304 280.904 55.1304 280.904C54.6337 281.125 54.0818 281.07 53.7507 280.794Z" fill="url(#paint17_linear_4581_194638)" />
              <path opacity="0.6" d="M63.2982 289.818L57.1725 284.866C56.3999 284.261 56.8414 282.665 56.8414 282.665L61.6978 280.629C62.1393 280.409 62.6911 280.519 63.0222 280.849L69.1479 285.361C69.1479 285.361 69.3134 286.792 68.3753 287.452C67.4923 288.058 64.6778 290.038 64.6778 290.038C64.1811 290.204 63.6293 290.149 63.2982 289.818Z" fill="#2B0691" />
              <path d="M63.3533 289.434L57.2277 284.481C56.4551 283.876 56.8966 282.28 56.8966 282.28L61.7529 280.244C62.1944 280.024 62.7463 280.134 63.0774 280.465L69.0375 284.811C69.0375 284.811 69.5893 286.187 68.4304 287.068C67.4923 287.783 64.733 289.654 64.733 289.654C64.2363 289.819 63.7396 289.764 63.3533 289.434Z" fill="#2178D8" />
              <path d="M63.4086 288.498L57.2829 283.601C56.5103 282.996 56.6759 281.73 57.5588 281.345L61.6426 279.309C62.0841 279.089 62.6359 279.199 62.9671 279.529L68.3201 283.931C69.2031 284.646 68.9271 285.582 68.0994 286.132C67.2164 286.737 64.6226 288.553 64.6226 288.553C64.2915 288.938 63.7397 288.828 63.4086 288.498Z" fill="url(#paint18_linear_4581_194638)" />
              <g opacity="0.6">
                <path d="M77.5912 340.112L73.0108 329.437L12.0854 273.861L12.1406 271.936L74.2248 328.502L79.1364 340.112H77.5912Z" fill="#6B2FDD" />
              </g>
              <path d="M20.4738 210.527H18.3767C17.6041 210.527 17.0522 209.922 17.0522 209.206V191.873C17.0522 191.103 17.6593 190.553 18.3767 190.553H20.4738C21.2464 190.553 21.7982 191.158 21.7982 191.873V209.206C21.7982 209.977 21.1912 210.527 20.4738 210.527Z" fill="url(#paint19_linear_4581_194638)" />
              <path d="M20.8601 225.164H17.9352C17.3834 225.164 16.9971 224.723 16.9971 224.228V216.14C16.9971 215.589 17.4386 215.204 17.9352 215.204H20.8601C21.4119 215.204 21.7982 215.644 21.7982 216.14V224.228C21.7982 224.779 21.4119 225.164 20.8601 225.164Z" fill="url(#paint20_linear_4581_194638)" />
              <path d="M20.8601 130.41H17.9352C17.3834 130.41 16.9971 129.97 16.9971 129.474V121.386C16.9971 120.835 17.4386 120.45 17.9352 120.45H20.8601C21.4119 120.45 21.7982 120.89 21.7982 121.386V129.474C21.7982 129.915 21.4119 130.41 20.8601 130.41Z" fill="url(#paint21_linear_4581_194638)" />
              <path d="M73.2314 308.582L80.2401 326.025H125.824L122.292 308.527L73.2314 308.582Z" fill="url(#paint22_radial_4581_194638)" />
              <path d="M137.247 285.967H80.7919L73.2314 308.582H137.247V285.967Z" fill="url(#paint23_radial_4581_194638)" />
              <path d="M130.128 285.968H80.792L80.6816 281.4H129.908L130.128 285.968Z" fill="url(#paint24_linear_4581_194638)" />
              <path d="M152.92 274.192L153.03 281.785H112.91H80.6265V280.025C80.6265 275.898 84.9862 270.615 89.2907 270.615C100.438 270.615 141.607 270.505 149.278 270.56C151.32 270.505 152.92 272.101 152.92 274.192Z" fill="url(#paint25_radial_4581_194638)" />
              <path opacity="0.7" d="M80.5161 280.024C80.5161 275.897 84.9862 270.505 89.2907 270.505C93.54 270.505 100.052 270.505 109.489 270.56C109.489 270.56 98.0653 271 91.9396 271.33C86.9729 271.605 84.3239 272.981 82.2269 276.007C80.6265 278.208 80.5161 280.024 80.5161 280.024Z" fill="white" />
              <path d="M124.886 295.431C136.254 295.431 145.47 293.534 145.47 291.194C145.47 288.854 136.254 286.957 124.886 286.957C113.517 286.957 104.301 288.854 104.301 291.194C104.301 293.534 113.517 295.431 124.886 295.431Z" fill="url(#paint26_radial_4581_194638)" />
              <path d="M101.266 331.088C99.5553 330.703 99.3897 321.403 100.99 310.233C102.59 299.063 105.35 290.259 107.061 290.589C108.771 290.919 108.937 300.274 107.336 311.444C105.736 322.669 103.032 331.418 101.266 331.088Z" fill="url(#paint27_radial_4581_194638)" />
              <path d="M90.3392 339.671C88.6837 339.286 88.7388 330.592 90.3392 320.192C91.9948 309.793 94.7541 301.759 96.4097 302.144C98.0653 302.529 98.0101 311.223 96.4097 321.623C94.6989 331.968 91.9948 340.057 90.3392 339.671Z" fill="url(#paint28_radial_4581_194638)" />
              <path d="M111.255 332.464H105.074C101.873 332.464 99.2793 329.877 99.2793 326.686V326.466C99.3897 325.53 102.204 304.456 103.694 297.137C104.688 292.24 108.661 288.773 113.462 288.773H124.83V292.075H113.462C110.206 292.075 107.612 294.386 106.895 297.798C105.515 304.786 102.756 325.035 102.535 326.796C102.59 328.172 103.694 329.162 105.019 329.162H111.199L111.255 332.464Z" fill="url(#paint29_linear_4581_194638)" />
              <path d="M111.255 332.464H105.074C101.873 332.464 99.2793 329.877 99.2793 326.686V326.466C99.3897 325.53 102.204 304.456 103.694 297.137C104.688 292.24 108.661 288.773 113.462 288.773H124.83V292.075H113.462C110.206 292.075 107.612 294.386 106.895 297.798C105.515 304.786 102.756 325.035 102.535 326.796C102.59 328.172 103.694 329.162 105.019 329.162H111.199L111.255 332.464Z" fill="#B4D6F2" />
              <path d="M111.255 332.464H105.074C101.873 332.464 99.2793 329.877 99.2793 326.686V326.466C99.3897 325.53 102.204 304.456 103.694 297.137C104.688 292.24 108.661 288.773 113.462 288.773H124.83V292.075H113.462C110.206 292.075 107.612 294.386 106.895 297.798C105.515 304.786 102.756 325.035 102.535 326.796C102.59 328.172 103.694 329.162 105.019 329.162H111.199L111.255 332.464Z" fill="url(#paint30_linear_4581_194638)" />
              <path d="M121.133 290.039H113.462C108.826 290.039 105.515 293.396 104.853 297.963L100.493 326.191C100.107 328.612 101.983 330.868 104.467 330.868H107.502" stroke="url(#paint31_linear_4581_194638)" strokeWidth="0.36043" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M124.72 303.685C140.752 303.685 153.748 301.739 153.748 299.338C153.748 296.937 140.752 294.991 124.72 294.991C108.689 294.991 95.6924 296.937 95.6924 299.338C95.6924 301.739 108.689 303.685 124.72 303.685Z" fill="url(#paint32_radial_4581_194638)" />
              <path d="M353.356 265.607V286.297L297.066 340.112H268.701L273.723 326.025L353.356 265.607Z" fill="url(#paint33_radial_4581_194638)" />
              <path d="M275.378 32.6298C275.378 32.6298 303.689 44.5704 321.182 63.1689C338.676 81.7675 333.986 117.369 333.986 117.369L345.63 133.381L350.983 118.69L353.466 81.6024C353.466 81.6024 350.376 56.3458 323.721 38.0774C304.02 25.0363 294.804 19.2037 259.319 11.0049L258.436 24.4311L275.378 32.6298Z" fill="url(#paint34_linear_4581_194638)" />
              <path d="M337.794 88.9759L353.908 84.6289L354.018 249.265L337.076 245.853L337.794 88.9759Z" fill="url(#paint35_radial_4581_194638)" />
              <path d="M337.794 89.4163L342.153 88.2607L341.325 245.853H337.076L337.794 89.4163Z" fill="url(#paint36_linear_4581_194638)" />
              <path d="M275.378 32.6298C275.378 32.6298 303.689 44.5704 321.182 63.1689C338.676 81.7675 333.986 117.369 333.986 117.369L345.63 133.381L352.583 119.405L353.411 81.1072C349.272 62.8388 338.952 48.5322 323.721 38.0774C304.02 25.0363 294.804 19.2037 259.319 11.0049L258.436 24.4311L275.378 32.6298Z" fill="url(#paint37_radial_4581_194638)" />
              <path d="M341.436 243.267L280.786 281.784L280.731 312.819H289.726L356.446 249.705L341.436 243.267Z" fill="url(#paint38_radial_4581_194638)" />
              <path d="M341.436 243.266L282.387 288.002L281.504 281.179L339.394 240.9L341.436 243.266Z" fill="url(#paint39_linear_4581_194638)" />
              <path d="M248.779 32.7399C248.724 32.85 251.317 33.7304 251.483 33.7854C253.028 34.2256 254.518 34.8309 256.008 35.3812C259.098 36.5367 262.189 37.6922 265.224 38.9578C273.171 41.489 277.31 42.6445 284.374 46.0561C286.14 46.9365 287.85 47.8719 289.561 48.8624C300.157 54.8051 310.09 60.9129 318.479 69.662C323.335 74.6693 329.35 85.014 329.902 95.6889C330.344 104.658 331.116 238.81 331.116 238.81L334.593 240.075L338.125 234.078V90.9017C338.125 81.9876 332.551 74.2841 329.24 69.9371C323.942 62.7288 317.596 57.6114 310.035 52.2739C302.861 47.2116 294.142 42.1493 286.305 38.0774C275.93 32.6299 255.015 25.3115 255.015 25.3115C255.015 25.3115 248.779 32.6849 248.779 32.7399Z" fill="url(#paint40_linear_4581_194638)" />
              <path d="M346.458 175.641H348.444C349.051 175.641 349.493 175.146 349.493 174.595V165.846C349.493 165.241 348.996 164.801 348.444 164.801H346.458C345.851 164.801 345.409 165.296 345.409 165.846V174.595C345.464 175.201 345.906 175.641 346.458 175.641Z" fill="url(#paint41_linear_4581_194638)" />
              <path d="M249.606 29.8232C266.549 35.2707 283.987 41.6537 299.108 51.063C305.896 55.2449 312.463 59.812 318.037 65.4246C325.211 72.6329 331.226 82.4825 331.999 92.6622C332.551 99.8705 332.661 107.079 332.772 114.342C332.937 124.467 332.937 134.646 332.882 144.716C332.827 155.721 332.937 235.673 332.937 238.975L339.504 240.79L339.67 92.277C339.67 83.528 335.476 73.8985 330.454 66.9103C325.653 60.0872 321.679 57.0608 314.616 51.063C301.371 39.8929 286.25 30.4285 269.639 24.5958C265.279 23.0551 261.03 21.7895 256.394 21.2393C254.021 24.2106 251.814 27.0169 249.606 29.8232Z" fill="url(#paint42_linear_4581_194638)" />
              <path d="M346.458 161.94H348.444C349.051 161.94 349.493 161.444 349.493 160.894V152.145C349.493 151.54 348.996 151.1 348.444 151.1H346.458C345.851 151.1 345.409 151.595 345.409 152.145V160.894C345.464 161.444 345.906 161.94 346.458 161.94Z" fill="url(#paint43_linear_4581_194638)" />
              <path d="M346.458 120.945H348.444C349.051 120.945 349.493 120.45 349.493 119.9V111.151C349.493 110.546 348.996 110.105 348.444 110.105H346.458C345.851 110.105 345.409 110.601 345.409 111.151V119.9C345.464 120.45 345.906 120.945 346.458 120.945Z" fill="url(#paint44_linear_4581_194638)" />
              <path d="M339.394 240.901L331.061 238.425L277.254 274.301L283.435 281.785L339.394 240.901Z" fill="url(#paint45_radial_4581_194638)" />
              <path d="M356.446 249.705L288.016 308.582H239.894L240.666 326.025H285.091L356.446 263.351V249.705Z" fill="url(#paint46_radial_4581_194638)" />
              <path d="M273.447 28.9985C273.447 28.9985 283.049 32.7402 294.086 38.7379C302.916 43.4701 315.112 51.6139 315.112 51.6139C315.112 51.6139 309.538 46.6616 298.336 38.958C285.643 30.8143 276.096 27.1826 276.096 27.1826L273.447 28.9985Z" fill="url(#paint47_linear_4581_194638)" />
              <path opacity="0.5" d="M299.06 290.719C302.682 290.212 305.349 287.897 305.019 285.55C304.688 283.202 301.483 281.711 297.861 282.219C294.239 282.726 291.571 285.041 291.902 287.388C292.233 289.735 295.438 291.227 299.06 290.719Z" fill="#00389B" />
              <path d="M304.627 285.031C304.958 287.232 302.364 289.488 298.887 289.928C295.41 290.423 292.32 288.993 291.989 286.737C291.658 284.536 294.252 282.28 297.728 281.839C301.205 281.399 304.295 282.83 304.627 285.031Z" fill="url(#paint48_radial_4581_194638)" />
              <path opacity="0.4" d="M306.779 285.416C307.22 288.387 303.799 291.304 299.163 291.964C294.527 292.624 290.499 290.698 290.057 287.782C289.616 284.811 293.037 281.894 297.673 281.234C302.254 280.574 306.392 282.445 306.779 285.416Z" fill="url(#paint49_radial_4581_194638)" />
              <path opacity="0.4" d="M321.182 273.421C321.624 276.392 318.202 279.309 313.566 279.969C308.931 280.629 304.902 278.703 304.461 275.787C304.019 272.816 307.441 269.899 312.076 269.239C316.712 268.579 320.741 270.45 321.182 273.421Z" fill="url(#paint50_radial_4581_194638)" />
              <path d="M313.348 278.836C316.97 278.329 319.638 276.014 319.307 273.667C318.976 271.32 315.771 269.828 312.149 270.336C308.527 270.843 305.86 273.158 306.19 275.505C306.521 277.853 309.726 279.344 313.348 278.836Z" fill="#7405A3" />
              <path d="M318.92 273.256C319.251 275.457 316.657 277.713 313.18 278.153C309.703 278.648 306.613 277.218 306.282 274.961C305.951 272.705 308.545 270.504 312.021 270.064C315.498 269.624 318.588 271 318.92 273.256Z" fill="url(#paint51_radial_4581_194638)" />
              <path opacity="0.4" d="M336.524 261.535C336.965 264.507 333.544 267.423 328.908 268.083C324.273 268.744 320.244 266.818 319.803 263.901C319.361 260.93 322.783 258.014 327.418 257.353C332.054 256.693 336.138 258.564 336.524 261.535Z" fill="url(#paint52_radial_4581_194638)" />
              <path opacity="0.5" d="M328.696 267.06C332.318 266.552 334.986 264.238 334.655 261.891C334.324 259.543 331.119 258.052 327.498 258.559C323.876 259.067 321.208 261.381 321.539 263.729C321.87 266.076 325.074 267.568 328.696 267.06Z" fill="#012E56" />
              <path d="M334.261 261.425C334.592 263.626 331.999 265.882 328.522 266.322C325.045 266.817 321.955 265.386 321.624 263.13C321.293 260.929 323.886 258.673 327.363 258.233C330.84 257.738 333.93 259.224 334.261 261.425Z" fill="url(#paint53_radial_4581_194638)" />
              <g opacity="0.6">
                <path d="M286.857 340.112H285.312L290.389 328.502L290.554 328.391L353.245 270.835V272.981L291.603 329.382L286.857 340.112Z" fill="#223CAA" />
              </g>
              <path d="M145.911 363.333H98.01C94.8092 363.333 91.7188 361.957 89.5665 359.646C87.5798 357.555 86.6417 354.859 86.8072 351.998C87.6902 340.663 91.5532 318.542 93.6503 306.602L93.9814 304.786C94.8644 299.834 98.8378 296.697 104.467 296.697H124.499V299.999H104.467C102.59 299.999 98.0652 300.549 97.2374 305.391L96.9063 307.207C94.8644 319.038 91.0014 341.048 90.1184 352.273C89.9528 354.144 90.6702 355.96 91.9947 357.39C93.5951 359.041 95.7474 359.976 98.01 359.976H145.911C150.05 359.976 151.375 355.96 151.871 353.593C152.258 351.668 153.306 345.835 153.306 345.835L156.562 346.44C156.562 346.44 155.459 352.273 155.127 354.199C154.079 359.976 150.657 363.333 145.911 363.333Z" fill="#A9E6E9" />
              <path d="M145.911 363.333H98.01C94.8092 363.333 91.7188 361.957 89.5665 359.646C87.5798 357.555 86.6417 354.859 86.8072 351.998C87.6902 340.663 91.5532 318.542 93.6503 306.602L93.9814 304.786C94.8644 299.834 98.8378 296.697 104.467 296.697H124.499V299.999H104.467C102.59 299.999 98.0652 300.549 97.2374 305.391L96.9063 307.207C94.8644 319.038 91.0014 341.048 90.1184 352.273C89.9528 354.144 90.6702 355.96 91.9947 357.39C93.5951 359.041 95.7474 359.976 98.01 359.976H145.911C150.05 359.976 151.375 355.96 151.871 353.593C152.258 351.668 153.306 345.835 153.306 345.835L156.562 346.44C156.562 346.44 155.459 352.273 155.127 354.199C154.079 359.976 150.657 363.333 145.911 363.333Z" fill="url(#paint54_linear_4581_194638)" />
              <path d="M154.189 347.541L152.81 355.135C151.982 359.207 148.34 362.178 144.201 362.178H98.9483C90.9464 362.013 87.0833 357.391 88.1871 348.256C89.6219 336.976 94.8646 305.392 94.8646 305.392C95.4716 301.32 99.445 297.798 103.529 297.798L120.526 297.743" stroke="url(#paint55_linear_4581_194638)" strokeWidth="0.36043" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M125.935 292.68H124.169C123.396 292.68 122.789 292.074 122.789 291.304V289.433C122.789 288.663 123.396 288.058 124.169 288.058H126.431L125.935 292.68Z" fill="url(#paint56_radial_4581_194638)" />
              <path d="M112.745 333.068H110.206C109.434 333.068 108.827 332.463 108.827 331.693V329.822C108.827 329.052 109.434 328.446 110.206 328.446H111.917L112.745 333.068Z" fill="url(#paint57_radial_4581_194638)" />
              <path d="M125.438 300.769H123.506C122.899 300.769 122.347 300.273 122.347 299.613V297.137C122.347 296.532 122.844 295.981 123.506 295.981H125.824L125.438 300.769Z" fill="url(#paint58_radial_4581_194638)" />
              <path d="M158.108 344.679V346.219C158.108 347.1 157.39 347.815 156.507 347.815H153.472C152.589 347.815 151.872 347.1 151.872 346.219V344.679H158.108Z" fill="url(#paint59_radial_4581_194638)" />
              <path d="M262.961 342.478H117.104C115.338 342.478 114.179 340.937 113.793 339.176C113.131 335.765 111.862 328.887 111.255 325.365C110.979 323.714 112.689 322.229 114.4 322.229H277.419C277.419 322.229 272.453 335.27 270.852 339.176C269.252 343.138 264.782 342.478 262.961 342.478Z" fill="url(#paint60_radial_4581_194638)" />
              <path d="M253.083 49.3579L268.369 39.7835C268.7 38.2978 269.307 36.6471 269.307 36.6471L273.391 28.9435L288.622 19.094L275.93 5.0625L253.083 49.3579Z" fill="url(#paint61_linear_4581_194638)" />
              <path d="M88.6837 5.17285L77.5361 19.6995C82.834 22.946 89.8426 27.3481 89.8426 27.3481C89.8426 27.3481 93.9816 33.9511 95.9131 37.1976C96.3546 38.7933 96.5753 39.2335 96.9616 40.6092L112.414 49.4132L88.6837 5.17285Z" fill="url(#paint62_linear_4581_194638)" />
              <path d="M95.8027 297.963C95.306 300.99 91.8293 303.411 88.0214 303.411C84.2136 303.411 81.5095 300.825 82.5028 297.853C83.4962 294.827 87.5799 292.186 90.9463 292.186C94.3126 292.186 96.2441 294.992 95.8027 297.963Z" fill="url(#paint63_linear_4581_194638)" />
              <path d="M94.9749 297.027C94.7542 299.834 91.443 302.475 87.9663 302.475C84.4344 302.475 82.6133 300.329 82.6133 297.413C82.6133 294.441 87.58 292.13 90.6152 292.13C93.7608 292.075 95.2509 293.781 94.9749 297.027Z" fill="url(#paint64_linear_4581_194638)" />
              <path d="M94.754 296.037C94.3126 298.788 91.1118 301.044 87.5799 301.044C84.048 301.044 81.9509 298.788 82.8339 296.037C83.7169 293.285 86.9176 291.029 90.0081 291.029C93.0985 291.029 95.2507 293.23 94.754 296.037Z" fill="url(#paint65_linear_4581_194638)" />
              <path d="M238.348 322.449H277.254C280.676 322.449 283.435 319.697 283.435 316.286V281.84H235.865L238.348 322.449Z" fill="url(#paint66_linear_4581_194638)" />
              <path d="M249.771 294.11C247.619 294.11 245.798 292.349 245.798 290.148C245.798 288.002 247.564 286.187 249.771 286.187C251.924 286.187 253.745 287.947 253.745 290.148C253.689 292.349 251.924 294.11 249.771 294.11Z" fill="#101B68" />
              <path d="M249.771 294.495C247.398 294.495 245.356 292.57 245.356 290.093C245.356 287.617 247.288 285.691 249.771 285.691C252.255 285.691 254.186 287.617 254.186 290.093C254.186 292.57 252.2 294.495 249.771 294.495ZM249.771 286.682C247.84 286.682 246.239 288.278 246.239 290.203C246.239 292.129 247.84 293.725 249.771 293.725C251.703 293.725 253.303 292.129 253.303 290.203C253.303 288.278 251.703 286.682 249.771 286.682Z" fill="url(#paint67_linear_4581_194638)" />
              <path d="M249.771 307.096C247.619 307.096 245.798 305.336 245.798 303.135C245.798 300.989 247.564 299.173 249.771 299.173C251.924 299.173 253.745 300.934 253.745 303.135C253.689 305.391 251.924 307.096 249.771 307.096Z" fill="#101B68" />
              <path d="M249.771 307.536C247.398 307.536 245.356 305.611 245.356 303.134C245.356 300.658 247.288 298.732 249.771 298.732C252.255 298.732 254.186 300.658 254.186 303.134C254.186 305.611 252.2 307.536 249.771 307.536ZM249.771 299.723C247.84 299.723 246.239 301.319 246.239 303.244C246.239 305.17 247.84 306.766 249.771 306.766C251.703 306.766 253.303 305.17 253.303 303.244C253.303 301.319 251.703 299.723 249.771 299.723Z" fill="url(#paint68_linear_4581_194638)" />
              <path d="M272.122 288.553V315.846C272.122 317.276 273.28 318.377 274.66 318.377C276.095 318.377 277.199 317.221 277.199 315.846V288.608C277.199 287.178 276.04 286.077 274.66 286.077C273.225 286.022 272.122 287.178 272.122 288.553Z" fill="url(#paint69_radial_4581_194638)" />
              <path d="M260.202 288.553V315.846C260.202 317.276 261.361 318.377 262.74 318.377C264.175 318.377 265.279 317.221 265.279 315.846V288.608C265.279 287.178 264.12 286.077 262.74 286.077C261.361 286.022 260.202 287.178 260.202 288.553Z" fill="url(#paint70_radial_4581_194638)" />
              <path d="M272.839 292.57V295.212C272.839 296.202 273.667 296.972 274.605 296.972C275.599 296.972 276.371 296.147 276.371 295.212V292.57C276.371 291.58 275.543 290.81 274.605 290.81C273.667 290.81 272.839 291.58 272.839 292.57Z" fill="url(#paint71_linear_4581_194638)" />
              <path d="M260.974 308.912V311.553C260.974 312.544 261.802 313.314 262.74 313.314C263.733 313.314 264.506 312.489 264.506 311.553V308.912C264.506 307.922 263.678 307.151 262.74 307.151C261.747 307.096 260.974 307.867 260.974 308.912Z" fill="url(#paint72_linear_4581_194638)" />
              <path d="M210.369 281.785H283.435L283.38 278.924C283.38 274.357 281.503 270.285 273.501 270.395C262.464 270.56 223.724 270.56 215.998 270.615C214.011 270.615 211.969 272.266 211.638 274.302C211.031 278.869 210.369 281.785 210.369 281.785Z" fill="url(#paint73_radial_4581_194638)" />
              <path d="M182.389 346.771C232.526 346.771 273.17 332.457 273.17 314.801C273.17 297.144 232.526 282.831 182.389 282.831C132.252 282.831 91.6084 297.144 91.6084 314.801C91.6084 332.457 132.252 346.771 182.389 346.771Z" fill="url(#paint74_radial_4581_194638)" />
              <path opacity="0.5" d="M127.7 285.967H80.792L59.5454 270.175L80.6265 286.407L127.645 286.517L127.7 285.967Z" fill="#2B0691" />
              <path d="M238.072 279.969H126.597V279.859C126.597 276.008 129.742 272.871 133.605 272.871H230.953C234.927 272.871 238.072 276.063 238.072 279.969Z" fill="url(#paint75_linear_4581_194638)" />
              <path opacity="0.7" d="M143.925 272.871H133.605C129.742 272.871 126.597 276.008 126.597 279.859C126.597 279.859 126.817 276.338 130.515 274.247C131.177 273.862 132.502 273.366 133.329 273.366C135.978 273.256 143.925 272.871 143.925 272.871Z" fill="white" />
              <path d="M238.072 279.97H126.596L125.106 301.87H239.617L238.072 279.97Z" fill="url(#paint76_linear_4581_194638)" />
              <path d="M157.777 292.349H132.998C132.502 292.349 132.06 291.909 132.06 291.414V289.323C132.06 288.553 132.667 287.947 133.44 287.947H157.722C158.218 287.947 158.66 288.387 158.66 288.883V291.469C158.66 291.909 158.273 292.349 157.777 292.349Z" fill="url(#paint77_radial_4581_194638)" stroke="#C2E9F2" strokeWidth="0.242577" strokeMiterlimit="10" />
              <path d="M157.777 292.625H132.998C132.336 292.625 131.784 292.075 131.784 291.414V289.323C131.784 288.388 132.557 287.728 133.385 287.728H157.666C158.329 287.728 158.88 288.278 158.88 288.938V291.524C158.936 292.075 158.439 292.625 157.777 292.625ZM133.495 288.168C132.888 288.168 132.391 288.663 132.391 289.268V291.359C132.391 291.744 132.667 292.02 133.053 292.02H157.832C158.218 292.02 158.494 291.744 158.494 291.359V288.773C158.494 288.388 158.218 288.113 157.832 288.113L133.495 288.168Z" fill="url(#paint78_linear_4581_194638)" />
              <path d="M197.731 292.35H167.545C167.048 292.35 166.606 291.909 166.606 291.414V288.828C166.606 288.333 167.048 287.893 167.545 287.893H197.731C198.228 287.893 198.669 288.333 198.669 288.828V291.414C198.669 291.909 198.283 292.35 197.731 292.35Z" fill="url(#paint79_radial_4581_194638)" />
              <path d="M207.499 292.349H232.223C232.719 292.349 233.161 291.909 233.161 291.414V289.323C233.161 288.553 232.554 287.947 231.781 287.947H207.499C207.003 287.947 206.561 288.387 206.561 288.883V291.469C206.561 291.909 207.003 292.349 207.499 292.349Z" fill="url(#paint80_radial_4581_194638)" stroke="#C2E9F2" strokeWidth="0.242577" strokeMiterlimit="10" />
              <path d="M232.223 292.625H207.499C206.837 292.625 206.285 292.075 206.285 291.414V289.323C206.285 288.388 207.058 287.728 207.886 287.728H232.167C232.83 287.728 233.381 288.278 233.381 288.938V291.524C233.437 292.075 232.885 292.625 232.223 292.625ZM207.941 288.168C207.334 288.168 206.837 288.663 206.837 289.268V291.359C206.837 291.744 207.113 292.02 207.499 292.02H232.223C232.609 292.02 232.885 291.744 232.885 291.359V288.773C232.885 288.388 232.609 288.113 232.223 288.113L207.941 288.168Z" fill="url(#paint81_linear_4581_194638)" />
              <path d="M182.334 297.192C179.133 297.192 176.54 294.606 176.54 291.414C176.54 288.223 179.133 285.637 182.334 285.637C185.535 285.637 188.129 288.223 188.129 291.414C188.129 294.551 185.535 297.192 182.334 297.192Z" fill="url(#paint82_radial_4581_194638)" />
              <path d="M182.831 293.451C181.01 293.451 179.52 291.965 179.52 290.149C179.52 288.333 181.01 286.848 182.831 286.848C184.652 286.848 186.142 288.333 186.142 290.149C186.142 291.965 184.652 293.451 182.831 293.451Z" fill="url(#paint83_linear_4581_194638)" />
              <path d="M80.6262 281.73L29.3584 243.212L80.6262 281.069V281.73Z" fill="url(#paint84_linear_4581_194638)" />
              <path d="M76.1013 307.152C75.6046 307.152 75.2735 306.657 75.3839 306.217L80.5713 286.352L74.2801 305.116C74.0042 305.997 72.9005 306.382 72.1831 305.831L8.88477 251.852L73.1764 308.528H92.2708L76.1013 307.152Z" fill="url(#paint85_linear_4581_194638)" />
              <path d="M253.083 49.3572H112.524L88.7388 5.17188H275.985L253.083 49.3572Z" fill="url(#paint86_linear_4581_194638)" />
              <path d="M112.524 49.3576L88.7388 5.11719H194.199L95.5266 6.60287C94.0918 6.6579 93.264 8.08856 93.8159 9.35414L112.524 49.3576Z" fill="url(#paint87_linear_4581_194638)" />
              <path d="M80.6265 281.73V281.069L113.904 281.73H80.6265Z" fill="url(#paint88_radial_4581_194638)" />
              <path d="M239.833 330.486H123.579V343.637L126.715 354.973C126.995 355.908 127.891 356.568 128.843 356.568H234.961C236.025 356.568 236.865 355.853 237.089 354.918L239.777 343.747V330.486H239.833Z" fill="url(#paint89_linear_4581_194638)" />
              <path d="M239.945 343.636H123.691L115.739 337.363H247.057L239.945 343.636Z" fill="url(#paint90_linear_4581_194638)" />
              <path d="M156.339 351.945H131.475C130.915 351.945 130.411 351.505 130.411 350.9V348.534C130.411 347.984 130.859 347.488 131.475 347.488H156.339C156.899 347.488 157.403 347.928 157.403 348.534V350.9C157.347 351.505 156.899 351.945 156.339 351.945Z" fill="url(#paint91_linear_4581_194638)" />
              <path d="M130.411 351.01V348.534C130.411 347.984 130.859 347.488 131.475 347.488H156.339C156.899 347.488 157.403 347.928 157.403 348.534V351.01C157.403 351.01 157.291 349.744 157.235 349.194C157.123 348.479 156.787 348.314 156.059 348.314H131.699C131.195 348.314 130.747 348.479 130.523 349.249L130.411 351.01Z" fill="url(#paint92_linear_4581_194638)" />
              <path d="M196.882 351.945H166.418C165.858 351.945 165.354 351.505 165.354 350.9V348.534C165.354 347.984 165.802 347.488 166.418 347.488H196.938C197.498 347.488 198.002 347.928 198.002 348.534V350.9C197.946 351.505 197.498 351.945 196.882 351.945Z" fill="url(#paint93_linear_4581_194638)" />
              <path d="M207.018 351.945H231.881C232.441 351.945 232.945 351.505 232.945 350.9V348.534C232.945 347.984 232.497 347.488 231.881 347.488H207.018C206.458 347.488 205.954 347.928 205.954 348.534V350.9C205.954 351.505 206.402 351.945 207.018 351.945Z" fill="url(#paint94_linear_4581_194638)" />
              <path d="M205.954 351.01V348.534C205.954 347.984 206.402 347.488 207.018 347.488H231.881C232.441 347.488 232.945 347.928 232.945 348.534V351.01C232.945 351.01 232.833 349.744 232.777 349.194C232.665 348.479 232.329 348.314 231.601 348.314H207.242C206.738 348.314 206.29 348.479 206.066 349.249L205.954 351.01Z" fill="url(#paint95_linear_4581_194638)" />
              <path d="M165.411 351.01V348.534C165.411 347.984 165.859 347.488 166.475 347.488H196.938C197.498 347.488 198.002 347.928 198.002 348.534V351.01C198.002 351.01 197.89 349.744 197.834 349.194C197.722 348.479 197.386 348.314 196.658 348.314H166.755C166.251 348.314 165.803 348.479 165.579 349.249L165.411 351.01Z" fill="url(#paint96_linear_4581_194638)" />
              <path d="M246.161 337.584H116.635C115.627 337.584 114.899 336.759 115.011 335.823L115.627 329H247.001L247.785 335.768C247.841 336.759 247.113 337.584 246.161 337.584Z" fill="url(#paint97_linear_4581_194638)" />
              <path opacity="0.7" d="M128.969 280.024H126.652L125.658 294.386L126.983 280.795C126.983 280.52 127.203 280.355 127.479 280.3L128.969 280.024Z" fill="white" />
              <path d="M29.2485 88.5911L29.0829 91.0122C29.0829 91.0122 26.434 90.7371 24.3921 90.1318C20.4187 88.9213 17.7146 87.7107 15.6728 86.225C13.7412 84.7943 13.5757 84.3541 13.5757 84.3541L14.1275 81.5479C14.1275 81.5479 15.1761 83.8039 21.4121 86.3901C26.1029 88.316 29.2485 88.5911 29.2485 88.5911Z" fill="url(#paint98_linear_4581_194638)" />
              <path d="M339.394 88.3156L339.449 90.5166C339.449 90.5166 341.932 90.2415 343.808 89.6913C347.506 88.5908 350.044 87.4902 351.921 86.0596C353.742 84.739 353.852 84.2988 353.852 84.2988L353.411 81.6025C353.411 81.6025 352.362 83.8035 346.513 86.2247C342.208 88.0405 339.394 88.3156 339.394 88.3156Z" fill="url(#paint99_linear_4581_194638)" />
            </g>
            {/* bottom panel */}
            <MultiplierBtn multiplier={multiplier} handleChangeMultiplier={handleChangeMultiplier} isAnimating={isAnimating} isSafari={isSafari} />
            {/* <g id="svg_173"> */}
            {/*  <svg width="89" height="26" viewBox="0 0 92 26" fill="none" id="svg_165" x="142.2804" y="266" xmlns="http://www.w3.org/2000/svg"> */}
            {/*    <g clipPath="url(#svg_166)" id="svg_168"> */}
            {/*      <path d="m2.22814,21.7263l8.25566,-18.88653c0.3393,-0.79882 1.0744,-1.2553 1.9226,-1.2553l64.9711,0c0.8481,0 1.6398,0.51353 1.9225,1.31236l7.9164,18.88657c0.5655,1.4265 -0.4523,2.9671 -1.9225,2.9671l-81.08666,0c-1.52674,-0.0571 -2.60111,-1.5977 -1.9791,-3.0242z" fill="#0C056B" id="svg_172" /> */}
            {/*      <path d="m13.6504,1.01367c-1.4137,0 -2.7142,0.85589 -3.2797,2.16825l-8.65148,18.43008c-0.45236,1.0271 -0.45236,3.6518 1.41365,3.6518l83.06573,0c1.4702,0 1.866,-2.5677 1.4137,-3.6518l-8.3123,-18.37302c-0.5654,-1.36942 -1.866,-2.22531 -3.3362,-2.22531l-62.3134,0z" fill="url(#svg_8)" id="svg_171" /> */}
            {/*      <path d="m2.22814,21.7263l8.25566,-18.88653c0.3393,-0.79882 1.0744,-1.2553 1.9226,-1.2553l64.9711,0c0.8481,0 1.6398,0.51353 1.9225,1.31236l7.9164,18.88657c0.5655,1.4265 -0.4523,2.9671 -1.9225,2.9671l-81.08666,0c-1.52674,-0.0571 -2.60111,-1.5977 -1.9791,-3.0242z" fill="url(#svg_7)" id="svg_170" /> */}
            {/*      <path d="m85.0677,23.8374l-80.40807,0c-1.13092,0 -1.92256,-1.1983 -1.47019,-2.2253l7.57716,-17.28893c0.5089,-1.14118 1.6398,-1.94001 2.8838,-1.94001l62.4831,0c1.3005,0 2.4314,0.79883 2.9404,1.94001l7.2944,17.51713c0.3958,0.97 -0.2828,1.9971 -1.3006,1.9971zm-71.4738,-23.16601c-1.8661,0 -3.61897,1.14118 -4.35407,2.85295l-8.6515,19.79946c-0.45237,1.0842 0.28273,2.2253 1.47019,2.2253l85.32758,0c1.1309,0 1.9225,-1.1411 1.4702,-2.2253l-8.2557,-19.68534c-0.7351,-1.82589 -2.488,-2.96707 -4.4106,-2.96707l-62.5961,0z" fill="url(#svg_6)" id="svg_169" /> */}
            {/*    </g> */}
            {/*    <defs> */}
            {/*      <clipPath id="svg_166"> */}
            {/*        <rect width="88.4941" height="24.9348" fill="white" y="0.67041" x="0.47522" id="svg_167" /> */}
            {/*      </clipPath> */}
            {/*    </defs> */}
            {/*    <foreignObject x="0" y="6" width="100%" height="100%"> */}
            {/*      <MiddleScreen countdown={countdown} userEnergy={userEnergy} maxEnergy={maxEnergy} /> */}
            {/*    </foreignObject> */}
            {/*  </svg> */}
            {/* </g> */}
            {/* top panel */}
            <SpinResultBlock isSpinning={isSpinning} />
          </g>
        </g>
      </svg>
      <div className="mt-[-104px] flex justify-center flex-col items-center">
        <div className="z-3 translate-y-[10px]">
          <SpinsScreen />
        </div>
        <div>
          <BottomPart />
        </div>
      </div>
    </>
  );
}
export default Machine;
