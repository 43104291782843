import {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Howl } from 'howler';
import eventEmitter, { emitGameEvent, GameEvents } from '../../pages/Home/eventEmiter';
import { useEnergyContext } from '../../providers/EnergyProvider';
import { useWebSocketContext } from '../../providers/WebSocketProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  changeGame,
  getActiveSlotEffects,
  getSlotMachineSpinData,
  setSlotMachineMultiplier,
} from '../../store/reducers/aviator/asyncActions';
import { updateProfileCoins, updateProfileEnergy, updateUserData } from '../../store/reducers/profile/asyncActions';
import { Purchase, SlotMachineSpinResponse } from '../../types';
import ActiveBoosterCard from '../ActiveBoosterCard';
import AutopilotCard from '../AutopilotCard';
import Drawer from '../Drawer';
import AutopilotModal from './AutopilotModal';
import AviatorBoostersModal from './AviatorBoostersModal';
import Machine from './Machine/Machine';
import { MultiplierT } from './Machine/slotConstants';
import { randomIntFromInterval } from '../../helpers';
import slotReelStart from '../../assets/sounds/sc_slot_reelStart.wav';
import slotMultiplierX2 from '../../assets/sounds/sc_slot_multiplier_x2.wav';
import slotMultiplierX3 from '../../assets/sounds/sc_slot_multiplier_x3.wav';
import slotMultiplierX5 from '../../assets/sounds/sc_slot_multiplier_x5.wav';
import slotReelSpin from '../../assets/sounds/sc_slot_reelSpin.wav';
import slotReelStop1 from '../../assets/sounds/sc_slot_reelStop1.wav';
import slotReelStop2 from '../../assets/sounds/sc_slot_reelStop2.wav';
import slotReelStop3 from '../../assets/sounds/sc_slot_reelStop3.wav';

type PurchasesModalType = 'autopilot' | 'aviatorBoosters'

const sounds = {
  spinStart: new Howl({ src: [slotReelStart] }),
  multiplier2: new Howl({ src: [slotMultiplierX2] }),
  multiplier3: new Howl({ src: [slotMultiplierX3] }),
  multiplier5: new Howl({ src: [slotMultiplierX5] }),
  reelSpinning: new Howl({ src: [slotReelSpin] }),
  reelStop1: new Howl({ src: [slotReelStop1] }),
  reelStop2: new Howl({ src: [slotReelStop2] }),
  reelStop3: new Howl({ src: [slotReelStop3] }),
};
function SlotMachineScreen() {
  const dispatch = useAppDispatch();
  const { webSocket } = useWebSocketContext();

  const { userEnergy } = useEnergyContext();
  const reduxStateRef = useRef<SlotMachineSpinResponse | null>(null);
  const { slotMachineSpin, slotMachineType, slotMachineMultiplier: multiplier } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const { purchases } = useAppSelector(
    ({ shop }) => shop,
  );

  reduxStateRef.current = slotMachineSpin;
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [purchasesModal, setPurchasesModal] = useState<{open: boolean; type?: PurchasesModalType}>({ open: false });

  let endGameTimeout: ReturnType<typeof setTimeout> | null = null;
  const spinTimeout: ReturnType<typeof setTimeout> | null = null;
  useEffect(() => () => {
    if (endGameTimeout) {
      clearTimeout(endGameTimeout);
    }
    if (spinTimeout) {
      clearTimeout(spinTimeout);
    }
  }, []);

  const endGame = async (spinData: SlotMachineSpinResponse) => {
    sounds.reelSpinning.stop();
    [sounds.reelStop1, sounds.reelStop2, sounds.reelStop3][randomIntFromInterval(0, 2)].play();
    const spinEffects = spinData?.effects;

    let connected = webSocket?.server?.readyState !== WebSocket.OPEN;

    if (spinEffects?.length) {
      const spinEffect = spinEffects[0];
      dispatch(updateUserData(spinData.user));
      switch (spinEffect.name) {
        case 'regenerate_energy':
          eventEmitter.emit(GameEvents.OVERLAY_SHOW_ENERGY_ANIMATION);
          eventEmitter.emit(GameEvents.SLOT_HIDE_MIDDLE);
          break;
        case 'reward_multiplier':
          eventEmitter.emit(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, spinEffect.amount);
          eventEmitter.emit(GameEvents.SLOT_HIDE_MIDDLE);
          break;
        case 'bonus':
          dispatch(updateProfileCoins({ coins: spinData.user.coins, replace: true }));
          break;
        case 'aviator':
          if (!connected) {
            connected = await webSocket.connect();
          }

          if (connected) {
            eventEmitter.emit(GameEvents.OVERLAY_GALAXY_TRANSITION);
            endGameTimeout = setTimeout(() => {
              dispatch(changeGame('aviator'));
            }, 1000 * 1.4);
          }

          break;
        default:
          break;
      }
    }
    if (spinEffects?.[0]?.name !== 'aviator') {
      setIsSpinning(false);
    }
    dispatch(getActiveSlotEffects());
  };
  const spinReels = async () => {
    sounds.spinStart.play();
    sounds.spinStart.on('end', () => {
      sounds.reelSpinning.loop(true);
      sounds.reelSpinning.play();
    });
    if (userEnergy.userEnergy >= userEnergy.energyForSpin) {
      setIsAnimating(true);
      setIsSpinning(true);
      const resp = await dispatch(getSlotMachineSpinData({ multiplier }));

      if (resp?.payload?.slots) {
        dispatch(updateProfileEnergy({ energy: -(userEnergy.energyForSpin * multiplier), replace: false }));
        emitGameEvent({ event: GameEvents.SLOT_START_SPIN, targetSlots: (resp.payload as SlotMachineSpinResponse).slots.map((slot) => slot.type) });
      } else {
        setIsAnimating(false);
        setIsSpinning(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getActiveSlotEffects());
    const handleStopEvent = () => {
      if (reduxStateRef.current) {
        endGame(reduxStateRef.current);
      }
    };
    eventEmitter.on(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    return () => {
      eventEmitter.off(GameEvents.SLOT_STOP_SPIN, handleStopEvent);
    };
  }, []);
  useEffect(() => {
    const startAnimation = () => {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 50);
    };
    eventEmitter.on(GameEvents.ENERGY_HIT_SCREEN, startAnimation);
    return () => {
      eventEmitter.off(GameEvents.ENERGY_HIT_SCREEN, startAnimation);
    };
  }, []);

  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsAnimating(false);
    }, 500);
  }, [isAnimating]);
  useEffect(() => {
    dispatch(getActiveSlotEffects());
  }, []);
  useEffect(() => {
    if (slotMachineType === 'auto' && !isSpinning) {
      setTimeout(() => {
        spinReels();
      }, 2000);
    }
  }, [slotMachineType, isSpinning]);

  const renderPurchase = useCallback((p: Purchase) => {
    const isAutopilot = p.store_item_level.effects.findIndex((e) => e.name === 'autopilot') !== -1;

    if (p.is_active) {
      if (isAutopilot) {
        return <AutopilotCard key={p.id} purchase={p} onClick={() => setPurchasesModal({ open: true, type: 'autopilot' })} />;
      }

      return <ActiveBoosterCard key={p.id} purchase={p} onBoosterClick={() => setPurchasesModal({ open: true, type: 'aviatorBoosters' })} />;
    }

    return null;
  }, []);

  const temporaryBoosters = useMemo(() => {
    if (!purchases) {
      return [];
    }

    return purchases.filter((item) => item.is_active && item.store_item_level.store_item.type === 'temporary');
  }, [purchases]);

  const onPurchaseModalClose = useCallback(() => {
    setPurchasesModal((prev) => ({ open: false, type: prev.type }));
  }, []);

  const changeMultiplier = (val: MultiplierT) => {
    switch (val) {
      case 2:
        sounds.multiplier2.play();
        break;
      case 5:
        sounds.multiplier3.play();
        break;
      case 10:
        sounds.multiplier5.play();
        break;
      default:
        break;
    }
    dispatch(setSlotMachineMultiplier(val));
  };
  return (
    <div
      className="bg-cover absolute top-0 w-screen h-screen flex flex-col items-center justify-end select-none"
      style={{
        backgroundImage: 'url(/images/slotBg.png)',

      }}
    >
      <div className="w-full h-fit fixed bottom-5">
        {/* <MultiplierText rewardMultipler={rewardMultipler} /> */}
        {/* <motion.div */}
        {/*  className="box" */}
        {/*  animate={{ y: isAnimating ? 2 : 0 }} */}
        {/*  transition={{ */}
        {/*    type: 'spring', */}
        {/*    stiffness: 1000, */}
        {/*    damping: 20, */}
        {/*  }} */}
        {/* > */}
        {/* <AnimatedBox isAnimating={isAnimating}> */}
        <div
          style={{
            position: 'relative',
            transform: 'none',
          }}
        >
          <Machine
            onSpin={() => {
              spinReels();
            }}
            isSpinning={isSpinning}
            multiplier={multiplier}
            setMultiplier={changeMultiplier}
          />
        </div>
      </div>
      <div className="absolute top-[5.1rem] right-[0.7rem] p-1 rounded flex flex-col gap-[24px] max-h-[58vh] overflow-y-scroll">
        {purchases?.map((purchase) => renderPurchase(purchase))}
      </div>
      <Drawer
        side="bottom"
        open={purchasesModal.open}
        onClose={onPurchaseModalClose}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-fit shadow-xl rounded-t-[20px] before:rounded-t-[20px] bg-gradient-to-b from-[#4344C1] to-[#2D2EA0] z-[9] relative stroke-gray-10"
      >
        {purchasesModal.type === 'autopilot' && (
        <AutopilotModal
          onClose={onPurchaseModalClose}
        />
        )}
        {purchasesModal.type === 'aviatorBoosters' && (
          <AviatorBoostersModal
            items={temporaryBoosters}
            onClose={onPurchaseModalClose}
          />
        )}
      </Drawer>
    </div>
  );
}

export default SlotMachineScreen;
