import { ReactComponent as BrainSvg } from '../../assets/boosters/brain.svg';
import { useAutopilotContext } from '../../providers/AutopilotProvider';
import { Purchase } from '../../types';

type AutopilotCardProps = {
  onClick: () => void;
  purchase: Purchase
}

function AutopilotCard({ onClick, purchase }: AutopilotCardProps) {
  const { autopilot, time } = useAutopilotContext();

  if (!autopilot) {
    return null;
  }

  return (
    <div className="flex flex-col items-center max-w-[60px]" onClick={onClick}>
      <BrainSvg width={45} height={45} />
      <div className="font-Bangers text-xs uppercase leading-[12.77px]">
        {purchase.store_item_level.store_item.name}
      </div>
      {!time.isFinished && (
      <div className="font-Barlow text-xs uppercase leading-[12px]">
        <div>
          {!!time.remain.hours && (
          <>
            <span>
              {time.remain.hours}
            </span>
            :
          </>
          )}
          <span>
            {time.remain.formatedMinutes}
          </span>
          :
          <span>
            {time.remain.formatedSecond}
          </span>
        </div>
      </div>
      )}
    </div>
  );
}

export default AutopilotCard;
