import React from 'react';
import AvatarPlaceholder from '../../../assets/images/avatar-placeholder-v2.svg';
import { ReactComponent as Coin } from '../../../assets/images/coin-size.svg';
import { ReferralT } from '../../../store/reducers/referrals/types';
import { formatNumbers } from '../../../helpers';

type FriendsCardProps = {
  referral: ReferralT
}
function FriendCard({ referral }: FriendsCardProps) {
  return (
    <div className="bg-black-light-bg p-3 pt-[.4rem] rounded-10 flex justify-between w-full max-h-[100px]">
      <div className="flex flex-col gap-[6px] overflow-hidden justify-between">
        <img src={AvatarPlaceholder} alt="avatar" className="rounded max-w-[18px] max-h-[18px] h-fit" />
        <div className="text-base font-bold line-normal text-ellipsis whitespace-nowrap mt-[0.1rem]">
          {referral.username}
        </div>
      </div>
      <div className="flex flex-col items-end justify-between gap-[6px]">
        <div className="text-gray-300 text-sm">
          Total profit
        </div>
        <div className="text-base flex items-center gap-[1px] pr-1 mt-[-.15rem] line-normal font-bold">
          <span className="pr-1">
            {formatNumbers(referral.coins || 0, 3, false)}
          </span>
          <Coin />
        </div>
      </div>
    </div>
  );
}
export default FriendCard;
