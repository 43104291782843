import AlienIcon from '../../icons/Alien';
import BrainBotIcon from '../../icons/BrainBot';
import CoinIcon from '../../icons/Coin';
import CrashGuardIcon from '../../icons/CrashGuard';
import EnergyCapacityIcon from '../../icons/EnergyCapacity';
import UFOIcon from '../../icons/UFO';
import { MultiColorIconProps } from '../../types/icons';

const getStoreItemIcon = (type: string, iconProps?: MultiColorIconProps) => {
  switch (type) {
    case 'coin':
      return <CoinIcon {...iconProps} />;
    case 'brain-bot':
      return <BrainBotIcon {...iconProps} />;
    case 'ufo':
      return <UFOIcon {...iconProps} />;
    case 'crash-guard':
      return <CrashGuardIcon {...iconProps} />;
    case 'energy':
      return <EnergyCapacityIcon {...iconProps} />;
    case 'alien':
      return <AlienIcon {...iconProps} />;
    default:
      return <CoinIcon {...iconProps} />;
  }
};

export default getStoreItemIcon;
