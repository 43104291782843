import { useMemo } from 'react';
import { Purchase } from '../../types';
import Button from '../Button';
import { IsActiveTimerButton } from '../ShopComponents/ShopButtons';
import getStoreItemIcon from '../utils/getStoreItemIcon';

type AviatorBoostersModalProps = {
    items: Purchase[]
    onClose: () => void;
}

type GroupedDict = {
  [k: string]: {
    name: string;
    items: Purchase[]
  }
}

function AviatorBoostersModal({ items, onClose }: AviatorBoostersModalProps) {
  const grouped = useMemo(() => items.reduce((groupes, item) => {
    const { category } = item.store_item_level.store_item;

    if (!groupes[category.id]) {
      groupes[category.id] = {
        name: category.name,
        items: [item],
      };
    } else {
      groupes[category.id].items.push(item);
    }

    return groupes;
  }, {} as GroupedDict), []);

  return (
    <div className="pt-2 pb-6 px-4">

      {Object.entries(grouped).map(([id, group]) => (
        <div key={id}>
          <div className="my-4 text-lg font-medium">{group.name}</div>
          <div className="flex flex-col gap-4">
            {group.items.map((item) => (
              <div key={item.id} className="flex bg-[#FFFFFF26] p-3 rounded-10 gap-4">
                <div className="stroke-[none]">{getStoreItemIcon(item.store_item_level.store_item.icon, { colors: { background: '#37389C', glow: '#FFFFFF' } })}</div>
                <div className="flex flex-col text-start">
                  <div className="leading-[16px] text-sm font-bold">{item.store_item_level.store_item.name}</div>
                  <div className="mt-2 leading-[14px] text-xs text-[#FFFFFFCC] mb-4">{item.store_item_level.store_item.description}</div>
                  <IsActiveTimerButton expiresAt={item.expires_at} className="mt-auto mb-0 font-Barlow" />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Button onClick={onClose} className="btn neutral py-[12.5px] leading-[19px] font-normal mt-6">Close</Button>
    </div>
  );
}

export default AviatorBoostersModal;
