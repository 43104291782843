/* eslint-disable */
import { useEffect, useState } from 'react';
import Portal from '../Components/Portal';
import { WSEvents } from '../constants/ws';
import { useWebSocketContext, } from '../providers/WebSocketProvider';
import { useAppSelector } from '../store';

function EstablishConnectionModal() {
  const { webSocket } = useWebSocketContext()
  const { activeGame } = useAppSelector(({aviator}) => aviator)
  
  const [connected, setConnected] = useState(webSocket.server?.readyState === WebSocket.OPEN);
  
    useEffect(() => {
      const onConnected = () => {
        setConnected(true);
      };
  
      const onConnecting = () => {
        setConnected(false);
      };
  
      window.addEventListener(WSEvents.CONNECTING, onConnecting);
      window.addEventListener(WSEvents.CONNECTED, onConnected);
  
      return () => {
        window.removeEventListener(WSEvents.CONNECTING, onConnecting);
        window.removeEventListener(WSEvents.CONNECTED, onConnected);
        webSocket.cleanup();
      };
    }, []);
  
  if (connected || activeGame === 'aviator') {
    return null;
  }

  return <Portal>
    <div className="fixed inset-0 p-6 bg-black bg-opacity-60 flex justify-center items-center z-[5]">
      <div className="bg-white p-6 rounded-md shadow-md text-center">
        <p>
          Establishing connection
        </p>
      </div>
    </div>
  </Portal>
}

export default EstablishConnectionModal;
