import { ReactComponent as Bronze } from '../../assets/leaderboard/BronzeLeague.svg';
import { ReactComponent as Challenger } from '../../assets/leaderboard/ChallengerLeague.svg';
import { ReactComponent as Diamond } from '../../assets/leaderboard/DiamondLeague.svg';
import { ReactComponent as Gold } from '../../assets/leaderboard/GoldLeague.svg';
import { ReactComponent as Legendary } from '../../assets/leaderboard/LegendaryLeague.svg';
import { ReactComponent as Master } from '../../assets/leaderboard/MasterLeague.svg';
import { ReactComponent as Platinum } from '../../assets/leaderboard/PlatinumLeague.svg';
import { ReactComponent as Silver } from '../../assets/leaderboard/SilverLeague.svg';
import { LeaguesUnion } from '../../types';

function LeagueIcon({ league }: { league: LeaguesUnion }) {
  const getLeagueIcon = () => {
    switch (league) {
      case 'Bronze':
        return <Bronze />;
      case 'Silver':
        return <Silver />;
      case 'Gold':
        return <Gold />;
      case 'Platinum':
        return <Platinum />;
      case 'Diamond':
        return <Diamond />;
      case 'Challenger':
        return <Challenger />;
      case 'Legendary':
        return <Legendary />;
      case 'Master':
        return <Master />;
      default:
        return <Bronze />;
    }
  };
  return (
    <div className="h-[inherit] w-[inherit] border-red">
      {getLeagueIcon()}
    </div>
  );
}

export default LeagueIcon;
