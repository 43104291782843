import { ReactComponent as Coin } from '../../../assets/images/coin-size-26x26.svg';
import Button from '../../../Components/Button';
import Drawer from '../../../Components/Drawer';
import LeagueIcon from '../../../Components/LeagueIcon';
import { numberWithCommas } from '../../../helpers';
import {
  LeaguesSetting,
  SettingsResponse,
} from '../../../store/reducers/settings/types';

type LeaguesModalProps = {
  leagues: SettingsResponse['leagues'];
  open: boolean;
  onClose: () => void;
};
function LeaguesModal({ leagues, onClose, open }: LeaguesModalProps) {
  return (
    <Drawer
      side="bottom"
      open={open}
      onClose={onClose}
      hideClose
      wrapperClassName="flex flex-col overflow-y-scroll h-screen shadow-xl combined-page-bg z-[9] relative stroke-gray-10 text-start stroke-[none]"
    >
      <div className="mt-4 px-4">
        <div className="flex justify-between text-start items-center">
          <div className="text-[22px] font-bold pt-1 ml-[-1px] font-Bangers"> About ranks </div>
          <div>
            <Button
              className="bg-blue-500 p-2.5 rounded-10"
              pureClass
              onClick={onClose}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5625 4.0625L14.4375 14.9375M14.4375 4.0625L3.5625 14.9375"
                  stroke="#fff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-[9px] mt-5 stroke-[none]">
          {leagues.map((league) => (
            <LeagueCard
              name={league.name}
              from={league.from}
              key={league.name}
            />
          ))}
        </div>
      </div>
    </Drawer>
  );
}
export default LeaguesModal;

function LeagueCard({ name, from }: LeaguesSetting) {
  return (
    <div
      className="gradient-border primary flex justify-between items-center px-4 py-2 rounded-10 bg-leagueCardBg"
    >
      <div>
        <span className="text-lg font-Bangers">{name}</span>
        <div className="flex items-center gap-2 text-sm">
          from
          {' '}
          {numberWithCommas(from)}
          <Coin width=".9rem" height=".9rem" />
        </div>
      </div>
      <div className="max-h-[3.1rem] max-w-[3.1rem] border-none flex items-center">
        <LeagueIcon league={name} />
      </div>
    </div>
  );
}
