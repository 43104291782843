import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Loader from './Components/Loader';
import AppWrapper from './Layouts/AppWrapper';
import AutopilotProvider from './providers/AutopilotProvider';
import EnergyProvider from './providers/EnergyProvider';
import MixpanelProvider from './providers/MixpanelProvider';
import WebSocketProvider from './providers/WebSocketProvider';
import RoutesComponent from './Router';
import { store } from './store';

const tg = window?.Telegram?.WebApp;

function App() {
  useEffect(() => {
    tg.ready();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tg.lockOrientation();
  }, []);
  const initTgData = process.env.REACT_APP_ENV === 'production'
    ? tg.initData
    : tg.initData || process.env.REACT_APP_DEV_TG_INIT_DATA;

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          {initTgData // wait for telegram initialization
            ? (
              <MixpanelProvider>
                <AppWrapper>
                  <WebSocketProvider>
                    <EnergyProvider>
                      <AutopilotProvider>
                        <RoutesComponent />
                      </AutopilotProvider>
                    </EnergyProvider>
                  </WebSocketProvider>
                </AppWrapper>
              </MixpanelProvider>
            )
            : <Loader progress={20} />}
        </Router>
      </Provider>
    </div>
  );
}

export default App;
