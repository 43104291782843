import { MultiplierT } from './slotConstants';

type AllWinsBannerProps = {
    multiplier: MultiplierT
    isAnimating: boolean,
    scale: {
        scaleX: number,
        scaleY: number
    }
    isSafari: boolean
}
function AllWinsBanner({
  multiplier, isAnimating, scale, isSafari,
}: AllWinsBannerProps) {
  const machineContentRect = document.getElementById('machine-content')?.getBoundingClientRect();

  if (!isAnimating || !machineContentRect) {
    return null;
  }

  const width = machineContentRect.width * 0.895;
  const height = machineContentRect.height / 3 - 10;
  const top = machineContentRect.height / 2 - 3;
  const left = 30 * scale.scaleX;
  const fontSize = 30 * scale.scaleX;

  return (
    <div
      style={{
        height: isSafari ? height / scale.scaleY : height,
        width: isSafari ? width / scale.scaleY : width,
        top: isSafari ? top / (scale.scaleY - 0.05) : top,
        left: `${left}px`,
        fontSize: `${fontSize}px`,
      }}
      className="all-wins-box flex items-center justify-center"
    >
      <div className="font-Bangers all-wins-box-text uppercase ml-[10%] w-full">
        all wins
        {' '}
        x
        {multiplier}
      </div>
    </div>
  );
}

export default AllWinsBanner;
