import { useCallback, useEffect, useState } from 'react';

type UseDateProgressOptions = {
  onTick?: (remain: number, elapsed: number) => void
  onFinish?: () => void;
}

type DateProgressState = {
    elapsed: number;
    remain: number
    isFinished: boolean;
    startTime: number;
    endTime: number;
}

export type DateProgressReturnType = {
  elapsed: ReturnType<typeof formatTime>
  remain: ReturnType<typeof formatTime>
  isFinished: boolean
  start: (startTime: number, endTime: number) => void;
}

const formatTime = (time: number) => {
  // eslint-disable-next-line no-param-reassign
  time /= 1000;

  const hours = time ? Math.floor(time / 3600) : 0;
  const minutes = time ? Math.floor((time % 3600) / 60) : 0;
  const seconds = time ? Math.round(time % 60) : 0;
  const formatedSecond = seconds < 10 ? `0${seconds}` : seconds.toFixed(0);
  const formatedMinutes = minutes < 10 ? `0${minutes}` : minutes.toFixed(0);

  return {
    hours,
    minutes,
    seconds,
    formatedMinutes,
    formatedSecond,
  };
};

export const useDateProgress = (options?: UseDateProgressOptions): DateProgressReturnType => {
  const [time, setTime] = useState<DateProgressState>(() => ({
    elapsed: 0,
    remain: 0,
    isFinished: true,
    endTime: 0,
    startTime: 0,
  }));

  const start = useCallback((startTime: number, endTime: number) => {
    setTime((prev) => {
      if (!prev.isFinished) {
        return prev;
      }

      const currentTime = Math.floor(Date.now() / 1000) * 1000;
      const sTime = Math.floor(startTime / 1000) * 1000;
      const eTime = Math.floor(endTime / 1000) * 1000;

      const remain = eTime - currentTime;
      const elapsed = currentTime - sTime;
      const isFinished = remain <= 0;

      return {
        elapsed,
        remain,
        isFinished,
        startTime,
        endTime,
      };
    });
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!time.isFinished) {
      timer = setInterval(() => {
        setTime((prev) => {
          const currentTime = Math.floor(Date.now() / 1000) * 1000;

          const remain = prev.endTime - currentTime;
          const elapsed = currentTime - prev.startTime;
          const isFinished = remain <= 0;

          if (isFinished) {
            options?.onFinish?.();
          }

          if (!isFinished) {
            options?.onTick?.(remain, elapsed);
          }

          return {
            ...prev,
            elapsed,
            remain,
            isFinished,
          };
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time.isFinished]);

  return {
    elapsed: formatTime(time.elapsed), remain: formatTime(time.remain), isFinished: time.isFinished, start,
  };
};
