import { useLocation } from 'react-router-dom';
import Pages from '../../Router/pages';
import { useAppSelector } from '../../store';
import CoinsInfo from './CoinsInfo';
import EnergyInfo from './EnergyInfo';
import UserLeague from './UserLeague';

const energyInfoPages: string[] = [Pages.store, Pages.quests, Pages.leaderbord];
const userLeaguePages: string[] = [Pages.friends, Pages.store, Pages.home];

function Header() {
  const location = useLocation();

  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );

  const isUserLeagueDisplayed = userLeaguePages.includes(location.pathname);
  const isEnergyInfoDisplayed = energyInfoPages.includes(location.pathname);

  return (
    <div
      id="header"
      className="py-2 flex justify-between items-end pr-4 pl-4 text-white text-xs z-[3] relative w-full"
    >
      <div
        className="w-full rounded-[14px] bg-[#1212141F] border-[#ffffff33] border-[0.5px] flex justify-between py-[10px] px-[8px] overflow-hidden"
        style={{
          backdropFilter: 'blur(40px)',
        }}
      >
        {isUserLeagueDisplayed && <UserLeague />}
        {isEnergyInfoDisplayed && <EnergyInfo />}
        <CoinsInfo coins={userData?.coins || 0} />
      </div>
    </div>
  );
}
export default Header;
