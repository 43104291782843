import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AuthResponse } from '../../../types';
import { axiosInstance } from '../../index';
import { createUser } from '../profile/asyncActions';

const verifyUser = async (initData: string) => {
  const requestData = process.env.REACT_APP_ENV === 'production'
    ? initData
    : initData || process.env.REACT_APP_DEV_TG_INIT_DATA;
  const { data } = await axiosInstance.post<AuthResponse>('/auth/verify', {
    initData: requestData,
  });

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
  localStorage.setItem('jwtToken', data.token);
  localStorage.setItem('jwtTokenRefresh', data.refreshToken);

  return data.token;
};

export const authenticateUser = createAsyncThunk(
  'auth/authenticateUser',
  async (initData: string, { dispatch, rejectWithValue }) => {
    try {
      return await verifyUser(initData);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 404) {
        const tg = window.Telegram.WebApp;
        const userData = tg?.initDataUnsafe?.user;

        if (!userData) {
          return rejectWithValue(err);
        }

        try {
          await dispatch(createUser({
            telegram_id: userData.id,
            first_name: userData.first_name || '',
            last_name: userData.last_name || '',
            username: userData.username || '',
            parent_id: tg?.initDataUnsafe.start_param,
          }));

          return await verifyUser(initData);
        } catch (createUserError) {
          return rejectWithValue(createUserError as AxiosError);
        }
      }

      return rejectWithValue(err);
    }
  },
);

export const refreshAuthToken = createAsyncThunk(
  'auth/refreshAuthToken',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = localStorage.getItem('jwtTokenRefresh');

      const { data } = await axiosInstance.post<AuthResponse>('/auth/refresh', {
        refreshToken,
      });

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      localStorage.setItem('jwtToken', data.token);

      return data.token;
    } catch (error) {
      const err = error as AxiosError;
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('jwtTokenRefresh');
      return rejectWithValue(err);
    }
  },
);
