import { MultiColorIconProps } from '../types/icons';

function CoinIcon({ colors = { background: '#0D0D0F', glow: '#FADB27' } }: MultiColorIconProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4041_193082)">
        <rect width="64" height="64" rx="12" fill={colors.background ?? '#0D0D0F'} />
        <g filter="url(#filter0_f_4041_193082)">
          <path d="M32 0L33.4908 26.4363L48 4.28719L36.0729 27.9271L59.7128 16L37.5637 30.5092L64 32L37.5637 33.4908L59.7128 48L36.0729 36.0729L48 59.7128L33.4908 37.5637L32 64L30.5092 37.5637L16 59.7128L27.9271 36.0729L4.28719 48L26.4363 33.4908L0 32L26.4363 30.5092L4.28719 16L27.9271 27.9271L16 4.28719L30.5092 26.4363L32 0Z" fill={colors?.glow ?? '#FADB27'} />
        </g>
        <g clipPath="url(#clip1_4041_193082)">
          <path d="M34.3469 16.4266L39.8961 18.203C40.5409 18.4423 41.0924 18.867 41.5235 19.4183L46.4431 25.4011C47.0843 26.177 47.4419 27.1901 47.4078 28.2044L47.1658 37.5532C47.1451 38.597 46.7352 39.5697 46.0293 40.2857L41.4918 44.9348C41.04 45.4274 40.4482 45.7701 39.8183 45.952L34.6022 47.4883L34.3469 16.4266Z" fill="url(#paint0_linear_4041_193082)" />
          <path d="M34.1103 16.3982L25.5674 17.2132C24.7274 17.1704 23.9182 17.5059 23.3461 18.1249L17.7236 24.841C17.2264 25.3901 16.9257 26.1343 16.9149 26.8883L16.7499 35.2387C16.7391 35.9927 17.018 36.7564 17.5057 37.3531L22.8874 45.1213C23.4451 45.7929 24.2299 46.182 25.0993 46.2112L33.6551 47.6016C34.4951 47.6444 35.3042 47.309 35.8763 46.6899L42.4665 39.6727C42.9637 39.1237 43.2779 38.4089 43.2752 37.6255L43.4408 27.099C43.4517 26.3451 43.1728 25.5813 42.6851 24.9846L36.3222 17.4881C35.751 16.787 34.9662 16.3979 34.1103 16.3982Z" fill="url(#paint1_linear_4041_193082)" />
          <path d="M32.4192 20.0984L26.1026 20.7128C25.48 20.6773 24.8906 20.9476 24.4548 21.3972L20.3103 26.4367C19.9334 26.8592 19.7125 27.3885 19.693 27.9681L19.5558 34.2015C19.5363 34.7811 19.7501 35.325 20.0977 35.7719L24.0668 41.5487C24.471 42.041 25.0703 42.3368 25.6794 42.3428L31.9989 43.3678C32.6215 43.4033 33.2109 43.133 33.6467 42.6834L38.523 37.4509C38.8999 37.0284 39.1208 36.4991 39.1403 35.9195L39.2746 28.0468C39.2942 27.4671 39.0803 26.9232 38.7327 26.4763L34.0318 20.8925C33.6117 20.4432 33.0418 20.1339 32.4192 20.0984Z" fill="#F08F3B" />
          <mask id="mask0_4041_193082" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="19" y="20" width="21" height="24">
            <path d="M32.4192 20.0984L26.1026 20.7128C25.48 20.6773 24.8906 20.9476 24.4548 21.3972L20.3103 26.4367C19.9334 26.8592 19.7125 27.3885 19.693 27.9681L19.5558 34.2015C19.5363 34.7811 19.7501 35.325 20.0977 35.7719L24.0668 41.5487C24.471 42.041 25.0703 42.3368 25.6794 42.3428L31.9989 43.3678C32.6215 43.4033 33.2109 43.133 33.6467 42.6834L38.523 37.4509C38.8999 37.0284 39.1208 36.4991 39.1403 35.9195L39.2746 28.0468C39.2942 27.4671 39.0803 26.9232 38.7327 26.4763L34.0318 20.8925C33.6117 20.4432 33.0418 20.1339 32.4192 20.0984Z" fill="white" />
          </mask>
          <g mask="url(#mask0_4041_193082)">
            <path d="M34.1153 20.1414L27.7986 20.7557C27.176 20.7203 26.5867 20.9906 26.1508 21.4401L22.0064 26.4796C21.6295 26.9021 21.4086 27.4315 21.3891 28.0111L21.2519 34.2444C21.2323 34.824 21.4462 35.368 21.7938 35.8149L25.7629 41.5916C26.167 42.0839 26.7663 42.3797 27.3754 42.3857L33.695 43.4108C34.3176 43.4462 34.9069 43.1759 35.3428 42.7264L40.219 37.4939C40.5959 37.0714 40.8168 36.542 40.8363 35.9624L40.9707 28.0897C40.9902 27.5101 40.7764 26.9661 40.4288 26.5192L35.7278 20.9355C35.3237 20.4432 34.7379 20.1768 34.1153 20.1414Z" fill="#FFA42F" />
          </g>
          <path d="M31.3713 38.7335L28.9633 39.1602L32.7567 24.723L35.9893 26.9497C36.2448 27.1179 36.4548 27.3426 36.59 27.6373C36.9303 28.3015 37.2757 29.6768 36.4732 31.9709C35.9283 33.5048 34.4872 35.8064 33.2608 37.3319C32.8496 37.9128 32.0282 38.6106 31.3713 38.7335Z" fill="#F08F3B" />
          <path d="M24.629 26.1324C28.1304 22.9572 34.105 24.3188 35.373 27.1613C37.1115 31.1076 31.5532 39.3636 28.746 39.1531C26.4889 38.9756 21.1729 29.2512 24.629 26.1324Z" fill="url(#paint2_linear_4041_193082)" />
          <path d="M26.2765 28.4799C25.891 28.728 25.632 29.0965 25.6038 29.5017C25.5549 30.0949 25.6975 31.0281 26.7294 32.2669C28.5289 34.4019 29.2428 31.2925 29.0461 30.2414C28.7104 28.5763 27.6983 27.6138 26.2765 28.4799Z" fill="#E17E3B" />
          <mask id="mask1_4041_193082" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="25" y="28" width="5" height="5">
            <path d="M26.2765 28.4799C25.891 28.728 25.632 29.0965 25.6038 29.5017C25.5549 30.0949 25.6975 31.0281 26.7294 32.2669C28.5289 34.4019 29.2428 31.2925 29.0461 30.2414C28.7104 28.5763 27.6983 27.6138 26.2765 28.4799Z" fill="white" />
          </mask>
          <g mask="url(#mask1_4041_193082)">
            <path d="M26.9054 28.762C26.5198 29.0101 26.2608 29.3786 26.2327 29.7839C26.1837 30.377 26.3264 31.3102 27.3582 32.549C29.1577 34.6841 29.8717 31.5746 29.6749 30.5235C29.3257 28.8289 28.3566 27.8824 26.9054 28.762Z" fill="#F8902C" />
          </g>
          <path d="M34.0585 29.5834C34.3423 30.2022 34.3179 30.9267 33.8932 31.4782C33.5298 31.9302 32.9466 32.4473 31.8563 32.9474C28.9978 34.2584 29.9254 30.837 30.6681 29.89C31.8355 28.3915 33.2451 27.8877 34.0585 29.5834Z" fill="#E27A36" />
          <mask id="mask2_4041_193082" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="29" y="28" width="6" height="6">
            <path d="M34.0585 29.5834C34.3423 30.2022 34.3179 30.9267 33.8932 31.4782C33.5298 31.9302 32.9466 32.4473 31.8563 32.9474C28.9978 34.2584 29.9254 30.837 30.6681 29.89C31.8355 28.3915 33.2451 27.8877 34.0585 29.5834Z" fill="white" />
          </mask>
          <g mask="url(#mask2_4041_193082)">
            <path d="M34.5695 29.9198C34.8533 30.5386 34.8289 31.2632 34.4042 31.8146C34.0408 32.2666 33.4576 32.7838 32.3673 33.2838C29.5088 34.5948 30.4364 31.1734 31.1791 30.2264C32.3465 28.728 33.7266 28.2377 34.5695 29.9198Z" fill="#F8902C" />
          </g>
        </g>
      </g>
      <defs>
        <filter id="filter0_f_4041_193082" x="-12" y="-12" width="88" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4041_193082" />
        </filter>
        <linearGradient id="paint0_linear_4041_193082" x1="39.4387" y1="40.2825" x2="41.7689" y2="22.0086" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#E48818" />
          <stop offset="0.4383" stopColor="#E48A19" />
          <stop offset="0.5891" stopColor="#E6911E" />
          <stop offset="0.6966" stopColor="#E99C27" />
          <stop offset="0.783" stopColor="#ECAD34" />
          <stop offset="0.8296" stopColor="#EFBA3D" />
          <stop offset="0.843" stopColor="#EEB839" />
          <stop offset="0.8845" stopColor="#ECB632" />
          <stop offset="1" stopColor="#EBB530" />
        </linearGradient>
        <linearGradient id="paint1_linear_4041_193082" x1="22.196" y1="45.0838" x2="36.9828" y2="21.0111" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FFBF20" />
          <stop offset="0.4599" stopColor="#FABF36" />
          <stop offset="0.7859" stopColor="#FAD64D" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <linearGradient id="paint2_linear_4041_193082" x1="24.3051" y1="33.1632" x2="36.0853" y2="29.3305" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#FACA4C" />
          <stop offset="0.3908" stopColor="#FACD4E" />
          <stop offset="0.7089" stopColor="#FAD855" />
          <stop offset="1" stopColor="#FAE95F" />
        </linearGradient>
        <clipPath id="clip0_4041_193082">
          <rect width="64" height="64" rx="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_4041_193082">
          <rect width="32" height="32" fill="white" transform="translate(16 16)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CoinIcon;
