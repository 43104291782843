import { ReactComponent as AutopilotSvg } from '../../assets/images/autopilot.svg';
import { ReactComponent as Coin } from '../../assets/images/coin-size-26x26.svg';
import { useAutopilotContext } from '../../providers/AutopilotProvider';
import { useAppDispatch } from '../../store';
import { finishAutopilot, getAutopilot } from '../../store/reducers/store/asyncActions';
import Button from '../Button';

type AutopilotModalProps = {
    onClose: () => void
}
function AutopilotModal({ onClose }: AutopilotModalProps) {
  const dispatch = useAppDispatch();

  const { balance, autopilot, time } = useAutopilotContext();

  const handleCollectFarm = async () => {
    const resp = await dispatch(finishAutopilot());

    if (!resp?.error) {
      await dispatch(getAutopilot());
    }

    onClose();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="bg-autopilotStarBg h-full w-full bg-center-center bg-no-repeat p-[16px] stroke-none">
      <div className="mt-[1.025rem] stroke-none">
        <AutopilotSvg height="100px" width="100px" className="m-auto" />
      </div>
      <div className="text-[22px] mt-1 font-Bangers">
        Auto-pilot
      </div>
      <div className="text-sm text-gray-2 font-[400] line-[17px]">
        The bot is on and will continue to collect coins until you reach the limit. You can still tap and get rewards for this activity while it`s running.
      </div>
      <div className="main-content mt-2">
        <div className="bg-white bg-opacity-[0.15] p-[12px] rounded-10 mt-3 flex flex-col align-center">
          {!autopilot?.end_at
          && <div>Earned</div>}
          <div className="flex gap-1.5 mx-auto my-0 items-center">
            <span className="text-[26px] font-Bangers">
              {(!autopilot?.end_at ? '' : '+') + Math.floor(balance)}
            </span>
            <span>
              <Coin className="mt-[-2px]" />
            </span>
          </div>
        </div>
        {!time.isFinished && <FarmingInfo />}
      </div>
      <Button
        onClick={time.isFinished ? handleCollectFarm : handleClose}
        pureClass
        className="btn neutral w-full mt-6 mb-10 py-[12.5px] text-lg leading-[19px] disabled:bg-gray-10 disabled:text-gray-600 focus:ring-4 transform active:scale-75 transition-transform"
      >
        {time.isFinished ? 'Claim Rewards' : 'Close'}
      </Button>
    </div>
  );
}

export default AutopilotModal;

function FarmingInfo() {
  const { time } = useAutopilotContext();

  return (
    <div className="mt-2 flex gap-2">
      <div className="bg-white bg-opacity-[0.15] p-[10px] rounded-10 w-full flex flex-col">
        <span className="text-[26px] font-Bangers">
          {time.remain.hours}
        </span>
        <span className="text-sm">
          Hours
        </span>
      </div>
      <div className="bg-white bg-opacity-[0.15] p-[10px] rounded-10 w-full flex flex-col">
        <span className="text-[26px] font-Bangers">
          {time.remain.formatedMinutes}
        </span>
        <span className="text-sm">
          Minutes
        </span>
      </div>
      <div className="bg-white bg-opacity-[0.15] p-[10px] rounded-10 w-full flex flex-col">
        <span className="text-[26px] font-Bangers">
          {time.remain.formatedSecond}
        </span>
        <span className="text-sm">
          Seconds
        </span>
      </div>
    </div>
  );
}
