/* eslint-disable class-methods-use-this */

import mixpanel from 'mixpanel-browser';

type TMixpanelData = {
    [k: string]: string | number;
}

class MixpanelService {
  private static instance: MixpanelService | null = null;

  private isIdentified = false;

  constructor() {
    if (MixpanelService.instance) {
      // eslint-disable-next-line no-constructor-return
      return MixpanelService.instance;
    }

    const token = process.env.REACT_APP_MIXPANEL_API_KEY;

    if (!token) {
      throw new Error('Mixpanel token not found');
    }

    mixpanel.init(token, {
      track_pageview: true,
    });

    MixpanelService.instance = this;
  }

  identify(userId: number) {
    mixpanel.identify(userId.toString());

    this.isIdentified = true;

    // mixpanel.start_session_recording();
  }

  trackEvent(eventName: string, data: TMixpanelData) {
    if (!this.isIdentified) {
      return;
    }

    mixpanel.track(eventName, data);
  }

  trackPage(page: string) {
    if (!this.isIdentified) {
      return;
    }

    mixpanel.track_pageview({
      Page: page,
    });
  }

  disconnect() {
    // mixpanel.stop_session_recording();
  }
}

export default MixpanelService;
