import { ReactComponent as CoinSVG } from '../../assets/images/coin-size.svg';
import { ReactComponent as EnergySvg } from '../../assets/images/energy-22x22.svg';
import { useUserEnergy } from '../../hooks/useUserEnergy';
import { useAutopilotContext } from '../../providers/AutopilotProvider';
import { useAppSelector } from '../../store';
import { ShopItemLevelT, ShopItemT } from '../../types';
import ProgressBar from '../ProgressBar';
import ShopItemButtons from '../ShopComponents/ShopButtons';
import getStoreItemIcon from '../utils/getStoreItemIcon';

type StoreItemProps = {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
  onStartFarm: () => void
  onCollectFarm: () => void
}
function PermanentStoreItem({
  item, onBuy, onStartFarm, onCollectFarm,
}: StoreItemProps) {
  const { time } = useAutopilotContext();

  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const isAutopilot = item?.levels?.[0]?.effects[0]?.name === 'autopilot';
  const isEnergyCapacity = item?.levels?.[0]?.effects[0]?.name === 'energy_capacity';
  const progressbarLegend = item.levels?.reduce((progress, level, idx, list) => {
    if (idx > 0) {
      const current = parseInt(level.effects[0]?.value ?? '0', 10);
      const prev = parseInt(list[idx - 1].effects[0]?.value ?? '0', 10);

      return [...progress, `+${current - prev}`];
    }

    return [`+${level.effects[0]?.value}`];
  }, []);
  const purchasedLevels = [...item.levels || []].filter((level) => level.purchased_at);
  const currentLevel: ShopItemLevelT | undefined = purchasedLevels[purchasedLevels.length - 1];
  const nextLevel = [...item.levels || []].sort((level) => level.level).find((level) => !level.purchased_at);
  const startSumValue = Number(currentLevel?.effects?.[0]?.value || 0) + (userSettings?.aviator_base_coins || 1000);
  const { maxEnergy } = useUserEnergy();

  const icon = getStoreItemIcon(item.icon);

  return (
    <div className="bg-black-modal-bg py-2 px-4 rounded-10 flex flex-col gap-1">
      <div className="flex gap-2">
        <div className="bg-black-main rounded-10 min-h-[64px] min-w-[64px] bg-centerCenter">
          <div className="w-full h-full flex flex-col relative">
            {icon}
          </div>
        </div>
        <div className="flex-col flex justify-between w-full ml-1">
          <div>
            <div className="text-lg mt-[3px] font-Bangers">{item.name}</div>
            <div className="text-sm text-gray-2 mt-2 leading-4">
              {item.description}
            </div>
          </div>
        </div>
      </div>
      {item?.levels?.[0]?.effects[0]?.interval
        ? (
          <div className="bg-black-light-bg p-4 rounded-10 mt-3">
            {isEnergyCapacity ? (
              <div className="text-base flex items-center gap-2">
                <span className="text-sm text-gray-2">
                  {item.levels[0]?.effects[0]?.description}
                </span>
                <span className="font-Bangers">{maxEnergy}</span>
                <EnergySvg />
              </div>
            )
              : (
                <div className="text-base flex items-center gap-2">
                  <span className="font-Bangers">{currentLevel?.effects[0]?.value ?? item.levels?.[0].effects[0]?.value}</span>
                  <CoinSVG />
                  <span className="text-sm text-gray-2">
                    /
                    {' '}
                    {item.levels[0]?.effects[0]?.description}
                  </span>
                </div>
              )}

          </div>
        )
        : (
          <div className="bg-black-light-bg p-4 rounded-10 mt-3">
            <div className="text-base flex items-center gap-2">
              <span className="text-sm text-gray-2">
                {item.levels?.[0]?.effects[0]?.description}
              </span>
              <span className="font-Bangers">{isAutopilot ? currentLevel?.effects[0]?.value ?? item.levels?.[0].effects[0]?.value : startSumValue}</span>
              {isEnergyCapacity ? <EnergySvg /> : <CoinSVG />}
            </div>
          </div>
        )}
      {isAutopilot
          && time.isFinished
          && (
          <EarnedInfo />
          )}
      {currentLevel && item?.levels?.length
          && (
          <ProgressBar
            currentValue={purchasedLevels.length}
            goal={item.levels.length}
            size="small"
            hideLine
            legend={progressbarLegend}
          />
          )}
      {nextLevel?.price
      && (
      <div className="flex items-center gap-1 mt-2">
        <span className="text-gray-3 text-xs">
          Price
        </span>
        <span className="text-white font-[700] text-sm">
          {nextLevel?.price}
        </span>
        {' '}
        <CoinSVG />
      </div>
      )}
      <ShopItemButtons onBuy={onBuy} item={item} activeTab="permanent" onStartFarm={onStartFarm} onCollectFarm={onCollectFarm} />
    </div>
  );
}

export default PermanentStoreItem;

function EarnedInfo() {
  const { balance, time } = useAutopilotContext();

  if (time.isFinished) {
    return null;
  }

  return (
    <div className="bg-black-light-bg p-3 rounded-10 text-gray-2 flex gap-2 items-center">
      Earned
      {' '}
      {Math.floor(balance)}
      <CoinSVG />
      {' '}
      for
      {' '}
      {time.remain.hours}
      :
      {time.remain.formatedMinutes}
      :
      {time.remain.formatedSecond}
    </div>
  );
}
