import { LeaguesUnion } from '../../types';

type LinearProgressBarProps = {
  currentValue: number
  goal: number
  hideLine?: boolean
  progressClass?: 'energy' | LeaguesUnion
}
function LinearProgressBar({
  currentValue, goal, hideLine, progressClass,
}: LinearProgressBarProps) {
  const currentPercentage = (currentValue / goal) * 100;
  const completedPercentage = Math.floor(currentPercentage > 100 ? 100 : currentPercentage); // maximum value should be 100
  const positionClass = `before:left-[${completedPercentage}%]`;
  return (
    <div className="p-0 border-none h-full">
      <div
        className={`flex gap-[1px] h-full
    ${!hideLine && `before:content[''] before:${completedPercentage === 100 ? 'w-0' : 'w-0.5'}`} 
    before:h-full p-[1px] before:relative before:bg-gray-200 rounded-[8px] ${positionClass} ${`progress-bar-${progressClass}`}
    `}>
        <div
          className="w-full rounded progress-line"
          style={{
            borderRadius: '20px',
            width: `${completedPercentage}%`,
          }}
        />
      </div>
    </div>
  );
}

export default LinearProgressBar;
