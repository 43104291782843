import SpinResult from './SpinResult';

interface ISpinResultBlock {
    isSpinning: boolean
}
function SpinResultBlock(props:ISpinResultBlock) {
  const { isSpinning } = props;

  return (
    <svg width="365" height="43" viewBox="0 -15 133 43" fill="none" id="spin-result">
      <g clipPath="url(#clip0_4581_194824)">
        <path d="M116.508 22.3976H12.6946L0.50293 0.498047H131.867L116.508 22.3976Z" fill="#0C056B" />
        <path d="M116.508 22.3976H12.6946L0.50293 0.498047H131.867L116.508 22.3976Z" fill="url(#paint0_linear_4581_194824)" />
        <path d="M116.508 22.9482H12.6945C12.3778 22.9482 12.0611 22.8015 11.9556 22.5814L-0.236099 0.718577C-0.341655 0.535164 -0.288877 0.351751 -0.130544 0.20502C0.0277895 0.05829 0.238901 -0.0517578 0.502789 -0.0517578H131.867C132.131 -0.0517578 132.394 0.05829 132.553 0.20502C132.711 0.351751 132.711 0.571847 132.606 0.75526L117.247 22.6548C117.089 22.8382 116.825 22.9482 116.508 22.9482ZM15.0167 21.8478H114.45C115.4 21.8478 116.297 21.4809 116.719 20.8573L129.492 2.62607C130.019 1.89242 129.228 1.04872 128.067 1.04872H3.98612C2.87779 1.04872 2.08612 1.81906 2.50835 2.55271L12.6417 20.7106C13.0111 21.4076 13.9611 21.8478 15.0167 21.8478Z" fill="url(#paint1_linear_4581_194824)" />
      </g>
      <foreignObject x="-3" y="4" width="100%" height="100%">
        <SpinResult isSpinning={isSpinning} />
      </foreignObject>
      <defs>
        <linearGradient id="paint0_linear_4581_194824" x1="78.3724" y1="30.0052" x2="69.7427" y2="-13.2947" gradientUnits="userSpaceOnUse">
          <stop stopColor="#000064" />
          <stop offset="0.1033" stopColor="#040E6E" stopOpacity="0.9277" />
          <stop offset="0.2992" stopColor="#0F3388" stopOpacity="0.7906" />
          <stop offset="0.567" stopColor="#216FB2" stopOpacity="0.6031" />
          <stop offset="0.8931" stopColor="#39C2EB" stopOpacity="0.3748" />
          <stop offset="1" stopColor="#41DEFF" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient id="paint1_linear_4581_194824" x1="-0.288877" y1="11.4538" x2="132.691" y2="11.4538" gradientUnits="userSpaceOnUse">
          <stop offset="2.23767e-07" stopColor="#A7BEE8" />
          <stop offset="0.2669" stopColor="#BACCED" />
          <stop offset="0.8064" stopColor="#ECF1FA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_4581_194824">
          <rect width="133" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default SpinResultBlock;
