import React, {
  createContext, useContext,
  useMemo, useState,
} from 'react';
import WebSocketServer from '../../services/WebSocketServer';
import EstablishConnectionModal from '../../templates/EstablishConnection';

type StopGameT = {
  aviatorId: number
  hash: string
}
type StartGameT = { multiplier: number | undefined, revive?: boolean, slotMultiplier: number }
interface WebSocketContextProps {
    webSocket: WebSocketServer;
    startGame: ({ multiplier, slotMultiplier, revive }: StartGameT) => void;
    stopGame: (data: StopGameT) => void;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      'useWebSocketContext must be used within an WebSocketProvider',
    );
  }

  return context;
};

function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const [webSocket] = useState<WebSocketContextProps['webSocket']>(() => new WebSocketServer());

  const startGame = ({ multiplier, revive, slotMultiplier }: StartGameT) => {
    webSocket.server?.send(
      JSON.stringify({
        type: 'start', value: multiplier, revive, slotMultiplier,
      }),
    );
  };

  const stopGame = ({ aviatorId, hash }: StopGameT) => {
    webSocket.server?.send(
      JSON.stringify({ type: 'stop', aviatorId, hash }),
    );
  };

  const contextValue = useMemo(() => ({
    webSocket, startGame, stopGame,
  }), [webSocket, startGame]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      <EstablishConnectionModal />
      {children}
    </WebSocketContext.Provider>
  );
}

export default WebSocketProvider;
