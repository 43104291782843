import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';
import { DisplayObject } from 'pixi.js';
import { Howl } from 'howler';
import eventEmitter, { GameEvents } from '../../pages/Home/eventEmiter';
import { getMiddleElementPosition, getSlotCanvasRect } from '../../Components/SlotMachine/SlotMachinePixi';
import { getMachineFrameScale } from '../../Components/SlotMachine/Machine/Machine';
import { overlayFileList } from '../../PIXIHelpers/pixiConstants';
import { loadSpineAtlas } from '../../PIXIHelpers';
import slotWinEnergy from '../../assets/sounds/sc_slot_win_energy.wav';
import slotWinCoin from '../../assets/sounds/sc_slot_win_coin.wav';
import aviatorPortal from '../../assets/sounds/sc_aviator_portal.wav';
import aviatorCoinshower from '../../assets/sounds/sc_aviator_coinshower.wav';
import aviatorFallingCoins from '../../assets/sounds/sc_aviator_falling_coins.wav';

export const overlayPixiApp = new PIXI.Application({
  // width: 430,
  // height: 932,
  width: window.innerWidth,
  height: window.innerHeight,
  resolution: 2,
  autoDensity: true,
  backgroundAlpha: 0,
  hello: true,
});
const folderPath = '../spine/';
const sounds = {
  energySound: new Howl({ src: [slotWinEnergy] }),
  coinsAudio: new Howl({ src: [slotWinCoin] }),
  galaxyTransition: new Howl({ src: [aviatorPortal] }),
  coinsTransition: new Howl({ src: [aviatorCoinshower] }),
  coinsRainAudio: new Howl({ src: [aviatorFallingCoins] }),
};

type SpineDataListT = {
  [key in typeof overlayFileList[number]['name']]?: Spine
}
export async function preloadOverlay() {
  await Promise.all(
    overlayFileList.map(async (animationFile) => {
      const path = folderPath + animationFile.jsonPath;
      try {
        const spineAtlas = await loadSpineAtlas(path);
        PIXI.Assets.add({
          src: path,
          alias: path,
          data: {
            spineAtlas,
          },
        });
        await PIXI.Assets.load(path);
      } catch (error) {
        window.console.error(`Preload error ${path}:`, error);
      }
    }),
  );
}

function getOrCreateSpineAnimation(spineName: keyof SpineDataListT) {
  const animationFile = overlayFileList.find((el) => el.name === spineName);
  if (animationFile) {
    const path = folderPath + animationFile.jsonPath;
    const { spineData } = PIXI.Assets.get(path);
    if (spineData) {
      const spineObject: Spine = new Spine(spineData);
      overlayPixiApp.stage.addChild(spineObject as DisplayObject);
      return spineObject;
    }
    window.console.error('Cache not found');
  }
  return null;
}

const calculateScale = (animation: Spine, targetWidth: number, targetHeight: number) => {
  const originalWidth = animation.width;
  const originalHeight = animation.height;
  const scaleX = targetWidth / originalWidth;
  const scaleY = targetHeight / originalHeight;
  return Math.min(scaleX, scaleY);
};

const startGalaxyTransition = () => {
  const machineContentBounding = getBoundingById('machine-content');
  const elementPosition = getMiddleElementPosition();
  const machineFrameScaleData = getMachineFrameScale();

  if (!elementPosition || !machineContentBounding || !machineFrameScaleData) {
    return;
  }

  const { scaleX, scaleY } = machineFrameScaleData;
  const galaxy = getOrCreateSpineAnimation('galaxy');
  if (!galaxy) {
    throw new Error('galaxy animation not found');
  }
  const galaxyScale = Math.min(scaleX, scaleY) / 9;
  galaxy.visible = true;
  galaxy.scale.set(galaxyScale);

  const canvasCenterX = machineContentBounding.left + machineContentBounding.width / 2;
  const canvasCenterY = machineContentBounding.top + machineContentBounding.height / 2;
  const yOffset = 50 * scaleY;
  const xOffset = 15 * scaleX;
  const position = {
    x: canvasCenterX - xOffset,
    y: canvasCenterY + galaxy.height / 2 - yOffset,
  };
  galaxy.x = position.x;
  galaxy.y = position.y;
  galaxy.visible = true;
  galaxy.timeScale = 1.2;
  galaxy.state.setAnimation(0, 'animation', false).trackTime = 0.5;
  sounds.galaxyTransition.rate(1.5);
  sounds.galaxyTransition.play();
};

const showWinCoinTransition = () => {
  const coins = getOrCreateSpineAnimation('coins');
  if (!coins) {
    throw new Error('coins animation not found');
  }
  coins.scale.set(calculateScale(coins, overlayPixiApp.renderer.width, overlayPixiApp.renderer.height) / 2);
  coins.x = (overlayPixiApp.renderer.width / 2) / 2;
  coins.y = (overlayPixiApp.renderer.height / 2);
  coins.visible = true;
  sounds.coinsRainAudio.loop(false);
  sounds.coinsRainAudio.stop();
  setTimeout(() => {
    sounds.coinsTransition.play();
  }, 400);
  coins.state.setAnimation(0, 'win', false);
  coins.state.addListener({
    complete: () => eventEmitter.emit(GameEvents.OVERLAY_COINS_RAIN_STOP),
  });
};

const startMeteorTransition = () => {
  const meteors = getOrCreateSpineAnimation('meteors');
  if (!meteors) {
    throw new Error('meteors animation not found');
  }
  meteors.scale.set(0.4255044287565546, 0.3255044287565546);
  meteors.x = (overlayPixiApp.renderer.width / 2) / 2 + 40;
  meteors.y = (overlayPixiApp.renderer.height / 2);
  meteors.visible = true;
  meteors.state.setAnimation(0, 'win', false);
};

const runCoinRain = () => {
  const coinsRain = getOrCreateSpineAnimation('coinsRain');
  const coinsRainCopy = getOrCreateSpineAnimation('coinsRainCopy');
  if (!coinsRain) {
    throw new Error('coinsRain animation not found');
  }
  if (!coinsRainCopy) {
    throw new Error('coinsRainCopy animation not found');
  }
  sounds.coinsRainAudio.loop(true);
  sounds.coinsRainAudio.play();

  coinsRain.state.setAnimation(0, 'win', true);
  coinsRain.scale.set(0.5);
  coinsRain.x = (overlayPixiApp.renderer.width / 2) / 3;
  coinsRain.y = (overlayPixiApp.renderer.height / 2);
  coinsRain.visible = true;
  coinsRainCopy.visible = true;
  coinsRainCopy.scale.set(0.5);
  coinsRainCopy.x = (overlayPixiApp.renderer.width / 2) / 3;
  coinsRainCopy.y = (overlayPixiApp.renderer.height / 2);
  const targetFrame = 30;
  let frame = 0;
  let isReached = false;
  function loopRain() {
    if (frame === targetFrame && !isReached) {
      if (!coinsRainCopy) {
        throw new Error('coinsRainCopy animation not found');
      }
      coinsRainCopy.state.setAnimation(0, 'win', true);
      isReached = true;
      frame = 0;
    }
    frame += 1;
  }

  function stopCoinsRain() {
    if (!coinsRainCopy) {
      throw new Error('coinsRainCopy animation not found');
    }
    if (!coinsRain) {
      throw new Error('coinsRain animation not found');
    }
    coinsRain.visible = false;
    coinsRainCopy.visible = false;
    overlayPixiApp.ticker.remove(loopRain);
    eventEmitter.off(GameEvents.OVERLAY_COINS_RAIN_STOP, stopCoinsRain);
  }
  overlayPixiApp.ticker.add(loopRain);
  eventEmitter.on(GameEvents.OVERLAY_COINS_RAIN_STOP, stopCoinsRain);
};
const showMultiplierAnimation = (multiplerValue: number) => {
  const machineContentBounding = getBoundingById('machine-content');
  const elementPosition = getMiddleElementPosition();
  const machineFrameScaleData = getMachineFrameScale();

  if (!elementPosition || !machineContentBounding || !machineFrameScaleData) {
    return;
  }

  const { scaleX, scaleY } = machineFrameScaleData;
  const multiplier = getOrCreateSpineAnimation('multiplier');
  if (!multiplier) {
    throw new Error('multiplier animation not found');
  }
  multiplier.scale.set(Math.min(scaleX, scaleY) / 4.5);
  multiplier.visible = true;

  const canvasCenterX = machineContentBounding.left + machineContentBounding.width / 2;
  const canvasCenterY = machineContentBounding.top + machineContentBounding.height / 2;
  const yOffset = 70 * scaleY;
  const xOffset = 15 * scaleX;
  const position = {
    x: canvasCenterX - xOffset,
    y: canvasCenterY + multiplier.height / 2 + yOffset,
  };

  multiplier.x = position.x;
  multiplier.y = position.y;
  multiplier.state.setAnimation(0, `multiplier_x${multiplerValue}`, false);
  const onAnimationComplete = () => {
    multiplier.visible = false;
    eventEmitter.emit(GameEvents.SLOT_SHOW_MIDDLE);
  };
  multiplier.state.addListener({
    complete: onAnimationComplete,
  });
};

const getBoundingById = (itemId:string) => {
  if (!itemId) {
    return null;
  }
  const el = document.getElementById(itemId);
  if (el) {
    return el.getBoundingClientRect() as DOMRect;
  }
  return null;
};

const showEnergyAnimation = () => {
  sounds.energySound.play();
  const machineContentBounding = getBoundingById('machine-content');
  const elementPosition = getMiddleElementPosition();
  const machineFrameScaleData = getMachineFrameScale();

  if (!elementPosition || !machineContentBounding || !machineFrameScaleData) {
    return;
  }

  const { scaleX, scaleY } = machineFrameScaleData;
  const energy = getOrCreateSpineAnimation('energy');
  if (!energy) {
    throw new Error('energy animation not found');
  }
  const energyScale = Math.min(scaleX, scaleY) / 4.5;
  energy.visible = true;
  energy.scale.set(energyScale);
  const canvasCenterX = machineContentBounding.left + machineContentBounding.width / 2;
  const canvasCenterY = machineContentBounding.top + machineContentBounding.height / 2;
  const yOffset = 37.5 * scaleY;
  const xOffset = 15 * scaleX;
  const position = {
    x: canvasCenterX - xOffset,
    y: canvasCenterY + energy.height / 2 + yOffset,
  };
  energy.x = position.x;
  energy.y = position.y;
  energy.state.setAnimation(0, 'animation', false);

  const targetFrame = 24;
  let frame = 0;
  let isReached = false;
  function CalculateEnergyFrame() {
    if (frame === targetFrame && !isReached) {
      eventEmitter.emit(GameEvents.ENERGY_HIT_SCREEN);
      isReached = true;
      frame = 0;
    }
    frame += 1;
  }
  const onAnimationComplete = () => {
    eventEmitter.emit(GameEvents.SLOT_SHOW_MIDDLE);
    energy.visible = false;
    overlayPixiApp.ticker.remove(CalculateEnergyFrame);
  };
  energy.state.addListener({
    complete: onAnimationComplete,
  });
  overlayPixiApp.ticker.add(CalculateEnergyFrame);
};
const showCoinsExplosion = (animation: 'coins_small'| 'coins_mid' | 'coins_big') => {
  const coinsExplosion = getOrCreateSpineAnimation('coinsExplosion');
  if (!coinsExplosion) {
    throw new Error('coinsExplosion animation not found');
  }
  coinsExplosion.visible = true;
  const elementPosition = getMiddleElementPosition();
  coinsExplosion.scale.set(0.2);
  const canvasPosition = getSlotCanvasRect();
  const position = {
    x: canvasPosition.x + elementPosition.calculatedPosition.x + (elementPosition.elementWidth / 2),
    y: canvasPosition.bottom + (elementPosition.calculatedPosition.y) + elementPosition.baseYOffset,
  };
  coinsExplosion.x = (window.innerWidth / 2) + 20;
  coinsExplosion.y = position.y;
  coinsExplosion.state.setAnimation(0, animation, false);
  sounds.coinsAudio.play();
  const onAnimationComplete = () => {
    coinsExplosion.visible = false;
  };
  coinsExplosion.state.addListener({
    complete: onAnimationComplete,
  });
};
eventEmitter.on(GameEvents.OVERLAY_GALAXY_TRANSITION, () => {
  startGalaxyTransition();
});
eventEmitter.on(GameEvents.SHOW_WIN_COINS, () => {
  showWinCoinTransition();
});

eventEmitter.on(GameEvents.SHOW_METEORS, () => {
  startMeteorTransition();
});
eventEmitter.on(GameEvents.OVERLAY_COINS_RAIN_START, () => {
  runCoinRain();
});
eventEmitter.on(GameEvents.OVERLAY_SHOW_MULTIPLIER_ANIMATION, (val: number) => {
  showMultiplierAnimation(val);
});
eventEmitter.on(GameEvents.OVERLAY_SHOW_ENERGY_ANIMATION, () => {
  showEnergyAnimation();
});
eventEmitter.on(GameEvents.SLOT_COINS_EXPLOSION, ({ animation }: { animation: 'coins_small'| 'coins_mid' | 'coins_big' }) => {
  showCoinsExplosion(animation);
});
// const debugSquare = () => {
//   const redSquare: any = new PIXI.Graphics();
//
//   redSquare.beginFill(0xff0000);
//   redSquare.drawRect(0, 0, 100, 100);
//   redSquare.endFill();
//
//   redSquare.x = position.x;
//   redSquare.y = position.y;
//   overlayPixiApp.stage.addChild(redSquare);
// }
