import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as EarnIco } from '../../assets/svg/menu/earn.svg';
import { ReactComponent as FriendsIco } from '../../assets/svg/menu/friends.svg';
import { ReactComponent as StoreIco } from '../../assets/svg/menu/new-store.svg';
import Spin from '../../assets/svg/menu/spinv2.png';
import { ReactComponent as TopPlayers } from '../../assets/svg/menu/top-players.svg';
import { useAppSelector } from '../../store';
import { MenuItemsT } from '../../types';

const MenuItems: MenuItemsT[] = [
  {
    label: 'Top players',
    link: 'leaderbord',
    ico: <TopPlayers className="absolute top-[5px] h-[32px]" />,
    boxStyle: {
      transform: 'translate(-1px, -5px)',
    },
    titleStyle: {
      transform: 'translate(-2px, -2px)',
    },
  },
  {
    label: 'Earn',
    link: 'quests',
    ico: <EarnIco className="absolute top-[5px]" />,
    boxStyle: {
      transform: 'translate(1px, -5px)',
    },
    titleStyle: {
      transform: 'translateY(-1px)',
    },
  },
  {
    label: 'Spin',
    link: '/',
    ico: <img src={Spin} height={62} alt="spin" className="spin-menu-button ml-[1px] mt-[-15px] h-[62px]" />,
    titleStyle: {
      transform: 'translateY(-21px)',
      background: 'none',
      color: '#fff',
      fontSize: '1rem',
    },
  },
  {
    label: 'Friends',
    link: 'friends',
    ico: <FriendsIco className="absolute top-[4px]" />,
    boxStyle: {
      transform: 'translateY(-5px)',
    },
  },
  {
    label: 'Store',
    link: 'store',
    ico: <StoreIco className="absolute top-0" />,
    boxStyle: {
      transform: 'translateY(-5px)',
    },
  },
];
function Menu() {
  const { activeGame } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const { pathname } = useLocation();
  const isMenuDisabled = activeGame === 'aviator';
  return (
    <div
      id="bottom_navigation_menu"
      className={`bg-menuBg bg-no-repeat bg-fullfull h-[53px] bg-contain fixed bottom-0 mx-auto flex justify-center w-full menu z-[4] ${isMenuDisabled ? 'hidden' : 'visible'}`}
    >
      <ul
        className="mx-auto rounded-10 flex justify-around p-1 mb-1 w-full mt-[-8px] pl-[18px] pr-[13px]"
        id="app-menu-list"
      >
        {MenuItems.map((menuItem) => {
          const path = pathname.substring(1);
          let isActive = path.includes(menuItem.link);
          if (menuItem.link === '/' && pathname === '/') {
            isActive = false;
          }
          return (
            <li
              className="rounded mt-0 w-full"
              key={menuItem.label}
              style={{
                ...menuItem?.boxStyle,
              }}
            >
              <Link
                to={menuItem.link}
                className={`
                  min-h-[50px]
                  cursor-pointer 
                  p-1 
                  flex flex-col items-center justify-end
                  rounded-8 
                  ${isActive ? 'bg-activeMenuItem gradient-border active-menu-item before:rounded-8 before:z-[1]' : ''}
                `}
              >
                {menuItem.ico}
                <div
                  className="text-sm text-nowrap font-Bangers mt-[-8px] z-[1] px-1 leading-[15px]"
                  style={{
                    background: 'linear-gradient(180deg, #6064FF 10%, #FFFFFF 69%)',
                    color: 'transparent',
                    backgroundClip: 'text',
                    ...menuItem.titleStyle,
                  }}
                >
                  {menuItem.label}
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Menu;
