import {
  createContext, PropsWithChildren, useContext,
  useEffect,
  useState,
} from 'react';
import { DateProgressReturnType, useDateProgress } from '../../hooks/useDateProgress';
import { useAppSelector } from '../../store';
import { AutopilotT } from '../../types';

type AutopilotContextProps = {
    time: DateProgressReturnType
    balance: number
    autopilot: AutopilotT | null
}

const AutopilotContext = createContext({} as AutopilotContextProps);

export const useAutopilotContext = () => {
  const ctx = useContext(AutopilotContext);

  if (!ctx) {
    throw new Error(
      'useAutopilotContext must be used within an EnergyProvider',
    );
  }

  return ctx;
};

function AutopilotProvider({ children }: PropsWithChildren) {
  const { autopilot } = useAppSelector(({ shop }) => shop);

  const [currentBalance, setCurrentBalance] = useState<number>(autopilot?.balance ?? 0);

  const time = useDateProgress(
    {
      onTick: (_, elapsed) => {
        if (autopilot) {
          const current = Math.round(elapsed / 1000);

          setCurrentBalance(current * autopilot.coins_per_second);
        }
      },
      onFinish: () => {
        if (autopilot) {
          setCurrentBalance(autopilot.coins_per_interval);
        }
      },
    },
  );

  useEffect(() => {
    if (!autopilot) {
      setCurrentBalance(0);
    } else if (autopilot?.started_at && autopilot?.end_at) {
      time.start(new Date(autopilot.started_at).getTime(), new Date(autopilot.end_at).getTime());
    }
  }, [autopilot]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AutopilotContext.Provider value={{ balance: currentBalance, time, autopilot }}>{children}</AutopilotContext.Provider>;
}

export default AutopilotProvider;
