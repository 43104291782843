import { ReactComponent as Energy } from '../../../assets/svg/header/energy.svg';
import { useEnergyContext } from '../../../providers/EnergyProvider';
import LinearProgressBar from '../../LinearProgressBar';

function EnergyInfo() {
  const { userEnergy: userEnergyContext } = useEnergyContext();
  const { maxEnergy, userEnergy } = userEnergyContext;
  return (
    <div
      className="header-energy bg-headerEnergyBG shadow-headerEnergy h-[33px] min-w-[97px]  relative flex justify-center items-center ml-3"
      style={{
        borderRadius: '8px',
        border: 'solid 0.5px transparent',
        backgroundImage: 'radial-gradient(55.84% 427.73% at 50.36% 43.94%, rgb(103, 196, 254) 33.5%, rgb(27, 0, 91) 149%)',
        padding: 0,
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
        background: '#0089CE',
      }}
    >
      <div className="absolute left-[-22%] top-[-17%]">
        <Energy />
      </div>
      <div>
        <div className="flex items-center justify-between mt-[2px]">
          <EnergyTimer />
          <div
            className="font-Bangers text-sm ml-[2px] mt-[2px] pr-1"
            style={{
              background: 'linear-gradient(180deg, #FFFFFF 0%, #6FC3FF 100%)',
              color: 'transparent',
              backgroundClip: 'text',
            }}
          >
            {Math.floor(userEnergy)}
            {' '}
            <span className="text-headerEnergyText font-Barlow text-xs">
              /
            </span>
            {' '}
            {maxEnergy}
          </div>
        </div>
        <div className="border-none translate-x-[0px] translate-y-[-6px] mt-[2px] pl-2 h-[11px]">
          <LinearProgressBar
            goal={maxEnergy}
            currentValue={userEnergy}
            progressClass="energy"
          />
        </div>
      </div>
    </div>
  );
}

export default EnergyInfo;

function EnergyTimer() {
  const { energyRecoveryTimer } = useEnergyContext();
  const { countdown, shouldStartTimer } = energyRecoveryTimer({});
  return (
    <div className={`bg-[#FFFFFF26] rounded font-Barlow text-[10px] ml-1 px-[4px] h-fit ${shouldStartTimer ? 'visible' : 'invisible'}`}>
      {countdown.formatedMinutes}
      :
      {countdown.formatedSecond}
    </div>
  );
}
