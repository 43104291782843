// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { QuestStartedResp, QuestT, TQuestsResponse } from '../../../types';
import { axiosInstance, RootState } from '../../index';
import { getMe, updateProfileEnergy } from '../profile/asyncActions';
// Types

export const getQuests = createAsyncThunk<TQuestsResponse>(
  'quests/getQuests',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('/quests/user');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const startQuest = createAsyncThunk<
    QuestStartedResp,
    { id: string | number }
>(
  'quests/start',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.patch(`/quests/${id}/start`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const finishQuest = createAsyncThunk<
    QuestStartedResp,
    { id: string | number }
>(
  'quests/finish',
  async ({ id }, { rejectWithValue, dispatch, getState }) => {
    try {
      const activeEnergyCapacityEffect = (getState() as RootState).shop.purchases?.find((purchase) => purchase.is_active
            && purchase.store_item_level.effects.map((effect) => effect.name).includes('energy_capacity'))?.store_item_level?.effects[0];
      const maxEnergy = ((getState() as RootState)?.settings.settings?.max_energy || 100) + parseInt(activeEnergyCapacityEffect?.value || '0', 10);
      const { data }: { data: QuestStartedResp } = await axiosInstance.patch(`/quests/${id}/finish`);
      if (data.quest.quest_type === 'daily_energy_refill' && maxEnergy) {
        dispatch(updateProfileEnergy({ energy: maxEnergy, replace: true }));
        dispatch(getQuests()); // required to update timers
      }
      dispatch(getMe());
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const verifyQuest = createAsyncThunk<
    QuestT,
    QuestT
>(
  'quests/verify',
  async (quest, { rejectWithValue }) => {
    try {
      return quest;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
