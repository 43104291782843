import {
  FC,
  PropsWithChildren,
  useState,
} from 'react';
import { DrawerProps } from '../Drawer';

type TModal = {
    id: string;
    component: FC<DrawerProps>;
    enabled: boolean
}

type TSequentialModalsProps = {
    modals: TModal[]
} & PropsWithChildren

function SequentialModals({ modals, children }: TSequentialModalsProps) {
  const [activeModal, setActiveModal] = useState<TModal | undefined>(() => modals.find((m) => m.enabled));

  const onModalClose = (id: string) => {
    const currentIdx = modals.findIndex((m) => m.id === id && m.enabled);

    if (currentIdx === -1 || currentIdx === modals.length - 1) {
      setActiveModal(undefined);
    }

    const nextModal = modals.find((m, idx) => idx > currentIdx && m.enabled);

    if (!nextModal) {
      setActiveModal(undefined);
    }

    setActiveModal(nextModal);
  };

  return (
    <>
      {modals.map(({ id, component: ModalComponent }) => (
        <ModalComponent
          key={id}
          open={activeModal?.id === id}
          onClose={() => onModalClose(id)}
          side="bottom"
        />
      ))}
      {children}
    </>
  );
}

export default SequentialModals;
