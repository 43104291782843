import { useEffect, useRef } from 'react';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { ReactComponent as TopPlayers } from '../../assets/leaderboard/top-players.svg';
import { numberWithCommas } from '../../helpers';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { TopPlayerT } from '../../types';

type LeaderboardCurrentUserCardProps = Pick<TopPlayerT, 'username' | 'league'| 'total_earned_coins'> &{
    id?: string
    first_name?: string
    last_name?: string
}
function LeaderboardCurrentUserCard({
  username, first_name: firstName, last_name: lastName, league, total_earned_coins: totalCoins, id,
}: LeaderboardCurrentUserCardProps) {
  const leaderBoardCurrentUserContainerRef = useRef<HTMLDivElement>(null);

  const rect = useResizeObserver(leaderBoardCurrentUserContainerRef);

  useEffect(() => {
    if (!leaderBoardCurrentUserContainerRef.current || !rect) {
      return;
    }

    leaderBoardCurrentUserContainerRef.current.style.top = `${window.innerHeight - 155}px`;
  }, [rect]);

  return (
    <div className="py-2 px-4 absolute z-[4] w-full" ref={leaderBoardCurrentUserContainerRef}>
      <div id={id} className="relative rounded-10 justify-between min-h-[77px] user-league-card">
        <div className="user-league-card-bg min-h-[77px] absolute h-full w-full" />
        <div className={`top-line ${league.name}`} />
        <div className={`bottom-line ${league.name}`} />
        <div className="absolute h-full w-full flex justify-between items-center px-3 py-1 rounded-10 pt-[1.8rem] pl-[0.86rem] top-0">
          <div className="flex pl-[.225rem] pt-[0.1rem] translate-y-[-6px]">
            <div className="flex flex-col items-start">
              <div className="text-base font-bold font-Bangers tracking-[0.3px]">
                {username || (`${firstName} ${lastName}`)}
              </div>
              <div className="flex items-center text-12 font-normal font-Inrias">
                {numberWithCommas(totalCoins)}
                <Coin width="18px" height="18px" className="ml-1" />
              </div>
            </div>
          </div>
          <div className="text-26 my-auto mx-0 font-normal font-Bangers usercard-rank min-w-[44px] mr-[20px] px-8 translate-y-[-7px]">
            #
            {numberWithCommas(league.rank)}
            <span className="absolute h-[3.3rem] top-[-15px] right-[-15px]">
              <TopPlayers />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderboardCurrentUserCard;
