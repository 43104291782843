function AviatorGameContainer() {
  return (
    <div>
      <div id="aviator" className="w-screen h-screen absolute top-0" />
      <canvas id="multiplier_scale" width="105" height="190" className="w-[105px] h-[190px] absolute bottom-[24rem] left-[0.5rem]" />
    </div>
  );
}

export default AviatorGameContainer;
