import { SlotTypes } from '../types/constants';

export type PIXIFile = {
  name: string
  jsonPath: string
  atlasPath: string
  animations: string[] | null[],
  attachments?: string[],
  skins?: string[],
  scale?: number
  customOffset?: { x: number, y: number }
}
export type PIXIFileListT = PIXIFile[]
export const overlayFileList = [
  {
    name: 'coins',
    jsonPath: 'overlay/coins/coins.json',
    atlasPath: 'overlay/coins/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'meteors',
    jsonPath: 'overlay/meteors/meteors.json',
    atlasPath: 'overlay/meteors/meteors.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'multiplier',
    jsonPath: 'slot-machine/multiplier/multiplier.json',
    atlasPath: 'slot-machine/multiplier/multiplier.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'galaxy',
    jsonPath: 'overlay/galaxySmall/galaxy.json',
    atlasPath: 'overlay/galaxySmall/galaxy.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsRain',
    jsonPath: 'overlay/coinsRain/coins.json',
    atlasPath: 'overlay/coinsRain/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsRainCopy',
    jsonPath: 'overlay/coinsRain/coins.json',
    atlasPath: 'overlay/coinsRain/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'energy',
    jsonPath: 'overlay/energy/energy.json',
    atlasPath: 'overlay/energy/energy.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'coinsExplosion',
    jsonPath: 'overlay/coinsExplosion/coins.json',
    atlasPath: 'overlay/coinsExplosion/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
] as const;
export const slotFileList: PIXIFileListT = [
  {
    name: SlotTypes.energy_refill, jsonPath: 'energy/energy_small.json', atlasPath: 'energy/energy_small.atlas', animations: ['animation'], scale: 1.9, customOffset: { x: 0, y: 0 },
  },
  {
    name: SlotTypes.coin_bag, jsonPath: 'coin_bag/coin_bag.json', atlasPath: 'coin_bag/coin_bag.atlas', animations: ['animation'], scale: 0.9, customOffset: { x: 0, y: 0 },
  },
  {
    name: SlotTypes.treasure, jsonPath: 'treasure/treasure.json', atlasPath: 'treasure/treasure.atlas', animations: ['animation'], scale: 1, customOffset: { x: 0, y: 0 },
  },
  // {
  //   name: SlotTypes.multiplier_x2, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x2'], scale: 1.25, customOffset: { x: -25, y: 125 },
  // },
  // {
  //   name: SlotTypes.multiplier_x5, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x5'], scale: 1.25, customOffset: { x: -25, y: 125 },
  // },
  // {
  //   name: SlotTypes.multiplier_x3, jsonPath: 'multiplier/multiplier.json', atlasPath: 'multiplier/multiplier.atlas', animations: ['multiplier_x3'], scale: 1.25, customOffset: { x: -25, y: 125 },
  // },
  // {
  //   name: SlotTypes.revive, jsonPath: 'second_chance/second_chance.json', atlasPath: 'second_chance/second_chance.atlas', animations: ['animation'],
  // },
  // {
  //   name: 'coin_booster', jsonPath: 'coin_booster/coins_booster.json', atlasPath: 'coin_booster/coins_booster.atlas', animations: ['animation'],
  // },
  // {
  //   name: SlotTypes.aviator, jsonPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.json', atlasPath: 'reduce_crash_pr_booster/reduce_crash_pr_booster.atlas', animations: ['animation'], scale: 1.1, customOffset: { x: 0, y: 0 },
  // },
  {
    name: SlotTypes.aviator, jsonPath: 'galaxy/galaxy.json', atlasPath: 'galaxy/galaxy.atlas', animations: ['animation'], scale: 1.7, customOffset: { x: 0, y: -5 },
  },
];
