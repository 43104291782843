const tg = window?.Telegram.WebApp;

export type PlatformsT = 'ios' | 'android' | 'web' | 'unknown' | 'tdesktop'
export type GetPlatformT = {
  platform: PlatformsT
  isMobile: boolean
}
export default function useGetPlatform(): GetPlatformT {
  return {
    platform: tg.platform as PlatformsT,
    isMobile: ['ios', 'android'].includes(tg.platform),
  };
}
