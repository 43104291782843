import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { ReactComponent as Coin } from '../../../assets/svg/header/coin.svg';
import { formatNumbers } from '../../../helpers';
import { useAppSelector } from '../../../store';
import { ProfileResponseT } from '../../../types';

type CoinsInfoProps = {
    coins: ProfileResponseT['coins']
}
function CoinsInfo({ coins }: CoinsInfoProps) {
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const [prevCoins, setPrevCoins] = useState<number | null>(coins);
  const [counter, setCounter] = useState(coins || 0);
  const [isPulsing, setIsPulsing] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    if (!userData?.coins || !prevCoins || prevCoins >= userData.coins) return () => null;
    setIsBlinking(false);
    const targetValue = userData.coins;
    const duration = 0.5;
    let startTime: number;

    const updateCounter = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / 1000 / duration;

      if (progress < 1) {
        setCounter(Math.round(counter + (targetValue - counter) * progress));
        timer = requestAnimationFrame(updateCounter);
      } else {
        setCounter(targetValue);
        setIsPulsing(false);
        setIsBlinking(true);
        setPrevCoins(targetValue);
      }
    };

    let timer = requestAnimationFrame(updateCounter);

    return () => cancelAnimationFrame(timer);
  }, [userData?.coins, prevCoins]);
  return (
    <div className="header-coin-card gradient-border bg-headerCoinsBG h-[33px] min-w-[79px] pl-[27px] rounded-8 before:rounded-8 relative flex justify-start items-center pr-1 ml-[18px]">
      <div className="absolute left-[-37%] top-[-1px]">
        <Coin />
      </div>
      <div className="font-Bangers text-base pr-2">
        <motion.div
          initial={{ scale: 1 }}
          animate={{
            scale: isPulsing ? [1, 1.2, 1] : 1,
            opacity: isBlinking ? [1, 0, 1] : 1,
          }}
          transition={{
            duration: (isPulsing || isBlinking) ? 0.5 : 0,
            ease: 'easeInOut',
            // eslint-disable-next-line no-nested-ternary
            repeat: isPulsing ? Infinity : isBlinking ? 4 : 0,
            repeatType: 'loop',
            times: [0, 0.5, 1],
          }}
        >
          {formatNumbers(counter, 2)}
        </motion.div>
      </div>
    </div>
  );
}

export default CoinsInfo;
