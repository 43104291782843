import { motion, useAnimate } from 'framer-motion';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { ReactComponent as Energy } from '../../assets/images/energy-22x22.svg';
import { numberWithCommas } from '../../helpers';
import { QuestT } from '../../types';
import getRewardIcon from '../utils/getRewardIcon';

type DailyQuestProps = {
    quest: QuestT
    onComplete: (quest: QuestT) => void;
    isCompleted: boolean;
}
function DailyQuest({ quest, onComplete, isCompleted }: DailyQuestProps) {
  const [scope, animate] = useAnimate();

  const onClick = () => {
    animate(scope.current, { scale: [0.9, 1], transition: { duration: 0.25, times: [0, 1] } });

    if (isCompleted) {
      return;
    }

    onComplete(quest);
  };

  return (
    <motion.button
      variants={{
        hidden: { scale: 0 },
        visible: { scale: [0, 1.1, 1], transition: { duration: 0.25, times: [0, 0.75, 1] } },
      }}
      className={`
      ${isCompleted ? 'bg-dailyRewardCardActiveBG' : 'bg-dailyRewardCardBG'}
      rounded-[12px]
      py-[7px]
      px-[11px]
      flex flex-col items-center
      gradient-border cyan
      before:rounded-[12px]
      w-full
      max-w-[109px]
    `}
      onClick={onClick}
      ref={scope}
    >
      <span className="text-xl font-Bangers">
        Day
        {' '}
        {quest.order + 1}
      </span>
      <div className="w-[64px] h-[64px] flex items-center justify-center mt-[-6px]">
        {getRewardIcon(quest.order)}
      </div>
      {
        isCompleted ? <span className="font-Bangers bg-dailyRewardCompletedText bg-clip-text text-transparent">Collected</span> : (
          <div className="flex justify-around items-center w-full">
            {quest.rewards.coins && (
            <span className="mt-1 mb-1 text-xs font-Bangers flex items-center gap-0.5">
              <Coin className="h-[14px] w-[14px]" />
              +
              {numberWithCommas(quest.rewards.coins)}
            </span>
            )}
            {quest.rewards.energy && (
            <span className="mt-1 mb-1 text-xs font-Bangers flex items-center gap-0.5">
              <Energy className="h-[14px] w-[14px]" />
              +
              {numberWithCommas(quest.rewards.energy)}
            </span>
            )}
          </div>
        )
      }
    </motion.button>
  );
}

export default DailyQuest;
