function MachineSvgDefs() {
  return (
    <>
      <radialGradient id="paint0_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.0941 322.258) rotate(35.7592) scale(68.4832 20.8003)">
        <stop stopColor="#4937C2" />
        <stop offset="0.2189" stopColor="#402FA6" />
        <stop offset="0.5575" stopColor="#332380" />
        <stop offset="0.8299" stopColor="#2C1D69" />
        <stop offset="1" stopColor="#291A60" />
      </radialGradient>
      <linearGradient id="paint1_linear_4581_194638" x1="34.2106" y1="134.541" x2="101.575" y2="134.725" gradientUnits="userSpaceOnUse">
        <stop offset="5.0614e-07" stopColor="#130073" />
        <stop offset="0.0470282" stopColor="#3E219D" />
        <stop offset="0.0983581" stopColor="#6540C4" />
        <stop offset="0.1455" stopColor="#8257E1" />
        <stop offset="0.1864" stopColor="#9364F2" />
        <stop offset="0.2166" stopColor="#9969F8" />
        <stop offset="0.2309" stopColor="#9B70F8" />
        <stop offset="0.3211" stopColor="#A996F6" />
        <stop offset="0.3935" stopColor="#B2ADF5" />
        <stop offset="0.4385" stopColor="#B5B6F5" />
        <stop offset="0.5351" stopColor="#A4ABF5" />
        <stop offset="0.8463" stopColor="#728AF3" />
        <stop offset="1" stopColor="#5F7DF3" />
      </linearGradient>
      <linearGradient id="paint2_linear_4581_194638" x1="9.17235" y1="100.45" x2="118.24" y2="32.0975" gradientUnits="userSpaceOnUse">
        <stop stopColor="#33057C" />
        <stop offset="0.147" stopColor="#291482" />
        <stop offset="0.4292" stopColor="#0D3B92" />
        <stop offset="0.4595" stopColor="#0A4094" />
        <stop offset="0.6135" stopColor="#0E67B7" />
        <stop offset="0.8199" stopColor="#15A2EA" />
        <stop offset="0.9255" stopColor="#B9D9EA" />
      </linearGradient>
      <radialGradient id="paint3_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(84.5486 39.9282) rotate(-29.2572) scale(75.391 29.8761)">
        <stop stopColor="#C4EBFF" />
        <stop offset="0.1464" stopColor="#C1E9FE" stopOpacity="0.8923" />
        <stop offset="0.272" stopColor="#B6E4FC" stopOpacity="0.7999" />
        <stop offset="0.3896" stopColor="#A4DBF8" stopOpacity="0.7133" />
        <stop offset="0.4078" stopColor="#A1D9F7" stopOpacity="0.7" />
        <stop offset="0.7916" stopColor="#43B4EE" stopOpacity="0.2463" />
        <stop offset="1" stopColor="#15A2EA" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint4_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(73.9882 309.989) rotate(-144.805) scale(97.8739 33.3152)">
        <stop offset="6.71301e-07" stopColor="#EEEEEE" />
        <stop offset="0.3578" stopColor="#6AD1F7" />
        <stop offset="0.7914" stopColor="#5D2FB3" />
        <stop offset="1" stopColor="#43146D" />
      </radialGradient>
      <radialGradient id="paint5_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.4247 133.551) rotate(180) scale(35.2175 130.524)">
        <stop stopColor="#1E429C" />
        <stop offset="0.2515" stopColor="#223487" />
        <stop offset="0.7669" stopColor="#2B1152" />
        <stop offset="1" stopColor="#300038" />
      </radialGradient>
      <linearGradient id="paint6_linear_4581_194638" x1="33.3427" y1="166.491" x2="22.7624" y2="166.991" gradientUnits="userSpaceOnUse">
        <stop offset="0.0144135" stopColor="#4F72BD" />
        <stop offset="0.4118" stopColor="#323B99" />
        <stop offset="0.7619" stopColor="#1C107E" />
        <stop offset="0.9404" stopColor="#130073" />
      </linearGradient>
      <linearGradient id="paint7_linear_4581_194638" x1="-0.472594" y1="202.473" x2="114.615" y2="54.7372" gradientUnits="userSpaceOnUse">
        <stop offset="5.0614e-07" stopColor="#2E1877" />
        <stop offset="0.453" stopColor="#2B3282" />
        <stop offset="0.6313" stopColor="#2A3E87" />
        <stop offset="0.6849" stopColor="#38519B" />
        <stop offset="0.7944" stopColor="#5C82D0" />
        <stop offset="0.8712" stopColor="#77A8F8" />
        <stop offset="0.8975" stopColor="#7EADF8" />
        <stop offset="0.9369" stopColor="#93BAF8" />
        <stop offset="0.9843" stopColor="#B4CFF8" />
        <stop offset="1" stopColor="#C1D7F8" />
      </linearGradient>
      <linearGradient id="paint8_linear_4581_194638" x1="51.5429" y1="268.604" x2="56.635" y2="261.032" gradientUnits="userSpaceOnUse">
        <stop offset="0.275" stopColor="#8688F8" />
        <stop offset="0.3926" stopColor="#6864D6" />
        <stop offset="0.5522" stopColor="#4439AF" />
        <stop offset="0.6961" stopColor="#2A1A92" />
        <stop offset="0.8177" stopColor="#1B0780" />
        <stop offset="0.9025" stopColor="#15007A" />
      </linearGradient>
      <radialGradient id="paint9_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.3165 278.028) rotate(-144.636) scale(67.9339 19.2654)">
        <stop stopColor="#A8EEFF" />
        <stop offset="0.5093" stopColor="#B19BF1" />
        <stop offset="1" stopColor="#00277C" />
      </radialGradient>
      <radialGradient id="paint10_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45.5262 300.589) rotate(35.4834) scale(68.1542 32.2407)">
        <stop stopColor="#42329C" />
        <stop offset="0.393" stopColor="#392A87" />
        <stop offset="1" stopColor="#291A60" />
      </radialGradient>
      <radialGradient id="paint11_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(92.7279 49.0828) rotate(-110.8) scale(24.0635 45.8313)">
        <stop stopColor="#9E00F8" stopOpacity="0.2" />
        <stop offset="1" stopColor="#9E00F8" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint12_linear_4581_194638" x1="45.5377" y1="58.1512" x2="89.1384" y2="28.5371" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66CBFF" />
        <stop offset="0.1235" stopColor="#6FCEFF" />
        <stop offset="0.3275" stopColor="#88D7FF" />
        <stop offset="0.5866" stopColor="#B1E5FF" />
        <stop offset="0.8878" stopColor="#E9F8FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint13_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(58.3337 281.245) rotate(36.375) scale(16.0198 7.15539)">
        <stop stopColor="#7BC1FC" />
        <stop offset="0.2405" stopColor="#78BDFA" />
        <stop offset="0.4763" stopColor="#6FB1F3" />
        <stop offset="0.7102" stopColor="#619EE8" />
        <stop offset="0.9417" stopColor="#4C82D9" />
        <stop offset="1" stopColor="#467AD4" />
      </radialGradient>
      <radialGradient id="paint14_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.7603 260.892) rotate(28.7886) scale(14.8495 6.26985)">
        <stop stopColor="#6B79DC" />
        <stop offset="0.1516" stopColor="#6A72D8" />
        <stop offset="0.3671" stopColor="#6660CE" />
        <stop offset="0.6198" stopColor="#6041BD" />
        <stop offset="0.7474" stopColor="#5D2FB3" />
        <stop offset="1" stopColor="#23087A" />
      </radialGradient>
      <linearGradient id="paint15_linear_4581_194638" x1="24.532" y1="255.811" x2="35.7393" y2="258.331" gradientUnits="userSpaceOnUse">
        <stop offset="2.23767e-07" stopColor="#ED00AE" />
        <stop offset="0.0811218" stopColor="#F01BB5" />
        <stop offset="0.2477" stopColor="#F44CC2" />
        <stop offset="0.4128" stopColor="#F876CD" />
        <stop offset="0.5731" stopColor="#FB96D5" />
        <stop offset="0.7272" stopColor="#FDADDB" />
        <stop offset="0.8726" stopColor="#FFBADF" />
        <stop offset="1" stopColor="#FFBFBE" />
      </linearGradient>
      <linearGradient id="paint16_linear_4581_194638" x1="33.1803" y1="262.904" x2="44.2984" y2="265.404" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFA100" />
        <stop offset="0.1452" stopColor="#FFAB15" />
        <stop offset="0.4463" stopColor="#FFC54C" />
        <stop offset="0.8741" stopColor="#FFEFA4" />
        <stop offset="1" stopColor="#FFFCBF" />
      </linearGradient>
      <linearGradient id="paint17_linear_4581_194638" x1="52.3079" y1="273.391" x2="55.7799" y2="280.895" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BFFFF9" />
        <stop offset="0.213" stopColor="#91F6ED" />
        <stop offset="0.5939" stopColor="#43E6D8" />
        <stop offset="0.8657" stopColor="#13DDCB" />
        <stop offset="1" stopColor="#00D9C6" />
      </linearGradient>
      <linearGradient id="paint18_linear_4581_194638" x1="60.6059" y1="280.405" x2="65.7719" y2="288.293" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3BDBFF" />
        <stop offset="0.1931" stopColor="#35D2FF" />
        <stop offset="0.5076" stopColor="#23BAFF" />
        <stop offset="0.9025" stopColor="#0792FF" />
        <stop offset="0.9963" stopColor="#0088FF" />
      </linearGradient>
      <linearGradient id="paint19_linear_4581_194638" x1="19.4122" y1="210.569" x2="19.4122" y2="190.565" gradientUnits="userSpaceOnUse">
        <stop offset="1.02294e-06" stopColor="#FF0089" />
        <stop offset="0.1677" stopColor="#FF2093" />
        <stop offset="0.5339" stopColor="#FF71AB" />
        <stop offset="0.6946" stopColor="#FF97B6" />
        <stop offset="1" stopColor="#FFCBF3" />
      </linearGradient>
      <linearGradient id="paint20_linear_4581_194638" x1="19.4122" y1="225.203" x2="19.4122" y2="215.195" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFB6A" />
        <stop offset="0.2044" stopColor="#FDFB6D" />
        <stop offset="0.3673" stopColor="#FDFB77" />
        <stop offset="0.5161" stopColor="#FDFC88" />
        <stop offset="0.6563" stopColor="#FEFCA0" />
        <stop offset="0.7905" stopColor="#FEFDBE" />
        <stop offset="0.9185" stopColor="#FFFEE3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint21_linear_4581_194638" x1="19.4122" y1="130.383" x2="19.4122" y2="120.375" gradientUnits="userSpaceOnUse">
        <stop stopColor="#009DC8" />
        <stop offset="0.1198" stopColor="#05A0C9" />
        <stop offset="0.2664" stopColor="#14A7CD" />
        <stop offset="0.427" stopColor="#2DB4D4" />
        <stop offset="0.5977" stopColor="#50C6DC" />
        <stop offset="0.7762" stopColor="#7CDDE8" />
        <stop offset="0.9588" stopColor="#B2F8F6" />
        <stop offset="1" stopColor="#BFFFF9" />
      </linearGradient>
      <radialGradient id="paint22_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(74.9463 309.785) rotate(23.0674) scale(48.694 25.576)">
        <stop stopColor="#0095D1" />
        <stop offset="0.4693" stopColor="#1E429C" />
        <stop offset="0.56" stopColor="#203C93" />
        <stop offset="0.7112" stopColor="#242B79" />
        <stop offset="0.903" stopColor="#2C1050" />
        <stop offset="1" stopColor="#300038" />
      </radialGradient>
      <radialGradient id="paint23_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.6254 304.54) rotate(5.48768) scale(62.4829 29.3685)">
        <stop stopColor="#D3F6FF" />
        <stop offset="0.0648649" stopColor="#C5ECFE" />
        <stop offset="0.2281" stopColor="#A6D7FD" />
        <stop offset="0.3194" stopColor="#9ACFFC" />
        <stop offset="0.3997" stopColor="#96C2F9" />
        <stop offset="0.5486" stopColor="#8C9FF1" />
        <stop offset="0.6017" stopColor="#8891EE" />
        <stop offset="1" stopColor="#003375" />
      </radialGradient>
      <linearGradient id="paint24_linear_4581_194638" x1="105.105" y1="288.006" x2="105.624" y2="280.567" gradientUnits="userSpaceOnUse">
        <stop offset="0.1819" stopColor="#7594E6" />
        <stop offset="0.3015" stopColor="#6C85DB" />
        <stop offset="0.5298" stopColor="#535FC0" />
        <stop offset="0.8406" stopColor="#2B2293" />
        <stop offset="1" stopColor="#15007A" />
      </linearGradient>
      <radialGradient id="paint25_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.1899 273.114) rotate(-0.661891) scale(63.2284 12.3052)">
        <stop offset="0.2384" stopColor="#CFDCFF" />
        <stop offset="0.3192" stopColor="#C5D5FC" />
        <stop offset="0.4582" stopColor="#A9C1F4" />
        <stop offset="0.6372" stopColor="#7DA2E7" />
        <stop offset="0.784" stopColor="#5385DB" />
        <stop offset="1" stopColor="#4148AD" />
      </radialGradient>
      <radialGradient id="paint26_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(124.661 291.172) rotate(-0.383591) scale(20.0711 4.28252)">
        <stop offset="0.0506305" stopColor="#0A4094" />
        <stop offset="0.1419" stopColor="#0A4094" stopOpacity="0.9039" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint27_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(104.134 310.66) rotate(97.8455) scale(19.9767 3.2713)">
        <stop stopColor="#234094" />
        <stop offset="0.00962466" stopColor="#244094" stopOpacity="0.9904" />
        <stop offset="0.6479" stopColor="#444094" stopOpacity="0.3521" />
        <stop offset="1" stopColor="#504094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint28_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(93.3447 320.708) rotate(98.899) scale(18.5803 3.12281)">
        <stop offset="0.1814" stopColor="#002F78" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint29_linear_4581_194638" x1="112.054" y1="332.49" x2="112.054" y2="330.114" gradientUnits="userSpaceOnUse">
        <stop offset="0.1198" stopColor="#000067" stopOpacity="0.6" />
        <stop offset="0.3961" stopColor="#06126A" stopOpacity="0.4117" />
        <stop offset="0.7219" stopColor="#0B206D" stopOpacity="0.1896" />
        <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint30_linear_4581_194638" x1="112.054" y1="332.49" x2="112.054" y2="307.845" gradientUnits="userSpaceOnUse">
        <stop stopColor="#000067" stopOpacity="0.6" />
        <stop offset="0.0649893" stopColor="#010368" stopOpacity="0.561" />
        <stop offset="0.6457" stopColor="#0A1C6C" stopOpacity="0.2126" />
        <stop offset="1" stopColor="#0D256E" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint31_linear_4581_194638" x1="110.808" y1="331.28" x2="110.808" y2="289.629" gradientUnits="userSpaceOnUse">
        <stop offset="0.0037245" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <radialGradient id="paint32_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(124.453 299.337) rotate(-0.27884) scale(28.3431 4.39685)">
        <stop stopColor="#324094" />
        <stop offset="1" stopColor="#324094" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint33_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(323.896 319.29) rotate(-34.9766) scale(63.7313 24.4041)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint34_linear_4581_194638" x1="358.423" y1="100.45" x2="249.356" y2="32.0975" gradientUnits="userSpaceOnUse">
        <stop stopColor="#33057C" />
        <stop offset="0.147" stopColor="#291482" />
        <stop offset="0.1645" stopColor="#211F87" />
        <stop offset="0.1993" stopColor="#162F8D" />
        <stop offset="0.2391" stopColor="#0F3891" />
        <stop offset="0.2942" stopColor="#0D3B92" />
        <stop offset="0.4595" stopColor="#0A4094" />
        <stop offset="0.6135" stopColor="#0E67B7" />
        <stop offset="0.8199" stopColor="#15A2EA" />
        <stop offset="0.9255" stopColor="#B9D9EA" />
      </linearGradient>
      <radialGradient id="paint35_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(337.231 147.375) scale(31.4803 142.485)">
        <stop offset="0.00753421" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint36_linear_4581_194638" x1="337.132" y1="167.077" x2="342.202" y2="167.077" gradientUnits="userSpaceOnUse">
        <stop stopColor="#001038" />
        <stop offset="0.9925" stopColor="#1E429C" />
      </linearGradient>
      <radialGradient id="paint37_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(275.453 31.0314) rotate(-145.345) scale(70.0331 24.2897)">
        <stop offset="2.23767e-07" stopColor="#F7F7F7" stopOpacity="0.9" />
        <stop offset="1" stopColor="#EEC8F7" stopOpacity="0" />
      </radialGradient>
      <radialGradient id="paint38_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(305.707 296.894) rotate(144.797) scale(82.1696 38.4473)">
        <stop stopColor="#E6E4EE" />
        <stop offset="0.1457" stopColor="#28F7F7" />
        <stop offset="0.5139" stopColor="#15A2EA" />
        <stop offset="0.6289" stopColor="#117AC7" />
        <stop offset="0.7335" stopColor="#0D59AA" />
        <stop offset="0.7371" stopColor="#0D57A9" />
        <stop offset="0.8565" stopColor="#062891" />
        <stop offset="0.9479" stopColor="#020B82" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <linearGradient id="paint39_linear_4581_194638" x1="315.306" y1="270.829" x2="307.99" y2="259.53" gradientUnits="userSpaceOnUse">
        <stop offset="0.3676" stopColor="#5890DC" />
        <stop offset="0.5277" stopColor="#3A5DA5" />
        <stop offset="0.6767" stopColor="#223579" />
        <stop offset="0.8097" stopColor="#10185A" />
        <stop offset="0.922" stopColor="#060647" />
        <stop offset="1" stopColor="#020040" />
      </linearGradient>
      <linearGradient id="paint40_linear_4581_194638" x1="248.763" y1="132.656" x2="338.139" y2="132.656" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3C67ED" />
        <stop offset="0.2955" stopColor="#7E99F5" />
        <stop offset="0.6397" stopColor="#CFD6FF" />
        <stop offset="0.6759" stopColor="#CBD2FF" />
        <stop offset="0.7117" stopColor="#BFC7FE" />
        <stop offset="0.7473" stopColor="#ABB5FD" />
        <stop offset="0.7828" stopColor="#909BFB" />
        <stop offset="0.8179" stopColor="#6C7AF8" />
        <stop offset="0.8207" stopColor="#6977F8" />
        <stop offset="0.9851" stopColor="#130073" />
      </linearGradient>
      <linearGradient id="paint41_linear_4581_194638" x1="347.478" y1="175.692" x2="347.478" y2="164.814" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD3390" />
        <stop offset="0.1181" stopColor="#FD3C96" />
        <stop offset="0.315" stopColor="#FD56A8" />
        <stop offset="0.5664" stopColor="#FE80C4" />
        <stop offset="0.8595" stopColor="#FFB9EB" />
        <stop offset="1" stopColor="#FFD7FF" />
      </linearGradient>
      <linearGradient id="paint42_linear_4581_194638" x1="360.187" y1="217.485" x2="280.079" y2="31.3206" gradientUnits="userSpaceOnUse">
        <stop offset="5.0614e-07" stopColor="#210C63" />
        <stop offset="0.1568" stopColor="#201066" />
        <stop offset="0.3124" stopColor="#1B1C70" />
        <stop offset="0.4677" stopColor="#153081" />
        <stop offset="0.6217" stopColor="#0B4C99" />
        <stop offset="0.7591" stopColor="#006BB3" />
        <stop offset="0.7995" stopColor="#0D73B7" />
        <stop offset="0.8746" stopColor="#308AC2" />
        <stop offset="0.9755" stopColor="#68AED4" />
        <stop offset="1" stopColor="#77B7D9" />
      </linearGradient>
      <linearGradient id="paint43_linear_4581_194638" x1="345.432" y1="156.546" x2="349.511" y2="156.546" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFB6A" />
        <stop offset="0.2044" stopColor="#FDFB6D" />
        <stop offset="0.3673" stopColor="#FDFB77" />
        <stop offset="0.5161" stopColor="#FDFC88" />
        <stop offset="0.6563" stopColor="#FEFCA0" />
        <stop offset="0.7905" stopColor="#FEFDBE" />
        <stop offset="0.9185" stopColor="#FFFEE3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint44_linear_4581_194638" x1="347.478" y1="120.955" x2="347.478" y2="110.077" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00D9C6" />
        <stop offset="0.1298" stopColor="#24E0D0" />
        <stop offset="0.3499" stopColor="#5BEBDE" />
        <stop offset="0.5546" stopColor="#86F4EA" />
        <stop offset="0.7376" stopColor="#A5FAF2" />
        <stop offset="0.8923" stopColor="#B8FEF7" />
        <stop offset="1" stopColor="#BFFFF9" />
      </linearGradient>
      <radialGradient id="paint45_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(301.426 269.433) rotate(143.821) scale(61.7194 25.564)">
        <stop stopColor="#A1EFFF" />
        <stop offset="0.2272" stopColor="#4EA8EE" />
        <stop offset="0.6417" stopColor="#0A4094" />
        <stop offset="0.9244" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint46_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(298.389 303.956) rotate(-34.9225) scale(88.6531 44.4191)">
        <stop stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint47_linear_4581_194638" x1="277.159" y1="29.0107" x2="336.057" y2="61.0559" gradientUnits="userSpaceOnUse">
        <stop offset="0.00370003" stopColor="white" />
        <stop offset="0.2626" stopColor="#D4F0FF" stopOpacity="0.7402" />
        <stop offset="0.7678" stopColor="#85D6FF" stopOpacity="0.2331" />
        <stop offset="1" stopColor="#66CBFF" stopOpacity="0" />
      </linearGradient>
      <radialGradient id="paint48_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(296.732 285.191) scale(8.14614 4.8826)">
        <stop stopColor="#BFFFF9" />
        <stop offset="0.0936113" stopColor="#B7FBF8" />
        <stop offset="0.2353" stopColor="#A3F0F4" />
        <stop offset="0.4074" stopColor="#80DDEF" />
        <stop offset="0.6034" stopColor="#51C4E7" />
        <stop offset="0.8167" stopColor="#15A3DD" />
        <stop offset="0.886" stopColor="#0098D9" />
        <stop offset="1" stopColor="#005CA8" />
      </radialGradient>
      <radialGradient id="paint49_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(298.702 286.457) rotate(-6.60944) scale(8.53907 5.38643)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint50_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(312.808 274.607) rotate(-9.94523) scale(8.44722 5.36165)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint51_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(311.355 273.533) rotate(-6.61353) scale(7.81765 4.9322)">
        <stop offset="2.23767e-07" stopColor="#FFBFDD" />
        <stop offset="0.1284" stopColor="#FFBBDB" />
        <stop offset="0.255" stopColor="#FFAFD6" />
        <stop offset="0.3811" stopColor="#FF9BCE" />
        <stop offset="0.5068" stopColor="#FF80C2" />
        <stop offset="0.6323" stopColor="#FF5CB3" />
        <stop offset="0.7559" stopColor="#FF30A1" />
        <stop offset="0.8734" stopColor="#FF008C" />
        <stop offset="1" stopColor="#A400AD" />
      </radialGradient>
      <radialGradient id="paint52_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(328.178 262.722) rotate(-9.26468) scale(8.34592 5.32457)">
        <stop stopColor="#128DDC" />
        <stop offset="0.335" stopColor="#128BDB" />
        <stop offset="0.4958" stopColor="#1183D5" />
        <stop offset="0.6194" stopColor="#0F76CD" />
        <stop offset="0.7241" stopColor="#0D64C0" />
        <stop offset="0.8167" stopColor="#0A4CB0" />
        <stop offset="0.9009" stopColor="#062E9C" />
        <stop offset="0.9771" stopColor="#020C84" />
        <stop offset="1" stopColor="#00007C" />
      </radialGradient>
      <radialGradient id="paint53_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(326.69 261.704) rotate(-6.61353) scale(7.81765 4.9322)">
        <stop stopColor="#FFFCBF" />
        <stop offset="0.1278" stopColor="#FFFABB" />
        <stop offset="0.254" stopColor="#FFF4AF" />
        <stop offset="0.3795" stopColor="#FFEB9B" />
        <stop offset="0.5047" stopColor="#FFDE80" />
        <stop offset="0.6296" stopColor="#FFCD5C" />
        <stop offset="0.7527" stopColor="#FFB830" />
        <stop offset="0.8696" stopColor="#FFA100" />
        <stop offset="1" stopColor="#AD6800" />
      </radialGradient>
      <linearGradient id="paint54_linear_4581_194638" x1="121.673" y1="363.32" x2="121.673" y2="296.685" gradientUnits="userSpaceOnUse">
        <stop offset="2.23767e-07" stopColor="#006E8C" />
        <stop offset="1" stopColor="#006E8C" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint55_linear_4581_194638" x1="121.096" y1="362.536" x2="121.096" y2="297.307" gradientUnits="userSpaceOnUse">
        <stop offset="0.0037245" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.9" />
      </linearGradient>
      <radialGradient id="paint56_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(124.111 288.894) rotate(69.4882) scale(3.65851 4.93019)">
        <stop stopColor="#FCFEFF" />
        <stop offset="0.5536" stopColor="#5D96D4" />
        <stop offset="0.6858" stopColor="#437CC0" />
        <stop offset="1" stopColor="#0A4094" />
      </radialGradient>
      <radialGradient id="paint57_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(110.129 329.189) scale(3.49414 3.48395)">
        <stop stopColor="#5D96D4" />
        <stop offset="0.174" stopColor="#538BCA" />
        <stop offset="0.4813" stopColor="#386DAF" />
        <stop offset="0.8831" stopColor="#0E3D83" />
        <stop offset="1" stopColor="#002E75" />
      </radialGradient>
      <radialGradient id="paint58_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(123.833 297.204) scale(3.86666 3.86853)">
        <stop stopColor="#FCFEFF" />
        <stop offset="0.5536" stopColor="#5D96D4" />
        <stop offset="0.6858" stopColor="#437CC0" />
        <stop offset="1" stopColor="#0A4094" />
      </radialGradient>
      <radialGradient id="paint59_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(154.204 347.768) scale(4.05918 2.60549)">
        <stop stopColor="#5D96D4" />
        <stop offset="0.174" stopColor="#538BCA" />
        <stop offset="0.4813" stopColor="#386DAF" />
        <stop offset="0.8831" stopColor="#0E3D83" />
        <stop offset="1" stopColor="#002E75" />
      </radialGradient>
      <radialGradient id="paint60_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(284.079 344.978) scale(231.077 31.3989)">
        <stop stopColor="#66ABFF" />
        <stop offset="1" stopColor="#162E6F" />
      </radialGradient>
      <linearGradient id="paint61_linear_4581_194638" x1="253.062" y1="27.2254" x2="288.665" y2="27.2254" gradientUnits="userSpaceOnUse">
        <stop offset="2.23767e-07" stopColor="#4F6AD6" />
        <stop offset="0.3007" stopColor="#5684E1" />
        <stop offset="0.9419" stopColor="#69C8FC" />
        <stop offset="1" stopColor="#6BCEFF" />
      </linearGradient>
      <linearGradient id="paint62_linear_4581_194638" x1="104.999" y1="11.195" x2="91.0982" y2="43.8163" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CFEFFF" />
        <stop offset="1" stopColor="#3C88FC" />
      </linearGradient>
      <linearGradient id="paint63_linear_4581_194638" x1="82.273" y1="297.821" x2="95.8232" y2="297.821" gradientUnits="userSpaceOnUse">
        <stop offset="0.1819" stopColor="#7594E6" />
        <stop offset="0.3015" stopColor="#6C85DB" />
        <stop offset="0.5298" stopColor="#535FC0" />
        <stop offset="0.8406" stopColor="#2B2293" />
        <stop offset="1" stopColor="#15007A" />
      </linearGradient>
      <linearGradient id="paint64_linear_4581_194638" x1="83.834" y1="294.417" x2="93.9334" y2="299.803" gradientUnits="userSpaceOnUse">
        <stop offset="0.0144132" stopColor="#FFC4FE" />
        <stop offset="0.1324" stopColor="#F3A7F1" />
        <stop offset="0.4419" stopColor="#D460D3" />
        <stop offset="0.6998" stopColor="#BE2CBC" />
        <stop offset="0.8932" stopColor="#B00CAE" />
        <stop offset="1" stopColor="#AB00A9" />
      </linearGradient>
      <linearGradient id="paint65_linear_4581_194638" x1="85.2722" y1="292.65" x2="92.3534" y2="299.752" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FED2FD" />
        <stop offset="0.1403" stopColor="#FAC8FD" />
        <stop offset="0.3761" stopColor="#EEAEFC" />
        <stop offset="0.6786" stopColor="#DC84FA" />
        <stop offset="1" stopColor="#C54FF8" />
      </linearGradient>
      <linearGradient id="paint66_linear_4581_194638" x1="235.867" y1="302.12" x2="283.439" y2="302.12" gradientUnits="userSpaceOnUse">
        <stop offset="4.47534e-07" stopColor="#002673" />
        <stop offset="0.0423547" stopColor="#104288" />
        <stop offset="0.1333" stopColor="#3079B2" />
        <stop offset="0.2178" stopColor="#48A5D3" />
        <stop offset="0.2933" stopColor="#5AC4EB" />
        <stop offset="0.357" stopColor="#65D7FA" />
        <stop offset="0.4013" stopColor="#69DEFF" />
        <stop offset="0.5893" stopColor="#8CE5FF" />
        <stop offset="0.8627" stopColor="#BAEFFF" />
        <stop offset="1" stopColor="#CCF3FF" />
      </linearGradient>
      <linearGradient id="paint67_linear_4581_194638" x1="247.834" y1="287.554" x2="253.033" y2="294.571" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CCF3FF" />
        <stop offset="0.1232" stopColor="#B0EDFF" />
        <stop offset="0.3864" stopColor="#69DEFF" />
        <stop offset="0.4693" stopColor="#64D8F9" />
        <stop offset="0.5824" stopColor="#57C7E7" />
        <stop offset="0.713" stopColor="#42AACB" />
        <stop offset="0.8565" stopColor="#2483A3" />
        <stop offset="1" stopColor="#005473" />
      </linearGradient>
      <linearGradient id="paint68_linear_4581_194638" x1="245.949" y1="301.065" x2="253.594" y2="305.315" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CCF3FF" />
        <stop offset="0.1232" stopColor="#B0EDFF" />
        <stop offset="0.3864" stopColor="#69DEFF" />
        <stop offset="0.4653" stopColor="#65D8FB" />
        <stop offset="0.5731" stopColor="#59C6EF" />
        <stop offset="0.6976" stopColor="#44AADD" />
        <stop offset="0.8345" stopColor="#2981C2" />
        <stop offset="0.9801" stopColor="#054FA1" />
        <stop offset="1" stopColor="#00479C" />
      </linearGradient>
      <radialGradient id="paint69_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(274.627 302.2) scale(4.0776 18.733)">
        <stop stopColor="#AE5AE6" />
        <stop offset="0.1811" stopColor="#944FCC" />
        <stop offset="0.57" stopColor="#513288" />
        <stop offset="1" stopColor="#000F36" />
      </radialGradient>
      <radialGradient id="paint70_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(262.711 302.192) scale(3.82465 16.2211)">
        <stop offset="2.23767e-07" stopColor="#00A4DB" />
        <stop offset="0.1147" stopColor="#029ED7" />
        <stop offset="0.2633" stopColor="#078ECD" />
        <stop offset="0.4304" stopColor="#0F73BC" />
        <stop offset="0.61" stopColor="#1A4FA4" />
        <stop offset="0.6637" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <linearGradient id="paint71_linear_4581_194638" x1="272.54" y1="292.684" x2="276.77" y2="295.133" gradientUnits="userSpaceOnUse">
        <stop offset="0.0144132" stopColor="#FFC4FE" />
        <stop offset="0.0498518" stopColor="#FFBEFE" />
        <stop offset="0.7069" stopColor="#FA56F7" />
        <stop offset="1" stopColor="#F82DF5" />
      </linearGradient>
      <linearGradient id="paint72_linear_4581_194638" x1="260.614" y1="309.275" x2="264.851" y2="311.167" gradientUnits="userSpaceOnUse">
        <stop offset="0.0144132" stopColor="#D6FDFF" />
        <stop offset="0.1408" stopColor="#C2FBFE" />
        <stop offset="0.4009" stopColor="#8DF7FD" />
        <stop offset="0.7685" stopColor="#38F0FA" />
        <stop offset="1" stopColor="#00ECF8" />
      </linearGradient>
      <radialGradient id="paint73_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(265.094 275.386) scale(54.3674 9.03032)">
        <stop offset="0.0144134" stopColor="#D1E9F3" />
        <stop offset="0.4998" stopColor="#69CAF8" />
        <stop offset="1" stopColor="#27277D" />
      </radialGradient>
      <radialGradient id="paint74_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(181.533 314.811) rotate(-0.653782) scale(88.6765 32.2424)">
        <stop offset="0.0506305" stopColor="#0A4094" />
        <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint75_linear_4581_194638" x1="182.373" y1="280.965" x2="182.218" y2="272.082" gradientUnits="userSpaceOnUse">
        <stop offset="2.23767e-07" stopColor="#69AAFF" />
        <stop offset="0.0647868" stopColor="#7BB5FF" />
        <stop offset="0.2341" stopColor="#A3CDFF" />
        <stop offset="0.3999" stopColor="#C4E1FF" />
        <stop offset="0.5594" stopColor="#DAEFFF" />
        <stop offset="0.7095" stopColor="#E8F7FF" />
        <stop offset="0.8408" stopColor="#EDFAFF" />
        <stop offset="0.8506" stopColor="#E8F9FF" />
        <stop offset="0.8906" stopColor="#D8F6FF" />
        <stop offset="0.9364" stopColor="#CFF4FF" />
        <stop offset="1" stopColor="#CCF3FF" />
      </linearGradient>
      <linearGradient id="paint76_linear_4581_194638" x1="182.424" y1="303.869" x2="181.526" y2="278.06" gradientUnits="userSpaceOnUse">
        <stop offset="5.0614e-07" stopColor="#2E1877" />
        <stop offset="0.0614511" stopColor="#30207C" />
        <stop offset="0.1544" stopColor="#37348C" />
        <stop offset="0.2672" stopColor="#4356A4" />
        <stop offset="0.3956" stopColor="#5286C7" />
        <stop offset="0.5355" stopColor="#66C2F2" />
        <stop offset="0.5538" stopColor="#69CAF8" />
        <stop offset="0.9856" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint77_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(145.38 290.145) scale(14.2216 3.83215)">
        <stop stopColor="#BA87FF" />
        <stop offset="0.1721" stopColor="#AB78EF" />
        <stop offset="0.5094" stopColor="#8451C5" />
        <stop offset="0.9753" stopColor="#451281" />
        <stop offset="1" stopColor="#420F7D" />
      </radialGradient>
      <linearGradient id="paint78_linear_4581_194638" x1="145.387" y1="292.639" x2="145.387" y2="287.677" gradientUnits="userSpaceOnUse">
        <stop offset="2.23767e-07" stopColor="#67A3FC" />
        <stop offset="0.1558" stopColor="#6CA7FC" />
        <stop offset="0.3518" stopColor="#7CB1FD" />
        <stop offset="0.5694" stopColor="#96C2FD" />
        <stop offset="0.8008" stopColor="#B9DAFE" />
        <stop offset="0.9856" stopColor="#DBF1FF" />
      </linearGradient>
      <radialGradient id="paint79_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.617 290.166) scale(15.9019 4.03192)">
        <stop offset="2.23767e-07" stopColor="#00A4DB" />
        <stop offset="0.1147" stopColor="#029ED7" />
        <stop offset="0.2633" stopColor="#078ECD" />
        <stop offset="0.4304" stopColor="#0F73BC" />
        <stop offset="0.61" stopColor="#1A4FA4" />
        <stop offset="0.6637" stopColor="#1E429C" />
        <stop offset="1" stopColor="#001038" />
      </radialGradient>
      <radialGradient id="paint80_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(219.854 290.174) scale(14.0503 3.62712)">
        <stop stopColor="#BA87FF" />
        <stop offset="0.1721" stopColor="#AB78EF" />
        <stop offset="0.5094" stopColor="#8451C5" />
        <stop offset="0.9753" stopColor="#451281" />
        <stop offset="1" stopColor="#420F7D" />
      </radialGradient>
      <linearGradient id="paint81_linear_4581_194638" x1="219.865" y1="292.639" x2="219.865" y2="287.677" gradientUnits="userSpaceOnUse">
        <stop offset="2.23767e-07" stopColor="#67A3FC" />
        <stop offset="0.1558" stopColor="#6CA7FC" />
        <stop offset="0.3518" stopColor="#7CB1FD" />
        <stop offset="0.5694" stopColor="#96C2FD" />
        <stop offset="0.8008" stopColor="#B9DAFE" />
        <stop offset="0.9856" stopColor="#DBF1FF" />
      </linearGradient>
      <radialGradient id="paint82_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.394 290.647) rotate(31.1629) scale(4.72812 4.44945)">
        <stop offset="0.4126" stopColor="#001959" />
        <stop offset="0.8768" stopColor="#001959" stopOpacity="0" />
      </radialGradient>
      <linearGradient id="paint83_linear_4581_194638" x1="180.155" y1="288.213" x2="185.484" y2="292.096" gradientUnits="userSpaceOnUse">
        <stop offset="0.0144134" stopColor="white" />
        <stop offset="0.1866" stopColor="#EBF2FE" />
        <stop offset="0.5409" stopColor="#B6D2FC" />
        <stop offset="1" stopColor="#69A2F8" />
      </linearGradient>
      <linearGradient id="paint84_linear_4581_194638" x1="29.3721" y1="262.491" x2="80.6189" y2="262.491" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66CBFF" />
        <stop offset="0.0937973" stopColor="#77D1FF" />
        <stop offset="0.5283" stopColor="#C0E9FF" />
        <stop offset="0.8401" stopColor="#EDF9FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint85_linear_4581_194638" x1="54.1089" y1="264.093" x2="45.2038" y2="305.162" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66CBFF" />
        <stop offset="0.0937973" stopColor="#77D1FF" />
        <stop offset="0.5283" stopColor="#C0E9FF" />
        <stop offset="0.8401" stopColor="#EDF9FF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint86_linear_4581_194638" x1="186.766" y1="55.1294" x2="181.7" y2="-2.9504" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66CBFF" />
        <stop offset="0.2751" stopColor="#92D4F9" />
        <stop offset="0.6" stopColor="#BFDCF3" />
        <stop offset="0.8512" stopColor="#DBE2EF" />
        <stop offset="0.9963" stopColor="#E6E4EE" />
      </linearGradient>
      <linearGradient id="paint87_linear_4581_194638" x1="145.114" y1="46.5027" x2="141.104" y2="0.537104" gradientUnits="userSpaceOnUse">
        <stop stopColor="#66CBFF" />
        <stop offset="0.1422" stopColor="#8AD7FF" />
        <stop offset="0.3306" stopColor="#B4E6FF" />
        <stop offset="0.5146" stopColor="#D5F1FF" />
        <stop offset="0.6906" stopColor="#ECF9FF" />
        <stop offset="0.855" stopColor="#FAFDFF" />
        <stop offset="0.9963" stopColor="white" />
      </linearGradient>
      <radialGradient id="paint88_radial_4581_194638" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(81.7566 281.245) rotate(-0.661899) scale(30.4045 5.91723)">
        <stop stopColor="#CFDCFF" />
        <stop offset="0.1292" stopColor="#C9D6FB" />
        <stop offset="0.309" stopColor="#B8C4F1" />
        <stop offset="0.5185" stopColor="#9BA6E1" />
        <stop offset="0.7504" stopColor="#747DCA" />
        <stop offset="0.9982" stopColor="#4148AD" />
        <stop offset="1" stopColor="#4148AD" />
      </radialGradient>
      <linearGradient id="paint89_linear_4581_194638" x1="180.686" y1="330.004" x2="186.306" y2="370.705" gradientUnits="userSpaceOnUse">
        <stop offset="5.0614e-07" stopColor="#2E1877" />
        <stop offset="0.2066" stopColor="#4153A2" />
        <stop offset="0.5239" stopColor="#5EA8DF" />
        <stop offset="0.6685" stopColor="#69CAF8" />
        <stop offset="0.7443" stopColor="#7ED1F8" />
        <stop offset="0.8995" stopColor="#B2E2F8" />
        <stop offset="1" stopColor="#D8EEF8" />
      </linearGradient>
      <linearGradient id="paint90_linear_4581_194638" x1="182.12" y1="347.591" x2="181.107" y2="332.854" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B6D7F3" />
        <stop offset="0.2304" stopColor="#6BB4F3" />
        <stop offset="1" stopColor="#00356E" />
      </linearGradient>
      <linearGradient id="paint91_linear_4581_194638" x1="143.879" y1="351.972" x2="143.879" y2="347.556" gradientUnits="userSpaceOnUse">
        <stop offset="7.88513e-07" stopColor="#FF63B6" />
        <stop offset="0.1947" stopColor="#FF5FB4" />
        <stop offset="0.3982" stopColor="#FF52AE" />
        <stop offset="0.6059" stopColor="#FF3DA5" />
        <stop offset="0.815" stopColor="#FF2098" />
        <stop offset="1" stopColor="#FF0089" />
      </linearGradient>
      <linearGradient id="paint92_linear_4581_194638" x1="143.879" y1="351.053" x2="143.879" y2="347.557" gradientUnits="userSpaceOnUse">
        <stop offset="0.00372451" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient id="paint93_linear_4581_194638" x1="181.694" y1="351.972" x2="181.694" y2="347.556" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEFFEB" />
        <stop offset="0.3836" stopColor="#E7F148" />
        <stop offset="0.7478" stopColor="#F6CF1B" />
        <stop offset="1" stopColor="#FFBB00" />
      </linearGradient>
      <linearGradient id="paint94_linear_4581_194638" x1="219.453" y1="351.972" x2="219.453" y2="347.556" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00FFEA" />
        <stop offset="0.1191" stopColor="#00F2E6" />
        <stop offset="0.3374" stopColor="#00D1DA" />
        <stop offset="0.6307" stopColor="#009BC8" />
        <stop offset="0.9844" stopColor="#0051AE" />
        <stop offset="1" stopColor="#004EAD" />
      </linearGradient>
      <linearGradient id="paint95_linear_4581_194638" x1="219.453" y1="351.053" x2="219.453" y2="347.557" gradientUnits="userSpaceOnUse">
        <stop offset="0.00372451" stopColor="white" stopOpacity="0" />
        <stop offset="0.9963" stopColor="white" stopOpacity="0.9" />
      </linearGradient>
      <linearGradient id="paint96_linear_4581_194638" x1="181.666" y1="351.053" x2="181.666" y2="347.557" gradientUnits="userSpaceOnUse">
        <stop offset="0.00372451" stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient id="paint97_linear_4581_194638" x1="114.97" y1="333.262" x2="247.757" y2="333.262" gradientUnits="userSpaceOnUse">
        <stop offset="0.00372442" stopColor="#E6E4EE" />
        <stop offset="0.21" stopColor="#E3E3EE" />
        <stop offset="0.3889" stopColor="#D8DFF0" />
        <stop offset="0.5576" stopColor="#C6D8F3" />
        <stop offset="0.72" stopColor="#ADCFF6" />
        <stop offset="0.8766" stopColor="#8DC3FB" />
        <stop offset="1" stopColor="#6EB7FF" />
      </linearGradient>
      <linearGradient id="paint98_linear_4581_194638" x1="20.5554" y1="89.0168" x2="21.8656" y2="85.6281" gradientUnits="userSpaceOnUse">
        <stop offset="0.1855" stopColor="#1B69F8" />
        <stop offset="0.7404" stopColor="#2E1877" />
        <stop offset="1" stopColor="#221157" />
      </linearGradient>
      <linearGradient id="paint99_linear_4581_194638" x1="347.497" y1="88.6813" x2="346.215" y2="85.4868" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0379FF" />
        <stop offset="0.1277" stopColor="#0363DC" />
        <stop offset="0.3486" stopColor="#0340A5" />
        <stop offset="0.554" stopColor="#022479" />
        <stop offset="0.7375" stopColor="#02105A" />
        <stop offset="0.8924" stopColor="#020447" />
        <stop offset="1" stopColor="#020040" />
      </linearGradient>
    </>
  );
}
export default MachineSvgDefs;
