import Phaser from 'phaser';
import { Howl } from 'howler';
import { calculateScale } from '../../helpers';
import eventEmitter, { emitGameEvent, GameEvents } from './eventEmiter';
import { MultiplierScale } from './MultiplierScale';
import aviatorEngine from '../../assets/sounds/sc_aviator_engine.wav';
import aviatorEngineEnd from '../../assets/sounds/sc_aviator_engine_end.wav';
import aviatorExplode from '../../assets/sounds/sc_aviator_xplode.wav';
import aviatorLaunch from '../../assets/sounds/sc_aviator_launch.wav';

export type AviatorAnimationsUnion = 'all' | 'emotions' | 'explosion' | 'fire' | 'flying' | 'flying_with_blinking' | 'flying_with_blinking_hot'
| 'flying_with_emotions' | 'hot' | 'planet' | 'racoon' | 'sun' | 'takeoff' | 'ufo'
export const fileListEdit = [
  {
    name: 'rocket',
    jsonPath: 'aviator/character/characters.json',
    atlasPath: 'aviator/character/characters.atlas',
    skins: ['default'],
    attachments: [null],
    animations: ['all'],
  },
  {
    name: 'background',
    jsonPath: 'aviator/looped_bg/background.json',
    atlasPath: 'aviator/looped_bg/background.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'racoon',
    jsonPath: 'aviator/racoon/racoon.json',
    atlasPath: 'aviator/racoon/racoon.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: ['racoon', 'racoon_fly1', 'racoon_fly2', 'racoon_fly3'],
  },
  {
    name: 'sun',
    jsonPath: 'aviator/sun/sun.json',
    atlasPath: 'aviator/sun/sun.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
  {
    name: 'ufo',
    jsonPath: 'aviator/ufo/ufo.json',
    atlasPath: 'aviator/ufo/ufo.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: ['ufo', 'ufo_fly_1', 'ufo_fly_2', 'ufo_fly_3'],
  },
  {
    name: 'planet',
    jsonPath: 'aviator/planet/planet.json',
    atlasPath: 'aviator/planet/planet.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: ['planet', 'planet_fly', 'planet_fly2', 'planet_fly3'],
  },
  {
    name: 'coins_shower',
    jsonPath: 'overlay/coins/coins.json',
    atlasPath: 'overlay/coins/coins.atlas',
    skins: ['default'],
    attachments: ['default'],
    animations: [null],
  },
] as const;

type FlyingObjectsNameT = 'racoon' | 'sun' | 'ufo' | 'planet'
type AnimationData = typeof fileListEdit[number]
interface AviatorPhaserSpine extends SpineGameObject {
  isFlyingObject?: boolean
}
const sounds = {
  aviatorFlight: new Howl({ src: [aviatorEngine] }),
  winGameAudio: new Howl({ src: [aviatorEngineEnd] }),
  explosionSound: new Howl({ src: [aviatorExplode] }),
  aviatorStart: new Howl({ src: [aviatorLaunch] }),
};
export class AviatorScene extends Phaser.Scene {
  private rocketData: AnimationData;

  private backgroundData: AnimationData;

  private racoonData: AnimationData;

  private ufoData: AnimationData;

  private planetData: AnimationData;

  private coinsShowerData: AnimationData;

  private sunData: AnimationData;

  private flyingObjectsData: {[key in FlyingObjectsNameT]: typeof fileListEdit[number]};

  private isGameStarted = false;

  private multiplierScale = null;

  constructor() {
    super('AviatorScene');
  }

  preload() {
    this.load.setPath('spine');
    [this.rocketData, this.backgroundData, this.racoonData, this.sunData] = fileListEdit;

    const objectsData: {[key in FlyingObjectsNameT]: typeof fileListEdit[number]} = {
      racoon: fileListEdit[2],
      sun: fileListEdit[3],
      ufo: fileListEdit[4],
      planet: fileListEdit[5],
    };
    this.flyingObjectsData = objectsData;

    // eslint-disable-next-line prefer-destructuring
    this.coinsShowerData = fileListEdit[6];
    fileListEdit.forEach((file) => {
      this.load.spine(file.name, file.jsonPath, file.atlasPath);
    });
    this.load.svg('pointer', 'aviator/pointer.svg');
  }

  drawMultiplierScale(value = 0) {
    const multiplierScaleDrawer = this.multiplierScale || new MultiplierScale();
    multiplierScaleDrawer.draw(value);
  }

  create() {
    let rocket: AviatorPhaserSpine;
    const coinsShower: AviatorPhaserSpine = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.coinsShowerData.name,
      undefined,
      false,
    );
    let background: SpineGameObject;
    const sun: AviatorPhaserSpine = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height,
      this.sunData.name,
      'sun',
      true,
    );
    let currentTrackTime: number;
    let stopOverlayAnimations = false;
    let delayedAnimations: Phaser.Time.TimerEvent[] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.game.renderer.pipelines.imageSmoothingQuality = 'high';
    this.cameras.main.roundPixels = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.scale.resolution = window.devicePixelRatio;
    this.cameras.main.setRoundPixels(false);
    this.textures.get('characters.png').setFilter(Phaser.Textures.FilterMode.LINEAR);
    this.textures.get('characters2.png').setFilter(Phaser.Textures.FilterMode.LINEAR);

    const setBackground = () => {
      // eslint-disable-next-line prefer-const
      background = this.add.spine(
        this.game.scale.width / 2,
        this.game.scale.height,
        this.backgroundData.name,
        this.backgroundData.animations[0] as string,
        true,
      );
      // background.setScale(0.8);
      const scaleX = this.cameras.main.width / (background.width - 30);
      const scaleY = this.cameras.main.height / background.height;
      const scale = Math.max(scaleX, scaleY);
      background.setScale(scale).setScrollFactor(0);
    };
    setBackground();
    const setAnimation = (char: AviatorPhaserSpine, animation: AviatorAnimationsUnion, loop = false, track = 0) => {
      char.state.setAnimation(track, animation, loop);
    };

    // eslint-disable-next-line prefer-const
    rocket = this.add.spine(
      this.game.scale.width / 2,
      this.game.scale.height * 0.83,
      this.rocketData.name,
      undefined,
      false,
    );
    rocket.setScale(calculateScale(rocket, 93, 190) * 2);
    rocket.setDepth(7);

    function pauseSpineAnimation(gameObject: AviatorPhaserSpine, trackIndex = 0) {
      const currentTrack = gameObject.state.getCurrent(trackIndex);
      if (currentTrack) {
        // Save animation time
        currentTrackTime = currentTrack.trackTime;
        gameObject.state.setEmptyAnimation(trackIndex, 0); // Stop animation
        window.console.log('Animation paused:', currentTrackTime);
      } else {
        window.console.error('Animation track not found');
      }
    }

    function resumeSpineAnimation(gameObject: AviatorPhaserSpine, animationName: string, trackIndex = 0) {
      gameObject.setAnimation(trackIndex, animationName, false); // Start animation again
      const currentTrack = gameObject.state.getCurrent(trackIndex);
      if (currentTrack) {
        currentTrack.trackTime = currentTrackTime; // Reset track time
        window.console.log('Resume animation:', currentTrackTime);
      } else {
        window.console.error('Failed to resume animation.');
      }
    }

    const cleanUpGameOverlaysAnimation = () => {
      this.scene.scene.children.list.forEach((child) => {
        if ((child as unknown as AviatorPhaserSpine).isFlyingObject) {
          child.destroy();
        }
      });
      stopOverlayAnimations = true;
      delayedAnimations.forEach((obj) => {
        obj.remove();
      });
      delayedAnimations = [];
    };

    const showExplosion = () => {
      sounds.aviatorStart.loop(false);
      sounds.aviatorStart.stop();
      sounds.aviatorFlight.loop(false);
      sounds.aviatorFlight.stop();
      const currentTrack = rocket.state.getCurrent(0);
      if (currentTrack) {
        currentTrackTime = currentTrack.trackTime;
        const rootDiv = document.getElementById('root')?.getBoundingClientRect();
        if (rootDiv) {
          rocket.y = rootDiv.height / 2 + 350;
          sounds.explosionSound.play();
          rocket.state.setAnimation(0, 'explosion', false);
          rocket.once('complete', () => {
            cleanUpGameOverlaysAnimation();
            emitGameEvent({ event: GameEvents.EXPLOSION_END });
          });
        }
      }
      this.time.removeAllEvents();
    };

    rocket.stateData.setMix('takeoff', 'takeoff', 0.2);
    rocket.stateData.setMix('takeoff', 'all', 0.5);
    rocket.stateData.setMix('all', 'explosion', 0.2);
    rocket.stateData.setMix('explosion', 'all', 0.2);

    const takeoff = () => {
      this.time.addEvent({
        delay: 700,
        // repeatCount: 6,
        callback: () => {
          // Start from
          setAnimation(rocket, 'takeoff', true);
          setAnimation(background, 'takeoff', true);
        },
        loop: true,
      });
    };

    const playObjectFly = ({ spineObject, onComplete, maxDepth = 11 }: {spineObject: AviatorPhaserSpine, onComplete: () => void, maxDepth?: number}) => {
      const startX = Phaser.Math.Between(50, this.cameras.main.width - 50);
      const startY = -100;
      const endY = this.cameras.main.height + 1000;
      spineObject.visible = true;
      spineObject.setPosition(startX, startY);
      const depth = Math.floor(Math.random() * maxDepth) + 1;
      spineObject.setDepth(depth);
      spineObject.setScale(depth * 0.07);
      const duration = Phaser.Math.Between(4000, 10000);
      this.tweens.add({
        targets: spineObject,
        y: endY,
        duration,
        ease: 'Linear',
        onComplete: () => {
          spineObject.destroy();
          onComplete();
        },
      });

      this.tweens.add({
        targets: spineObject,
        x: {
          getStart: () => startX,
          getEnd: () => Phaser.Math.Between(50, this.cameras.main.width - 50),
        },
        duration: Phaser.Math.Between(1000, 2000),
        ease: 'Sine.easeInOut',
        yoyo: true,
        repeat: -1,
      });
    };
    const startRandomAnimationLoop = (spineName: FlyingObjectsNameT, minDelay: number, maxDelay: number, maxDepth = 11) => {
      const scheduleNextAnimation = () => {
        const spineObject: AviatorPhaserSpine = this.add.spine(
          this.game.scale.width / 2,
          this.game.scale.height,
          this.flyingObjectsData[spineName].name,
          this.flyingObjectsData[spineName].animations[0] as string,
          true,
        );
        spineObject.isFlyingObject = true;
        spineObject.name = this.flyingObjectsData[spineName].name;
        spineObject.visible = false;
        const delay = Phaser.Math.Between(minDelay, maxDelay);
        const delayed = spineObject.scene.time.delayedCall(delay, () => {
          if (stopOverlayAnimations) {
            return;
          }
          const onAnimationEnd = () => {
            if (stopOverlayAnimations) {
              return;
            }
            scheduleNextAnimation();
          };
          playObjectFly({ spineObject, onComplete: onAnimationEnd, maxDepth });
        });
        delayedAnimations.push(delayed);
      };
      scheduleNextAnimation();
    };

    sun.visible = false;
    sun.setScale(0.4);

    // coinsShower =
    coinsShower.setScale(calculateScale(coinsShower, this.game.scale.width + 50, this.game.scale.height + 100));
    const playNextAnimationWithDelay = (spineObject: AviatorPhaserSpine) => {
      const frameRate = this.game.loop.actualFps;
      const delay = (440 / frameRate) * 1000;
      const delayed = spineObject.scene.time.delayedCall(delay, () => {
        const startX = this.cameras.main.width / 2;
        const startY = -100;
        const endY = this.cameras.main.height + 1000;
        sun.visible = true;
        sun.timeScale = 0.5;
        sun.setPosition(startX, startY);
        const duration = Phaser.Math.Between(4000, 10000);
        this.tweens.add({
          targets: sun,
          y: endY,
          duration,
          ease: 'Linear',
        });
      });
      delayedAnimations.push(delayed);
    };

    function startGame() {
      rocket.state.setAnimation(0, 'all', false).trackTime = 2;
      playNextAnimationWithDelay(sun);
      startRandomAnimationLoop('racoon', 1000, 2000);
      startRandomAnimationLoop('ufo', 2000, 20000);
      startRandomAnimationLoop('planet', 2000, 10000, 6);
      rocket.stateData.setMix('all', 'flying_with_blinking_hot', 0.2);
      rocket.state.addAnimation(0, 'flying_with_blinking_hot', true, 0);
      background.state.setAnimation(0, 'takeoff', false).trackTime = 1;
      background.state.addAnimation(0, 'loop', true, 0);
      sounds.aviatorStart.play();
      sounds.aviatorStart.on('end', () => {
        sounds.aviatorFlight.loop(true);
        sounds.aviatorFlight.play();
      });
      // background.timeScale = 0.5;
      // background.on('complete', () => {
      //   background.state.setAnimation(0, 'takeoff', false).trackTime = 8.5;
      // });
    }

    const runCoinShower = () => {
      coinsShower.setDepth(20);
      coinsShower.state.setAnimation(0, 'win', false).trackTime = 1;
      coinsShower.once('complete', () => {
        emitGameEvent({ event: GameEvents.OVERLAY_COINS_RAIN_START });
      });
    };

    const winGame = () => {
      sounds.aviatorStart.loop(false);
      sounds.aviatorStart.stop();
      sounds.aviatorFlight.loop(false);
      sounds.aviatorFlight.stop();
      this.isGameStarted = false;
      sounds.winGameAudio.play();
      background.timeScale = 0;
      this.tweens.add({
        targets: rocket,
        y: -rocket.height,
        duration: 2000,
        ease: 'Power1',
        callbacks: () => {
          cleanUpGameOverlaysAnimation();
          runCoinShower();
        },
      });
    };
    eventEmitter.on(GameEvents.SHOW_WIN_COINS_AVIATOR_LAYER, () => {
      runCoinShower();
    });
    eventEmitter.on(GameEvents.START_GAME, () => {
      this.time.removeAllEvents();
      startGame();
      this.isGameStarted = true;
    });
    eventEmitter.on(GameEvents.STOP_GAME, () => {
      this.isGameStarted = false;
      showExplosion();
      background.timeScale = 0;
    });
    eventEmitter.on(GameEvents.TAKEOFF, () => {
      takeoff();
    });
    eventEmitter.on(GameEvents.RESUME_GAME, () => {
      resumeSpineAnimation(rocket, 'all');
    });
    eventEmitter.on(GameEvents.PAUSE_GAME, () => {
      this.isGameStarted = false;
      pauseSpineAnimation(rocket, 0);
    });
    eventEmitter.on(GameEvents.SET_AVIATOR_MULTIPLIER, ({ value }: {value: number}) => {
      this.drawMultiplierScale(value);
    });

    eventEmitter.on(GameEvents.RESTART, () => {
      eventEmitter.off(GameEvents.START_GAME);
      eventEmitter.off(GameEvents.STOP_GAME);
      eventEmitter.off(GameEvents.TAKEOFF);
      eventEmitter.off(GameEvents.RESUME_GAME);
      eventEmitter.off(GameEvents.PAUSE_GAME);
      eventEmitter.off(GameEvents.RESTART);
      eventEmitter.off(GameEvents.WIN_GAME);
      eventEmitter.off(GameEvents.SET_AVIATOR_MULTIPLIER);
      eventEmitter.off(GameEvents.SHOW_WIN_COINS_AVIATOR_LAYER);
      this.scene.restart();
    });
    eventEmitter.on(GameEvents.WIN_GAME, winGame);
  }
}
