import { useEffect, useState } from 'react';
import { ReactComponent as CoinSVG } from '../../../assets/images/coin-size.svg';
import { ReactComponent as CircleCheck } from '../../../assets/svg/circleCheck.svg';
import { getSecondsBetweenDates } from '../../../helpers';
import { useCountDown } from '../../../hooks/useCoundown';
import { useAutopilotContext } from '../../../providers/AutopilotProvider';
import { useAppSelector } from '../../../store';
import { LeaguesSetting, SettingsResponse } from '../../../store/reducers/settings/types';
import {
  AutopilotT, LeaguesUnion, ShopItemBuyResponse, ShopItemT,
} from '../../../types';
import Button, { ButtonProps } from '../../Button';
import LeagueIcon from '../../LeagueIcon';
import { ShopTabs } from '../tabs';

type ShopItemButtonsProps = {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
  activeTab?: typeof ShopTabs[number]
  onStartFarm?: () => void
  onCollectFarm?: () => void
  disabled?: boolean
  expiresAt?: ShopItemBuyResponse['expires_at']
  activeTemporaryItemIds?: number[]
}
function ShopItemButtons({
  item, onBuy, activeTab, onStartFarm, onCollectFarm, disabled, expiresAt, activeTemporaryItemIds,
}: ShopItemButtonsProps) {
  const { autopilot } = useAppSelector(
    ({ shop }) => shop,
  );
  const purchasedLevels = [...item.levels || []].filter((level) => !!level.purchased_at);
  const autopilotButtons = () => (
    <div className="flex gap-2 flex-col-reverse items-end">
      {/* eslint-disable-next-line no-nested-ternary */}
      {purchasedLevels?.length
        ? autopilot?.started_at ? <EarnedButton autopilot={autopilot} onCollectFarm={onCollectFarm} disabled={disabled} /> : <StartFarmButton onClick={onStartFarm} disabled={disabled} /> : null}
      <BuyButtons item={item} onBuy={onBuy} disabled={disabled} />
    </div>
  );
  const renderDynamicItemButtons = () => {
    const isValidDate = expiresAt && new Date(expiresAt) > new Date();
    const [isVisible, setIsVisible] = useState<boolean>(!!isValidDate);
    useEffect(() => {
      setIsVisible(!!isValidDate);
    }, [isValidDate]);
    if (activeTemporaryItemIds?.some((id) => item.levels?.map((el) => el.id).includes(id)) && isValidDate && isVisible) {
      return <IsActiveTimerButton expiresAt={expiresAt} setIsVisible={setIsVisible} />;
    }
    return <BuyButton onClick={() => onBuy(item)} />;
  };
  const renderPermanentTabButtons = () => {
    if (item?.levels?.[0]?.effects[0].name === 'autopilot') {
      return autopilotButtons();
    }
    return <BuyButtons item={item} onBuy={onBuy} disabled={disabled} />;
  };
  const renderButton = () => {
    if (activeTab === 'permanent') {
      return renderPermanentTabButtons();
    }
    return renderDynamicItemButtons();
  };
  return renderButton();
}

export default ShopItemButtons;

type BuyBtnsProps = ButtonProps & {
  item: ShopItemT
  onBuy: (item: ShopItemT) => void
}
function BuyButtons({ item, onBuy, ...props }: BuyBtnsProps) {
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const { purchases } = useAppSelector(
    ({ shop }) => shop,
  );
  const activeItemIds = purchases?.filter((purchedItem) => purchedItem.is_active).map((purchedItem) => purchedItem.store_item_level?.id) || [];

  const purchasedLevels = [...item.levels || []].map((level) => level.id)
    .filter((levelID) => activeItemIds.includes(levelID));
  const nextLevel = [...item.levels || []].sort((level) => level.level).find((level) => !level.purchased_at);
  const requiredLeagueIndex = (userSettings as SettingsResponse).leagues.findIndex((league: LeaguesSetting) => league.name === nextLevel?.required_league);
  const userLeagueIndex = (userSettings as SettingsResponse).leagues.findIndex((league: LeaguesSetting) => league.name === userData?.league.name);

  if (requiredLeagueIndex > userLeagueIndex) {
    return <RequiredLeagueButton league={nextLevel?.required_league || 'Bronze'} {...props} />;
  }
  if (purchasedLevels?.length && item.levels) {
    const [lastPurchaseId] = purchasedLevels;
    const lastLevel = item.levels[item.levels.length - 1];

    if (lastPurchaseId === lastLevel?.id) {
      return <MaximumLevelReached {...props} />;
    }
  }
  if (purchasedLevels?.length === item.levels?.length) {
    return <PurchasedButton {...props} />;
  }
  if ((userData?.coins || 0) < (nextLevel?.price || 0)) {
    return <DisabledButton />;
  }
  if (purchasedLevels.length && purchasedLevels?.length !== item.levels?.length) {
    return (
      <ImproveButton onClick={() => onBuy(item)} {...props} />
    );
  }
  return <BuyButton onClick={() => onBuy(item)} {...props} />;
}

export function BuyButton({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      className="btn primary mt-2"
      pureClass
      {...props}
    >
      Buy
      {' '}
    </Button>
  );
}
type IsActiveTimerButtonProps = ButtonProps & {
  expiresAt: ShopItemBuyResponse['expires_at']
  setIsVisible?: (val: boolean) => void
}
export function IsActiveTimerButton({
  expiresAt, onClick, setIsVisible, className, ...props
}: IsActiveTimerButtonProps) {
  const secondsToFinish = getSecondsBetweenDates(new Date(), new Date(expiresAt || ''));
  const {
    formatedSecond, formatedMinutes, isFinished,
  } = useCountDown(secondsToFinish);
  useEffect(() => {
    if (isFinished && setIsVisible) {
      setIsVisible(false);
    }
  }, [isFinished]);
  return (
    <Button
      onClick={onClick}
      className={`btn warning-inversed mt-2 ${className}`}
      pureClass
      {...props}
      disabled={isFinished || props.disabled}
    >
      Bonus is
      {' '}
      {!isFinished ? 'active' : 'disabled'}
      {' '}
      {!isFinished ? `${formatedMinutes}:${formatedSecond}` : ''}
    </Button>
  );
}

export function ImproveButton({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      className="btn primary mt-2"
      pureClass
      onClick={onClick}
      {...props}
      disabled={false}
    >
      Improve
    </Button>
  );
}
export function StartFarmButton({ onClick, ...props }: ButtonProps) {
  return (
    <Button
      className="btn primary mt-2"
      pureClass
      onClick={onClick}
      {...props}
    >
      Start Farm
    </Button>
  );
}
export function RequiredLeagueButton({ league }: ButtonProps & {league?: LeaguesUnion}) {
  return (
    <Button
      className="mt-2 w-full h-[36px] px-4 bg-orange-bg rounded-10 text-orange-text font-bold text-sm line-normal"
      pureClass
      disabled
    >
      <div className="flex items-center gap-2">
        <div className={`h-[21.6px] ${league === 'Legendary' ? 'w-[23px]' : 'w-[25px]'}`}>
          <LeagueIcon league={league || 'Bronze'} />
        </div>
        <div>
          Reach
          {' '}
          {league}
          {' '}
          League
        </div>
      </div>
    </Button>
  );
}

export function PurchasedButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full py-3 px-4 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      <CircleCheck stroke="#1FD969" />
      {' '}
      Purchased
    </Button>
  );
}
export function MaximumLevelReached({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full py-3 px-3 bg-green-main bg-opacity-15 rounded-10 text-green-main flex gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-xs line-normal"
      pureClass
      {...props}
    >
      You have reached the maximum level
    </Button>
  );
}
export function SelectedButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      <CircleCheck stroke="#1FD969" />
      {' '}
      Selected
    </Button>
  );
}

export function SelectButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="btn neutral mt-2"
      pureClass
      {...props}
    >
      Select
    </Button>
  );
}

type EarnedButtonProps = ButtonProps & {
  autopilot: AutopilotT
  onCollectFarm?: () => void
}
export function EarnedButton({ autopilot, onCollectFarm, ...props }:EarnedButtonProps) {
  const { balance, time } = useAutopilotContext();

  if (balance >= autopilot.coins_per_interval || time.isFinished) {
    return <CollectFarm onClick={onCollectFarm} />;
  }
  return (
    <Button
      className="mt-2 w-full h-[36px] py-[9px] px-4 bg-gradient-to-r from-[#6433A7] to-[#4C2088] rounded-10 text-black-text flex items-center justify-between gap-1 font-bold text-sm line-normal"
      pureClass
      {...props}
    >
      <div className="flex gap-2 text-white text-base items-center">
        <span className="text-gray-2 text-sm">
          Earned
        </span>
        {Math.floor(balance)}
        <CoinSVG />
      </div>
      <div className="text-white bg-white bg-opacity-15 p-1 rounded-10">
        {time.remain.formatedMinutes}
        :
        {time.remain.formatedSecond}
      </div>
    </Button>
  );
}

export function CollectFarm({ ...props }: ButtonProps) {
  return (
    <Button
      className="btn warning mt-2"
      pureClass
      {...props}
    >
      Collect reward
    </Button>
  );
}

export function DisabledButton({ ...props }: ButtonProps) {
  return (
    <Button
      className="mt-2 w-full h-[36px] py-3 px-4 bg-green-main rounded-10 text-black-text flex items-center justify-center gap-1 focus:ring-4 transform active:scale-75 transition-transform font-bold text-sm line-normal disabled:bg-gray-10 disabled:text-gray-600"
      pureClass
      disabled
      {...props}
    >
      Insufficient Coin Balance
    </Button>
  );
}
