import BottomScreen from './BottomScreen';
import { MultiplierT } from './slotConstants';

interface IMultiplierBtn {
    multiplier: MultiplierT,
    handleChangeMultiplier: () => void,
    isAnimating: boolean
    isSafari: boolean
}
function MultiplierBtn(props:IMultiplierBtn) {
  const {
    multiplier, handleChangeMultiplier, isAnimating, isSafari,
  } = props;
  return (
    <g
      id="svg_164"
    >
      {/* width="136" */}
      {/* height="26" */}
      {/* viewBox="0 5 144 32" */}
      {/* fill="none" */}
      {/* id="svg_4" */}
      {/* x="116" */}
      {/* y="305.41553" */}
      {/* xmlns="http://www.w3.org/2000/svg" */}
      <svg width="136" height="24" viewBox="0 0 140 24" fill="none" id="svg_4" x="116" y="305.41553" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#svg_9)" id="svg_147">
          <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="#0C056B" id="svg_152" />
          {/* <path d="m1.34214,22.9748c-0.28203,0 -0.45124,-0.1695 -0.50765,-0.2825c-0.05641,-0.0565 -0.16922,-0.2825 0,-0.565l10.37861,-20.90692c0.1129,-0.22602 0.3385,-0.33903 0.5641,-0.33903l112.5298,0c0.225,0 0.451,0.11301 0.507,0.33903l10.04,20.90692c0.113,0.1695 0.057,0.3955 -0.056,0.565c-0.113,0.1695 -0.282,0.2825 -0.508,0.2825l-132.94786,0z" fill="url(#svg_3)" id="svg_151" /> */}
          {/* <path d="m132.542,22.3534l-129.39484,0c-0.84609,0 -1.41014,-0.9041 -1.0153,-1.6387l9.30694,-18.64664c0.1692,-0.39553 0.5641,-0.62155 1.0153,-0.62155l111.1189,0c0.452,0 0.846,0.22602 1.016,0.62155l8.968,18.64664c0.339,0.7911 -0.169,1.6387 -1.015,1.6387zm-8.235,-22.03699l-112.5298,0c-0.4512,0 -0.846,0.22602 -1.0153,0.62155l-10.09659,20.11584c-0.56406,1.1301 0.28203,2.4297 1.52295,2.4297l131.25574,0c1.241,0 2.087,-1.2996 1.523,-2.4297l-9.645,-20.05933c-0.169,-0.39554 -0.621,-0.67806 -1.015,-0.67806z" fill="url(#svg_2)" id="svg_148" /> */}
        </g>
        <defs>
          <clipPath id="svg_9">
            <rect width="134.697" height="23.2236" fill="white" y="0.3169" x="0.49609" id="svg_12" />
          </clipPath>
        </defs>
        <foreignObject
          x="0"
          y={isSafari ? '0' : '2'}
          width="100%"
          height="100%"
        >
          <BottomScreen multiplier={multiplier} onMultiplierClick={handleChangeMultiplier} isAnimating={isAnimating} isSafari={isSafari} />
        </foreignObject>
      </svg>
    </g>
  );
}

export default MultiplierBtn;
