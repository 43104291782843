import {
  motion, useAnimate,
  Variants,
} from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import Lottie, { EventListener, Options } from 'react-lottie';
import animationData from '../../assets/animations/daily_reward.json';
import { ReactComponent as CoinSVG } from '../../assets/images/coin-size.svg';
import { ReactComponent as EnergySvg } from '../../assets/images/energy-22x22.svg';
import TreasuresBg from '../../assets/svg/treasures-bg.svg';
import Treasures from '../../assets/svg/treasures.svg';
import DailyQuest from '../../Components/DailyQuest';
import Drawer from '../../Components/Drawer';
import Portal from '../../Components/Portal';
import { useAppDispatch, useAppSelector } from '../../store';
import { finishQuest } from '../../store/reducers/quests/asyncActions';
import { QuestT } from '../../types';
import { TDefaultDrawerProps } from '../../types/components';

type TDailyRewardsModalProps = TDefaultDrawerProps

type TRewardAnimationState = {
  play: boolean;
  data: { coins: number; energy: number; }
}

const lottieRewardAnimationOptions: Options = {
  animationData,
  loop: true,
};

const rewardsAnimationOptions: Variants = {
  hidden: { opacity: 0, top: '57.5%' },
};

const rewardsWrapperAnimationOptions: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.2 } },
};

function DailyRewardsModal({ onClose, open }: TDailyRewardsModalProps) {
  const dispatch = useAppDispatch();

  const { questsList } = useAppSelector(({ quests }) => quests);
  const { userData } = useAppSelector(({ profile }) => profile);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [rewardAnimation, setRewardAnimation] = useState<TRewardAnimationState>();

  const [rewardWrapperScope, rewardWrapperAnimate] = useAnimate();
  const [rewardsScope, rewardsScopeAnimate] = useAnimate();

  const rewardAnimationEvents: EventListener[] = [
    {
      eventName: 'loopComplete',
      callback: async () => {
        await rewardWrapperAnimate(rewardWrapperScope.current, { opacity: 0 });

        setRewardAnimation(undefined);
      },
    },
  ];

  const isDailyRewardAvailable = useMemo(
    () => {
      if (!userData?.user_schedule_task) {
        return true;
      }

      const availableAtDate = new Date(userData.user_schedule_task.click_time);

      availableAtDate.setDate(availableAtDate.getDate() + 1);

      return availableAtDate.toISOString() < new Date().toISOString();
    },
    [userData],
  );

  const dailyQuests = useMemo(() => {
    if (!questsList?.daily) {
      return [];
    }

    return [...questsList.daily].sort(
      (a, b) => a.order - b.order,
    );
  }, [questsList]);

  useEffect(() => {
    if (rewardAnimation) {
      setTimeout(() => {
        const el = document.querySelector('#rewards-lottie-wrapper [clip-path="url(#__lottie_element_12)"] image');

        if (!el) {
          return;
        }

        const rect = el.getBoundingClientRect();

        const top = rect.y + rect.height * 0.75 + 20;

        rewardsScopeAnimate(rewardsScope.current, { opacity: 1, top: `${top}px` });
      }, 1_500);
    }
  }, [rewardAnimation]);

  const isDailyQuestCompleted = useMemo(() => {
    const activeQuest = dailyQuests.find(
      (q) => q.id === userData?.user_schedule_task?.quest_id,
    );

    return (order: number) => !!activeQuest && order < activeQuest.order;
  }, [dailyQuests, userData]);

  const handleDailyClaim = async (dailyQuest: QuestT) => {
    if (!isDailyRewardAvailable) {
      return;
    }

    let quest = dailyQuest;

    if (!userData?.user_schedule_task) {
      [quest] = dailyQuests;

      if (quest?.id !== dailyQuest.id) {
        return;
      }
    } else if (userData?.user_schedule_task?.quest_id !== dailyQuest?.id) {
      return;
    }

    const response = await dispatch(finishQuest({ id: quest.id }));

    if (response.error) return;

    setRewardAnimation({ play: true, data: { coins: dailyQuest.rewards.coins || 0, energy: dailyQuest.rewards.energy || 0 } });
  };

  if (!open) {
    return null;
  }

  return (
    <Drawer
      onClose={onClose}
      open={open}
      side="bottom"
      closeStroke="gray-10"
      wrapperClassName="px-4 py-6 bg-dailyRewardDrawerBG rounded-t-[20px] overflow-hidden text-[white]"
    >
      <div className="relative min-h-[100px] mt-[13px]">
        <img
          className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
          src={TreasuresBg}
          alt="treasures-bg"
        />
        <img
          className="absolute top-0 left-1/2 transform -translate-x-1/2"
          src={Treasures}
          alt="treasures"
        />
        <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-[22px] font-Bangers">
          Daily Reward
        </span>
      </div>
      <motion.div
        className="relative flex flex-wrap gap-2 mt-6 z-[1] justify-center"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
        }}
      >
        {dailyQuests.map((dailyQuest) => (
          <DailyQuest
            quest={dailyQuest}
            key={dailyQuest.id}
            isCompleted={isDailyQuestCompleted(dailyQuest.order)}
            onComplete={handleDailyClaim}
          />
        ))}
      </motion.div>
      {rewardAnimation?.play && (
      <Portal>
        <motion.div
          className="absolute inset-0 w-full h-full z-[1000] bg-[#000000cc]"
          initial="hidden"
          animate="visible"
          variants={rewardsWrapperAnimationOptions}
          ref={rewardWrapperScope}
        >
          <div id="rewards-lottie-wrapper" className="absolute top-[50%] transform translate-y-[-50%]">
            <Lottie
              options={lottieRewardAnimationOptions}
              isClickToPauseDisabled
              eventListeners={rewardAnimationEvents}
              height="unset"
              width={isSafari ? '100vw' : 'unset'}
            />
          </div>
          <motion.div
            className="
            absolute
            left-[50%]
            transform -translate-x-[50%]
            flex items-center gap-4
            "
            initial="hidden"
            animate="visible"
            variants={rewardsAnimationOptions}
            ref={rewardsScope}
          >
            {!!rewardAnimation.data.coins && (
            <div className="flex items-center gap-2 text-white font-Bangers text-2xl px-2">
              <CoinSVG width={48} height={48} />
              +
              {rewardAnimation.data.coins}
            </div>
            )}
            {!!rewardAnimation.data.energy && (
            <div className="flex items-center gap-2 text-white font-Bangers text-2xl px-2">
              <EnergySvg width={48} height={48} />
              +
              {rewardAnimation.data.energy}
            </div>
            )}
          </motion.div>
        </motion.div>
      </Portal>
      )}
    </Drawer>
  );
}

export default DailyRewardsModal;
