import { useEffect, useRef, useState } from 'react';
import Quest from '../../../Components/Quest';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import { QuestT, TQuestsResponse } from '../../../types';

type QuestDrawerContentProps = {
    quests: TQuestsResponse
    invalidQuests: number[]
    handleQuestStart: (quest: QuestT) => void
    handleQuestCheck: (quest: QuestT) => void
}
function QuestDrawerContent({
  quests, invalidQuests, handleQuestStart, handleQuestCheck,
}: QuestDrawerContentProps) {
  const [activeTab, setActiveTab] = useState<QuestT['quest_type']>('social');

  const questsContainerRef = useRef<HTMLDivElement>(null);

  const rect = useResizeObserver(questsContainerRef);

  useEffect(() => {
    if (!questsContainerRef.current || !rect) {
      return;
    }

    // ${window.innerHeight - 80 - rect.y}px - 80 is bottom height + some padding

    questsContainerRef.current.style.maxHeight = `${window.innerHeight - 48 - rect.y}px`;
  }, [rect]);

  return (
    <div className="pb-2">
      <div className="flex justify-around bg-navy-300 rounded-8 text-center">
        <div
          className={`btn ${activeTab === 'social' && 'neutral'} rounded-8`}
          onClick={() => setActiveTab('social')}
        >
          Daily Task
          {' '}
          {quests.social.length}
        </div>
        <div
          className={`btn ${activeTab === 'crash_task' && 'neutral'} rounded-8`}
          onClick={() => setActiveTab('crash_task')}
        >
          Crash Task
          {' '}
          {quests.crash_task.length}
        </div>

      </div>
      <div className="mt-4 overflow-y-scroll max-h-[400px] pb-8" ref={questsContainerRef}>
        {quests[activeTab].map((quest) => (
          <Quest
            quest={quest}
            key={quest.id}
            onStart={() => handleQuestStart(quest)}
            onCheck={() => handleQuestCheck(quest)}
            isValid={!invalidQuests.includes(quest.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default QuestDrawerContent;
