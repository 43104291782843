import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { ReactComponent as Coin } from '../../assets/images/coin-size-26x26.svg';
import { numberWithCommas } from '../../helpers';
import { useCountDown } from '../../hooks/useCoundown';
import AviatorGameContainer from '../../pages/Home/AviatorGameContainer';
import eventEmitter, { emitGameEvent, GameEvents } from '../../pages/Home/eventEmiter';
import { MultiplierScale } from '../../pages/Home/MultiplierScale';
import { useWebSocketContext } from '../../providers/WebSocketProvider';
import { AviatorGameStartedData, AviatorGameWSMessages } from '../../providers/WebSocketProvider/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  changeGame, getActiveSlotEffects,
  getAviatorHistory,
  setAviatorGameStatus,
} from '../../store/reducers/aviator/asyncActions';
import { updateProfileCoins, updateUserData } from '../../store/reducers/profile/asyncActions';
import { getPurchases } from '../../store/reducers/store/asyncActions';
import { IAviatorStore } from '../../types';
import Button from '../Button';
import AviatorLoseModal from './AviatorLoseModal';
import AviatorWinModal from './AviatorWinModal';
import BetHistoryCard from './BetHistoryCard';
import aviatorCntdwn3 from '../../assets/sounds/sc_aviator_cntdwn_3.wav';
import aviatorCntdwn2 from '../../assets/sounds/sc_aviator_cntdwn_2.wav';
import aviatorCntdwn1 from '../../assets/sounds/sc_aviator_cntdwn_1.wav';

const sounds = {
  countdown3: new Howl({ src: [aviatorCntdwn3] }),
  countdown2: new Howl({ src: [aviatorCntdwn2] }),
  countdown1: new Howl({ src: [aviatorCntdwn1] }),
};
function AviatorRocket() {
  const {
    startGame, webSocket, stopGame,
  } = useWebSocketContext();
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { aviatorHistory, earnedEffects } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const { purchases } = useAppSelector(
    ({ shop }) => shop,
  );
  const {
    aviatorGameStatus, slotMachineMultiplier,
  } = useAppSelector(
    ({ aviator }) => aviator,
  );

  const activeStartSumItems = purchases?.filter((purchasedItem) => purchasedItem.store_item_level.store_item.type === 'permanent' && purchasedItem.store_item_level.effects[0].name === 'bonus' && purchasedItem.is_active);
  const calculatedStartSumEffect = activeStartSumItems?.reduce(
    (acc, obj) => acc + obj.store_item_level.effects.reduce((effectAccumulator, effectObj) => effectAccumulator + Number(effectObj.value), 0),
    userSettings?.aviator_base_coins || 0,
  );
  const startSumBonusGoldEffect = calculatedStartSumEffect || (userSettings?.aviator_base_coins || 1000);
  const initialTimerSeconds = 5;
  const dispatch = useAppDispatch();
  const bet = startSumBonusGoldEffect * slotMachineMultiplier;
  const [gameData, setGameData] = useState<AviatorGameStartedData['data'] | null>(null);
  const [multiplier, setMultiplier] = useState<number>(0);
  const [rocketValue, setRocketValue] = useState<number | null>(null);
  const [winModalOpen, setWinModalOpen] = useState<boolean>(false);
  const [loseModalOpen, setLoseModalOpen] = useState<boolean>(false);
  const { formatedSecond, restart: restartCounter, seconds } = useCountDown(0);

  useEffect(() => {
    const onClose = () => {
      handleStop({ byUser: false, isWin: false });
      dispatch(setAviatorGameStatus('lose'));
    };

    const onMessage = (event: MessageEvent<string>) => {
      const message: AviatorGameWSMessages = JSON.parse(event.data);

      if (message.type === 'start') {
        dispatch(updateUserData(message.data.user));
        setGameData(message.data);
        handleAnimationsStart();
      }
      if (message.type === 'progress') {
        setRocketValue(message.data.currentValue);
        emitGameEvent({ event: GameEvents.SET_AVIATOR_MULTIPLIER, value: message.data.currentValue });
      } else if (message.type === 'result') {
        dispatch(updateUserData(message.data.user));
        handleStop({ isWin: message.data.isWinner });
        if (message.data.isWinner) {
          dispatch(setAviatorGameStatus('won'));
          dispatch(updateProfileCoins({ coins: message.data.user.coins, replace: true }));
        } else {
          dispatch(setAviatorGameStatus('lose'));
        }
      } else if (message.type === 'error') {
        window.console.log('error', message);
      }
    };

    webSocket.server?.addEventListener('close', onClose);

    webSocket.server?.addEventListener('error', onClose);

    webSocket.server?.addEventListener('message', onMessage);

    return () => {
      webSocket.server?.removeEventListener('close', onClose);
      webSocket.server?.removeEventListener('error', onClose);
      webSocket.server?.removeEventListener('message', onMessage);
    };
  }, []);

  const handleAnimationsStart = () => {
    if (aviatorGameStatus !== 'running' && aviatorGameStatus !== 'stoppedByUser') {
      dispatch(setAviatorGameStatus('running'));
      emitGameEvent({ event: GameEvents.START_GAME, animations: 'all', loop: false });
    }
  };
  const handleAnimationsStop = (pause: boolean) => {
    if (pause) {
      emitGameEvent({ event: GameEvents.WIN_GAME });
    } else {
      emitGameEvent({ event: GameEvents.STOP_GAME });
    }
  };
  const handleStart = (revive?: boolean) => {
    dispatch(setAviatorGameStatus('starting'));
    setMultiplier((prev) => { // hack because start called from useEffect[]
      startGame({ multiplier: prev || undefined, revive, slotMultiplier: slotMachineMultiplier });
      return prev;
    });
  };
  const handleStop = ({ byUser, isWin }: {byUser?: boolean, isWin?: boolean}) => {
    handleAnimationsStop(!!(byUser || isWin));
    if (isWin) {
      setTimeout(() => {
        setWinModalOpen(true);
      }, 1500);
    }
    dispatch(getAviatorHistory());
    dispatch(getPurchases());
    if (gameData && byUser) {
      dispatch(setAviatorGameStatus('stoppedByUser'));
      stopGame({ aviatorId: gameData.aviatorId, hash: gameData.hash });
    }
  };

  const handleCloseGame = () => {
    if (aviatorGameStatus === 'won') {
      emitGameEvent({ event: GameEvents.SHOW_WIN_COINS });
    }
    setTimeout(() => {
      setWinModalOpen(false);
      dispatch(getActiveSlotEffects());
      dispatch(changeGame('slotMachine'));
      emitGameEvent({ event: GameEvents.TAKEOFF, withSound: false });
    }, 2000);
    dispatch(setAviatorGameStatus('bet'));
  };

  useEffect(() => {
    setTimeout(() => {
      emitGameEvent({ event: GameEvents.TAKEOFF, withSound: true });
    }, 1000);
    (new MultiplierScale()).draw();
    const endGameStatuses: IAviatorStore['aviatorGameStatus'][] = ['won', 'lose'];
    if (!endGameStatuses.includes(aviatorGameStatus)) {
      dispatch(setAviatorGameStatus('starting'));
      restartCounter(initialTimerSeconds);
      const autostartTimeout = setTimeout(() => {
        handleStart();
      }, initialTimerSeconds * 1000);
      return () => {
        emitGameEvent({ event: GameEvents.RESTART });
        clearTimeout(autostartTimeout);
      };
    }
    return () => null;
  }, []);

  useEffect(() => { // pay sound on countdown
    if (seconds === 3) {
      sounds.countdown3.play();
    }
    if (seconds === 2) {
      sounds.countdown2.play();
    }
    if (seconds === 1) {
      sounds.countdown1.play();
    }
  }, [seconds]);
  useEffect(() => {
    const handleExplosionEnd = () => {
      setLoseModalOpen(() => true);
      setTimeout(() => {
        emitGameEvent({ event: GameEvents.SHOW_METEORS });
      }, 1000);
      setTimeout(() => {
        handleCloseGame();
      }, 500);
    };
    eventEmitter.on(GameEvents.EXPLOSION_END, handleExplosionEnd);
    return () => {
      eventEmitter.off(GameEvents.EXPLOSION_END, handleExplosionEnd);
    };
  }, []);
  const displayBetsUi = ['bet', 'starting'].includes(aviatorGameStatus);

  const rewardMultiplerEffect = earnedEffects?.find((el) => el.effect.name === 'reward_multiplier');
  const cahsoutValue = Math.round(bet * (rocketValue || 1) * (rewardMultiplerEffect?.effect?.value ? rewardMultiplerEffect.effect.value : 1));
  return (
    <div className="absolute h-screen w-screen top-0 overflow-hidden">
      <div className="absolute h-screen w-screen">
        <AviatorGameContainer />
      </div>
      <div className="z-[3] relative h-full w-full flex items-end justify-center">
        <div className="z-[3] absolute top-[60px] gap-2 flex flex-col w-full">
          <div className="z-[3] w-full flex justify-center">
            {aviatorGameStatus === 'starting'
                  && (
                  <div className="flex flex-col mt-1">
                    <span className="text-xs">Start in</span>
                    <span className="text-[52px] font-Bangers leading-[55px] px-2">{formatedSecond}</span>
                  </div>
                  )}
          </div>
        </div>
        <div className="h-fit mb-[19px] w-full flex justify-center flex-col items-center w-full gap-2">
          <div className="flex w-full overflow-scroll scrollbar-hidden gap-1 px-[5vw]">
            {/* eslint-disable-next-line react/no-array-index-key */}
            {aviatorHistory.map((h, idx) => <BetHistoryCard key={h.created_at + idx} history={h} />)}
          </div>
          <div className="max-w-[90vw] w-full">
            {(displayBetsUi || (['running'].includes(aviatorGameStatus) && multiplier))
              ? (
                <div
                  className="btn neutral text-[24px] py-4 px-2"
                >
                  <span>Game start in</span>
                  {' '}
                  <span
                    className="px-[2px]"
                  >
                    {formatedSecond}
                  </span>
                </div>
              ) : null}
            {aviatorGameStatus === 'running'
             && (
             <Button
               onClick={() => handleStop({ byUser: true })}
               className="btn gold rounded-[16px]"
               pureClass
             >
               <div className="max-w-[139px] w-full flex flex-col items-center text-lg leading-[19px]">
                 <div className="flex gap-1">
                   {numberWithCommas(cahsoutValue)}
                   <Coin width="18px" height="18px" />
                 </div>
                 <span>Current Cashout</span>
               </div>
             </Button>
             )}
            {(aviatorGameStatus === 'lose' || aviatorGameStatus === 'won')
             && (
             <Button
               className="btn silver rounded-[16px]"
               pureClass
             >
               <div className="max-w-[139px] w-full flex flex-col items-center text-lg leading-[19px]">
                 <div className="flex gap-1">
                   {numberWithCommas(cahsoutValue)}
                   <Coin width="18px" height="18px" />
                 </div>
                 <span>Current Cashout</span>
               </div>
             </Button>
             )}
          </div>
        </div>
      </div>
      <AviatorWinModal
        open={winModalOpen}
        onOkay={handleCloseGame}
        multiplier={rocketValue}
        coinsWon={cahsoutValue}
      />
      <AviatorLoseModal
        open={loseModalOpen}
        onClose={handleCloseGame}
      />
    </div>
  );
}

export default AviatorRocket;
