import { RefObject, useLayoutEffect, useState } from 'react';

import { useIsMounted } from './useIsMounted';

export const useResizeObserver = <T extends HTMLElement | SVGSVGElement>(
  ref: RefObject<T>,
) => {
  const [rect, setRect] = useState<DOMRect | undefined>();
  const isMounted = useIsMounted();

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeHandler = () => {
      if (!ref.current) {
        return;
      }

      const elemRect = ref.current.getBoundingClientRect();

      setRect(elemRect);
    };

    window.addEventListener('resize', resizeHandler);

    if (!isMounted()) {
      resizeHandler();
    }

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', resizeHandler);
  }, [ref, isMounted]);

  return rect;
};
