import { useEffect } from 'react';
import useLongPress from '../../../hooks/useLongPress';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setSlotMachineGameType } from '../../../store/reducers/aviator/asyncActions';

interface SpinButtonProps {
  onClick: () => void
  isSpinning: boolean
}
function SpinButton({ onClick, isSpinning }: SpinButtonProps) {
  const { slotMachineType } = useAppSelector(
    ({ aviator }) => aviator,
  );
  const dispatch = useAppDispatch();
  const onLongPress = () => {
    dispatch(setSlotMachineGameType('auto'));
  };

  const handleClick = () => {
    dispatch(setSlotMachineGameType('single'));
    if (!isSpinning) {
      onClick();
    }
  };
  const {
    onMouseDown, onMouseUp, onTouchStart, onTouchEnd,
  } = useLongPress({
    onLongPress,
    onClick: handleClick,
    ms: 500,
  });
  useEffect(() => {
    if (!isSpinning) {
      const animatedPath = document.getElementById('eMuaYO0y5tU15_to');
      if (animatedPath) {
        animatedPath.id = 'animate';
      }
    } else {
      const animatedPath = document.getElementById('animate');
      if (animatedPath) {
        animatedPath.id = 'eMuaYO0y5tU15_to';
      }
    }
  }, [isSpinning]);
  const isAutospin = slotMachineType === 'auto';
  return (
    <div
      className="mt-[0px]"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <SpinIco isAutospin={isAutospin} />
    </div>
  );
}

export default SpinButton;

function SpinIco({ isAutospin }: {isAutospin: boolean}) {
  return (
    <svg className="w-full h-full" id="eMuaYO0y5tU1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329 163" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
      <style>
        {'#eMuaYO0y5tU15_to {animation: eMuaYO0y5tU15_to__to 600ms linear 1 normal forwards}@keyframes eMuaYO0y5tU15_to__to { 0% {transform: translate(168.481709px,72.735876px);animation-timing-function: cubic-bezier(0.77,0,0.175,1)} 50% {transform: translate(168.481709px,81.735876px);animation-timing-function: cubic-bezier(0.77,0,0.175,1)} 100% {transform: translate(168.481709px,72.735876px)}}'}
      </style>
      <defs>
        <linearGradient id="eMuaYO0y5tU6-fill" x1="9.28623" y1="93.4604" x2="327.956" y2="87.9016" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU6-fill-0" offset="0%" stopColor="#d4ffff" />
          <stop id="eMuaYO0y5tU6-fill-1" offset="4.5133%" stopColor="#b6fafc" />
          <stop id="eMuaYO0y5tU6-fill-2" offset="12.72%" stopColor="#86f1f8" />
          <stop id="eMuaYO0y5tU6-fill-3" offset="18.97%" stopColor="#67ecf5" />
          <stop id="eMuaYO0y5tU6-fill-4" offset="22.5%" stopColor="#5ceaf4" />
          <stop id="eMuaYO0y5tU6-fill-5" offset="44.13%" stopColor="#46ceef" />
          <stop id="eMuaYO0y5tU6-fill-6" offset="90.71%" stopColor="#0d85e3" />
          <stop id="eMuaYO0y5tU6-fill-7" offset="100%" stopColor="#0176e0" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU8-fill" x1="10.0996" y1="112.919" x2="68.8895" y2="112.919" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU8-fill-0" offset="0%" stopColor="#30c7ef" />
          <stop id="eMuaYO0y5tU8-fill-1" offset="99.63%" stopColor="#01b0ef" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU9-fill" x1="268.088" y1="112.919" x2="326.878" y2="112.919" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU9-fill-0" offset="0%" stopColor="#1042af" />
          <stop id="eMuaYO0y5tU9-fill-1" offset="100%" stopColor="#003593" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU10-fill" x1="68.8894" y1="142.677" x2="268.088" y2="142.677" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU10-fill-0" offset="0%" stopColor="#019cc9" />
          <stop id="eMuaYO0y5tU10-fill-1" offset="99.63%" stopColor="#103199" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU11-fill" x1="178.733" y1="11.8056" x2="155.284" y2="144.704" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU11-fill-0" offset="54.75%" stopColor="rgba(102,203,255,0)" />
          <stop id="eMuaYO0y5tU11-fill-1" offset="60.31%" stopColor="rgba(111,206,255,0.124)" />
          <stop id="eMuaYO0y5tU11-fill-2" offset="69.5%" stopColor="rgba(136,215,255,0.3287)" />
          <stop id="eMuaYO0y5tU11-fill-3" offset="81.17%" stopColor="rgba(177,229,255,0.5888)" />
          <stop id="eMuaYO0y5tU11-fill-4" offset="94.74%" stopColor="rgba(233,248,255,0.8911)" />
          <stop id="eMuaYO0y5tU11-fill-5" offset="99.63%" stopColor="#fff" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU16-fill" x1="54.1235" y1="120.05" x2="287.113" y2="25.8409" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU16-fill-0" offset="0%" stopColor="#fff" />
          <stop id="eMuaYO0y5tU16-fill-1" offset="1.68465%" stopColor="#fff6fc" />
          <stop id="eMuaYO0y5tU16-fill-2" offset="13.19%" stopColor="#fdbae9" />
          <stop id="eMuaYO0y5tU16-fill-3" offset="24.27%" stopColor="#fc88d9" />
          <stop id="eMuaYO0y5tU16-fill-4" offset="34.65%" stopColor="#fb62cd" />
          <stop id="eMuaYO0y5tU16-fill-5" offset="44.16%" stopColor="#fb46c4" />
          <stop id="eMuaYO0y5tU16-fill-6" offset="52.49%" stopColor="#fa35bf" />
          <stop id="eMuaYO0y5tU16-fill-7" offset="58.75%" stopColor="#fa2fbd" />
          <stop id="eMuaYO0y5tU16-fill-8" offset="59.41%" stopColor="#f82fbe" />
          <stop id="eMuaYO0y5tU16-fill-9" offset="100%" stopColor="#6342ff" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU17-fill" x1="34.8703" y1="89.3748" x2="80.1152" y2="89.3748" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU17-fill-0" offset="0.372461%" stopColor="#ff99fd" />
          <stop id="eMuaYO0y5tU17-fill-1" offset="16.49%" stopColor="#fa93fd" />
          <stop id="eMuaYO0y5tU17-fill-2" offset="37.98%" stopColor="#ed82fd" />
          <stop id="eMuaYO0y5tU17-fill-3" offset="62.51%" stopColor="#d866fe" />
          <stop id="eMuaYO0y5tU17-fill-4" offset="89.09%" stopColor="#bb40ff" />
          <stop id="eMuaYO0y5tU17-fill-5" offset="100%" stopColor="#ad2eff" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU18-fill" x1="258.648" y1="89.3748" x2="302.069" y2="89.3748" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU18-fill-0" offset="0%" stopColor="#3605a3" />
          <stop id="eMuaYO0y5tU18-fill-1" offset="100%" stopColor="#55036d" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU19-fill" x1="80.1208" y1="111.118" x2="258.648" y2="111.118" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU19-fill-0" offset="0%" stopColor="#9507e0" />
          <stop id="eMuaYO0y5tU19-fill-1" offset="53.17%" stopColor="#54049d" />
          <stop id="eMuaYO0y5tU19-fill-2" offset="100%" stopColor="#3606d4" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU20-fill" x1="34.8703" y1="94.1776" x2="302.069" y2="94.1776" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU20-fill-0" offset="0%" stopColor="#8c06bd" />
          <stop id="eMuaYO0y5tU20-fill-1" offset="31.81%" stopColor="#6204a4" />
          <stop id="eMuaYO0y5tU20-fill-2" offset="86.59%" stopColor="#120073" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU21-fill" x1="36.461" y1="58.6911" x2="287.602" y2="31.5471" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU21-fill-0" offset="8.37976%" stopColor="#fff" />
          <stop id="eMuaYO0y5tU21-fill-1" offset="16.49%" stopColor="#fef3ff" />
          <stop id="eMuaYO0y5tU21-fill-2" offset="31.3%" stopColor="#fcd2ff" />
          <stop id="eMuaYO0y5tU21-fill-3" offset="51.15%" stopColor="#f89cff" />
          <stop id="eMuaYO0y5tU21-fill-4" offset="75.32%" stopColor="#f353ff" />
          <stop id="eMuaYO0y5tU21-fill-5" offset="100%" stopColor="#ed00ff" />
        </linearGradient>
        <linearGradient id="eMuaYO0y5tU22-fill" x1="34.8636" y1="84.5339" x2="302.113" y2="84.5339" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
          <stop id="eMuaYO0y5tU22-fill-0" offset="8.37976%" stopColor="#fff" />
          <stop id="eMuaYO0y5tU22-fill-1" offset="16.49%" stopColor="#fef3ff" />
          <stop id="eMuaYO0y5tU22-fill-2" offset="31.3%" stopColor="#fcd2ff" />
          <stop id="eMuaYO0y5tU22-fill-3" offset="51.15%" stopColor="#f89cff" />
          <stop id="eMuaYO0y5tU22-fill-4" offset="75.32%" stopColor="#f353ff" />
          <stop id="eMuaYO0y5tU22-fill-5" offset="100%" stopColor="#ed00ff" />
        </linearGradient>
      </defs>
      <g clipPath="url(#eMuaYO0y5tU12)">
        <g>
          <g opacity="0.45"><path d="M255.796,36.0987h-174.6141L10.0996,105.623L65.9882,163h204.9248l55.965-57.377-71.082-69.5243Z" fill="#060693" /></g>
          <path d="M68.8893,156.582c-.1527,0-.3817-.076-.5344-.229L9.56499,96.8371c-.1527-.1528-.22905-.3056-.22905-.5348v-26.3582c0-.2292.1527-.4584.3054-.6112L80.8,24.7914c.0764-.0764.2291-.1528.3818-.1528h174.6902c.152,0,.229,0,.381.0764l71.083,44.5415c.229.1528.305.382.305.6112v26.3582c0,.1528-.076.382-.229.5348l-58.79,59.5163c-.152.153-.305.229-.534.229h-199.1987v.076Z" fill="url(#eMuaYO0y5tU6-fill)" />
          <path d="M255.796,25.4027l71.082,44.5415v26.3582l-58.79,59.5156h-199.1985L10.0996,96.3024v-26.3582L81.1819,25.4027h174.6141Zm0-1.4517h-174.6141c-.3054,0-.5344.0765-.7635.2293L9.33608,68.7218c-.4581.2292-.68715.6876-.68715,1.2224v26.3582c0,.3819.1527.764.38175.9932L67.8206,156.812c.3054.305.6108.458.9925.458h199.1989c.381,0,.763-.153.992-.458l58.79-59.5164c.229-.3056.382-.6113.382-.9932v-26.3582c0-.4584-.229-.9168-.687-1.2224l-70.93-44.5415c-.229-.1528-.458-.2293-.763-.2293Z" fill="#060693" />
          <path d="M68.8895,155.895L10.0996,96.3023v-26.3581L68.8895,129.46v26.435Z" fill="url(#eMuaYO0y5tU8-fill)" />
          <path d="M268.088,155.895l58.79-59.5927v-26.3581L268.088,129.46v26.435Z" fill="url(#eMuaYO0y5tU9-fill)" />
          <path d="M229.607,129.46h-122.313-38.4046v26.435h199.1986v-26.435h-38.481Z" fill="url(#eMuaYO0y5tU10-fill)" />
          <path d="M10.0996,69.9442L68.8895,129.46h199.1985l58.79-59.5158-71.082-44.5416h-174.6141L10.0996,69.9442Z" fill="url(#eMuaYO0y5tU11-fill)" />
        </g>
        <clipPath id="eMuaYO0y5tU12"><rect width="319.68" height="139.049" rx="0" ry="0" transform="translate(8.64893 23.951)" fill="#fff" /></clipPath>
      </g>
      <g mask="url(#eMuaYO0y5tU41)">
        <g id="animate" transform="translate(168.481709,72.735876)">
          <g transform="translate(-168.481708,-72.735876)">
            <path d="M254.298,30.0923h-171.5553L34.8794,62.7946v9.6726L80.1306,115.917h178.5464l43.407-43.4498v-9.6726L254.298,30.0923Z" transform="translate(0-.53755)" fill="url(#eMuaYO0y5tU16-fill)" />
            <path d="M80.1306,115.917L34.8794,72.4671v-9.6725L80.1306,106.321v9.596Z" fill="url(#eMuaYO0y5tU17-fill)" />
            <path d="M258.677,115.917l43.407-43.4499v-9.6725L258.677,106.321v9.596Z" fill="url(#eMuaYO0y5tU18-fill)" />
            <path d="M258.677,106.321h-178.5464v9.596h178.5464v-9.596Z" fill="url(#eMuaYO0y5tU19-fill)" />
            <path d="M34.8794,72.4671L80.1306,115.917h178.5464l43.407-43.4499-43.407,41.5309h-178.5464L34.8794,72.4671Z" opacity="0.7" fill="url(#eMuaYO0y5tU20-fill)" />
            <path d="M34.8794,62.7946l47.556-32.7023h172.4766l35.802,24.7954-36.186-23.6439-171.3243-.1536L34.8794,62.7946Z" fill="url(#eMuaYO0y5tU21-fill)" />
            <path d="M34.8794,62.7946L80.1306,106.244h178.5464l43.407-43.4494-44.022,40.8394h-177.6241L34.8794,62.7946Z" opacity="0.8" fill="url(#eMuaYO0y5tU22-fill)" />
            <g clipPath="url(#eMuaYO0y5tU39)">
              {isAutospin
                ? <StopText />
                : <SpinText />}
            </g>
          </g>
        </g>
        <mask
          id="eMuaYO0y5tU41"
          style={{
            maskType: 'luminance',
          }}
          x="-150%"
          y="-150%"
          height="400%"
          width="400%"
        >
          <path
            d="M34.879401,72.9444v-10.68735l47.8633-32.786555l171.7853.051572l47.556,32.947585v9.997448l-43.407,43.4499h-178.5464l-45.2512-42.9726Z"
            fill="#fff"
            strokeWidth="0.658"
          />
        </mask>
      </g>
      <foreignObject x={0} y={130} width="100%" height="20px">
        <div className="text-[#83F3F1] font-Russo select-none">Hold for AutoSpin</div>
      </foreignObject>
    </svg>
  );
}

function SpinText() {
  return (
    <>
      <g>
        <path d="M78.6653,74.0429c8.2279.3923,16.5343.7846,24.7627.863c1.018,0,1.959-.2353,2.664-.7846s1.175-1.1769,1.41-2.04c.471-2.04.706-3.06,1.254-5.1-6.034,0-9.0115,0-14.9669,0-4.545,0-7.6011-.8631-9.0116-2.6677s-1.3322-4.7078.1567-8.6309c.2351-.7061.3918-1.02.6269-1.7261c1.4889-4.0016,3.5263-6.8262,5.9555-8.6308c2.4292-1.8047,5.7204-2.7462,9.7164-2.7462c8.15,0,12.225,0,20.296,0-.627,3.1385-.94,4.7862-1.646,7.9247-7.522,0-11.205,0-18.728,0-2.4292,0-3.9964,1.0984-4.7016,3.3738-.1568.4708-.2351.7062-.3919,1.0985-.7052,2.2754.1568,3.3739,2.6643,3.3739c4.5452,0,6.7392,0,11.2842,0c4.075,0,6.896.863,8.541,2.5892c1.568,1.7262,2.116,4.4723,1.411,8.1601-.235,1.0984-.314,1.7261-.549,2.8246-.705,3.6877-2.194,6.4339-4.701,8.16-2.508,1.7262-6.034,2.5893-10.579,2.5893-2.351,0-4.8586,0-7.5229-.0785-2.586-.0784-3.8397-.1569-6.3473-.2354-4.7017-.1569-9.5601-.4707-14.4969-.7846c1.1754-2.9031,1.8023-4.4723,2.8994-7.5323Z" fill="#fcf8ff" />
        <path d="M130.15,42.5796c8.071,0,16.22,0,24.292,0c4.074,0,6.974.8631,8.855,2.6677c1.88,1.8046,2.899,4.7077,3.056,8.6308.078,2.2754.078,3.3739.156,5.6493.157,4.0015-.783,6.8262-2.821,8.6308-2.037,1.8046-5.407,2.6677-10.108,2.6677-6.348,0-9.56,0-15.986,0-.47,4.5508-.705,6.8262-1.176,11.2985-5.015,0-7.522,0-12.616,0c2.194-13.1031,4.31-26.2847,6.348-39.5448Zm25.232,11.377c.078-2.2754-1.097-3.3739-3.526-3.3739-4.859,0-7.21,0-12.068,0-.549,5.0216-.784,7.4539-1.254,12.4755c5.094,0,7.68,0,12.773,0c2.586,0,3.84-1.0985,3.918-3.3739.079-2.3539.079-3.4523.157-5.7277Z" fill="#fcf8ff" />
        <path d="M196.6,82.2813c-8.384,0-16.769,0-25.154,0-.157-3.1384-.235-4.7861-.392-7.9246c2.43,0,3.605,0,6.034,0-.627-7.9247-1.175-15.8494-1.802-23.774-2.194,0-3.291,0-5.407,0-.157-3.1385-.235-4.7862-.392-7.9247c8.306,0,12.46,0,20.844,0c.47,3.1385.784,4.7862,1.254,7.9247-2.194,0-3.291,0-5.407,0c1.019,7.9246,2.038,15.8493,3.135,23.774c2.429,0,3.604,0,6.033,0c.471,3.1385.784,4.7862,1.254,7.9246Z" fill="#fcf8ff" />
        <path d="M245.498,82.2814c-5.329,0-7.993,0-13.243,0-7.68-7.7678-15.281-15.5355-22.49-23.774c1.881,7.9246,3.84,15.8493,5.72,23.774-5.015,0-7.522,0-12.616,0-2.429-13.2601-4.858-26.4417-7.366-39.7018c4.388,0,6.583,0,10.971,0c6.817,8.2385,14.027,16.0847,21.393,23.774-2.508-7.9247-5.016-15.8493-7.523-23.774c4.153,0,6.269,0,10.422,0c4.937,13.2601,9.873,26.4417,14.732,39.7018Z" fill="#fcf8ff" />
        <path d="M101.312,42.658c-4.0744,0-7.2872.8631-9.7948,2.6677-2.5076,1.8047-4.545,4.7077-5.9555,8.6308-.2351.7062-.3918,1.0201-.6269,1.7262-.8619,2.2754-1.1754,4.1585-1.097,5.6493.0783-1.4123.4701-2.9816,1.097-4.7862.2351-.7062.3918-1.02.6269-1.7262c1.4889-4.0015,3.5263-6.8262,5.9555-8.6308c2.5076-1.8046,5.7204-2.6677,9.7948-2.6677c8.072,0,12.146,0,20.139,0c.079-.3138.079-.5492.157-.8631-8.15,0-12.224,0-20.296,0Z" fill="#e8b0ff" />
        <path d="M98.8833,59.3704c4.5447,0,6.7387,0,11.2837,0c4.075,0,6.896.8631,8.542,2.5893c1.097,1.1769,1.645,2.8246,1.724,4.8646.078-2.5108-.47-4.3938-1.724-5.7277-1.567-1.7262-4.388-2.5892-8.542-2.5892-4.545,0-6.739,0-11.2837,0-1.8023,0-2.7427-.5493-2.8994-1.7262-.2351,1.7262.7053,2.5892,2.8994,2.5892Z" fill="#e8b0ff" />
        <path d="M103.428,75.769c1.019,0,1.959-.2354,2.665-.7846.705-.5492,1.175-1.1769,1.41-2.04.47-2.04.94-3.9231,1.411-5.9631-.079,0-.157,0-.235,0-.392,1.4907-.784,3.3738-1.254,5.1-.235.8631-.706,1.5692-1.411,2.04-.705.5492-1.645.7846-2.664.7846-8.2281,0-16.5344-.4707-24.7624-.8631-1.0187,3.0601-1.6456,4.6293-2.7426,7.6893.0783,0,.2351,0,.3134,0c.9404-2.5108,1.4889-4.08,2.5076-6.8262c8.228.3923,16.5343.8631,24.762.8631Z" fill="#e8b0ff" />
        <path d="M166.431,53.8781c-.157-4.0015-1.175-6.8262-3.056-8.6308s-4.78-2.6677-8.855-2.6677c-8.15,0-16.221,0-24.292,0-2.116,13.2601-4.153,26.4417-6.269,39.7018.078,0,.078,0,.157,0c2.037-12.9463,4.075-25.8925,6.112-38.8387c8.071,0,16.221,0,24.292,0c4.075,0,6.974.8631,8.855,2.6677s2.899,4.7077,3.056,8.6308c.078,2.2754.078,3.3739.157,5.5708c0-.2354,0-.5492,0-.7846-.079-2.197-.157-3.3739-.157-5.6493Z" fill="#e8b0ff" />
        <path d="M155.225,59.6058c-.078,2.2754-1.41,3.4524-3.918,3.4524h-12.851c0,.3138-.078.5492-.078.863h12.851c2.586,0,3.918-1.1769,3.918-3.4523.078-.4708.078-.8631.078-.8631Z" fill="#e8b0ff" />
        <path d="M195.347,74.3566c0,0-6.034,0-6.113,0c.079.3139.079.5493.079.8631h6.112c0-.3138,0-.5492-.078-.8631Z" fill="#e8b0ff" />
        <path d="M190.331,42.5796c-8.306,0-12.459,0-20.844,0c0,.3138,0,.5492.078.8631c8.307,0,12.617,0,20.923,0-.078-.2354-.078-.4708-.157-.8631Z" fill="#e8b0ff" />
        <path d="M177.088,74.3566c-2.351,0-3.683,0-6.034,0c0,.3139,0,.6277.079.8631c2.429,0,3.683,0,6.112,0-.079-.3138-.079-.6277-.157-.8631Z" fill="#e8b0ff" />
        <path d="M245.184,82.2814c.079,0,.235,0,.314,0-4.859-13.2601-9.795-26.4417-14.654-39.7018-4.153,0-6.269,0-10.422,0c.078.3138.157.5492.314.8631c3.996,0,6.033,0,10.108,0c4.78,12.9462,9.56,25.8924,14.34,38.8387Z" fill="#e8b0ff" />
        <path d="M228.337,67.6874c-.157-.4707-.314-.863-.392-1.3338-7.209-7.6108-14.732-15.6924-21.393-23.774-4.388,0-6.582,0-10.97,0c.078.3138.078.5492.156.8631c4.31,0,6.504,0,10.814,0c6.818,8.2385,14.419,16.5555,21.785,24.2447Z" fill="#e8b0ff" />
      </g>
      <clipPath id="eMuaYO0y5tU39"><rect width="169.653" height="40.251" rx="0" ry="0" transform="translate(75.845 42.5796)" fill="#fff" /></clipPath>
    </>
  );
}
function StopText() {
  return (
    <svg width="100%" height="125" viewBox="-215 0 929 125" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_495_4160)">
        <path
          d="M19.7916 98.1968C43.7371 99.4151 67.9269 100.633 91.6281 100.877C94.5602 100.877 97.2479 100.146 99.447 98.4405C101.646 96.9785 103.112 94.7855 103.601 92.1052C105.067 85.7699 106.044 82.8459 107.51 76.5106C90.162 76.5106 81.3657 76.5106 64.0174 76.5106C50.823 76.5106 42.0267 73.8303 38.1172 68.226C34.2077 62.6217 34.4521 53.8498 39.0946 41.6666C39.8276 39.4736 40.3163 38.4989 41.0493 36.5496C45.4475 24.3664 51.556 15.5945 58.8863 9.99017C66.2165 4.38588 75.7458 1.70557 87.4743 1.70557C111.175 1.70557 122.904 1.70557 146.605 1.70557C144.406 11.4522 143.429 16.3254 141.474 26.072C119.727 26.072 108.732 26.072 86.9856 26.072C79.8997 26.072 75.2572 29.4833 73.0581 36.5496C72.5694 38.0116 72.3251 38.7426 71.8364 39.9609C69.6373 47.0272 72.0807 50.4385 79.411 50.4385C92.6054 50.4385 99.2027 50.4385 112.153 50.4385C124.126 50.4385 132.189 53.1188 136.587 58.4794C140.985 63.8401 142.451 72.1247 140.008 83.5769C139.275 86.9882 139.03 88.6939 138.297 92.3488C136.098 103.801 131.456 112.086 124.126 117.446C116.795 122.807 106.533 125.487 93.0941 125.487C86.2525 125.487 78.9223 125.487 71.1034 125.244C63.7731 125 60.108 124.756 52.5334 124.513C38.8502 124.025 24.9228 123.051 10.5066 122.076C14.4161 112.086 16.1265 107.456 19.7916 98.1968Z"
          fill="#FCF8FF"
        />
        <path
          d="M209.401 123.294C194.74 123.294 187.41 123.294 172.75 123.294C177.392 90.8868 182.035 58.4794 186.433 26.072C171.772 26.072 164.442 26.072 149.537 26.072C151.492 16.3254 152.469 11.4522 154.424 1.70557C188.143 1.70557 221.862 1.70557 255.581 1.70557C255.337 11.4522 255.093 16.3254 254.848 26.072C240.188 26.072 232.858 26.072 217.953 26.072C215.265 58.4794 212.333 90.8868 209.401 123.294Z"
          fill="#FCF8FF"
        />
        <path
          d="M388.503 90.3996C390.458 102.583 388.992 111.355 383.372 116.959C377.752 122.563 367.979 125.244 353.563 125.244C333.282 125.244 323.02 125.244 302.739 125.244C288.568 125.244 278.061 122.563 271.708 116.959C265.355 111.355 262.423 102.583 262.667 90.3996C263.156 68.2261 263.156 57.0175 263.645 34.8441C263.889 22.6608 266.821 13.8889 272.197 8.2846C277.572 2.68031 286.124 0 297.853 0C314.712 0 323.02 0 339.879 0C351.608 0 360.404 2.68031 366.757 8.2846C373.11 13.8889 377.264 22.6608 379.218 34.8441C383.128 57.0175 384.838 67.9825 388.503 90.3996ZM347.21 34.8441C346.477 27.7778 342.567 24.3665 335.481 24.3665C323.509 24.3665 317.644 24.3665 305.672 24.3665C298.586 24.3665 295.409 27.7778 295.654 34.8441C296.387 57.0175 296.875 68.2261 297.608 90.3996C297.853 97.2222 302.006 100.877 309.825 100.877C323.264 100.877 329.861 100.877 343.3 100.877C351.119 100.877 354.784 97.4659 353.807 90.3996C351.119 67.9825 349.897 57.0175 347.21 34.8441Z"
          fill="#FCF8FF"
        />
        <path
          d="M390.702 1.70557C414.404 1.70557 437.86 1.70557 461.562 1.70557C473.29 1.70557 482.819 4.38588 490.15 9.99017C497.48 15.5945 503.588 24.3664 507.986 36.5496C510.674 43.3722 511.896 47.0272 514.339 53.8498C518.738 66.0331 519.226 74.805 515.072 80.4093C510.919 86.0136 502.122 88.6939 488.683 88.6939C470.113 88.6939 460.829 88.6939 442.259 88.6939C445.679 102.583 447.39 109.405 450.81 123.294C436.15 123.294 428.82 123.294 414.404 123.294C406.585 82.8459 398.521 42.1539 390.702 1.70557ZM475.978 36.5496C473.779 29.727 469.136 26.072 462.05 26.072C448.123 26.072 441.037 26.072 426.865 26.072C430.53 41.4229 432.485 48.9765 436.394 64.3274C451.299 64.3274 458.629 64.3274 473.534 64.3274C480.865 64.3274 483.552 60.9161 481.353 53.8498C479.154 46.7835 478.177 43.3722 475.978 36.5496Z"
          fill="#FCF8FF"
        />
        <path
          d="M38.8502 44.1032C39.5832 41.9102 40.0719 40.9356 40.8049 38.9863C45.2031 26.803 51.3116 18.0311 58.6419 12.4268C65.9722 6.82253 75.5015 4.14221 87.2299 4.14221C110.687 4.14221 122.415 4.14221 145.872 4.14221C146.116 3.41122 146.116 2.68023 146.361 1.70557C122.659 1.70557 110.931 1.70557 87.2299 1.70557C75.5015 1.70557 65.9722 4.38588 58.6419 9.99017C51.3116 15.5945 45.4474 24.3664 40.8049 36.5496C40.0719 38.7426 39.5832 39.7173 38.8502 41.6666C36.1625 48.7329 34.9407 54.5808 35.1851 59.4541C35.4294 55.0681 36.6511 49.9512 38.8502 44.1032Z"
          fill="#E8B0FF"
        />
        <path
          d="M19.7915 100.634C43.737 101.852 67.9268 103.07 91.628 103.314C94.5601 103.314 97.2478 102.583 99.4469 100.877C101.646 99.4152 103.112 97.2222 103.601 94.5419C105.067 88.2066 106.533 82.846 107.999 76.5107C107.755 76.5107 107.51 76.5107 107.51 76.5107C106.288 81.1404 105.067 86.7447 103.601 92.1053C102.868 94.7856 101.646 96.9786 99.4469 98.4406C97.2478 99.9026 94.8044 100.877 91.628 100.877C67.6825 100.877 43.737 99.4152 19.7915 98.1969C16.3707 107.456 14.6603 112.329 11.2395 121.589C11.4838 121.589 11.7282 121.589 12.2169 121.589C14.9046 113.791 16.615 109.162 19.7915 100.634Z"
          fill="#E8B0FF"
        />
        <path
          d="M112.153 50.4387C98.9581 50.4387 92.3609 50.4387 79.4107 50.4387C74.2796 50.4387 71.5918 48.7331 71.1031 45.3218C70.3701 50.1951 73.3022 52.8754 79.4107 52.8754C92.6052 52.8754 99.2024 52.8754 112.153 52.8754C124.125 52.8754 132.189 55.5557 136.587 60.9163C139.274 64.084 140.985 68.7136 141.229 74.0742C141.474 67.2516 139.763 62.1346 136.587 58.4797C132.189 53.119 124.125 50.4387 112.153 50.4387Z"
          fill="#E8B0FF"
        />
        <path
          d="M154.912 4.14221C188.632 4.14221 222.351 4.14221 255.826 4.14221C255.826 3.41122 255.826 2.68023 255.826 1.70557C222.106 1.70557 188.387 1.70557 154.668 1.70557C152.713 11.4522 151.736 16.3254 149.781 26.072C150.026 26.072 150.026 26.072 150.27 26.072C151.98 18.0311 152.958 13.1578 154.912 4.14221Z"
          fill="#E8B0FF"
        />
        <path
          d="M263.645 37.2807C263.889 25.0975 266.821 16.3255 272.197 10.7212C277.572 5.11696 286.124 2.43665 297.852 2.43665C314.712 2.43665 323.02 2.43665 339.879 2.43665C351.608 2.43665 360.404 5.11696 366.757 10.7212C373.11 16.3255 377.264 25.0975 379.218 37.2807C382.883 59.4542 384.838 70.6628 388.503 92.8363C388.992 95.5166 389.236 98.1969 389.236 100.634C389.236 97.4659 388.992 94.2982 388.503 90.3996C384.838 68.2261 382.883 57.0175 379.218 34.8441C377.264 22.6608 372.865 13.8889 366.757 8.2846C360.404 2.68031 351.608 0 339.879 0C323.02 0 314.712 0 297.852 0C286.124 0 277.572 2.68031 272.197 8.2846C266.821 13.8889 263.889 22.6608 263.645 34.8441C263.156 57.0175 263.156 68.2261 262.667 90.3996C262.667 90.8869 262.667 91.1306 262.667 91.6179C263.156 70.1754 263.4 58.9669 263.645 37.2807Z"
          fill="#E8B0FF"
        />
        <path
          d="M297.608 92.8364C297.852 99.659 302.006 103.314 309.825 103.314C323.264 103.314 329.861 103.314 343.3 103.314C350.875 103.314 354.54 99.9027 353.807 93.3237C353.318 98.197 349.897 100.877 343.3 100.877C329.861 100.877 323.264 100.877 309.825 100.877C302.006 100.877 297.852 97.466 297.608 90.3997C296.875 68.7136 296.386 57.505 295.653 36.0625C295.653 36.5498 295.653 36.7935 295.653 37.2808C296.386 59.4543 296.875 70.4192 297.608 92.8364Z"
          fill="#E8B0FF"
        />
        <path
          d="M461.317 4.14221C473.046 4.14221 482.575 6.82253 489.905 12.4268C497.235 18.0311 503.344 26.803 507.742 38.9863C510.43 45.8089 511.652 49.4639 514.095 56.2865C516.294 62.1344 517.516 67.0077 517.76 71.3937C518.004 66.5204 516.783 60.9161 514.095 53.8498C511.407 47.0272 510.185 43.3722 507.742 36.5496C503.344 24.3664 497.235 15.5945 489.905 9.99017C482.575 4.38588 473.046 1.70557 461.317 1.70557C437.616 1.70557 414.159 1.70557 390.458 1.70557C390.702 2.43656 390.702 3.41122 390.947 4.14221C414.648 4.14221 437.86 4.14221 461.317 4.14221Z"
          fill="#E8B0FF"
        />
        <path
          d="M482.086 59.2107C481.597 62.622 478.91 64.3276 473.534 64.3276C458.629 64.3276 451.299 64.3276 436.394 64.3276C432.729 49.9514 430.286 39.7175 426.865 26.0723C426.621 26.0723 426.376 26.0723 426.376 26.0723C430.041 41.4231 432.729 51.4134 436.394 66.7643C451.299 66.7643 458.629 66.7643 473.534 66.7643C479.887 66.7643 482.819 64.084 482.086 59.2107Z"
          fill="#E8B0FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_495_4160">
          <rect width="529" height="125" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
