import React, {
  createContext, useContext, useEffect, useMemo,
} from 'react';
import {
  EnergyRecoveryTimerProps,
  EnergyRecoveryTimerT,
  useEnergyRecoveryTimer,
} from '../../hooks/useEnergyRecoveryTimer';
import { useUserEnergy, UseUserEnergyT } from '../../hooks/useUserEnergy';
import { useAppDispatch } from '../../store';
import { updateProfileEnergy } from '../../store/reducers/profile/asyncActions';

interface EnergyContextProps {
  energyRecoveryTimer: (val:EnergyRecoveryTimerProps) => EnergyRecoveryTimerT
  userEnergy: UseUserEnergyT
}

const EnergyContext = createContext<EnergyContextProps | undefined>(undefined);

export const useEnergyContext = () => {
  const context = useContext(EnergyContext);
  if (!context) {
    throw new Error(
      'useEnergyContext must be used within an EnergyProvider',
    );
  }

  return context;
};

function EnergyProvider({ children }: { children: React.ReactNode }) {
  const energyRecoveryTimer = useEnergyRecoveryTimer;

  const dispatch = useAppDispatch();
  const userEnergy = useUserEnergy();

  useEffect(() => {
    const energyFillInterval = setInterval(() => {
      if (userEnergy.energyRecoveryPerSecond && (userEnergy.userEnergy < userEnergy.maxEnergy)) {
        dispatch(updateProfileEnergy({ energy: userEnergy.energyRecoveryPerSecond, replace: false }));
      }
    }, 1000);
    return () => clearInterval(energyFillInterval);
  }, [userEnergy.energyRecoveryPerSecond, userEnergy.userEnergy, userEnergy.maxEnergy]);

  const contextValue = useMemo(() => ({
    energyRecoveryTimer, userEnergy,
  }), [energyRecoveryTimer, userEnergy]);

  return (
    <EnergyContext.Provider value={contextValue}>
      {children}
    </EnergyContext.Provider>
  );
}

export default EnergyProvider;
