import { useEnergyContext } from '../../../providers/EnergyProvider';
import MiddleScreen from './MiddleScreen';

function SpinsScreen() {
  const { energyRecoveryTimer, userEnergy: userEnergyContext } = useEnergyContext();
  const { userEnergy, maxEnergy, energyForSpin } = userEnergyContext;
  const { countdown } = energyRecoveryTimer({ energyUnit: energyForSpin });
  return (
    <div className="relative">
      <div className="absolute bottom-[10px] slot-spins-screen">
        <MiddleScreen countdown={countdown} userEnergy={userEnergy} maxEnergy={maxEnergy} energyForSpin={energyForSpin} />
      </div>
      <svg width="159" height="63" viewBox="0 -2 159 52" fill="none" xmlns="http://www.w3.org/2000/svg" className="z-2">
        <path d="M100.218 9.9736V7.21304C100.218 3.29483 97.0128 0 93.0067 0H66.8335C62.9165 0 59.6226 3.20578 59.6226 7.21304V9.9736H100.218Z" fill="url(#paint0_radial_4803_197874)" />
        <path opacity="0.7" d="M59.8008 9.9736V7.21304C59.8008 3.29483 63.0056 0 67.0117 0H93.1849L67.4569 0.534279C65.3203 0.71238 62.7386 1.51385 60.9581 4.36345C60.0678 5.96636 59.8008 9.9736 59.8008 9.9736Z" fill="white" />
        <path d="M79.3212 61.5335C123.129 61.5335 158.642 54.1577 158.642 45.0592C158.642 35.9607 123.129 28.585 79.3212 28.585C35.5136 28.585 0.000488281 35.9607 0.000488281 45.0592C0.000488281 54.1577 35.5136 61.5335 79.3212 61.5335Z" fill="url(#paint1_radial_4803_197874)" />
        <path d="M27.3067 12.2891C24.992 12.2891 22.9444 13.6248 22.0542 15.673L8.34445 44.6143C7.63225 46.3062 7.54323 50.4025 10.6591 50.4025H149.715C152.119 50.4025 152.653 46.3062 152.03 44.7033L138.854 15.762C137.964 13.6248 135.916 12.2891 133.602 12.2891H27.3067Z" fill="url(#paint2_linear_4803_197874)" />
        <path d="M28.3751 9.9748C25.5263 9.9748 22.7666 11.7558 21.6092 14.3383L8.34461 44.7044C7.63242 46.3963 8.78974 48.0883 10.6592 48.0883H149.715C151.496 48.0883 152.653 46.3073 152.03 44.7044L139.21 14.4273C138.053 11.7558 135.382 9.88574 132.356 9.88574L28.3751 9.9748Z" fill="url(#paint3_linear_4803_197874)" />
        <g clipPath="url(#clip0_4803_197874)">
          <path d="M10.3633 41.8643L23.0938 12.923C23.628 11.6763 24.7853 10.9639 26.0316 10.9639H133.662C134.997 10.9639 136.244 11.7653 136.6 13.012L148.885 41.9533C149.775 44.1796 148.173 46.4949 145.947 46.4949H13.3902C10.9865 46.4059 9.47308 43.9124 10.3633 41.8643Z" fill="#0C056B" />
          <path d="M28.0793 10.0734C25.8537 10.0734 23.8951 11.4092 23.0049 13.3683L9.65121 41.5972C8.93901 43.2001 8.93901 47.2074 11.8768 47.2074H147.461C149.776 47.2074 150.31 43.2891 149.687 41.5972L136.867 13.3683C135.977 11.2311 134.018 9.98438 131.704 9.98438H28.0793V10.0734Z" fill="url(#paint4_linear_4803_197874)" />
          <path d="M10.3633 41.8643L23.0938 12.923C23.628 11.6763 24.7853 10.9639 26.0316 10.9639H133.662C134.997 10.9639 136.244 11.7653 136.6 13.012L148.885 41.9533C149.775 44.1796 148.173 46.4949 145.947 46.4949H13.3902C10.9865 46.4059 9.47308 43.9124 10.3633 41.8643Z" fill="url(#paint5_linear_4803_197874)" />
          <path d="M27.901 9.53925C25.0522 9.53925 22.2924 11.3202 21.1351 13.9027L7.87049 44.2688C7.15829 45.9608 8.31562 47.6527 10.1851 47.6527H149.241C151.022 47.6527 152.179 45.8717 151.556 44.2688L138.736 13.9918C137.579 11.3203 134.908 9.4502 131.881 9.4502L27.901 9.53925Z" fill="url(#paint6_linear_4803_197874)" />
          <path d="M9.19379 44.5622L22.8366 13.0661C23.3973 11.7339 24.612 10.9727 26.0137 10.9727H133.381C134.782 10.9727 136.091 11.829 136.558 13.1612L149.64 44.6573C150.574 47.0362 148.892 49.6053 146.463 49.6053H12.4643C9.94134 49.5102 8.16591 46.941 9.19379 44.5622Z" fill="#0C056B" />
          <path d="M28.0695 10.0215C25.7334 10.0215 23.5842 11.4488 22.6497 13.6374L8.35282 44.3722C7.60527 46.085 7.60527 50.4621 10.6889 50.4621H147.958C150.388 50.4621 151.042 46.1801 150.294 44.3722L136.558 13.7325C135.623 11.4488 133.474 10.0215 131.045 10.0215H28.0695Z" fill="url(#paint7_linear_4803_197874)" />
          <path d="M9.19379 44.5622L22.8366 13.0661C23.3973 11.7339 24.612 10.9727 26.0137 10.9727H133.381C134.782 10.9727 136.091 11.829 136.558 13.1612L149.64 44.6573C150.574 47.0362 148.892 49.6053 146.463 49.6053H12.4643C9.94134 49.5102 8.16591 46.941 9.19379 44.5622Z" fill="url(#paint8_linear_4803_197874)" />
          <path d="M146.089 48.0829H13.212C11.3431 48.0829 10.0349 46.0846 10.7825 44.3719L23.304 15.5401C24.1449 13.637 26.0138 12.3048 28.0696 12.3048H131.325C133.474 12.3048 135.343 13.637 136.184 15.5401L148.238 44.7525C148.893 46.3701 147.771 48.0829 146.089 48.0829ZM27.9761 9.4502C24.8925 9.4502 21.9957 11.3533 20.781 14.2079L6.48406 47.2265C5.7365 49.0344 6.95128 50.9375 8.9136 50.9375H149.92C151.789 50.9375 153.098 49.0344 152.35 47.2265L138.707 14.3982C137.492 11.3533 134.596 9.4502 131.419 9.4502H27.9761Z" fill="url(#paint9_linear_4803_197874)" />
        </g>
        <defs>
          <radialGradient id="paint0_radial_4803_197874" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.0189 2.1051) scale(36.7971 7.01064)">
            <stop stopColor="#BEE0EE" />
            <stop offset="0.1263" stopColor="#B4D7E8" />
            <stop offset="0.3432" stopColor="#98BEDA" />
            <stop offset="0.6247" stopColor="#6B95C2" />
            <stop offset="0.9565" stopColor="#2E5EA1" />
            <stop offset="1" stopColor="#25569C" />
          </radialGradient>
          <radialGradient id="paint1_radial_4803_197874" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(78.5047 45.1385) rotate(1.89311) scale(70.0392 15.141)">
            <stop stopColor="#0A4094" />
            <stop offset="1" stopColor="#0A4094" stopOpacity="0" />
          </radialGradient>
          <linearGradient id="paint2_linear_4803_197874" x1="20.5899" y1="70.2514" x2="132.825" y2="5.47129" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6BB4F3" />
            <stop offset="1" stopColor="#00356E" />
          </linearGradient>
          <linearGradient id="paint3_linear_4803_197874" x1="46.5842" y1="-5.59181" x2="110.066" y2="78.6273" gradientUnits="userSpaceOnUse">
            <stop offset="0.0144134" stopColor="white" />
            <stop offset="0.2348" stopColor="#FCFDFF" />
            <stop offset="0.3949" stopColor="#F3F7FE" />
            <stop offset="0.5359" stopColor="#E3EEFE" />
            <stop offset="0.6658" stopColor="#CDE0FD" />
            <stop offset="0.7883" stopColor="#B0CEFB" />
            <stop offset="0.9034" stopColor="#8DB8FA" />
            <stop offset="1" stopColor="#69A2F8" />
          </linearGradient>
          <linearGradient id="paint4_linear_4803_197874" x1="50.3922" y1="77.002" x2="112.637" y2="-5.57556" gradientUnits="userSpaceOnUse">
            <stop offset="2.23767e-07" stopColor="#41DEFF" stopOpacity="0.3" />
            <stop offset="0.0936256" stopColor="#37BDE8" stopOpacity="0.3655" />
            <stop offset="0.2719" stopColor="#2784C0" stopOpacity="0.4903" />
            <stop offset="0.4449" stopColor="#19559F" stopOpacity="0.6114" />
            <stop offset="0.6082" stopColor="#0E3086" stopOpacity="0.7258" />
            <stop offset="0.7597" stopColor="#061673" stopOpacity="0.8318" />
            <stop offset="0.8948" stopColor="#020668" stopOpacity="0.9263" />
            <stop offset="1" stopColor="#000064" />
          </linearGradient>
          <linearGradient id="paint5_linear_4803_197874" x1="59.9321" y1="73.8405" x2="105.834" y2="-5.64028" gradientUnits="userSpaceOnUse">
            <stop stopColor="#41DEFF" stopOpacity="0.2" />
            <stop offset="1" stopColor="#000064" />
          </linearGradient>
          <linearGradient id="paint6_linear_4803_197874" x1="46.1101" y1="-6.02735" x2="109.592" y2="78.1917" gradientUnits="userSpaceOnUse">
            <stop offset="0.0144134" stopColor="white" />
            <stop offset="0.2348" stopColor="#FCFDFF" />
            <stop offset="0.3949" stopColor="#F3F7FE" />
            <stop offset="0.5359" stopColor="#E3EEFE" />
            <stop offset="0.6658" stopColor="#CDE0FD" />
            <stop offset="0.7883" stopColor="#B0CEFB" />
            <stop offset="0.9034" stopColor="#8DB8FA" />
            <stop offset="1" stopColor="#69A2F8" />
          </linearGradient>
          <linearGradient id="paint7_linear_4803_197874" x1="49.5302" y1="80.9859" x2="114.753" y2="-4.01221" gradientUnits="userSpaceOnUse">
            <stop offset="2.23767e-07" stopColor="#41DEFF" stopOpacity="0.3" />
            <stop offset="0.0936256" stopColor="#37BDE8" stopOpacity="0.3655" />
            <stop offset="0.2719" stopColor="#2784C0" stopOpacity="0.4903" />
            <stop offset="0.4449" stopColor="#19559F" stopOpacity="0.6114" />
            <stop offset="0.6082" stopColor="#0E3086" stopOpacity="0.7258" />
            <stop offset="0.7597" stopColor="#061673" stopOpacity="0.8318" />
            <stop offset="0.8948" stopColor="#020668" stopOpacity="0.9263" />
            <stop offset="1" stopColor="#000064" />
          </linearGradient>
          <linearGradient id="paint8_linear_4803_197874" x1="59.3371" y1="77.681" x2="107.789" y2="-4.73128" gradientUnits="userSpaceOnUse">
            <stop stopColor="#41DEFF" stopOpacity="0.2" />
            <stop offset="1" stopColor="#000064" />
          </linearGradient>
          <linearGradient id="paint9_linear_4803_197874" x1="44.9706" y1="-5.63187" x2="111.539" y2="81.1198" gradientUnits="userSpaceOnUse">
            <stop offset="0.0144134" stopColor="white" />
            <stop offset="0.2348" stopColor="#FCFDFF" />
            <stop offset="0.3949" stopColor="#F3F7FE" />
            <stop offset="0.5359" stopColor="#E3EEFE" />
            <stop offset="0.6658" stopColor="#CDE0FD" />
            <stop offset="0.7883" stopColor="#B0CEFB" />
            <stop offset="0.9034" stopColor="#8DB8FA" />
            <stop offset="1" stopColor="#69A2F8" />
          </linearGradient>
          <clipPath id="clip0_4803_197874">
            <rect width="147.98" height="42.525" fill="white" transform="translate(4.72314 9.4502)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default SpinsScreen;
