import React, {
  useEffect, useRef, useState,
} from 'react';
import useGetPlatform from './useGetPlatform';

type UseLongPressProps = {
  onLongPress: () => void;
  onClick: () => void;
  ms?: number;
};

const intervalTime = 100;
export default function useLongPress({ onLongPress, onClick, ms = 300 }: UseLongPressProps) {
  const { isMobile } = useGetPlatform();
  const [startLongPress, setStartLongPress] = useState(false);
  const isLongPress = useRef(false);
  const [count, setCount] = useState(0);
  const descTimerId = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (startLongPress) {
      isLongPress.current = false;
      descTimerId.current = setTimeout(() => {
        isLongPress.current = true;
        onLongPress();
      }, ms);
    } else if (descTimerId.current) {
      clearTimeout(descTimerId.current);
      descTimerId.current = null;
    }

    return () => {
      if (descTimerId.current) {
        clearTimeout(descTimerId.current);
        descTimerId.current = null;
      }
    };
  }, [onLongPress, ms, startLongPress]);

  const descHandleStart = () => {
    setStartLongPress(true);
  };

  const descHandleEnd = (event: React.MouseEvent | React.TouchEvent) => {
    setStartLongPress(false);
    if (isLongPress.current) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onClick();
    }
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (startLongPress) {
      isLongPress.current = false;
      timerId = setTimeout(() => {
        isLongPress.current = true;
        onLongPress();
      }, ms);
    } else if (timerId) {
      clearTimeout(timerId);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [onLongPress, ms, startLongPress]);

  let interval: null | NodeJS.Timer = null;
  const handleStart = () => {
    setStartLongPress(true);
    setCount(0);
    interval = setInterval(() => {
      setCount((prev) => prev + 1); // this line is not allow to work properly on PC
    }, 100);
  };

  const handleMobileEnd = () => {
    if (count > (ms / intervalTime)) {
      onLongPress();
    } else {
      onClick();
    }
    setCount(0);
    setStartLongPress(false);
    if (interval) {
      clearInterval(interval);
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    if (isLongPress.current) {
      event.preventDefault();
    }
  };

  return {
    onMouseDown: () => {
      if (!isMobile) {
        descHandleStart();
      }
    },
    onMouseUp: (event: React.MouseEvent) => {
      if (!isMobile) {
        descHandleEnd(event);
      }
    },
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => {
      if (isMobile) {
        handleStart();
      } else {
        descHandleStart();
      }
    },
    onTouchEnd: handleMobileEnd,
    onClick: handleClick,
  };
}
