import { useEffect, useState } from 'react';
import { ReactComponent as CoinSize } from '../../assets/images/coin-size.svg';
import { ReactComponent as Energy } from '../../assets/images/energy-22x22.svg';
import { ReactComponent as InstagramIco } from '../../assets/images/socials/instagram.svg';
import { ReactComponent as TelegramIco } from '../../assets/images/socials/telegram.svg';
import { ReactComponent as TwitterIco } from '../../assets/images/socials/twitter.svg';
import { ReactComponent as YouTubeIco } from '../../assets/images/socials/youtube.svg';
import { ReactComponent as FollowTaskIco } from '../../assets/svg/follow-task.svg';
import { QuestStatus, QuestT } from '../../types';
import Button from '../Button';
import {
  CheckButton, ClaimedButton,
  ErrorButton,
  ValidatingButton,
} from '../QuestButtons';

type QuestProps = {
    quest: QuestT
    onStart?: () => void
    onCheck: () => void
    isValid: boolean
}
function Quest({
  quest, onStart, onCheck, isValid,
}: QuestProps) {
  const [checkDisable, setCheckDisable] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckDisable(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [checkDisable]);

  const getQuestIco = () => {
    switch (quest.icon) {
      case 'telegram':
        return <TelegramIco />;
      case 'twitter':
        return <TwitterIco />;
      case 'youtube':
        return <YouTubeIco />;
      case 'instagram':
        return <InstagramIco />;
      default:
        return <TelegramIco />;
    }
  };

  const handleCheck = () => {
    onCheck();
  };

  const getQuestCardBg = () => {
    if (!isValid) {
      return 'bg-questsCardBGError';
    }

    if (quest.progress?.status === 'done') {
      return 'bg-questsCardBGSuccess';
    }

    return 'bg-questsCardBG';
  };

  const displayStatusButton = (status?: QuestStatus) => {
    if (status === 'done') {
      return <ClaimedButton />;
    }

    if (status === 'started') {
      return <CheckButton onClick={handleCheck} checkDisable={false} />;
    }

    if (status === 'failed') {
      return <ErrorButton />;
    }

    if (status === 'validating') {
      return <ValidatingButton />;
    }

    return null;
  };

  return (
    <div className={`${getQuestCardBg()} px-4 py-[10px] rounded-[12px] mt-2 flex items-center justify-between gap-2 gradient-border cyan before:rounded-[12px]`}>
      <div className="flex flex-col justify-between">
        <div className="grid grid-cols-[34px,1fr] gap-x-[9.5px]">
          <div className="row-span-2 flex items-center">
            <div className="w-[34px] h-[30px]">
              {getQuestIco()}
            </div>
          </div>
          <div className="flex items-start line-normal font-bold min-w-[0px]">
            <div className="font-Bangers font-normal text-sm text-start leading-[15px] pr-2">{quest.name}</div>
          </div>
          <div className="flex gap-2 flex-1 mt-[6px]">
            {!!quest.rewards.energy && (
            <div className="flex gap-[2px] items-center text-sm">
              <Energy width={18} className="w-3.5 h-3.5" />
              <span className="text-xs line-normal font-Bangers whitespace-nowrap">
                +
                {' '}
                {quest?.rewards.energy}
              </span>
            </div>
            )}
            {!!quest.rewards.coins && (
            <div className="flex gap-[2px] items-center text-sm">
              <CoinSize width={18} className="w-4 h-4" />
              <span className="text-xs line-normal font-Bangers whitespace-nowrap">
                +
                {' '}
                {quest?.rewards.coins }
              </span>
            </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2.5 ">
        {/* eslint-disable-next-line no-nested-ternary */}
        {displayStatusButton(!isValid ? 'failed' : quest.progress?.status)}
        <Button
          pureClass
          onClick={onStart}
        >
          <FollowTaskIco />
        </Button>
      </div>
    </div>
  );
}

export default Quest;
