import {
  memo, useEffect, useMemo, useState,
} from 'react';
import TreasuresBg from '../../assets/svg/treasures-bg.svg';
import Treasures from '../../assets/svg/treasures.svg';
import { WSEvents } from '../../constants/ws';
import { useWebSocketContext } from '../../providers/WebSocketProvider';
import { QuestsWsMessages } from '../../providers/WebSocketProvider/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  finishQuest,
  getQuests,
  startQuest,
  verifyQuest,
} from '../../store/reducers/quests/asyncActions';
import { QuestT, TQuestsResponse } from '../../types';
import QuestDrawerContent from './QuestDrawerContent';

const tg = window?.Telegram.WebApp;

const QuestPage = memo(() => {
  const { webSocket } = useWebSocketContext();

  const dispatch = useAppDispatch();

  const { questsList } = useAppSelector(({ quests }) => quests);

  const [invalidQuests, setInvalidQuests] = useState<number[]>([]);

  const sortedQuests = useMemo(() => {
    const tempList: TQuestsResponse = {
      daily: [],
      social: [],
      referral: [],
      daily_energy_refill: [],
      crash_task: [],
    };
    Object.keys(questsList || []).forEach((key: keyof TQuestsResponse) => {
      const sortedList = [...(questsList as TQuestsResponse)[key]].sort((el) => (el.progress?.completed_at ? 0 : -1)) || [];
      Object.assign(tempList, { [key]: sortedList });
    });
    return tempList;
  }, [questsList]);

  useEffect(() => {
    const onMessage = ((event: MessageEvent<string>) => {
      const questMsg: QuestsWsMessages = JSON.parse(event.data);

      if (questMsg.type === 'quest-updated') {
        dispatch(verifyQuest(questMsg.data));
      }
    });

    const onConnected = () => {
      dispatch(getQuests());
    };

    webSocket.server?.addEventListener('message', onMessage);
    window.addEventListener(WSEvents.CONNECTED, onConnected);

    return () => {
      webSocket.server?.removeEventListener('message', onMessage);
      window.removeEventListener(WSEvents.CONNECTED, onConnected);
    };
  }, [webSocket.server]);

  const handleQuestStart = async (quest: QuestT) => {
    if (quest.url) {
      if (quest.icon === 'telegram') {
        tg.openTelegramLink(quest.url);
      } else {
        tg.openLink(quest.url);
      }
    }

    setInvalidQuests((prev) => (prev.filter((qId) => qId !== quest.id)));

    await dispatch(startQuest({ id: quest.id }));
  };

  const handleQuestCheck = async (quest: QuestT) => {
    const resp = await dispatch(finishQuest({ id: quest.id }));

    if (resp?.error) {
      setInvalidQuests((prev) => ([...prev, quest.id]));

      setTimeout(() => {
        setInvalidQuests((prev) => prev.filter((qId) => qId !== quest.id));
      }, 60_000);
    } else {
      setInvalidQuests((prev) => prev.filter((qId) => qId !== quest.id));
    }
  };

  return (
    <div className="px-2 h-full flex flex-col pt-[.4rem] bg-questsPageBG absolute h-screen w-screen top-0 backdrop-blur-[40px]">
      <div className="mt-[130px] relative min-h-[100px] z-[-1]">
        <img
          className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
          src={TreasuresBg}
          alt="treasures-bg"
        />
        <img
          className="absolute top-0 left-1/2 transform -translate-x-1/2"
          src={Treasures}
          alt="treasures"
        />
        <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-[22px] font-Bangers">
          Earn
        </span>
      </div>
      <div className="w-full text-white mt-[.9rem] mb-[6rem] h-full">
        <div className="px-2 py-3 h-full rounded-t-[0]">
          <QuestDrawerContent
            quests={sortedQuests}
            invalidQuests={invalidQuests}
            handleQuestCheck={handleQuestCheck}
            handleQuestStart={handleQuestStart}
          />
        </div>
      </div>
    </div>
  );
});
QuestPage.displayName = 'QuestPage';
export default QuestPage;
