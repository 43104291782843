import { AviatorHistoryT } from '../../../types';
import { getColorByMultiplier } from './utils';

type TBetHistoryCardProps = {
    history: AviatorHistoryT;
}

function BetHistoryCard({ history }: TBetHistoryCardProps) {
  const bgColor = getColorByMultiplier(history.result_value);

  return (
    <div className={`px-[10px] py-[2.5px] bg-${bgColor} text-sm font-bold rounded-8`}>
      {history.result_value}
      x
    </div>
  );
}

export default BetHistoryCard;
