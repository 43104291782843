import {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import Loader from '../../Components/Loader';
import Modal from '../../Components/Modal';
import SequentialModals from '../../Components/SequentialModals';
import { preloadSlot } from '../../Components/SlotMachine/SlotMachinePixi';
import { assetsPreload } from '../../PIXIHelpers';
import { useAppDispatch, useAppSelector } from '../../store';
import { authenticateUser, refreshAuthToken } from '../../store/reducers/auth/asyncActions';
import { getActiveSlotEffects, getAviatorHistory } from '../../store/reducers/aviator/asyncActions';
import { getTopPlayers } from '../../store/reducers/leaderboard/asyncActions';
import { getMe, updateTGProfile } from '../../store/reducers/profile/asyncActions';
import { getQuests } from '../../store/reducers/quests/asyncActions';
import { getReferrals } from '../../store/reducers/referrals/asyncActions';
import { getSettings, getVersion } from '../../store/reducers/settings/asyncActions';
import { getAutopilot, getPurchases, getShopItems } from '../../store/reducers/store/asyncActions';
import DailyRewardsModal from '../../templates/DailyRewardsModal';
import OverlayGame from './OverlayGame';
import { preloadOverlay } from './overlayApp';
import { toggleMute } from '../../helpers';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version: appVersion } = require('../../../package.json');

interface AppWrapperProps {
    children: ReactNode
}

const tg = window?.Telegram.WebApp;

function AppWrapper({ children }: AppWrapperProps) {
  const dispatch = useAppDispatch();

  const {
    loading: profileLoading, userData,
  } = useAppSelector(({ profile }) => profile);
  const {
    loading: questsLoading,
  } = useAppSelector(({ quests }) => quests);
  const {
    version: beVersion, loading: settingsLoading, settings: AppSetting,
  } = useAppSelector(({ settings }) => settings);
  const { error: authError, loading: authLoading } = useAppSelector(({ auth }) => auth);

  const [overlayLoading, setOverlayLoading] = useState<boolean>(true);
  const [slotLoading, setSlotLoading] = useState<boolean>(true);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(true);
  useEffect(() => {
    toggleMute(!!AppSetting?.enable_sound);
  }, [AppSetting?.enable_sound]);

  const handleAuth = async () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      await dispatch(refreshAuthToken());
    } else {
      await dispatch(authenticateUser(tg.initData));
    }
  };

  const initialLoadingData = async () => { // initial data loading to get all pages data on loading screen
    await Promise.all([
      dispatch(getMe()),
      dispatch(getVersion()),
      dispatch(getSettings()),
      dispatch(getReferrals()),
      dispatch(getTopPlayers()),
      dispatch(getShopItems()),
      dispatch(getPurchases()),
      dispatch(getAutopilot()),
      dispatch(getAviatorHistory()),
      dispatch(getActiveSlotEffects()),
    ]);

    await dispatch(getQuests());

    preloadOverlay().finally(() => {
      setOverlayLoading(false);
    });
    preloadSlot().then(() => {
      setSlotLoading(false);
    });
    assetsPreload().then(() => {
      setAssetsLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      await handleAuth();
      await initialLoadingData();
    })();
  }, []);

  useEffect(() => {
    dispatch(updateTGProfile(tg.initDataUnsafe));
    tg?.disableVerticalSwipes();
    window.console.log(tg, 'tg');
  }, [tg.initDataUnsafe]);

  useEffect(() => {
    if (beVersion) {
      window.console.log({
        BE_Version: beVersion,
        FE_Version: appVersion,
      }, 'app version');
    }
  }, [beVersion]);

  const handleReload = () => {
    tg?.close();
  };

  const loadingProgress = useMemo(() => {
    const loadingList = [profileLoading, settingsLoading, questsLoading, authLoading, overlayLoading, slotLoading, assetsLoading];

    const progress = loadingList.reduce((p, i) => {
      if (!i) {
        return p + 1;
      }

      return p;
    // eslint-disable-next-line no-mixed-operators
    }, 0) / loadingList.length * 100;

    return progress;
  }, [profileLoading, settingsLoading, questsLoading, authLoading, overlayLoading, slotLoading, assetsLoading]);

  const isDailyRewardAvailable = useMemo(
    () => {
      if (!userData?.user_schedule_task) {
        return true;
      }

      const availableAtDate = new Date(userData.user_schedule_task.click_time);

      availableAtDate.setDate(availableAtDate.getDate() + 1);

      return availableAtDate.toISOString() < new Date().toISOString();
    },
    [userData],
  );

  if (authError) {
    return <Modal message={authError} onReload={handleReload} />;
  }
  if (loadingProgress !== 100) {
    return <Loader progress={loadingProgress} />;
  }

  return (
    <SequentialModals modals={[{ id: 'daily-rewards-modal', component: DailyRewardsModal, enabled: isDailyRewardAvailable }]}>
      <OverlayGame />
      {children}
    </SequentialModals>
  );
}

export default AppWrapper;
