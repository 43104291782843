import { MultiColorIconProps } from '../types/icons';

function CrashGuardIcon({ colors = { background: '#0D0D0F', glow: '#EF5A0F' } }: MultiColorIconProps) {
  return (
    <svg width="90" height="134" viewBox="0 0 90 134" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5517_68493)">
        <rect width="90" height="134" rx="12" fill={colors.background ?? '#0D0D0F'} />
        <g filter="url(#filter0_f_5517_68493)">
          <path d="M45 19L47.2362 58.6544L69 25.4308L51.1094 60.8906L86.5692 43L53.3456 64.7638L93 67L53.3456 69.2362L86.5692 91L51.1094 73.1094L69 108.569L47.2362 75.3456L45 115L42.7638 75.3456L21 108.569L38.8906 73.1094L3.43078 91L36.6544 69.2362L-3 67L36.6544 64.7638L3.43078 43L38.8906 60.8906L21 25.4308L42.7638 58.6544L45 19Z" fill={colors.glow ?? '#EF5A0F'} />
        </g>
        <path d="M17.7654 67.9267L17.7012 67.9363L17.7033 68.008L17.744 68.0989L17.7322 68.1984L17.7514 68.2679L17.8156 68.2583L23.5461 79.854C23.5461 79.854 37.2245 69.1772 39.6164 65.4995C39.7533 65.2899 39.8571 65.0748 39.9352 64.8577L40.0903 64.8342C40.0593 64.7817 40.0272 64.7304 39.993 64.6801C40.0101 64.6213 40.0261 64.5624 40.039 64.5036L39.8838 64.5271C39.7437 64.3432 39.58 64.1699 39.3864 64.0105C36.0008 61.2217 19.0244 54.7393 19.0244 54.7393L17.7654 67.9267Z" fill="url(#paint0_linear_5517_68493)" />
        <path d="M15.7821 54.6598C20.5969 53.9196 26.2771 57.3341 27.6838 66.4823C29.099 75.6829 24.6993 80.594 19.8845 81.3342C15.7457 81.9707 10.6047 76.6756 9.47187 69.3096C8.33904 61.9435 11.2915 55.3498 15.7821 54.6598Z" fill="url(#paint1_linear_5517_68493)" />
        <path d="M16.2711 58.8108C19.5947 58.2995 22.9226 62.005 23.7045 67.0861C24.4854 72.1673 22.4251 76.7018 19.1015 77.2121C15.7779 77.7234 12.9079 73.9205 12.127 68.8394C11.345 63.7583 12.9475 59.3222 16.2711 58.8108Z" fill="url(#paint2_linear_5517_68493)" />
        <path d="M15.629 58.9173C18.9526 58.4059 22.2805 62.1114 23.0624 67.1926C23.8433 72.2737 21.7831 76.8083 18.4595 77.3185C15.1358 77.8298 12.2658 74.027 11.4849 68.9458C10.703 63.8647 12.3054 59.4286 15.629 58.9173Z" fill="url(#paint3_linear_5517_68493)" />
        <path d="M15.6612 63.2506C17.4327 62.9554 19.2127 64.9557 19.631 67.6696C20.0492 70.3835 18.9442 72.785 17.1728 73.077C15.6805 73.323 13.8652 71.3195 13.448 68.6056C13.0297 65.8917 14.169 63.4988 15.6612 63.2506Z" fill="url(#paint4_radial_5517_68493)" />
        <path d="M15.2333 63.4649C17.0047 63.1697 18.7847 65.1701 19.203 67.8839C19.6212 70.5978 18.5162 72.9993 16.7448 73.2913C15.2525 73.5374 13.4372 71.5338 13.02 68.8199C12.6018 66.1061 13.741 63.7131 15.2333 63.4649Z" fill="url(#paint5_radial_5517_68493)" />
        <path d="M15.9202 66.6428C16.5717 66.5347 17.2157 67.2065 17.3579 68.1264C17.4991 69.0464 17.0841 69.8669 16.4326 69.9738C15.8839 70.0637 15.2271 69.3908 15.0859 68.4709C14.9436 67.5509 15.3726 66.7337 15.9202 66.6428Z" fill="#005C88" />
        <path d="M15.8196 66.7318C16.4079 66.6334 16.9995 67.2988 17.1385 68.2005C17.2776 69.1023 16.9107 69.9003 16.3213 69.9977C15.8249 70.08 15.2227 69.4136 15.0836 68.5118C14.9456 67.6101 15.3243 66.8153 15.8196 66.7318Z" fill="#00FFFF" />
        <mask id="mask0_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="9" y="54" width="19" height="28">
          <path d="M15.7821 54.6598C20.5969 53.9196 26.2771 57.3341 27.6838 66.4823C29.099 75.6829 24.6993 80.594 19.8845 81.3342C15.7457 81.9707 10.6047 76.6756 9.47187 69.3096C8.33904 61.9435 11.2915 55.3498 15.7821 54.6598Z" fill="white" />
        </mask>
        <g mask="url(#mask0_5517_68493)">
          <path d="M15.6174 54.6847C20.5135 53.9316 26.2771 57.3344 27.6838 66.4826C29.099 75.6832 24.6148 80.6071 19.7187 81.3602C15.5104 82.0073 10.2988 76.723 9.16597 69.3569C8.03314 61.9908 11.0508 55.3875 15.6174 54.6847Z" stroke="url(#paint6_linear_5517_68493)" strokeWidth="1.3179" strokeMiterlimit="10" />
        </g>
        <path d="M7.19541 61.3875C7.19541 61.3875 12.2937 59.7637 14.6438 59.7829C16.0045 59.7936 15.6718 58.7945 19.1794 56.5599C20.5134 55.7094 22.1725 55.0879 22.1671 54.7349C22.1564 53.8963 20.9616 52.2532 19.4864 52.1366C18.0113 52.02 10.5019 54.6354 10.5019 54.6354C10.5019 54.6354 9.41079 55.3425 8.524 56.5224C7.16653 58.3281 5.9492 60.9339 7.19541 61.3875Z" fill="url(#paint7_linear_5517_68493)" />
        <mask id="mask1_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="6" y="52" width="17" height="10">
          <path d="M7.19541 61.3875C7.19541 61.3875 12.2937 59.7637 14.6438 59.7829C16.0045 59.7936 15.6718 58.7945 19.1794 56.5599C20.5134 55.7094 22.1725 55.0879 22.1671 54.7349C22.1564 53.8963 20.9616 52.2532 19.4864 52.1366C18.0113 52.02 10.5019 54.6354 10.5019 54.6354C10.5019 54.6354 9.41079 55.3425 8.524 56.5224C7.16653 58.3281 5.9492 60.9339 7.19541 61.3875Z" fill="white" />
        </mask>
        <g mask="url(#mask1_5517_68493)">
          <path d="M20.9007 51.9621C20.9007 51.9621 18.0392 51.8081 15.6248 56.0709C14.7455 57.6241 14.4375 59.1656 14.8814 59.5571C15.3253 59.9486 16.333 61.9394 20.6172 58.7323C24.9014 55.5243 25.3122 52.7302 25.3122 52.7302L20.9007 51.9621Z" fill="url(#paint8_linear_5517_68493)" />
        </g>
        <mask id="mask2_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="6" y="52" width="17" height="10">
          <path d="M7.19541 61.3875C7.19541 61.3875 12.2937 59.7637 14.6438 59.7829C16.0045 59.7936 15.6718 58.7945 19.1794 56.5599C20.5134 55.7094 22.1725 55.0879 22.1671 54.7349C22.1564 53.8963 20.9616 52.2532 19.4864 52.1366C18.0113 52.02 10.5019 54.6354 10.5019 54.6354C10.5019 54.6354 9.41079 55.3425 8.524 56.5224C7.16653 58.3281 5.9492 60.9339 7.19541 61.3875Z" fill="white" />
        </mask>
        <g mask="url(#mask2_5517_68493)">
          <path d="M21.4034 52.2136C21.4034 52.2136 18.5419 52.0596 16.1275 56.3224C15.2482 57.8756 14.9401 59.417 15.3841 59.8086C15.828 60.2001 16.8357 62.1908 21.1199 58.9838C25.4041 55.7757 25.8149 52.9816 25.8149 52.9816L21.4034 52.2136Z" fill="url(#paint9_linear_5517_68493)" />
        </g>
        <path d="M8.4032 75.9873C8.4032 75.9873 13.6694 76.9287 15.9971 76.6024C17.3449 76.4131 17.146 77.4475 20.9157 79.2039C22.3501 79.8725 24.0756 80.2715 24.1173 80.6224C24.2157 81.4546 23.2466 83.241 21.7992 83.5491C20.3519 83.8572 12.5644 82.2494 12.5644 82.2494C12.5644 82.2494 11.3898 81.6921 10.3565 80.6374C8.77546 79.0242 7.22759 76.6003 8.4032 75.9873Z" fill="url(#paint10_linear_5517_68493)" />
        <mask id="mask3_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="7" y="75" width="18" height="9">
          <path d="M8.4032 75.9873C8.4032 75.9873 13.6694 76.9287 15.9971 76.6024C17.3449 76.4131 17.146 77.4475 20.9157 79.2039C22.3501 79.8725 24.0756 80.2715 24.1173 80.6224C24.2157 81.4546 23.2466 83.241 21.7992 83.5491C20.3519 83.8572 12.5644 82.2494 12.5644 82.2494C12.5644 82.2494 11.3898 81.6921 10.3565 80.6374C8.77546 79.0242 7.22759 76.6003 8.4032 75.9873Z" fill="white" />
        </mask>
        <g mask="url(#mask3_5517_68493)">
          <path d="M23.2254 83.5354C23.2254 83.5354 20.4088 84.0628 17.4564 80.153C16.3814 78.7281 15.8732 77.2402 16.2626 76.7941C16.652 76.348 17.389 74.2418 22.0573 76.8604C26.7255 79.4791 27.4978 82.1951 27.4978 82.1951L23.2254 83.5354Z" fill="url(#paint11_linear_5517_68493)" />
        </g>
        <mask id="mask4_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="7" y="75" width="18" height="9">
          <path d="M8.4032 75.9873C8.4032 75.9873 13.6694 76.9287 15.9971 76.6024C17.3449 76.4131 17.146 77.4475 20.9157 79.2039C22.3501 79.8725 24.0756 80.2715 24.1173 80.6224C24.2157 81.4546 23.2466 83.241 21.7992 83.5491C20.3519 83.8572 12.5644 82.2494 12.5644 82.2494C12.5644 82.2494 11.3898 81.6921 10.3565 80.6374C8.77546 79.0242 7.22759 76.6003 8.4032 75.9873Z" fill="white" />
        </mask>
        <g mask="url(#mask4_5517_68493)">
          <path d="M23.7269 83.2005C23.7269 83.2005 20.9103 83.7278 17.9579 79.818C16.8828 78.3932 16.3747 76.9052 16.7641 76.4591C17.1535 76.0131 17.8905 73.9068 22.5587 76.5255C27.227 79.1441 27.9993 81.8601 27.9993 81.8601L23.7269 83.2005Z" fill="url(#paint12_linear_5517_68493)" />
        </g>
        <path d="M72.0888 60.2064C68.4475 48.2769 61.6559 43.736 60.0332 42.7519C58.3088 41.7046 55.8602 41.2928 53.8128 41.1441L53.8117 41.1195C53.8117 41.1195 53.0469 41.0607 51.899 41.0692C50.7523 41.0607 49.9864 41.1195 49.9864 41.1195L49.9853 41.1441C47.9368 41.2928 45.4882 41.7057 43.7649 42.7519C42.1422 43.7371 35.3516 48.2769 31.7093 60.2064C28.3685 71.1549 30.8278 84.6355 44.8956 85.4474C47.694 85.6089 50.0035 85.5854 51.899 85.4153C53.7957 85.5854 56.1052 85.6089 58.9025 85.4474C72.9714 84.6355 75.4306 71.1549 72.0888 60.2064Z" fill="url(#paint13_radial_5517_68493)" />
        <mask id="mask5_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="30" y="41" width="44" height="45">
          <path d="M72.0888 60.2064C68.4475 48.2769 61.6559 43.736 60.0332 42.7519C58.3088 41.7046 55.8602 41.2928 53.8128 41.1441L53.8117 41.1195C53.8117 41.1195 53.0469 41.0607 51.899 41.0692C50.7523 41.0607 49.9864 41.1195 49.9864 41.1195L49.9853 41.1441C47.9368 41.2928 45.4882 41.7057 43.7649 42.7519C42.1422 43.7371 35.3516 48.2769 31.7093 60.2064C28.3685 71.1549 30.8278 84.6355 44.8956 85.4474C47.694 85.6089 50.0035 85.5854 51.899 85.4153C53.7957 85.5854 56.1052 85.6089 58.9025 85.4474C72.9714 84.6355 75.4306 71.1549 72.0888 60.2064Z" fill="white" />
        </mask>
        <g mask="url(#mask5_5517_68493)">
          <path d="M57.0799 64.4588C62.2552 74.2071 60.0815 85.4904 52.2266 89.6602C44.3717 93.8299 33.8083 89.3072 28.633 79.5589C23.4577 69.8106 25.6314 58.5272 33.4863 54.3575C41.3412 50.1877 51.9046 54.7105 57.0799 64.4588Z" fill="url(#paint14_linear_5517_68493)" />
        </g>
        <mask id="mask6_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="30" y="41" width="44" height="45">
          <path d="M72.0888 60.2064C68.4475 48.2769 61.6559 43.736 60.0332 42.7519C58.3088 41.7046 55.8602 41.2928 53.8128 41.1441L53.8117 41.1195C53.8117 41.1195 53.0469 41.0607 51.899 41.0692C50.7523 41.0607 49.9864 41.1195 49.9864 41.1195L49.9853 41.1441C47.9368 41.2928 45.4882 41.7057 43.7649 42.7519C42.1422 43.7371 35.3516 48.2769 31.7093 60.2064C28.3685 71.1549 30.8278 84.6355 44.8956 85.4474C47.694 85.6089 50.0035 85.5854 51.899 85.4153C53.7957 85.5854 56.1052 85.6089 58.9025 85.4474C72.9714 84.6355 75.4306 71.1549 72.0888 60.2064Z" fill="white" />
        </mask>
        <g mask="url(#mask6_5517_68493)">
          <path d="M47.1989 63.4271C45.5248 52.5181 51.2948 42.5805 60.0858 41.2316C68.8767 39.8827 77.3595 47.6338 79.0325 58.5428C80.7066 69.4517 74.9366 79.3894 66.1468 80.7383C57.3558 82.0872 48.873 74.3371 47.1989 63.4271Z" fill="url(#paint15_linear_5517_68493)" />
        </g>
        <mask id="mask7_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="30" y="41" width="44" height="45">
          <path d="M72.0888 60.2064C68.4475 48.2769 61.6559 43.736 60.0332 42.7519C58.3088 41.7046 55.8602 41.2928 53.8128 41.1441L53.8117 41.1195C53.8117 41.1195 53.0469 41.0607 51.899 41.0692C50.7523 41.0607 49.9864 41.1195 49.9864 41.1195L49.9853 41.1441C47.9368 41.2928 45.4882 41.7057 43.7649 42.7519C42.1422 43.7371 35.3516 48.2769 31.7093 60.2064C28.3685 71.1549 30.8278 84.6355 44.8956 85.4474C47.694 85.6089 50.0035 85.5854 51.899 85.4153C53.7957 85.5854 56.1052 85.6089 58.9025 85.4474C72.9714 84.6355 75.4306 71.1549 72.0888 60.2064Z" fill="white" />
        </mask>
        <g mask="url(#mask7_5517_68493)">
          <path d="M54.7597 53.1744C60.5624 53.1744 65.2664 50.5508 65.2664 47.3145C65.2664 44.0782 60.5624 41.4546 54.7597 41.4546C48.957 41.4546 44.2529 44.0782 44.2529 47.3145C44.2529 50.5508 48.957 53.1744 54.7597 53.1744Z" fill="url(#paint16_radial_5517_68493)" />
        </g>
        <mask id="mask8_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="30" y="41" width="44" height="45">
          <path d="M72.0888 60.2064C68.4475 48.2769 61.6559 43.736 60.0332 42.7519C58.3088 41.7046 55.8602 41.2928 53.8128 41.1441L53.8117 41.1195C53.8117 41.1195 53.0469 41.0607 51.899 41.0692C50.7523 41.0607 49.9864 41.1195 49.9864 41.1195L49.9853 41.1441C47.9368 41.2928 45.4882 41.7057 43.7649 42.7519C42.1422 43.7371 35.3516 48.2769 31.7093 60.2064C28.3685 71.1549 30.8278 84.6355 44.8956 85.4474C47.694 85.6089 50.0035 85.5854 51.899 85.4153C53.7957 85.5854 56.1052 85.6089 58.9025 85.4474C72.9714 84.6355 75.4306 71.1549 72.0888 60.2064Z" fill="white" />
        </mask>
        <g mask="url(#mask8_5517_68493)">
          <path d="M50.0848 43.7085C53.2751 43.7085 55.8613 42.2186 55.8613 40.3806C55.8613 38.5427 53.2751 37.0527 50.0848 37.0527C46.8946 37.0527 44.3083 38.5427 44.3083 40.3806C44.3083 42.2186 46.8946 43.7085 50.0848 43.7085Z" fill="url(#paint17_linear_5517_68493)" />
        </g>
        <path d="M35.6297 75.1641C33.4411 72.7969 31.0856 67.9254 34.6253 58.7932C37.9681 50.1681 41.2639 47.8672 41.909 47.4821C44.1275 46.1588 58.4007 45.6464 60.7456 49.4503C61.9768 51.4486 72.214 70.6168 62.8379 77.1485C55.9383 81.9547 39.1459 78.9681 35.6297 75.1641Z" fill="#14003C" />
        <path d="M56.3917 39.1525C56.3917 41.7188 53.9634 43.38 50.5318 43.4635C48.2436 43.5191 48.1035 41.7177 48.1035 39.1525C48.1035 36.5874 48.1688 34.3837 50.4473 34.1709C54.0469 33.836 56.3917 36.5863 56.3917 39.1525Z" fill="url(#paint18_radial_5517_68493)" />
        <path d="M50.2388 43.5469C52.5275 43.5469 54.3829 41.4669 54.3829 38.9011C54.3829 36.3354 52.5275 34.2554 50.2388 34.2554C47.9501 34.2554 46.0947 36.3354 46.0947 38.9011C46.0947 41.4669 47.9501 43.5469 50.2388 43.5469Z" fill="url(#paint19_linear_5517_68493)" />
        <path d="M50.0538 42.627C51.8935 42.627 53.3848 40.9349 53.3848 38.8477C53.3848 36.7604 51.8935 35.0684 50.0538 35.0684C48.214 35.0684 46.7227 36.7604 46.7227 38.8477C46.7227 40.9349 48.214 42.627 50.0538 42.627Z" fill="#5B272D" />
        <path d="M49.9455 42.6264C51.7273 42.6264 53.1718 40.9583 53.1718 38.9006C53.1718 36.8429 51.7273 35.1748 49.9455 35.1748C48.1637 35.1748 46.7192 36.8429 46.7192 38.9006C46.7192 40.9583 48.1637 42.6264 49.9455 42.6264Z" fill="url(#paint20_radial_5517_68493)" />
        <path d="M49.6033 39.8125C50.0464 39.8125 50.4056 39.3795 50.4056 38.8454C50.4056 38.3114 50.0464 37.8784 49.6033 37.8784C49.1602 37.8784 48.801 38.3114 48.801 38.8454C48.801 39.3795 49.1602 39.8125 49.6033 39.8125Z" fill="#00FFFF" />
        <path d="M39.9094 35.5635L49.7497 37.9382L49.8054 39.6958L39.9094 42.7295V35.5635Z" fill="url(#paint21_linear_5517_68493)" />
        <mask id="mask9_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="32" y="46" width="35" height="34">
          <path d="M35.6297 75.1641C33.4411 72.7969 31.0856 67.9254 34.6253 58.7932C37.9681 50.1681 41.2639 47.8672 41.909 47.4821C44.1275 46.1588 58.4007 45.6464 60.7456 49.4503C61.9768 51.4486 72.214 70.6168 62.8379 77.1485C55.9383 81.9547 39.1459 78.9681 35.6297 75.1641Z" fill="white" />
        </mask>
        <g mask="url(#mask9_5517_68493)">
          <path d="M44.1234 71.7295C55.0324 74.5567 63.2178 79.3886 62.4059 82.5218C61.594 85.6561 52.0917 85.9043 41.1828 83.0781C30.2738 80.2508 22.0884 75.4189 22.9003 72.2857C23.7122 69.1525 33.2134 68.9022 44.1234 71.7295Z" fill="url(#paint22_radial_5517_68493)" />
        </g>
        <mask id="mask10_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="32" y="46" width="35" height="34">
          <path d="M35.6297 75.1641C33.4411 72.7969 31.0856 67.9254 34.6253 58.7932C37.9681 50.1681 41.2639 47.8672 41.909 47.4821C44.1275 46.1588 58.4007 45.6464 60.7456 49.4503C61.9768 51.4486 72.214 70.6168 62.8379 77.1485C55.9383 81.9547 39.1459 78.9681 35.6297 75.1641Z" fill="white" />
        </mask>
        <g mask="url(#mask10_5517_68493)">
          <path d="M37.8334 65.3745C38.4656 79.7717 36.3562 91.5579 33.1224 91.7001C29.8887 91.8424 26.7544 80.2863 26.1222 65.889C25.49 51.4917 27.5995 39.7056 30.8332 39.5633C34.067 39.421 37.2012 50.9772 37.8334 65.3745Z" fill="url(#paint23_radial_5517_68493)" />
        </g>
        <mask id="mask11_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="32" y="46" width="35" height="34">
          <path d="M35.6297 75.1641C33.4411 72.7969 31.0856 67.9254 34.6253 58.7932C37.9681 50.1681 41.2639 47.8672 41.909 47.4821C44.1275 46.1588 58.4007 45.6464 60.7456 49.4503C61.9768 51.4486 72.214 70.6168 62.8379 77.1485C55.9383 81.9547 39.1459 78.9681 35.6297 75.1641Z" fill="white" />
        </mask>
        <g mask="url(#mask11_5517_68493)">
          <path d="M52.3099 69.1225C58.3087 69.1225 63.1718 64.1469 63.1718 58.0093C63.1718 51.8716 58.3087 46.896 52.3099 46.896C46.311 46.896 41.448 51.8716 41.448 58.0093C41.448 64.1469 46.311 69.1225 52.3099 69.1225Z" fill="url(#paint24_radial_5517_68493)" />
        </g>
        <mask id="mask12_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="32" y="46" width="35" height="34">
          <path d="M35.6297 75.1641C33.4411 72.7969 31.0856 67.9254 34.6253 58.7932C37.9681 50.1681 41.2639 47.8672 41.909 47.4821C44.1275 46.1588 58.4007 45.6464 60.7456 49.4503C61.9768 51.4486 72.214 70.6168 62.8379 77.1485C55.9383 81.9547 39.1459 78.9681 35.6297 75.1641Z" fill="white" />
        </mask>
        <g mask="url(#mask12_5517_68493)">
          <path d="M49.3596 48.6115C49.3596 48.6115 48.7295 55.2427 48.8151 60.1644C48.9264 66.5271 50.8936 67.1411 50.9503 67.3636C51.0059 67.5872 64.387 67.5733 64.387 67.5733C64.387 67.5733 65.2663 68.6644 63.759 61.5037C62.7546 56.7317 61.1297 53.6338 59.9498 51.458C59.5026 50.6344 58.2553 49.754 57.3493 49.5165C55.5992 49.0597 52.6757 48.2264 49.3596 48.6115Z" fill="url(#paint25_linear_5517_68493)" />
        </g>
        <path d="M58.8188 47.9843C57.4015 47.3874 55.8782 47.0708 54.3592 46.8633C51.8165 46.5167 49.2417 46.6504 46.6872 46.7541C45.3394 46.8429 43.9562 46.8772 42.6597 47.2815C42.0072 47.4816 41.4969 47.9116 41.0134 48.378C40.5235 48.8454 40.0614 49.345 39.6292 49.8692C37.0127 53.0163 35.2273 56.7827 34.0581 60.6904C33.2837 63.2834 32.7285 66.0047 32.9403 68.7186C33.1189 71.4175 34.3288 74.0543 36.4629 75.7541C38.6248 77.4111 41.3675 78.1353 43.9926 78.7151C46.6669 79.2756 49.3936 79.6029 52.1213 79.7538C49.385 79.6714 46.6519 79.3815 43.9605 78.8691C39.8731 78.0337 35.6638 76.7917 33.7116 72.7375C32.1637 69.5861 32.4525 65.9138 33.2174 62.5902C34.3438 57.9284 36.327 53.3949 39.4163 49.6916C39.8571 49.1631 40.3245 48.655 40.8294 48.1833C41.3311 47.7051 41.9056 47.2451 42.5806 47.0494C43.907 46.6493 45.3062 46.6301 46.6765 46.553C49.2406 46.4717 51.8261 46.3733 54.371 46.7713C55.891 47.0034 57.4143 47.35 58.8188 47.9843Z" fill="#5CB9F2" />
        <path d="M53.725 53.6527C53.725 53.6527 55.6975 53.0033 56.7362 58.9563C57.7749 64.9093 56.4656 65.4463 55.7532 65.4901C53.6148 65.6228 53.3559 62.1933 53.0404 60.4453C52.3804 56.7912 51.9749 53.7607 53.725 53.6527Z" fill="#00FFFF" />
        <path d="M34.7298 66.002C34.7298 66.002 34.1093 70.7879 36.0477 73.0984C37.986 75.409 40.4913 76.2274 40.4913 76.2274C40.4913 76.2274 39.4001 71.2789 39.3734 69.7716C39.3734 69.7727 34.9672 66.7796 34.7298 66.002Z" fill="url(#paint26_linear_5517_68493)" />
        <path d="M42.2993 52.8962C42.2993 52.8962 40.5696 52.003 38.7276 57.3612C36.8855 62.7194 38.0183 63.4093 38.6719 63.5559C40.6327 63.9955 41.3794 60.8581 41.9303 59.2866C43.0813 56.0005 43.4247 54.6034 43.353 54.1595C43.2899 53.768 43.1359 53.1743 42.2993 52.8962Z" fill="#00FFFF" />
        <path d="M59.3623 83.2773L66.3058 95.8861C66.3058 95.8861 82.2435 83.5073 83.7667 79.2103C84.9177 75.9626 83.7443 73.6766 83.6544 73.4124C83.4619 73.0755 82.7216 70.199 79.3296 68.9966C75.0325 67.4734 55.5273 69.5508 55.5273 69.5508L59.3623 83.2773Z" fill="url(#paint27_linear_5517_68493)" />
        <mask id="mask13_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="55" y="68" width="30" height="28">
          <path d="M59.3623 83.2772L66.3058 95.886C66.3058 95.886 82.3269 83.2976 83.8502 79.0016C85.0012 75.7539 83.7443 73.6776 83.6544 73.4134C83.4619 73.0764 82.7216 70.2 79.3296 68.9976C75.0325 67.4743 55.5273 69.5517 55.5273 69.5517L59.3623 83.2772Z" fill="white" />
        </mask>
        <g mask="url(#mask13_5517_68493)">
          <path d="M66.217 74.7542C75.3898 67.8674 85.8313 66.2864 89.5378 71.2231C93.2444 76.1598 88.8126 85.7456 79.6398 92.6324C70.4669 99.5192 60.0255 101.1 56.3189 96.1635C52.6123 91.2268 57.0442 81.6411 66.217 74.7542Z" fill="url(#paint28_linear_5517_68493)" />
        </g>
        <path d="M62.2944 71.0581C62.2944 71.0581 78.6546 69.1422 79.7404 69.4963C80.8262 69.8514 81.6466 70.9886 81.9783 71.9459C82.3099 72.9033 69.0828 79.2221 69.0828 79.2221C69.0828 79.2221 66.3048 72.6273 62.2944 71.0581Z" fill="url(#paint29_linear_5517_68493)" />
        <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="url(#paint30_linear_5517_68493)" />
        <mask id="mask14_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask14_5517_68493)">
          <path d="M61.6866 76.2043C71.5761 82.0171 76.2251 92.4576 72.0714 99.5252C67.9177 106.593 56.5338 107.61 46.6443 101.797C36.7548 95.9844 32.1058 85.544 36.2595 78.4764C40.4132 71.4088 51.7982 70.3915 61.6866 76.2043Z" fill="url(#paint31_linear_5517_68493)" />
        </g>
        <mask id="mask15_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask15_5517_68493)">
          <path d="M52.9234 70.2421C58.1029 68.1219 65.2379 70.103 68.9894 79.2705C72.7623 88.4903 69.0418 94.853 63.8623 96.9721C59.4101 98.7938 52.4249 94.6979 49.404 87.3169C46.3842 79.9358 48.0925 72.2189 52.9234 70.2421Z" stroke="url(#paint32_linear_5517_68493)" strokeWidth="1.41591" strokeMiterlimit="10" />
        </g>
        <mask id="mask16_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask16_5517_68493)">
          <path d="M53.4753 71.2349L62.5027 96.233" stroke="url(#paint33_linear_5517_68493)" strokeWidth="1.74266" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <mask id="mask17_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask17_5517_68493)">
          <path d="M49.5742 76.0078L67.5593 92.9906" stroke="url(#paint34_linear_5517_68493)" strokeWidth="1.67959" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <mask id="mask18_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask18_5517_68493)">
          <path d="M59.3301 70.8442L57.4795 95.1995" stroke="url(#paint35_linear_5517_68493)" strokeWidth="1.74266" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <mask id="mask19_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask19_5517_68493)">
          <path d="M68.1092 79.6577L49.8235 87.4313" stroke="url(#paint36_linear_5517_68493)" strokeWidth="1.74266" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <mask id="mask20_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask20_5517_68493)">
          <path d="M64.7033 74.0225L53.2766 91.3829" stroke="url(#paint37_linear_5517_68493)" strokeWidth="1.78402" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <mask id="mask21_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="48" y="69" width="23" height="29">
          <path d="M53.1665 70.1427C58.3032 68.0407 65.4104 70.0828 69.2004 79.3433C73.0118 88.6573 69.3545 95.0424 64.2177 97.1455C59.8019 98.9522 52.8306 94.7803 49.7787 87.3234C46.7268 79.8664 48.3752 72.1035 53.1665 70.1427Z" fill="white" />
        </mask>
        <g mask="url(#mask21_5517_68493)">
          <path d="M69.5576 86.0197L49.0918 82.4009" stroke="url(#paint38_linear_5517_68493)" strokeWidth="1.78402" strokeMiterlimit="10" strokeLinecap="round" />
        </g>
        <path d="M53.8856 74.689C55.0698 74.3884 55.5618 73.8514 58.1987 74.7168C58.1987 74.7168 58.4843 74.6334 58.9732 74.8634C59.7198 75.2057 59.8728 75.7288 59.8728 75.7288C59.8728 75.7288 60.9297 76.5492 61.5565 77.1611C61.5875 77.1932 61.8111 77.0049 62.3267 77.5419C62.7589 78.0372 62.7589 78.602 62.7824 78.5603C63.2798 79.2225 63.5462 79.6867 63.9356 80.4805C64.2575 80.5596 64.4394 80.8356 64.5613 81.1351C64.771 81.7737 64.5924 82.0144 64.6031 82.084C64.9026 82.944 65.0063 83.5067 65.1262 84.3518C65.3369 84.447 65.4332 84.7529 65.4963 84.9936C65.6118 85.7467 65.2909 86.1307 65.2449 86.2355C65.2385 87.3116 65.0994 88.3514 64.8266 89.2981C64.9935 89.4447 64.9539 89.8169 64.8191 90.079C64.4907 90.7133 64.2469 90.7839 64.1666 90.9391C63.574 92.0794 62.6968 92.8924 61.5619 93.3587C58.2971 94.698 54.1552 91.5167 52.0393 86.3478C49.9223 81.1779 50.0346 75.6667 53.8856 74.689Z" fill="url(#paint39_linear_5517_68493)" />
        <path d="M53.8148 74.6748C57.3609 73.2231 61.9414 76.2173 64.0466 81.3615C66.1518 86.5058 64.9837 91.8522 61.4387 93.3028C57.8926 94.7544 53.7945 91.5313 51.6893 86.3881C49.5841 81.2439 50.2698 76.1264 53.8148 74.6748Z" fill="url(#paint40_radial_5517_68493)" />
        <path d="M53.9496 79.1715C55.9714 78.3436 58.6104 80.1139 59.8427 83.1252C61.075 86.1364 60.4353 89.2482 58.4125 90.0762C56.3897 90.9041 54.0267 89.0022 52.7943 85.9909C51.562 82.9808 51.9279 79.9995 53.9496 79.1715Z" fill="#31339A" />
        <path d="M71.0756 71.0675C71.7035 71.5992 73.0845 73.0134 74.7105 76.184C74.917 76.5862 75.0892 77.0066 75.224 77.4377C76.2873 80.8383 76.2937 82.8151 76.2188 83.6346C76.2038 83.795 76.3889 83.8934 76.514 83.7918C77.572 82.9339 80.6282 80.7089 80.8293 79.8093C81.0967 78.6122 80.672 76.1219 80.1126 74.7046C79.5948 73.3931 78.1796 71.2184 77.1495 70.553C76.3739 70.0524 72.5315 70.6172 71.1762 70.7477C71.0168 70.7637 70.9537 70.9638 71.0756 71.0675Z" fill="#14003C" />
        <path d="M58.7588 89.6536C58.6369 89.2321 58.9343 88.4961 59.528 87.8404C59.6638 87.6907 59.8061 87.5559 59.9484 87.4371C60.2072 86.1856 60.0671 84.6805 59.4713 83.2267C58.2518 80.2476 55.6481 78.796 53.4563 79.3897C53.1129 79.4827 52.8326 79.748 52.3416 80.1748C52.347 80.6102 51.9726 81.2478 51.35 81.7858C50.8675 82.203 50.3466 82.4651 49.9358 82.5293L47.8103 84.6302C47.7857 85.0634 47.4166 85.671 46.8197 86.1866C46.3737 86.5728 45.8955 86.8253 45.5019 86.913L42.8158 89.5691C42.6885 89.691 42.7719 89.906 42.9485 89.9103L52.8733 90.1692C53.2049 90.0194 53.6392 89.9167 54.1195 89.8932C54.8255 89.8579 55.4491 90.0023 55.8 90.2462L57.5361 90.2911L57.5233 90.258C57.6485 90.243 57.7661 90.2173 57.8731 90.1788C58.2026 90.059 58.4989 89.8804 58.7588 89.6536Z" fill="url(#paint41_linear_5517_68493)" />
        <g opacity="0.63">
          <path d="M74.0902 72.2529C74.6069 72.6252 75.3557 73.3247 76.0895 74.5785L78.3937 73.8072C77.969 72.4583 77.3464 71.6421 77.1999 71.5811C76.9881 71.4923 74.9973 71.7619 74.1576 71.8753C74.0089 71.8956 73.9415 72.0689 74.0346 72.1855C74.0549 72.209 74.072 72.2304 74.0902 72.2529Z" fill="#00FFFF" />
        </g>
        <path d="M71.4609 70.7322C72.3681 70.6135 73.2784 70.513 74.1908 70.4381C75.1044 70.3846 76.0308 70.2798 76.9411 70.4306C77.1764 70.4755 77.3593 70.6435 77.5455 70.7815C78.0868 71.2136 78.536 71.7495 78.9265 72.3165C80.4476 74.5511 81.0434 76.9794 80.9033 79.6633C80.9279 80.3586 79.2592 81.5909 78.7222 82.0498C78.0055 82.6189 77.2684 83.1623 76.5442 83.7218C77.2566 83.1473 77.9808 82.5879 78.6858 82.006C79.1928 81.5631 80.8284 80.3169 80.8081 79.6622C80.9397 77.0061 80.3332 74.5886 78.842 72.3732C78.4579 71.8105 78.0172 71.2821 77.4856 70.8531C77.3101 70.7205 77.14 70.5579 76.9197 70.513C76.0243 70.3546 75.0969 70.4498 74.193 70.4916C73.2816 70.5493 72.3702 70.6317 71.4609 70.7322Z" fill="#14A1BF" />
        <path d="M53.725 53.6527C53.725 53.6527 55.6975 53.0033 56.7362 58.9563C57.7749 64.9093 56.4656 65.4463 55.7532 65.4901C53.6148 65.6228 53.3559 62.1933 53.0404 60.4453C52.3804 56.7912 51.9749 53.7607 53.725 53.6527Z" fill="#00FFFF" />
        <g opacity="0.63">
          <path d="M79.0203 75.7889C78.8983 75.3214 78.7582 74.8678 78.6116 74.4399L76.438 75.2198C76.5589 75.4583 76.6776 75.7118 76.7942 75.9857C77.0659 76.6232 77.2852 77.2191 77.4628 77.7668L79.2438 76.7762C79.1765 76.4382 79.1016 76.1023 79.0203 75.7889Z" fill="#00FFFF" />
        </g>
        <g opacity="0.63">
          <path d="M79.3702 77.4536L77.6704 78.4463C77.8747 79.1598 78.0031 79.7663 78.0844 80.2391C78.125 80.2819 78.1914 80.2937 78.2459 80.2584C78.5369 80.0701 79.1616 79.6465 79.6301 79.2015C79.5841 78.8314 79.4964 78.1746 79.3702 77.4536Z" fill="#00FFFF" />
        </g>
        <path d="M42.2993 52.8962C42.2993 52.8962 40.5696 52.003 38.7276 57.3612C36.8855 62.7194 38.0183 63.4093 38.6719 63.5559C40.6327 63.9955 41.3794 60.8581 41.9303 59.2866C43.0813 56.0005 43.4247 54.6034 43.353 54.1595C43.2899 53.768 43.1359 53.1743 42.2993 52.8962Z" fill="#00FFFF" />
        <path d="M46.052 46.5612C49.9736 46.2553 54.9435 46.1248 58.6436 47.5785C59.4587 47.9273 60.292 48.4311 60.7873 49.2109C60.9713 49.5243 61.3746 50.2828 61.5564 50.608C63.8061 54.6076 65.5058 58.9389 66.2921 63.4713C66.4761 64.5121 66.6248 65.5604 66.7307 66.613C66.1252 62.439 64.9827 58.3388 63.1942 54.5146C62.3951 52.7752 61.4409 51.0594 60.5199 49.3799C58.4778 46.2703 49.5403 46.4275 46.052 46.5612Z" fill="#FFFFDA" />
        <path d="M74.0902 72.2529C74.6069 72.6252 75.3557 73.3247 76.0895 74.5785L78.3937 73.8072C77.969 72.4583 77.3464 71.6421 77.1999 71.5811C76.9881 71.4923 74.9973 71.7619 74.1576 71.8753C74.0089 71.8956 73.9415 72.0689 74.0346 72.1855C74.0549 72.209 74.072 72.2304 74.0902 72.2529Z" fill="#00FFFF" />
        <path d="M79.0203 75.7889C78.8983 75.3214 78.7582 74.8678 78.6116 74.4399L76.438 75.2198C76.5589 75.4583 76.6776 75.7118 76.7942 75.9857C77.0659 76.6232 77.2852 77.2191 77.4628 77.7668L79.2438 76.7762C79.1765 76.4382 79.1016 76.1023 79.0203 75.7889Z" fill="#00FFFF" />
        <path d="M79.3702 77.4536L77.6704 78.4463C77.8747 79.1598 78.0031 79.7663 78.0844 80.2391C78.125 80.2819 78.1914 80.2937 78.2459 80.2584C78.5369 80.0701 79.1616 79.6465 79.6301 79.2015C79.5841 78.8314 79.4964 78.1746 79.3702 77.4536Z" fill="#00FFFF" />
        <mask id="mask22_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="42" y="79" width="19" height="12">
          <path d="M58.7588 89.6536C58.6369 89.2321 58.9343 88.4961 59.528 87.8404C59.6638 87.6907 59.8061 87.5559 59.9484 87.4371C60.2072 86.1856 60.0671 84.6805 59.4713 83.2267C58.2518 80.2476 55.6481 78.796 53.4563 79.3897C53.1129 79.4827 52.8326 79.748 52.3416 80.1748C52.347 80.6102 51.9726 81.2478 51.35 81.7858C50.8675 82.203 50.3466 82.4651 49.9358 82.5293L47.8103 84.6302C47.7857 85.0634 47.4166 85.671 46.8197 86.1866C46.3737 86.5728 45.8955 86.8253 45.5019 86.913L42.8158 89.5691C42.6885 89.691 42.7719 89.906 42.9485 89.9103L52.8733 90.1692C53.2049 90.0194 53.6392 89.9167 54.1195 89.8932C54.8255 89.8579 55.4491 90.0023 55.8 90.2462L57.5361 90.2911L57.5233 90.258C57.6485 90.243 57.7661 90.2173 57.8731 90.1788C58.2026 90.059 58.4989 89.8804 58.7588 89.6536Z" fill="white" />
        </mask>
        <g mask="url(#mask22_5517_68493)">
          <path d="M47.543 82.8974C55.8076 78.2442 63.1415 75.5998 63.9256 76.9926C64.7097 78.3854 58.6455 83.2847 50.3809 87.9379C42.1163 92.5912 34.7823 95.2355 33.9982 93.8428C33.2152 92.4511 39.2794 87.5507 47.543 82.8974Z" fill="url(#paint42_radial_5517_68493)" />
        </g>
        <mask id="mask23_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="42" y="79" width="19" height="12">
          <path d="M58.7588 89.6536C58.6369 89.2321 58.9343 88.4961 59.528 87.8404C59.6638 87.6907 59.8061 87.5559 59.9484 87.4371C60.2072 86.1856 60.0671 84.6805 59.4713 83.2267C58.2518 80.2476 55.6481 78.796 53.4563 79.3897C53.1129 79.4827 52.8326 79.748 52.3416 80.1748C52.347 80.6102 51.9726 81.2478 51.35 81.7858C50.8675 82.203 50.3466 82.4651 49.9358 82.5293L47.8103 84.6302C47.7857 85.0634 47.4166 85.671 46.8197 86.1866C46.3737 86.5728 45.8955 86.8253 45.5019 86.913L42.8158 89.5691C42.6885 89.691 42.7719 89.906 42.9485 89.9103L52.8733 90.1692C53.2049 90.0194 53.6392 89.9167 54.1195 89.8932C54.8255 89.8579 55.4491 90.0023 55.8 90.2462L57.5361 90.2911L57.5233 90.258C57.6485 90.243 57.7661 90.2173 57.8731 90.1788C58.2026 90.059 58.4989 89.8804 58.7588 89.6536Z" fill="white" />
        </mask>
        <g mask="url(#mask23_5517_68493)">
          <path d="M50.4313 88.4406C59.9154 88.3475 67.6109 89.0803 67.6205 90.0783C67.6302 91.0764 59.9507 91.961 50.4666 92.0541C40.9825 92.1472 33.287 91.4144 33.2774 90.4164C33.2677 89.4183 40.9483 88.5337 50.4313 88.4406Z" fill="url(#paint43_radial_5517_68493)" />
        </g>
        <mask id="mask24_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="42" y="79" width="19" height="12">
          <path d="M58.7588 89.6536C58.6369 89.2321 58.9343 88.4961 59.528 87.8404C59.6638 87.6907 59.8061 87.5559 59.9484 87.4371C60.2072 86.1856 60.0671 84.6805 59.4713 83.2267C58.2518 80.2476 55.6481 78.796 53.4563 79.3897C53.1129 79.4827 52.8326 79.748 52.3416 80.1748C52.347 80.6102 51.9726 81.2478 51.35 81.7858C50.8675 82.203 50.3466 82.4651 49.9358 82.5293L47.8103 84.6302C47.7857 85.0634 47.4166 85.671 46.8197 86.1866C46.3737 86.5728 45.8955 86.8253 45.5019 86.913L42.8158 89.5691C42.6885 89.691 42.7719 89.906 42.9485 89.9103L52.8733 90.1692C53.2049 90.0194 53.6392 89.9167 54.1195 89.8932C54.8255 89.8579 55.4491 90.0023 55.8 90.2462L57.5361 90.2911L57.5233 90.258C57.6485 90.243 57.7661 90.2173 57.8731 90.1788C58.2026 90.059 58.4989 89.8804 58.7588 89.6536Z" fill="white" />
        </mask>
        <g mask="url(#mask24_5517_68493)">
          <path d="M48.5229 84.8529C56.3264 79.4637 62.9469 75.5197 63.3095 76.0438C63.6722 76.568 57.639 81.3624 49.8354 86.7527C42.0318 92.142 35.4114 96.086 35.0487 95.5618C34.6861 95.0366 40.7193 90.2421 48.5229 84.8529Z" fill="url(#paint44_radial_5517_68493)" />
        </g>
        <mask id="mask25_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="42" y="79" width="19" height="12">
          <path d="M58.7588 89.6536C58.6369 89.2321 58.9343 88.4961 59.528 87.8404C59.6638 87.6907 59.8061 87.5559 59.9484 87.4371C60.2072 86.1856 60.0671 84.6805 59.4713 83.2267C58.2518 80.2476 55.6481 78.796 53.4563 79.3897C53.1129 79.4827 52.8326 79.748 52.3416 80.1748C52.347 80.6102 51.9726 81.2478 51.35 81.7858C50.8675 82.203 50.3466 82.4651 49.9358 82.5293L47.8103 84.6302C47.7857 85.0634 47.4166 85.671 46.8197 86.1866C46.3737 86.5728 45.8955 86.8253 45.5019 86.913L42.8158 89.5691C42.6885 89.691 42.7719 89.906 42.9485 89.9103L52.8733 90.1692C53.2049 90.0194 53.6392 89.9167 54.1195 89.8932C54.8255 89.8579 55.4491 90.0023 55.8 90.2462L57.5361 90.2911L57.5233 90.258C57.6485 90.243 57.7661 90.2173 57.8731 90.1788C58.2026 90.059 58.4989 89.8804 58.7588 89.6536Z" fill="white" />
        </mask>
        <g mask="url(#mask25_5517_68493)">
          <path d="M52.4498 86.136C49.5552 84.2886 47.4489 84.54 46.7001 84.893C45.9513 85.246 44.4751 86.9789 44.4751 86.9789C44.4751 86.9789 46.8991 86.5275 49.3979 87.4079C50.8346 87.9139 52.8328 90.1153 52.8478 90.555C52.8638 90.9946 55.9146 90.6395 55.9146 90.6395C55.9146 90.6395 54.8888 87.6924 52.4498 86.136Z" fill="url(#paint45_radial_5517_68493)" />
        </g>
        <mask id="mask26_5517_68493" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="42" y="79" width="19" height="12">
          <path d="M58.7588 89.6536C58.6369 89.2321 58.9343 88.4961 59.528 87.8404C59.6638 87.6907 59.8061 87.5559 59.9484 87.4371C60.2072 86.1856 60.0671 84.6805 59.4713 83.2267C58.2518 80.2476 55.6481 78.796 53.4563 79.3897C53.1129 79.4827 52.8326 79.748 52.3416 80.1748C52.347 80.6102 51.9726 81.2478 51.35 81.7858C50.8675 82.203 50.3466 82.4651 49.9358 82.5293L47.8103 84.6302C47.7857 85.0634 47.4166 85.671 46.8197 86.1866C46.3737 86.5728 45.8955 86.8253 45.5019 86.913L42.8158 89.5691C42.6885 89.691 42.7719 89.906 42.9485 89.9103L52.8733 90.1692C53.2049 90.0194 53.6392 89.9167 54.1195 89.8932C54.8255 89.8579 55.4491 90.0023 55.8 90.2462L57.5361 90.2911L57.5233 90.258C57.6485 90.243 57.7661 90.2173 57.8731 90.1788C58.2026 90.059 58.4989 89.8804 58.7588 89.6536Z" fill="white" />
        </mask>
        <g mask="url(#mask26_5517_68493)">
          <path d="M57.3319 81.802C54.4373 79.9546 52.331 80.206 51.5822 80.559C50.8334 80.912 49.3572 82.645 49.3572 82.645C49.3572 82.645 51.5886 82.093 53.9398 83.3135C55.6343 84.1928 57.9031 85.9493 58.9365 90.1233C59.0424 90.5512 61.2588 86.8725 61.2588 86.8725C61.2588 86.8725 59.7709 83.3585 57.3319 81.802Z" fill="url(#paint46_radial_5517_68493)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_5517_68493" x="-15" y="7" width="120" height="120" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_5517_68493" />
        </filter>
        <linearGradient id="paint0_linear_5517_68493" x1="29.3574" y1="56.0441" x2="28.3583" y2="78.4115" gradientUnits="userSpaceOnUse">
          <stop offset="0.1079" stopColor="#E2A847" />
          <stop offset="0.1222" stopColor="#E4AA49" />
          <stop offset="0.2312" stopColor="#EEB456" />
          <stop offset="0.3354" stopColor="#F1B75A" />
          <stop offset="0.6319" stopColor="#DA7B00" />
          <stop offset="0.9749" stopColor="#BC747D" />
        </linearGradient>
        <linearGradient id="paint1_linear_5517_68493" x1="9.22833" y1="67.9737" x2="27.9524" y2="67.9737" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#EFAF00" />
          <stop offset="0.1533" stopColor="#DE9706" />
          <stop offset="0.3546" stopColor="#C9790E" />
          <stop offset="0.5534" stopColor="#B96414" />
          <stop offset="0.7466" stopColor="#B05717" />
          <stop offset="0.928" stopColor="#AD5318" />
        </linearGradient>
        <linearGradient id="paint2_linear_5517_68493" x1="11.9311" y1="68.011" x2="23.8697" y2="68.011" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C75C07" />
          <stop offset="0.928" stopColor="#5D1A18" />
        </linearGradient>
        <linearGradient id="paint3_linear_5517_68493" x1="11.289" y1="68.1175" x2="23.2276" y2="68.1175" gradientUnits="userSpaceOnUse">
          <stop offset="0.072" stopColor="#D6862B" />
          <stop offset="1" stopColor="#C75C07" />
        </linearGradient>
        <radialGradient id="paint4_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5393 68.1572) scale(3.42017 5.278)">
          <stop offset="0.1117" stopColor="#40A8C6" />
          <stop offset="1" stopColor="#272A80" />
        </radialGradient>
        <radialGradient id="paint5_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.1113 68.3715) scale(3.42017 5.278)">
          <stop offset="0.0531" stopColor="#4018C6" />
          <stop offset="0.2346" stopColor="#40A8C6" />
          <stop offset="1" stopColor="#62C6E1" />
        </radialGradient>
        <linearGradient id="paint6_linear_5517_68493" x1="9.57139" y1="75.0209" x2="26.8931" y2="60.2662" gradientUnits="userSpaceOnUse">
          <stop offset="0.072" stopColor="#C78640" />
          <stop offset="0.1355" stopColor="#C98331" />
          <stop offset="0.3053" stopColor="#CD7D0E" />
          <stop offset="0.3963" stopColor="#CF7B00" />
          <stop offset="0.9804" stopColor="#FFDE86" />
        </linearGradient>
        <linearGradient id="paint7_linear_5517_68493" x1="6.72902" y1="52.7138" x2="20.0568" y2="60.609" gradientUnits="userSpaceOnUse">
          <stop offset="0.00837989" stopColor="#3DFFFF" />
          <stop offset="0.243" stopColor="#6AA1DE" />
          <stop offset="0.4727" stopColor="#5492B3" />
          <stop offset="0.9377" stopColor="#5436B3" />
        </linearGradient>
        <linearGradient id="paint8_linear_5517_68493" x1="17.1562" y1="53.5089" x2="25.0377" y2="60.6447" gradientUnits="userSpaceOnUse">
          <stop offset="0.1825" stopColor="#AEECFF" />
          <stop offset="0.8924" stopColor="#DA7B00" />
        </linearGradient>
        <linearGradient id="paint9_linear_5517_68493" x1="17.6584" y1="53.7602" x2="25.54" y2="60.896" gradientUnits="userSpaceOnUse">
          <stop offset="0.0987" stopColor="#9FCEFF" />
          <stop offset="0.8924" stopColor="#DA7B00" />
        </linearGradient>
        <linearGradient id="paint10_linear_5517_68493" x1="7.98987" y1="79.788" x2="24.1241" y2="79.788" gradientUnits="userSpaceOnUse">
          <stop offset="0.00837989" stopColor="#49A0E2" />
          <stop offset="1" stopColor="#3E6DB3" />
        </linearGradient>
        <linearGradient id="paint11_linear_5517_68493" x1="16.1322" y1="79.5655" x2="35.6227" y2="79.5655" gradientUnits="userSpaceOnUse">
          <stop offset="0.0987" stopColor="#9AE4FF" />
          <stop offset="0.8924" stopColor="#DA7B00" />
        </linearGradient>
        <linearGradient id="paint12_linear_5517_68493" x1="16.6343" y1="79.2305" x2="36.1248" y2="79.2305" gradientUnits="userSpaceOnUse">
          <stop offset="0.0987" stopColor="#9FCEFF" />
          <stop offset="0.8924" stopColor="#DA7B00" />
        </linearGradient>
        <radialGradient id="paint13_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.6505 57.283) rotate(12.1112) scale(25.1784 33.4041)">
          <stop offset="0.0684" stopColor="#7695E4" />
          <stop offset="0.1896" stopColor="#7A98E4" />
          <stop offset="0.3165" stopColor="#87A3E3" />
          <stop offset="0.4461" stopColor="#9CB4E2" />
          <stop offset="0.5767" stopColor="#B9CCE0" />
          <stop offset="0.6616" stopColor="#D0DFDF" />
          <stop offset="0.9322" stopColor="#9FCEFF" />
        </radialGradient>
        <linearGradient id="paint14_linear_5517_68493" x1="28.6362" y1="79.5588" x2="57.0825" y2="64.4578" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E2A847" stopOpacity="0.7" />
          <stop offset="0.4513" stopColor="#DA7B00" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint15_linear_5517_68493" x1="79.0337" y1="58.5466" x2="47.201" y2="63.4304" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4CA8DB" />
          <stop offset="0.3843" stopColor="#797BCB" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint16_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.7602 47.3137) scale(10.5066 5.85997)">
          <stop offset="0.0678" stopColor="#E8E4E1" />
          <stop offset="0.405" stopColor="#E3E3E1" stopOpacity="0.5961" />
          <stop offset="0.7874" stopColor="#D6E0DF" stopOpacity="0.138" />
          <stop offset="0.9027" stopColor="#D0DFDF" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint17_linear_5517_68493" x1="44.8247" y1="38.6269" x2="55.9692" y2="41.8991" gradientUnits="userSpaceOnUse">
          <stop offset="0.1215" stopColor="#3C2D7E" stopOpacity="0.1" />
          <stop offset="0.7951" stopColor="#3B2C7E" stopOpacity="0.9" />
        </linearGradient>
        <radialGradient id="paint18_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.6659 37.2553) scale(4.85827 4.85827)">
          <stop offset="0.0363" stopColor="#E2BE58" />
          <stop offset="0.4382" stopColor="#F1DE5A" />
          <stop offset="0.5706" stopColor="#E6BB44" />
          <stop offset="0.7338" stopColor="#DA962C" />
          <stop offset="0.8661" stopColor="#D37F1D" />
          <stop offset="0.9497" stopColor="#D07718" />
          <stop offset="1" stopColor="#B6826E" />
        </radialGradient>
        <linearGradient id="paint19_linear_5517_68493" x1="46.406" y1="39.3587" x2="54.6355" y2="38.376" gradientUnits="userSpaceOnUse">
          <stop offset="0.0251" stopColor="#EF9B7E" />
          <stop offset="0.3185" stopColor="#EFAF00" />
          <stop offset="0.555" stopColor="#DC8D0B" />
          <stop offset="0.7909" stopColor="#CC7115" />
          <stop offset="0.928" stopColor="#C66718" />
        </linearGradient>
        <radialGradient id="paint20_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.9451 38.9003) scale(3.18123 3.76722)">
          <stop offset="0.2218" stopColor="#006279" />
          <stop offset="0.2488" stopColor="#025A74" />
          <stop offset="0.3695" stopColor="#083960" />
          <stop offset="0.4943" stopColor="#0D2050" />
          <stop offset="0.6236" stopColor="#110E45" />
          <stop offset="0.7602" stopColor="#13033E" />
          <stop offset="0.9162" stopColor="#14003C" />
          <stop offset="0.986" stopColor="#8B5053" />
        </radialGradient>
        <linearGradient id="paint21_linear_5517_68493" x1="38.8758" y1="39.1753" x2="49.5635" y2="38.8976" gradientUnits="userSpaceOnUse">
          <stop offset="0.0615" stopColor="#00FFFF" stopOpacity="0" />
          <stop offset="0.9525" stopColor="#00FFFF" />
        </linearGradient>
        <radialGradient id="paint22_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(42.6532 77.4) rotate(-75.4766) scale(6.16768 19.4856)">
          <stop offset="0.0251" stopColor="#4274DC" stopOpacity="0.5" />
          <stop offset="0.251" stopColor="#395CBB" stopOpacity="0.3842" />
          <stop offset="0.7446" stopColor="#212169" stopOpacity="0.131" />
          <stop offset="1" stopColor="#14003C" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint23_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.9795 65.6353) rotate(-2.51181) scale(6.16738 24.918)">
          <stop offset="0.0251" stopColor="#FFBB45" stopOpacity="0.3" />
          <stop offset="0.1346" stopColor="#FAB745" stopOpacity="0.2663" />
          <stop offset="0.2609" stopColor="#ECAC44" stopOpacity="0.2275" />
          <stop offset="0.3955" stopColor="#D59A43" stopOpacity="0.186" />
          <stop offset="0.536" stopColor="#B58042" stopOpacity="0.1428" />
          <stop offset="0.6811" stopColor="#8B5F41" stopOpacity="0.0981" />
          <stop offset="0.83" stopColor="#58363F" stopOpacity="0.0523" />
          <stop offset="0.9793" stopColor="#1D073C" stopOpacity="0.01" />
          <stop offset="1" stopColor="#14003C" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint24_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.3087 58.0113) scale(11.4282 10.6129)">
          <stop offset="0.0251" stopColor="#7E74DC" stopOpacity="0.5" />
          <stop offset="0.1788" stopColor="#7B71D8" stopOpacity="0.4212" />
          <stop offset="0.3332" stopColor="#7368CC" stopOpacity="0.342" />
          <stop offset="0.4881" stopColor="#665AB8" stopOpacity="0.2626" />
          <stop offset="0.6433" stopColor="#53459B" stopOpacity="0.1829" />
          <stop offset="0.7989" stopColor="#3B2B77" stopOpacity="0.1031" />
          <stop offset="0.9527" stopColor="#1E0B4B" stopOpacity="0.0243" />
          <stop offset="1" stopColor="#14003C" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint25_linear_5517_68493" x1="58.2317" y1="47.7205" x2="56.2021" y2="64.5394" gradientUnits="userSpaceOnUse">
          <stop offset="0.0251" stopColor="#FFFFDC" stopOpacity="0.3" />
          <stop offset="0.4925" stopColor="#FFFFEC" stopOpacity="0.1275" />
          <stop offset="0.838" stopColor="#FFFFFB" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint26_linear_5517_68493" x1="34.4181" y1="74.0476" x2="40.2185" y2="68.7102" gradientUnits="userSpaceOnUse">
          <stop offset="0.0251" stopColor="#D68F28" stopOpacity="0.3" />
          <stop offset="0.1269" stopColor="#CE8929" stopOpacity="0.2687" />
          <stop offset="0.2816" stopColor="#B97A2B" stopOpacity="0.2211" />
          <stop offset="0.47" stopColor="#97612E" stopOpacity="0.1631" />
          <stop offset="0.6848" stopColor="#673D33" stopOpacity="0.097" />
          <stop offset="0.919" stopColor="#2B113A" stopOpacity="0.0249" />
          <stop offset="1" stopColor="#14003C" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint27_linear_5517_68493" x1="68.8221" y1="66.4924" x2="73.1457" y2="91.138" gradientUnits="userSpaceOnUse">
          <stop offset="0.0363" stopColor="#E2BE58" />
          <stop offset="0.198" stopColor="#F1DE5A" />
          <stop offset="0.3869" stopColor="#E6BB44" />
          <stop offset="0.6199" stopColor="#DA962C" />
          <stop offset="0.8086" stopColor="#D37F1D" />
          <stop offset="0.928" stopColor="#D07718" />
        </linearGradient>
        <linearGradient id="paint28_linear_5517_68493" x1="79.6398" y1="92.6348" x2="66.218" y2="74.7566" gradientUnits="userSpaceOnUse">
          <stop offset="0.1955" stopColor="#54AEFF" />
          <stop offset="0.2073" stopColor="#59ACFA" stopOpacity="0.971" />
          <stop offset="0.3381" stopColor="#8797C7" stopOpacity="0.6495" />
          <stop offset="0.4521" stopColor="#A988A2" stopOpacity="0.369" />
          <stop offset="0.544" stopColor="#BE7E8B" stopOpacity="0.143" />
          <stop offset="0.6022" stopColor="#C67B83" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint29_linear_5517_68493" x1="63.1042" y1="69.1533" x2="81.5288" y2="77.6131" gradientUnits="userSpaceOnUse">
          <stop offset="0.0363" stopColor="#E2FFA8" />
          <stop offset="0.2011" stopColor="#DBFFBE" stopOpacity="0.8131" />
          <stop offset="0.3936" stopColor="#D5FFD0" stopOpacity="0.5947" />
          <stop offset="0.5975" stopColor="#D1FFDB" stopOpacity="0.3635" />
          <stop offset="0.8297" stopColor="#D0FFDE" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="paint30_linear_5517_68493" x1="50.4832" y1="90.0819" x2="71.0901" y2="74.1556" gradientUnits="userSpaceOnUse">
          <stop offset="0.0196" stopColor="#B67B50" />
          <stop offset="0.0767" stopColor="#BF7B34" />
          <stop offset="0.1388" stopColor="#C67B1D" />
          <stop offset="0.206" stopColor="#CB7B0D" />
          <stop offset="0.281" stopColor="#CE7B03" />
          <stop offset="0.3802" stopColor="#CF7B00" />
          <stop offset="0.5394" stopColor="#C26909" />
          <stop offset="0.7739" stopColor="#B35414" />
          <stop offset="0.928" stopColor="#AD4C18" />
        </linearGradient>
        <linearGradient id="paint31_linear_5517_68493" x1="46.6449" y1="101.794" x2="61.6881" y2="76.2009" gradientUnits="userSpaceOnUse">
          <stop offset="0.1955" stopColor="#BB86FF" />
          <stop offset="0.2073" stopColor="#BB86FA" stopOpacity="0.971" />
          <stop offset="0.3381" stopColor="#C081C7" stopOpacity="0.6495" />
          <stop offset="0.4521" stopColor="#C37EA2" stopOpacity="0.369" />
          <stop offset="0.544" stopColor="#C57C8B" stopOpacity="0.143" />
          <stop offset="0.6022" stopColor="#C67B83" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint32_linear_5517_68493" x1="50.1082" y1="90.713" x2="67.97" y2="75.3409" gradientUnits="userSpaceOnUse">
          <stop offset="0.072" stopColor="#AD4C18" />
          <stop offset="0.2261" stopColor="#B35414" />
          <stop offset="0.4606" stopColor="#C26909" />
          <stop offset="0.6198" stopColor="#CF7B00" />
          <stop offset="0.7012" stopColor="#D07E02" />
          <stop offset="0.7628" stopColor="#D48808" />
          <stop offset="0.8179" stopColor="#DA9812" />
          <stop offset="0.8693" stopColor="#E2AF1F" />
          <stop offset="0.9179" stopColor="#EDCC31" />
          <stop offset="0.964" stopColor="#FAF047" />
          <stop offset="0.9804" stopColor="#FFFF50" />
        </linearGradient>
        <linearGradient id="paint33_linear_5517_68493" x1="57.1532" y1="84.0379" x2="58.8247" y2="83.4295" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCC00" />
          <stop offset="0.0618" stopColor="#EEAF00" />
          <stop offset="0.127" stopColor="#E09800" />
          <stop offset="0.1974" stopColor="#D68800" />
          <stop offset="0.2762" stopColor="#D17E00" />
          <stop offset="0.3802" stopColor="#CF7B00" />
          <stop offset="0.5394" stopColor="#C26909" />
          <stop offset="0.7739" stopColor="#B35414" />
          <stop offset="0.928" stopColor="#AD4C18" />
        </linearGradient>
        <linearGradient id="paint34_linear_5517_68493" x1="57.9639" y1="85.1118" x2="59.137" y2="83.8628" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB800" />
          <stop offset="0.0242" stopColor="#F8AF00" />
          <stop offset="0.0971" stopColor="#E69800" />
          <stop offset="0.1758" stopColor="#D98800" />
          <stop offset="0.2639" stopColor="#D17E00" />
          <stop offset="0.3802" stopColor="#CF7B00" />
          <stop offset="0.5394" stopColor="#C26909" />
          <stop offset="0.7739" stopColor="#B35414" />
          <stop offset="0.928" stopColor="#AD4C18" />
        </linearGradient>
        <linearGradient id="paint35_linear_5517_68493" x1="57.2646" y1="82.9314" x2="59.5418" y2="83.0508" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB000" />
          <stop offset="0.3802" stopColor="#CF7B00" />
          <stop offset="0.5394" stopColor="#C26909" />
          <stop offset="0.7739" stopColor="#B35414" />
          <stop offset="0.928" stopColor="#AD4C18" />
        </linearGradient>
        <linearGradient id="paint36_linear_5517_68493" x1="58.6958" y1="82.6901" x2="59.3969" y2="84.324" gradientUnits="userSpaceOnUse">
          <stop offset="0.072" stopColor="#AD4C18" />
          <stop offset="0.2261" stopColor="#B35414" />
          <stop offset="0.4606" stopColor="#C26909" />
          <stop offset="0.6198" stopColor="#CF7B00" />
          <stop offset="0.768" stopColor="#CF7E00" />
          <stop offset="0.8802" stopColor="#D18800" />
          <stop offset="0.9802" stopColor="#D39800" />
          <stop offset="1" stopColor="#D49C00" />
        </linearGradient>
        <linearGradient id="paint37_linear_5517_68493" x1="57.8653" y1="81.7546" x2="60.2289" y2="83.5106" gradientUnits="userSpaceOnUse">
          <stop offset="0.072" stopColor="#AD4C18" />
          <stop offset="0.2261" stopColor="#B35414" />
          <stop offset="0.4606" stopColor="#C26909" />
          <stop offset="0.6198" stopColor="#CF7B00" />
          <stop offset="0.768" stopColor="#CF7E00" />
          <stop offset="0.8802" stopColor="#D18800" />
          <stop offset="0.9802" stopColor="#D39800" />
          <stop offset="1" stopColor="#D49C00" />
        </linearGradient>
        <linearGradient id="paint38_linear_5517_68493" x1="59.549" y1="83.3251" x2="59.2375" y2="85.1206" gradientUnits="userSpaceOnUse">
          <stop offset="0.072" stopColor="#AD4C18" />
          <stop offset="0.2261" stopColor="#B35414" />
          <stop offset="0.4606" stopColor="#C26909" />
          <stop offset="0.6198" stopColor="#CF7B00" />
          <stop offset="0.7428" stopColor="#D27E00" />
          <stop offset="0.8358" stopColor="#DA8800" />
          <stop offset="0.9188" stopColor="#E99800" />
          <stop offset="0.9959" stopColor="#FEAF00" />
          <stop offset="1" stopColor="#FFB000" />
        </linearGradient>
        <linearGradient id="paint39_linear_5517_68493" x1="50.8256" y1="84.3738" x2="65.4646" y2="83.7971" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C75C07" />
          <stop offset="0.928" stopColor="#5D1A18" />
        </linearGradient>
        <radialGradient id="paint40_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.1156 82.9636) rotate(-20.5661) scale(7.14986 11.5811)">
          <stop stopColor="#6CDBEC" />
          <stop offset="0.154" stopColor="#6BD2EB" />
          <stop offset="0.4017" stopColor="#68BBE8" />
          <stop offset="0.7103" stopColor="#6495E3" />
          <stop offset="0.8219" stopColor="#6285E1" />
          <stop offset="1" stopColor="#BAA873" />
        </radialGradient>
        <linearGradient id="paint41_linear_5517_68493" x1="48.6544" y1="77.2842" x2="53.2267" y2="94.1562" gradientUnits="userSpaceOnUse">
          <stop offset="0.0698" stopColor="#637595" />
          <stop offset="0.2471" stopColor="#3D75EC" />
          <stop offset="0.338" stopColor="#3C70E3" />
          <stop offset="0.484" stopColor="#3B61CC" />
          <stop offset="0.6335" stopColor="#394EAD" />
          <stop offset="0.6978" stopColor="#3D50AA" />
          <stop offset="0.7573" stopColor="#4854A3" />
          <stop offset="0.815" stopColor="#5B5C96" />
          <stop offset="0.8714" stopColor="#756883" />
          <stop offset="0.9271" stopColor="#97766C" />
          <stop offset="0.9813" stopColor="#C0884F" />
          <stop offset="1" stopColor="#D08F44" />
        </linearGradient>
        <radialGradient id="paint42_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.9655 85.4192) rotate(-119.38) scale(3.2574 15.601)">
          <stop stopColor="#75CAFF" stopOpacity="0.7" />
          <stop offset="0.1902" stopColor="#71C6FD" stopOpacity="0.5581" />
          <stop offset="0.3997" stopColor="#63BAF8" stopOpacity="0.4019" />
          <stop offset="0.6183" stopColor="#4DA5EE" stopOpacity="0.2389" />
          <stop offset="0.8418" stopColor="#2F89E2" stopOpacity="0.0721" />
          <stop offset="0.9385" stopColor="#1F7BDB" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint43_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.4497 90.2513) rotate(-90.5641) scale(2.03452 15.6009)">
          <stop stopColor="#F4C75C" stopOpacity="0.6" />
          <stop offset="0.2328" stopColor="#F5E066" stopOpacity="0.389" />
          <stop offset="0.4948" stopColor="#F6F76E" stopOpacity="0.1515" />
          <stop offset="0.662" stopColor="#F6FF71" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint44_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.1865 85.7969) rotate(-124.599) scale(1.30008 15.6014)">
          <stop stopColor="#75CAFF" stopOpacity="0.7" />
          <stop offset="0.1902" stopColor="#71C6FD" stopOpacity="0.5581" />
          <stop offset="0.3997" stopColor="#63BAF8" stopOpacity="0.4019" />
          <stop offset="0.6183" stopColor="#4DA5EE" stopOpacity="0.2389" />
          <stop offset="0.8418" stopColor="#2F89E2" stopOpacity="0.0721" />
          <stop offset="0.9385" stopColor="#1F7BDB" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint45_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.7179 87.1655) rotate(-59.6809) scale(4.74433 11.0975)">
          <stop stopColor="#53BBEC" />
          <stop offset="0.2282" stopColor="#4786D6" />
          <stop offset="0.4431" stopColor="#3D5AC3" />
          <stop offset="0.61" stopColor="#3740B8" />
          <stop offset="0.7087" stopColor="#3536B4" />
        </radialGradient>
        <radialGradient id="paint46_radial_5517_68493" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.9061 84.8809) rotate(33.3659) scale(8.49388 4.67616)">
          <stop stopColor="#6CCFEC" />
          <stop offset="0.2613" stopColor="#5795D4" />
          <stop offset="0.4874" stopColor="#4869C3" />
          <stop offset="0.6631" stopColor="#3E4FB8" />
          <stop offset="0.767" stopColor="#3B45B4" />
        </radialGradient>
        <clipPath id="clip0_5517_68493">
          <rect width="90" height="134" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CrashGuardIcon;
