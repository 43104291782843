import BasicRewardIcon from '../../icons/BasicReward';
import LargeRewardIcon from '../../icons/LargeReward';
import MediumRewardIcon from '../../icons/MediumReward';

const getRewardIcon = (order: number) => {
  if (order >= 5) {
    return <LargeRewardIcon />;
  }

  if (order >= 3) {
    return <MediumRewardIcon />;
  }

  return <BasicRewardIcon />;
};

export default getRewardIcon;
