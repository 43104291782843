import Phaser from 'phaser';
import 'phaser/plugins/spine4.1/dist/SpinePlugin';
import { memo, useEffect } from 'react';
import AviatorRocket from '../../Components/AviatorRocket';
import SlotMachineScreen from '../../Components/SlotMachine';
import { useAppSelector } from '../../store';
import { AviatorScene } from './AviatorScene';

class MyGame extends Phaser.Game {
  // eslint-disable-next-line no-useless-constructor
  constructor(config: Phaser.Types.Core.GameConfig) {
    super(config);
  }

  updateParent(newParentId: string) {
    const { canvas } = this;

    if (canvas) {
      const newParent = document.getElementById(newParentId);
      if (newParent) {
        newParent.appendChild(canvas);
      }
    }
  }
}

const config = {
  parent: 'aviator',
  plugins: {
    scene: [
      {
        key: 'SpinePlugin',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        plugin: window.SpinePlugin,
        sceneKey: 'spine',
        mapping: 'spine',
      },
    ],
  },
};

// Initialize game before parent render
const game = new MyGame({
  ...config,
  type: Phaser.AUTO,
  width: '200%',
  height: '200%',
  render: {
    pixelArt: false,
    antialias: true,
    antialiasGL: true,
    mipmapFilter: 'LINEAR_MIPMAP_LINEAR',
  },
  autoCenter: 1,
  parent: null,
  fps: {
    target: 30,
    forceSetTimeOut: true,
  },
  scene: AviatorScene,
});
const HomePage = memo(() => {
  const { activeGame } = useAppSelector(
    ({ aviator }) => aviator,
  );

  useEffect(() => {
    if (activeGame === 'aviator') {
      const parentId = 'aviator'; // ID container
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      game.renderer.pipelines.imageSmoothingQuality = 'high';
      game.updateParent(parentId);
      setTimeout(() => {
        const canvas = game.canvas.getContext('webgl', { antialias: true });
        if (canvas) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
          canvas.canvas.style.imageSmoothingQuality = 'high';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          canvas.canvas.style.imageSmoothingEnabled = true;
        }
      }, 2000);
    }
  }, [activeGame]);

  return (
    <div>
      {activeGame === 'slotMachine'
        ? <SlotMachineScreen />
        : <AviatorRocket />}
    </div>
  );
});
HomePage.displayName = 'HomePage';
export default HomePage;
