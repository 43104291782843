import { Howl } from 'howler';
import Button from '../../Components/Button';
import aviatorEngine from '../../assets/sounds/sc_aviator_engine.wav';

function TestField() {
  const sound = new Howl({
    src: [aviatorEngine],
    loop: true,
  });

  const onPlay = async () => {
    sound.play();
  };
  return (
    <div>
      <Button onClick={onPlay}>play</Button>
    </div>
  );
}

export default TestField;
