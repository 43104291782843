import {
  createContext, PropsWithChildren, useContext, useEffect, useState,
} from 'react';
import MixpanelService from '../../services/MixpanelService';
import { useAppSelector } from '../../store';

type TMixpanelContext = {
    mixpanel: MixpanelService
}

const MixpanelContext = createContext({} as TMixpanelContext);

export const useMixpanelContext = () => {
  const context = useContext(MixpanelContext);

  if (!context) {
    throw new Error('Mixpanel context not found');
  }

  return context;
};

function MixpanelProvider({ children }: PropsWithChildren) {
  const { userData } = useAppSelector(({ profile }) => profile);

  const [mixpanel] = useState(() => new MixpanelService());

  useEffect(() => {
    if (typeof userData?.id !== 'undefined') {
      mixpanel.identify(userData.id);

      mixpanel.trackEvent('User login', {
        userId: userData.id,
        username: userData.username,
        timestamp: new Date().toISOString(),
      });
    }

    return () => {
      mixpanel.disconnect();
    };
  }, [userData?.id]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <MixpanelContext.Provider value={{ mixpanel }}>{children}</MixpanelContext.Provider>;
}

export default MixpanelProvider;
