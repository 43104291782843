import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { numberWithCommas } from '../../helpers';
import { TopPlayerT } from '../../types';

type LeaderboardUserCardProps = Pick<TopPlayerT, 'username' | 'league'| 'total_earned_coins'> &{
    id?: string
    isUser?: boolean
    first_name?: string
    last_name?: string
}
function LeaderboardUserCard({
  username, first_name: firstName, last_name: lastName, league, total_earned_coins: totalCoins, isUser, id,
}: LeaderboardUserCardProps) {
  return (
    <div id={id} className={`${isUser ? 'bg-black-2' : 'gradient-border primary flex justify-between items-center px-4 py-2 rounded-10 bg-leagueCardBg'} p-2 rounded-10 flex justify-between`}>
      <div className="flex pl-[.125rem]">
        <div className="flex flex-col items-start">
          <div className="text-base font-bold font-Bangers tracking-[0.3px]">
            {username || (`${firstName} ${lastName}`)}
          </div>
          <div className="flex items-center text-12 font-normal font-Inrias translate-y-[-3px]">
            {numberWithCommas(totalCoins)}
            <Coin width="18px" height="18px" className="ml-2" />
          </div>
        </div>
      </div>
      <div className={`text-26 my-auto mx-0 font-normal font-Bangers pr-1
      ${league.rank === 1 && 'text-transparent bg-clip-text bg-gradient-to-b from-[#FFBB66] to-[#894C00]'}
      ${league.rank === 2 && 'text-[#A7B7BF]'}
      ${league.rank === 3 && 'text-[#F27C57]'}
      ${isUser && 'text-gray-300'}
      `}
      >
        #
        {numberWithCommas(league.rank)}
      </div>
    </div>
  );
}

export default LeaderboardUserCard;
