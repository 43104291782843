import AviatorMultiplierBg from '../../assets/svg/aviatorMultiplierBg.svg';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/*

    DESCRIPTION

    How it works?

    1. Loading multiplier red rect, then we use condition to render multiplier in drawMultiplierScaleArrow
    2. drawMultiplierScaleArrow - reset canvas and them draw multiplier with red rect as image
    3. drawMultiplierScale - render dashes on the right of multiplier

*/

let containerFigure: null | HTMLImageElement = null;

const img = new Image();

img.src = AviatorMultiplierBg;

img.onload = () => {
  containerFigure = img;
};

export class MultiplierScale {
  private config;

  constructor() {
    const canvas = document.getElementById('multiplier_scale') as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d');

    if (!ctx) {
      throw new Error('MultiplierScale canvas is not defined.');
    }

    this.config = {
      ctx,
      width: canvas.width,
      height: canvas.height,
      tickSpacing: 86, // Distance between major divisions (in pixels)
      subTicks: 4, // Number of additional elements between major divisions
      visibleNumbers: 4, // Number of numbers in the visible area
      maxValue: 1000, // Maximum scale value
      currentValue: 0, // Initial value
      isScrolling: false, // Flag for scroll control
      scrollInterval: 100, // Variable for controlling animation interval,
      centerY: canvas.height / 2 + 20,
    };
  }

  drawMultiplierScale(value: number) {
    const {
      ctx, height, visibleNumbers, tickSpacing, subTicks, maxValue,
    } = this.config;

    // This is width that was specified for canvas before adding new multiplier
    const width = 40;

    ctx.fillStyle = 'white';
    ctx.font = "14px 'Bangers'";
    ctx.textAlign = 'right';
    ctx.textBaseline = 'middle';
    ctx.shadowColor = 'transparent';

    // Calculating the first value in the current area
    const startValue = Math.floor(value);

    // Drawing numbers, main lines and additional elements
    for (let i = -1; i <= visibleNumbers + 1; i++) {
      const y = (height / 2) + 20 + (i * tickSpacing) + (value % 1) * tickSpacing; // Centering the scale
      const displayValue = startValue - i; // Values from top to bottom

      if (displayValue >= 0 && displayValue <= maxValue) {
        // Text
        ctx.fillText(`${displayValue}x`, width - 24, y - 1); // Shifting text to the left

        // Main line
        ctx.fillRect(width - 20, y - 1, 8, 1);

        // Additional elements between main lines
        for (let j = 1; j <= subTicks; j++) {
          const subTickY = y - (j * tickSpacing) / (subTicks + 1); // Equal distance
          ctx.fillRect(width - 17, subTickY - 1, 3, 1); // Length and position of additional lines
        }
      }
    }
  }

  drawMultiplierScaleArrow(value: number) {
    const {
      ctx, width, centerY, height,
    } = this.config;

    ctx.clearRect(0, 0, width, height);

    if (containerFigure) {
      ctx.drawImage(containerFigure, width - containerFigure.width, centerY - 15);

      this.drawMultiplierText(value);
    }
  }

  drawMultiplierText(value: number) {
    const {
      ctx, width, centerY,
    } = this.config;

    ctx.font = "20px 'Bangers'";
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'white';

    const text = `${value}X`;
    const textX = width - (65 / 2);

    ctx.shadowColor = '#FF5876';
    ctx.shadowOffsetX = -1;
    ctx.shadowOffsetY = -1;
    ctx.shadowBlur = 0;

    ctx.fillText(text, textX, centerY);

    ctx.shadowColor = '#FF5876';
    ctx.shadowOffsetX = -1;
    ctx.shadowOffsetY = 1;
    ctx.shadowBlur = 0;

    ctx.fillText(text, textX, centerY);

    ctx.shadowColor = '#FF5876';
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = -1;
    ctx.shadowBlur = 0;

    ctx.fillText(text, textX, centerY);

    ctx.shadowColor = '#FF5876';
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = 1;
    ctx.shadowBlur = 0;

    ctx.fillText(text, textX, centerY);
  }

  draw(value = 0) {
    this.drawMultiplierScaleArrow(value);
    this.drawMultiplierScale(value);
  }
}
