import { ReactComponent as Validating } from '../../assets/svg/validating.svg';
import Button, { ButtonProps, CheckButtonProps } from '../Button';

export function ClaimedButton() {
  return (
    <Button
      className="btn success task-success gradient-border before:rounded-[8px]"
      pureClass
    >
      Claimed
    </Button>
  );
}

export function ErrorButton() {
  return (
    <Button
      className="btn error task-error gradient-border before:rounded-[8px]"
      pureClass
    >
      Error
    </Button>
  );
}

export function CompleteTheTaskButton({ onClick }: ButtonProps) {
  return (
    <Button
      className="btn primary"
      pureClass
      onClick={onClick}
    >
      Complete the task
    </Button>
  );
}

export function CheckButton({ onClick }: CheckButtonProps) {
  return (
    <Button
      className="btn neutral task-neutral"
      pureClass
      onClick={onClick}
    >
      Validate
    </Button>
  );
}

export function ValidatingButton() {
  return (
    <Button
      className="btn warning task-warning gradient-border before:rounded-[8px]"
      pureClass
    >
      <span className="animate-spin">
        <Validating />
      </span>
      {' '}
      Validating
    </Button>
  );
}
