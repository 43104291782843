import * as PIXI from 'pixi.js';

export const slotMachinePixiApp = new PIXI.Application({
  width: 335,
  height: 258,
  resolution: 2,
  autoDensity: true,
  backgroundAlpha: 0,
  hello: true,
});
