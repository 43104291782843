import { MultiColorIconProps } from '../types/icons';

function BrainBotIcon({ colors = { background: '#0D0D0F', glow: '#8C40F3' } }: MultiColorIconProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4158_139017)">
        <rect width="64" height="64" rx="12" fill={colors.background ?? '#0D0D0F'} />
        <g filter="url(#filter0_f_4158_139017)">
          <path d="M32 0L33.4908 26.4363L48 4.28719L36.0729 27.9271L59.7128 16L37.5637 30.5092L64 32L37.5637 33.4908L59.7128 48L36.0729 36.0729L48 59.7128L33.4908 37.5637L32 64L30.5092 37.5637L16 59.7128L27.9271 36.0729L4.28719 48L26.4363 33.4908L0 32L26.4363 30.5092L4.28719 16L27.9271 27.9271L16 4.28719L30.5092 26.4363L32 0Z" fill={colors.glow ?? '#8C40F3'} />
        </g>
        <g clipPath="url(#clip1_4158_139017)">
          <path d="M39.6829 35.6153C40.1712 35.3816 42.9074 36.2656 44.2603 37.0886C45.9896 38.0641 47.0983 40.1471 46.4676 41.4375C45.837 42.728 44.7587 40.6145 44.7587 40.6145C44.7587 40.6145 44.8503 39.3139 43.6093 38.5112C42.2564 37.6373 40.7815 37.2817 39.4795 37.0581C38.4317 36.8752 39.6829 35.6153 39.6829 35.6153Z" fill="#191C61" />
          <path d="M39.6829 35.6153C40.1712 35.3816 42.9074 36.2656 44.2603 37.0886C45.9896 38.0641 47.0983 40.1471 46.4676 41.4375C45.837 42.728 44.7587 40.6145 44.7587 40.6145C44.7587 40.6145 44.8503 39.3139 43.6093 38.5112C42.2564 37.6373 40.7815 37.2817 39.4795 37.0581C38.4317 36.8752 39.6829 35.6153 39.6829 35.6153Z" fill="url(#paint0_linear_4158_139017)" />
          <path d="M39.6829 35.6153C40.1712 35.3816 42.9074 36.2656 44.2603 37.0886C45.9896 38.0641 47.0983 40.1471 46.4676 41.4375C45.837 42.728 44.7587 40.6145 44.7587 40.6145C44.7587 40.6145 44.8503 39.3139 43.6093 38.5112C42.2564 37.6373 40.7815 37.2817 39.4795 37.0581C38.4317 36.8752 39.6829 35.6153 39.6829 35.6153Z" fill="url(#paint1_linear_4158_139017)" />
          <path d="M39.6829 35.6153C40.1712 35.3816 42.9074 36.2656 44.2603 37.0886C45.9896 38.0641 47.0983 40.1471 46.4676 41.4375C45.837 42.728 44.7587 40.6145 44.7587 40.6145C44.7587 40.6145 44.8503 39.3139 43.6093 38.5112C42.2564 37.6373 40.7815 37.2817 39.4795 37.0581C38.4317 36.8752 39.6829 35.6153 39.6829 35.6153Z" fill="url(#paint2_radial_4158_139017)" />
          <path d="M47.6883 41.8843C47.6883 40.0553 46.6304 38.5718 45.3284 38.5718C44.5391 38.5718 44.1383 38.7039 43.9246 38.7852C43.2431 38.9477 42.6531 39.4558 42.2361 40.1772C42.1852 40.1772 42.1344 40.1671 42.0733 40.1671C41.3409 40.1975 40.8018 41.2035 40.8527 42.4126C40.9035 43.4389 41.3613 44.2721 41.9513 44.4957C42.4192 45.715 43.3347 46.538 44.3824 46.5482H44.4943C44.8605 46.5279 45.1961 46.4059 45.5115 46.2129C46.905 45.4102 47.6781 43.3881 47.6781 41.8944L47.6883 41.8843Z" fill="url(#paint3_radial_4158_139017)" />
          <path d="M47.7901 41.9658C47.7901 43.7643 46.6305 46.335 44.5961 46.5383C43.2941 46.6602 43.0601 43.7643 43.0601 41.9658C43.0601 40.1673 43.0398 39.3138 44.0264 38.9175C44.2401 38.8362 45.1962 38.7041 45.4302 38.7041C46.7322 38.7041 47.7901 40.1571 47.7901 41.9556V41.9658Z" fill="url(#paint4_linear_4158_139017)" />
          <path d="M44.4944 46.5384C46.0337 46.5384 47.2815 44.8233 47.2815 42.7077C47.2815 40.592 46.0337 38.877 44.4944 38.877C42.9551 38.877 41.7073 40.592 41.7073 42.7077C41.7073 44.8233 42.9551 46.5384 44.4944 46.5384Z" fill="url(#paint5_linear_4158_139017)" />
          <path opacity="0.9" d="M34.7088 45.441C34.6682 45.4207 33.3051 44.9228 32.9898 44.7907H32.9694C32.8575 44.7602 32.8067 44.8313 32.7253 44.9634C32.6745 44.8618 32.5931 44.8212 32.5321 44.8313H32.5219C31.759 45.0447 30.6095 45.4207 29.6635 45.8474V45.9287C29.3177 46.0303 27.9546 46.2437 26.9273 46.9144C26.1847 47.4021 30.6807 47.8898 30.6807 47.8898L30.8537 47.1684C31.6878 46.8229 32.4608 46.386 32.6643 46.3352C32.6948 46.3047 32.7355 46.2539 32.7558 46.1929C32.7863 46.2132 32.8067 46.2234 32.8372 46.2336C32.8372 46.2336 33.7222 46.5181 34.4037 46.264C35.0852 45.9999 34.9225 45.502 34.7088 45.4512V45.441Z" fill="#87FFEB" />
          <path d="M29.7654 45.959C29.5619 46.0606 28.0666 46.2435 26.9681 46.9649C26.2255 47.4527 30.7215 47.9404 30.7215 47.9404L30.9046 47.1885L29.7755 45.959H29.7654Z" fill="url(#paint6_linear_4158_139017)" />
          <path d="M29.7042 45.8982C30.6502 45.4816 31.8301 45.065 32.5625 44.8618C32.5727 46.1624 32.7049 46.386 32.7049 46.386C32.7049 46.386 31.9115 46.7213 30.9655 47.2192C30.4365 47.3309 29.6431 46.1319 29.694 45.8982H29.7042Z" fill="url(#paint7_linear_4158_139017)" />
          <path d="M32.6265 46.5573C32.7724 46.5497 32.8725 46.1982 32.8502 45.7723C32.8278 45.3464 32.6915 45.0073 32.5456 45.015C32.3997 45.0226 32.2996 45.3741 32.322 45.8C32.3443 46.2259 32.4807 46.565 32.6265 46.5573Z" fill="url(#paint8_linear_4158_139017)" />
          <path d="M33 46.193C33.3202 46.193 33.5798 45.9337 33.5798 45.6138C33.5798 45.294 33.3202 45.0347 33 45.0347C32.6798 45.0347 32.4202 45.294 32.4202 45.6138C32.4202 45.9337 32.6798 46.193 33 46.193Z" fill="url(#paint9_radial_4158_139017)" />
          <path d="M34.668 45.4613C34.4036 45.3902 32.9998 44.8415 32.9998 44.8415C32.5319 44.7195 32.4709 46.2335 32.8778 46.2742C32.8778 46.2742 33.7627 46.5587 34.4443 46.3046C35.1258 46.0405 34.9223 45.5324 34.668 45.4613Z" fill="url(#paint10_radial_4158_139017)" />
          <path d="M42.3826 44.5177C43.112 44.4808 43.6544 43.4876 43.5942 42.2995C43.5339 41.1113 42.8938 40.178 42.1644 40.2149C41.435 40.2518 40.8926 41.2449 40.9529 42.4331C41.0131 43.6213 41.6532 44.5546 42.3826 44.5177Z" fill="url(#paint11_radial_4158_139017)" />
          <path d="M42.0279 42.9868C42.5105 42.9658 42.8792 42.4306 42.8513 41.7915C42.8233 41.1523 42.4094 40.6513 41.9267 40.6723C41.444 40.6934 41.0754 41.2286 41.1033 41.8677C41.1313 42.5068 41.5452 43.0079 42.0279 42.9868Z" fill="url(#paint12_linear_4158_139017)" />
          <path d="M42.0216 42.8445C42.465 42.8251 42.8028 42.3141 42.7761 41.703C42.7493 41.0919 42.3683 40.6122 41.9249 40.6315C41.4815 40.6508 41.1437 41.1619 41.1704 41.773C41.1971 42.3841 41.5782 42.8638 42.0216 42.8445Z" fill="url(#paint13_linear_4158_139017)" />
          <path d="M42.1896 42.7354C42.5544 42.7195 42.8323 42.2976 42.8103 41.793C42.7882 41.2884 42.4746 40.8923 42.1098 40.9082C41.745 40.9241 41.4671 41.3461 41.4892 41.8506C41.5112 42.3552 41.8248 42.7514 42.1896 42.7354Z" fill="url(#paint14_linear_4158_139017)" />
          <path d="M46.6074 40.1824C46.3125 39.461 45.7252 39.2221 45.2878 39.3135C45.2675 39.3135 45.2471 39.3135 45.2268 39.3338C44.8301 39.4659 44.6876 39.9333 44.8809 40.4414C44.9826 40.7056 45.1963 40.9291 45.4404 41.0714C45.6743 41.3254 45.9897 41.4473 46.2643 41.3559C46.661 41.2238 46.8238 40.6954 46.6102 40.1874L46.6074 40.1824Z" fill="url(#paint15_radial_4158_139017)" />
          <path d="M46.3239 41.3302C46.6671 41.2054 46.8051 40.7196 46.6321 40.245C46.4592 39.7704 46.0409 39.4868 45.6977 39.6115C45.3546 39.7363 45.2166 40.2221 45.3895 40.6967C45.5625 41.1713 45.9808 41.455 46.3239 41.3302Z" fill="url(#paint16_linear_4158_139017)" />
          <path d="M47.2713 42.5854C47.2204 41.8233 46.7627 41.4372 46.3456 41.4067H46.2845C45.898 41.4271 45.5318 41.8335 45.5623 42.372C45.5827 42.6464 45.5827 43.002 45.7556 43.2052C45.8878 43.5101 46.3557 43.6015 46.6304 43.5914C47.0169 43.571 47.3221 43.1341 47.2814 42.5956L47.2713 42.5854Z" fill="url(#paint17_radial_4158_139017)" />
          <path d="M46.4621 43.2881C46.8661 43.2683 47.1756 42.8843 47.1534 42.4303C47.1312 41.9763 46.7856 41.6242 46.3817 41.6439C45.9777 41.6637 45.6682 42.0477 45.6904 42.5017C45.7126 42.9558 46.0581 43.3078 46.4621 43.2881Z" fill="url(#paint18_linear_4158_139017)" />
          <path d="M46.4242 45.0049C46.7328 44.9899 46.9685 44.6823 46.9507 44.318C46.9328 43.9536 46.6682 43.6705 46.3596 43.6856C46.051 43.7007 45.8152 44.0082 45.8331 44.3726C45.8509 44.7369 46.1156 45.02 46.4242 45.0049Z" fill="url(#paint19_radial_4158_139017)" />
          <path d="M46.2779 44.9207C46.5641 44.9067 46.7834 44.6364 46.7678 44.3169C46.7521 43.9974 46.5075 43.7498 46.2213 43.7637C45.9351 43.7777 45.7158 44.0481 45.7314 44.3676C45.7471 44.687 45.9918 44.9347 46.2779 44.9207Z" fill="url(#paint20_linear_4158_139017)" />
          <path d="M29.0534 42.6974C31.6713 42.6974 33.7935 41.7193 33.7935 40.5127C33.7935 39.3062 31.6713 38.3281 29.0534 38.3281C26.4355 38.3281 24.3132 39.3062 24.3132 40.5127C24.3132 41.7193 26.4355 42.6974 29.0534 42.6974Z" fill="url(#paint21_linear_4158_139017)" />
          <path d="M29.0534 42.3824C31.6713 42.3824 33.7935 41.4043 33.7935 40.1978C33.7935 38.9913 31.6713 38.0132 29.0534 38.0132C26.4355 38.0132 24.3132 38.9913 24.3132 40.1978C24.3132 41.4043 26.4355 42.3824 29.0534 42.3824Z" fill="url(#paint22_linear_4158_139017)" />
          <path d="M33.3867 40.2382C33.1934 41.2644 31.3319 42.0672 29.0636 42.0672C26.7952 42.0672 24.9236 41.2644 24.7405 40.2382C24.9337 39.2119 26.7952 38.4092 29.0636 38.4092C31.3319 38.4092 33.2036 39.2119 33.3867 40.2382Z" fill="url(#paint23_radial_4158_139017)" />
          <path d="M39.9067 36.2453C39.0624 38.7042 37.3332 39.9947 35.8379 39.1615C34.5562 38.4502 34.0884 36.418 35.014 34.2029C35.9396 31.9878 37.974 30.3315 39.1336 31.2155C40.4255 32.191 40.5679 34.3147 39.9067 36.2453Z" fill="url(#paint24_radial_4158_139017)" />
          <path d="M35.7567 34.6092C35.1261 36.9361 34.6887 38.8362 35.6448 39.1919C35.6652 39.1919 36.4077 39.4154 36.4281 39.4256C37.4453 39.6999 38.7982 37.9929 39.439 35.605C40.0798 33.2172 39.7136 31.439 38.7473 30.9614C38.7168 30.9411 37.7403 30.5753 37.6996 30.5652C36.6926 30.4331 36.367 32.3129 35.7466 34.6092H35.7567Z" fill="url(#paint25_linear_4158_139017)" />
          <path d="M38.4062 35.3138C39.0084 32.919 38.6662 30.7693 37.6418 30.5123C36.6175 30.2553 35.299 31.9883 34.6968 34.3831C34.0946 36.7779 34.4368 38.9276 35.4612 39.1846C36.4855 39.4416 37.804 37.7086 38.4062 35.3138Z" fill="url(#paint26_linear_4158_139017)" />
          <path d="M36.8858 37.0172L36.6315 38.0231C36.6213 38.0841 36.601 38.1552 36.5806 38.2162C36.0212 40.0249 32.8545 41.5736 29.033 41.4169C27.044 41.3354 27.1105 41.3255 26.2459 41.1629C25.778 41.0715 25.3304 40.9597 24.9032 40.8276C22.9807 40.2179 21.6482 39.1917 21.4244 38.0028L21.2209 37.2407H21.4447C21.8618 35.3508 25.0965 33.8774 29.033 33.8774C32.9696 33.8774 35.9703 35.2492 36.5705 37.0375H36.896L36.8858 37.0172Z" fill="url(#paint27_linear_4158_139017)" />
          <path d="M29.033 40.6144C33.3812 40.6144 36.9061 38.872 36.9061 36.7227C36.9061 34.5734 33.3812 32.8311 29.033 32.8311C24.6848 32.8311 21.1599 34.5734 21.1599 36.7227C21.1599 38.872 24.6848 40.6144 29.033 40.6144Z" fill="url(#paint28_linear_4158_139017)" />
          <path d="M29.033 39.3138C32.2464 39.3138 34.8513 38.0901 34.8513 36.5805C34.8513 35.0709 32.2464 33.8472 29.033 33.8472C25.8196 33.8472 23.2146 35.0709 23.2146 36.5805C23.2146 38.0901 25.8196 39.3138 29.033 39.3138Z" fill="#8CF2FF" />
          <path d="M29.0331 38.267C31.0162 38.267 32.6238 37.5118 32.6238 36.5803C32.6238 35.6487 31.0162 34.8936 29.0331 34.8936C27.05 34.8936 25.4424 35.6487 25.4424 36.5803C25.4424 37.5118 27.05 38.267 29.0331 38.267Z" fill="white" />
          <path opacity="0.5" d="M38.5946 21.9279C37.2316 10.3646 19.9901 11.147 19.8477 22.9237C19.8477 24.3564 20.163 25.6976 20.7631 26.9576C21.3531 28.6037 22.2889 31.8756 22.1364 35.7266C22.0753 40.167 36.0415 40.1771 35.9804 35.7266C35.9804 35.7266 39.8255 27.2523 38.6048 21.9279H38.5946Z" fill="url(#paint29_linear_4158_139017)" />
          <path d="M29.0228 15.923C29.4498 15.923 29.7959 15.7228 29.7959 15.4759C29.7959 15.229 29.4498 15.0288 29.0228 15.0288C28.5959 15.0288 28.2498 15.229 28.2498 15.4759C28.2498 15.7228 28.5959 15.923 29.0228 15.923Z" fill="url(#paint30_radial_4158_139017)" />
          <path opacity="0.5" d="M29.033 32.5869C25.0354 32.5869 21.7905 34.1924 21.7905 36.1636C21.7905 38.1348 25.0354 39.7403 29.033 39.7403C33.0306 39.7403 36.2754 38.1348 36.2754 36.1636C36.2754 34.1924 33.0306 32.5869 29.033 32.5869ZM29.033 38.46C26.4696 38.46 24.3844 37.4337 24.3844 36.1636C24.3844 34.8935 26.4595 33.8672 29.033 33.8672C31.6065 33.8672 33.6816 34.8935 33.6816 36.1636C33.6816 37.4337 31.5963 38.46 29.033 38.46Z" fill="url(#paint31_linear_4158_139017)" />
          <path opacity="0.5" d="M33.5187 15.6894V15.7707C33.478 16.0755 33.1118 16.1975 32.8677 16.0044C32.6643 15.8418 32.3998 15.7504 32.115 15.7504C31.464 15.7504 31.1181 16.3092 30.8435 16.8579C30.7215 17.112 29.6839 17.2745 28.982 17.2745C26.4797 17.2745 24.4453 16.5633 24.4453 15.6793C24.4453 14.7953 26.4797 14.084 28.982 14.084C31.4844 14.084 33.5187 14.7953 33.5187 15.6793L33.5187 15.6894Z" fill="url(#paint32_radial_4158_139017)" />
          <path opacity="0.9" d="M39.0219 21.7251C37.5876 9.62335 19.5527 10.4464 19.4001 22.7616C19.4001 24.2654 19.7256 25.6676 20.3563 26.9886C20.9768 28.7058 21.9533 32.1402 21.7906 36.164C21.7194 40.8076 36.3467 40.8177 36.2755 36.164C36.2755 36.164 39.7645 28.4314 39.0219 21.7251Z" stroke="url(#paint33_linear_4158_139017)" strokeWidth="0.048" strokeMiterlimit="10" />
          <path opacity="0.9" d="M39.0219 21.7251C37.5876 9.62335 19.5527 10.4464 19.4001 22.7616C19.4001 24.2654 19.7256 25.6676 20.3563 26.9886C20.9768 28.7058 21.9533 32.1402 21.7906 36.164C21.7194 40.8076 36.3467 40.8177 36.2755 36.164C36.2755 36.164 39.7645 28.4314 39.0219 21.7251ZM35.9703 35.7271C36.0314 40.1674 22.0652 40.1776 22.1262 35.7271C22.289 31.876 21.343 28.6042 20.753 26.9581C20.1529 25.6981 19.8477 24.3569 19.8375 22.9241C19.9901 11.1577 37.2214 10.3549 38.5845 21.9284C39.8051 27.2426 35.9601 35.7271 35.9601 35.7271H35.9703Z" fill="url(#paint34_linear_4158_139017)" />
          <path d="M42.7651 39.5878L42.1243 39.5167L43.823 38.9375L44.4943 39.0493L42.7651 39.5878Z" fill="#A399FF" />
          <path d="M29.033 15.6486H28.9822C28.8194 15.6181 28.7075 15.4657 28.7279 15.293C28.7279 15.2727 29.0636 13.1998 27.9344 11.7366C27.8327 11.6045 27.8531 11.4115 27.9853 11.3099C28.1176 11.2083 28.3108 11.2286 28.4125 11.3607C29.7145 13.0372 29.3483 15.3032 29.328 15.3946C29.3077 15.547 29.1754 15.6486 29.0228 15.6486H29.033Z" fill="url(#paint35_linear_4158_139017)" />
          <path d="M27.6292 11.9296C28.1797 11.9296 28.626 11.4837 28.626 10.9338C28.626 10.3838 28.1797 9.93799 27.6292 9.93799C27.0786 9.93799 26.6323 10.3838 26.6323 10.9338C26.6323 11.4837 27.0786 11.9296 27.6292 11.9296Z" fill="url(#paint36_radial_4158_139017)" />
          <path d="M40.7308 48.5197C39.6729 47.9812 38.5743 48.3978 37.4249 49.7695C37.374 49.8305 37.3334 49.8915 37.3028 49.9727L37.2723 49.9524L37.2316 50.8263V50.8466L37.1401 53.0719C37.1299 53.3665 37.2927 53.6104 37.5368 53.7222C37.5673 53.7323 37.5978 53.7527 37.6385 53.7628L38.3709 54.0372L38.2895 53.6815L40.4968 53.0007C40.7715 52.9194 40.9648 52.6654 40.9851 52.3708L41.1275 49.1599C41.1377 48.8855 40.9953 48.6417 40.7511 48.5197L40.7308 48.5197Z" fill="url(#paint37_linear_4158_139017)" />
          <path d="M44.7183 45.1767C44.2504 48.6112 42.4805 51.4258 40.2528 52.6654L40.3443 50.6129L39.1746 50.5621C41.1174 49.9016 42.806 47.7881 43.1925 44.943C43.4468 43.0531 43.1722 41.092 42.4194 39.6898L44.1487 39.1919C44.8404 40.9599 45.0133 43.0124 44.7285 45.1869L44.7183 45.1767Z" fill="#0071F2" />
          <path d="M37.6283 53.3563C36.2144 53.3258 34.9225 52.6552 33.885 51.5375L37.3435 51.4155L37.6283 53.3664V53.3563Z" fill="#1C2BC2" />
          <path d="M37.6386 50.6634L37.5165 53.4678C34.4344 52.8175 32.0847 49.0884 32.2882 44.3229C32.2983 43.9673 32.3288 43.6218 32.3695 43.2763L34.5057 42.5752C34.465 42.7683 34.4345 42.9715 34.4039 43.1747V43.2357C34.3836 43.3779 34.3633 43.5303 34.3531 43.6827V43.7336C34.3429 43.8656 34.3327 44.0079 34.3226 44.1502C34.1903 47.361 35.6754 50.4094 37.6488 50.6532L37.6386 50.6634Z" fill="#0818CC" />
          <path d="M45.4609 45.39C45.0031 48.7736 43.2739 51.5679 41.0869 52.8278L41.209 49.9827C42.5517 48.9565 43.5791 47.1072 43.874 44.9226C44.0673 43.4797 43.9249 42.0673 43.518 40.848C43.518 40.8277 43.4977 40.7972 43.4977 40.7769C43.4468 40.6448 43.4061 40.5127 43.3451 40.3907C43.3451 40.3704 43.3248 40.3399 43.3146 40.3196C43.2332 40.1367 43.1518 39.964 43.0603 39.7912L44.8098 39.334C45.5015 41.102 45.7559 43.2257 45.4609 45.39Z" fill="#0818CC" />
          <path d="M37.5775 51.9744L37.547 52.6755C34.8311 52.0151 32.8374 48.7331 33.0815 44.567C33.1018 44.252 33.1324 43.937 33.173 43.6322L34.3835 43.2461C34.3632 43.3883 34.3428 43.5408 34.3326 43.6932V43.744L33.7935 43.9167C33.7528 44.1911 33.7325 44.4654 33.7121 44.7499C33.4985 48.4181 35.2176 51.314 37.5775 51.9643L37.5775 51.9744Z" fill="#00C79C" />
          <path d="M45.0336 45.4814C44.5758 48.418 43.0399 50.8566 41.1072 52.0353L41.1377 51.2631C42.7347 50.1555 43.996 48.0623 44.3927 45.5729C44.6572 43.8455 44.4843 42.1486 43.9452 40.7464L43.8435 40.7667L43.5078 40.8582C43.5078 40.8379 43.4874 40.8074 43.4874 40.787C43.4366 40.655 43.3959 40.5229 43.3349 40.4009L43.6705 40.3095L44.525 40.0859C45.1251 41.6609 45.3286 43.5509 45.0234 45.4916L45.0336 45.4814Z" fill="#00C79C" />
          <path d="M30.8436 43.3981C31.5557 42.2906 33.3866 42.0162 34.0783 42.2093C33.7935 42.5852 33.4273 46.1619 35.0752 48.3262C34.4954 48.6615 34.4343 50.0536 34.0071 50.6023C33.5799 51.151 32.8679 50.7039 32.8679 50.7039C32.8679 50.7039 31.1386 49.4541 30.7826 46.3956C30.5486 44.3431 30.8436 43.3879 30.8436 43.3879L30.8436 43.3981Z" fill="url(#paint38_linear_4158_139017)" />
          <path d="M31.6166 43.7028C32.1761 42.8899 34.2613 42.3311 34.953 42.5241C34.6682 42.9001 34.5055 47.0458 36.1533 49.2203C35.0853 49.8401 33.3458 50.7952 33.3458 50.7952C33.3458 50.7952 31.6776 48.5903 31.4945 46.5378C31.3114 44.4852 31.6166 43.713 31.6166 43.713V43.7028Z" fill="#06088F" />
          <path d="M33.5088 50.1249C33.0511 49.4441 32.1356 47.9098 32.0034 46.4873C31.861 44.9123 32.0237 44.1401 32.0746 43.9369C32.4408 43.4999 33.5393 43.0935 34.3124 43.0122C34.1293 44.4144 34.3124 47.1884 35.4211 49.0479C34.7396 49.434 33.9564 49.8709 33.4986 50.1249H33.5088Z" fill="#0B3FF2" />
          <path d="M36.1635 49.2202L36.4483 49.962L34.1698 51.2727L33.3357 50.7952L36.1635 49.2202Z" fill="#030447" />
          <path d="M34.1699 51.2727L33.2443 50.9272L32.8679 50.7139L33.3358 50.7952L34.1699 51.2727Z" fill="#17187D" />
          <path d="M43.0031 46.0015L43.0194 46.0096H42.999C42.999 46.0096 43 46.0076 43.0031 46.0015Z" fill="url(#paint39_linear_4158_139017)" />
          <path d="M43.0032 46.0015L42.2973 45.654C42.2973 45.654 42.338 44.5667 42.2566 43.3169C42.2515 43.2489 42.2464 43.1818 42.2413 43.1137C42.2251 42.9064 42.2058 42.6981 42.1752 42.4837C42.1651 42.4024 42.1549 42.3211 42.1437 42.2409C42.0044 41.2217 41.7521 40.2656 41.2903 39.8419C41.8802 39.8012 42.1549 40.0248 42.1549 40.0248C42.1549 40.0248 42.8832 40.5613 43.1304 42.1535C43.1385 42.2012 43.1457 42.251 43.1517 42.3008C43.1619 42.3943 43.1711 42.4908 43.1802 42.5894C43.1894 42.6941 43.1975 42.8007 43.2026 42.9105V43.2052C43.2311 45.4345 43.0337 45.9364 43.0032 46.0015Z" fill="url(#paint40_linear_4158_139017)" />
          <path d="M45.2064 39.2017L45.4912 40.2279C45.4912 40.2279 45.837 41.1526 45.9184 42.758C45.9896 44.3635 45.5929 46.0705 45.5929 46.0705L45.2064 46.7107L43.4263 46.7615L43.0194 45.9994C43.0194 45.9994 43.3958 41.5285 42.1548 40.0145C42.1548 40.0145 42.6329 39.5167 43.5585 39.283C44.4842 39.0492 45.186 39.2017 45.186 39.2017" fill="#06088A" />
          <path d="M42.9584 40.2178C43.7213 41.1831 43.823 44.7089 43.6196 45.8876C44.8097 45.8267 45.2369 45.8165 45.2369 45.8165C45.2369 45.8165 45.5421 44.6175 45.4404 42.8698C45.3387 41.1221 44.8707 39.8316 44.8707 39.8316C44.1892 39.6691 43.5484 39.8012 42.9482 40.2178L42.9584 40.2178Z" fill="#0B3FF2" />
          <path d="M41.3005 39.842C41.3005 39.842 42.0634 39.1816 43.0094 38.958C43.9554 38.7345 45.2066 39.212 45.2066 39.212C43.9656 39.1409 42.9484 39.4051 42.1855 40.0452C41.8193 39.903 41.4837 39.842 41.3107 39.842H41.3005Z" fill="url(#paint41_linear_4158_139017)" />
          <path d="M41.6767 49.2612L41.0969 49.2916L37.9131 50.2773L37.2722 49.9521C38.2182 48.4686 39.4185 47.9809 40.5883 48.4076C40.7816 48.4788 41.2495 48.6921 41.5546 48.9766L41.6869 49.2612L41.6767 49.2612Z" fill="url(#paint42_linear_4158_139017)" />
          <path d="M41.748 49.5457L41.6157 52.6143C41.6055 52.9496 41.3716 53.2443 41.0563 53.3358L38.8184 54.0267C38.2895 54.1893 37.7606 53.7828 37.7911 53.2342L37.9131 50.5313V50.4602C37.9335 50.3078 37.9945 50.1655 38.0962 50.0537C39.2151 48.7227 40.2934 48.3162 41.3207 48.7938C41.6055 48.9259 41.757 49.2259 41.7469 49.5308L41.748 49.5457Z" fill="#1D2BC2" />
          <path d="M41.748 49.5457L41.6158 52.594C41.5751 52.8988 41.3615 53.1529 41.0665 53.2443L38.8287 53.9353C38.2997 54.0978 37.7708 53.6914 37.8013 53.1427L37.9234 50.4602C37.9437 50.3078 38.0047 50.1655 38.1065 50.0537C39.2254 48.7227 40.3036 48.3162 41.331 48.7938C41.6158 48.9259 41.7569 49.2321 41.7468 49.5369L41.748 49.5457Z" fill="#06088A" />
          <path d="M38.9406 53.61C38.5541 53.5897 38.2286 53.3154 38.137 52.9293C38.1167 52.8581 38.1167 52.7768 38.1167 52.6955L38.1981 50.765C38.1981 50.5719 38.2795 50.389 38.4015 50.2366C39.0627 49.444 39.7232 49.0897 40.3749 49.0884C41.1925 49.0867 41.5243 49.7082 41.504 50.0638L41.4124 52.2586C41.3921 52.6244 41.148 52.9496 40.8021 53.0613L39.2255 53.5592C39.1339 53.5897 39.0423 53.61 38.9406 53.61Z" stroke="#00C79C" strokeWidth="0.048" strokeMiterlimit="10" />
          <path d="M41.2598 50.0638L41.1682 52.2586C41.158 52.5025 40.9953 52.7057 40.7715 52.7768L39.1643 53.2747C38.8388 53.3763 38.5133 53.1731 38.4421 52.8581C38.4319 52.8073 38.4218 52.7565 38.4319 52.7057L38.5133 50.7751C38.5133 50.6532 38.5642 50.5312 38.6456 50.4296C39.2864 49.6676 39.9069 49.3322 40.4969 49.4034C40.6494 49.4237 40.802 49.4643 40.9546 49.5355C41.158 49.6269 41.2699 49.8403 41.2598 50.0638Z" fill="url(#paint43_linear_4158_139017)" />
          <path d="M40.5375 49.6472L40.446 51.842C40.4358 52.0859 40.2731 52.2891 40.0493 52.3602L38.4421 52.8581C38.4319 52.8073 38.4218 52.7565 38.4319 52.7057L38.5133 50.7751C38.5133 50.6532 38.5642 50.5312 38.6456 50.4296C39.2864 49.6676 39.9069 49.3322 40.4969 49.4034C40.5274 49.4847 40.5477 49.5659 40.5375 49.6472Z" fill="#0B3FF2" />
          <path d="M42.2974 45.644L43.0196 46.0098L43.4265 46.7719L42.7449 46.4061L42.2974 45.644Z" fill="#0818CC" />
          <path d="M34.9532 42.5246L34.0784 42.2097C34.0784 42.2097 33.6613 42.0471 32.7051 42.2605C31.5252 42.5246 30.925 43.2867 30.925 43.2867L31.6269 43.7033C31.6269 43.7033 31.9829 43.1953 33.1832 42.8092C34.3835 42.4332 34.9633 42.5247 34.9633 42.5247L34.9532 42.5246Z" fill="url(#paint44_linear_4158_139017)" />
          <path d="M23.3264 38.1248C24.4148 40.3501 24.0893 42.7379 22.594 43.4593C22.5533 43.4797 21.4649 44.008 21.4242 44.0284C19.9493 44.6279 18.9932 42.9107 17.9352 40.7464C16.9079 38.643 16.1348 36.8648 17.4368 36.0215C17.508 35.9808 18.5964 35.4525 18.6677 35.422C20.1528 34.7005 22.238 35.9097 23.3264 38.135L23.3264 38.1248Z" fill="url(#paint45_linear_4158_139017)" />
          <path d="M21.465 44.0078C19.9799 44.7292 17.8946 43.5201 16.8062 41.2948C15.728 39.1 16.0433 36.7325 17.4877 35.9907C17.5081 35.9907 17.5183 35.9704 17.5386 35.9704C17.9658 35.7672 18.4337 35.7164 18.9118 35.7977C20.1223 36.0111 21.4141 37.0881 22.1974 38.6733C23.2858 40.8985 22.9603 43.2864 21.465 44.0078Z" fill="url(#paint46_radial_4158_139017)" />
          <path d="M21.1902 43.4695C20.2239 44.2519 18.5353 43.7845 17.4164 42.4026C16.979 41.864 16.6942 41.2645 16.562 40.6955C16.4603 39.9131 16.6535 39.2018 17.1825 38.7751C18.1488 37.9927 19.8374 38.4702 20.9563 39.842C21.3937 40.3805 21.6785 40.98 21.8107 41.549C21.9125 42.3314 21.7192 43.0427 21.1902 43.4695Z" fill="url(#paint47_radial_4158_139017)" />
          <path d="M21.1395 43.51C20.1732 44.2924 18.4846 43.825 17.3657 42.4431C16.9283 41.9046 16.6435 41.3051 16.5113 40.736V40.7056C16.4197 39.9333 16.6232 39.2424 17.1318 38.8156C18.0981 38.0332 19.7867 38.5108 20.9056 39.8825C21.2921 40.3601 21.5668 40.8783 21.7092 41.3965C21.7295 41.4575 21.7397 41.5286 21.76 41.5896C21.8617 42.372 21.6685 43.0832 21.1395 43.51Z" fill="url(#paint48_radial_4158_139017)" />
          <path d="M21.0887 43.2865C20.1223 44.0689 18.4338 43.5913 17.3148 42.2195C16.9283 41.742 16.6537 41.2136 16.5113 40.7056C16.4197 39.9333 16.6232 39.2424 17.1318 38.8156C18.0981 38.0332 19.7867 38.5108 20.9056 39.8825C21.2921 40.3601 21.5668 40.8783 21.7092 41.3965C21.8109 42.1586 21.6074 42.8597 21.0887 43.2865Z" fill="url(#paint49_radial_4158_139017)" />
          <path d="M20.2444 42.4025C20.2241 42.7074 20.102 42.9716 19.8884 43.1544C19.3493 43.6015 18.3931 43.3272 17.7625 42.5549C17.1318 41.7827 17.0606 40.7971 17.5997 40.35C17.6607 40.3094 17.7218 40.2687 17.7828 40.2382C17.854 40.2077 17.9354 40.1773 18.0168 40.1569C18.5559 40.0553 19.2374 40.35 19.7257 40.9495C20.041 41.3356 20.2139 41.7827 20.2444 42.179L20.2444 42.4025Z" fill="url(#paint50_linear_4158_139017)" />
          <path d="M20.2953 42.3415C20.275 42.5854 20.1936 42.8089 20.0512 42.9816C20.1631 42.8191 20.2343 42.626 20.2444 42.4126V42.1891C20.2139 41.7826 20.041 41.3457 19.7257 40.9596C19.2374 40.3601 18.5457 40.0654 18.0168 40.167C17.9354 40.1874 17.854 40.2077 17.7828 40.2483C17.7218 40.2788 17.6607 40.3195 17.5997 40.3601C17.559 40.3906 17.5285 40.4312 17.4878 40.4617C17.5285 40.4007 17.5895 40.3398 17.6506 40.289C17.7116 40.2382 17.7726 40.2077 17.8337 40.1772C17.9049 40.1467 17.9862 40.1162 18.0676 40.0959C18.6067 39.9943 19.2883 40.289 19.7765 40.8885C20.0919 41.2746 20.2648 41.7217 20.2953 42.118V42.3415Z" fill="url(#paint51_linear_4158_139017)" />
          <path d="M20.2444 42.1891C19.6849 42.4533 18.8711 42.1688 18.3015 41.4778C17.9862 41.0917 17.8031 40.6446 17.7827 40.2484C18.3422 39.9842 19.1559 40.2687 19.7256 40.9596C20.0409 41.3458 20.224 41.7928 20.2444 42.1891Z" fill="#0A0B26" />
          <path d="M20.0512 42.9818C20.0003 43.0428 19.9495 43.1038 19.8884 43.1546C19.3493 43.6017 18.3932 43.3273 17.7625 42.5551C17.1725 41.8337 17.0708 40.9395 17.4879 40.4619C17.1318 40.9598 17.2539 41.8032 17.8134 42.4941C18.444 43.2664 19.4002 43.5305 19.9393 43.0936C19.98 43.0631 20.0207 43.0225 20.0512 42.9818Z" fill="url(#paint52_linear_4158_139017)" />
          <path d="M20.2443 42.3212V42.4025C19.9798 42.5854 19.6645 42.6972 19.3186 42.6972C18.4336 42.6972 17.7114 41.9758 17.7114 41.0918C17.7114 40.2078 17.7623 40.6244 17.8538 40.4211C17.8945 40.3297 17.9454 40.2382 18.0064 40.1569C18.5455 40.0553 19.227 40.35 19.7153 40.9495C20.0306 41.3356 20.2036 41.7827 20.2341 42.179V42.3212L20.2443 42.3212Z" fill="url(#paint53_radial_4158_139017)" />
          <path d="M20.2443 42.3215V42.4028C19.9798 42.5857 19.6645 42.6974 19.3186 42.6974C18.4336 42.6974 17.7114 41.976 17.7114 41.092C17.7114 40.208 17.7623 40.6246 17.8538 40.4214C18.4133 40.3502 19.105 40.6348 19.6339 41.2139C19.9493 41.5594 20.1527 41.9455 20.2443 42.3215Z" fill="url(#paint54_radial_4158_139017)" />
          <path d="M22.5637 50.0945L21.933 48.4993C21.933 48.4993 21.6787 48.5094 21.3125 48.4586C20.57 48.3468 19.3392 47.9404 18.8306 46.528C18.2406 44.8819 19.2273 42.8192 19.4918 42.3214C19.5833 42.1791 19.6342 42.0165 19.6342 41.8336C19.6342 41.3459 19.2375 40.9395 18.739 40.9395C18.2406 40.9395 18.5661 40.9496 18.4847 40.9801C18.3016 41.0309 18.1389 41.1528 18.0168 41.3053C17.9965 41.3459 17.9659 41.3764 17.9456 41.417C17.6506 41.9149 16.552 44.0182 17.1115 46.8735C17.5387 49.048 19.2883 49.8507 20.7226 50.1555C21.6279 50.3486 22.4111 50.3282 22.6451 50.3282L22.5535 50.1047L22.5637 50.0945Z" fill="url(#paint55_linear_4158_139017)" />
          <path d="M22.5637 50.0945L21.933 48.4993C21.933 48.4993 21.6787 48.5094 21.3125 48.4586C20.57 48.3468 19.3392 47.9404 18.8306 46.528C18.2406 44.8819 19.2273 42.8192 19.4918 42.3214C19.5833 42.1791 19.6342 42.0165 19.6342 41.8336C19.6342 41.3459 19.2375 40.9395 18.739 40.9395C18.2406 40.9395 18.5661 40.9496 18.4847 40.9801C18.3016 41.0309 18.1389 41.1528 18.0168 41.3053C17.9965 41.3459 17.9659 41.3764 17.9456 41.417C17.6506 41.9149 16.552 44.0182 17.1115 46.8735C17.5387 49.048 19.2883 49.8507 20.7226 50.1555C21.6279 50.3486 22.4111 50.3282 22.6451 50.3282L22.5535 50.1047L22.5637 50.0945Z" fill="url(#paint56_linear_4158_139017)" />
          <path d="M22.5637 50.0945L21.933 48.4993C21.933 48.4993 21.6787 48.5094 21.3125 48.4586C20.57 48.3468 19.3392 47.9404 18.8306 46.528C18.2406 44.8819 19.2273 42.8192 19.4918 42.3214C19.5833 42.1791 19.6342 42.0165 19.6342 41.8336C19.6342 41.3459 19.2375 40.9395 18.739 40.9395C18.2406 40.9395 18.5661 40.9496 18.4847 40.9801C18.3016 41.0309 18.1389 41.1528 18.0168 41.3053C17.9965 41.3459 17.9659 41.3764 17.9456 41.417C17.6506 41.9149 16.552 44.0182 17.1115 46.8735C17.5387 49.048 19.2883 49.8507 20.7226 50.1555C21.6279 50.3486 22.4111 50.3282 22.6451 50.3282L22.5535 50.1047L22.5637 50.0945Z" fill="url(#paint57_linear_4158_139017)" />
          <path d="M22.5637 50.0945L21.933 48.4993C21.933 48.4993 21.6787 48.5094 21.3125 48.4586C20.57 48.3468 19.3392 47.9404 18.8306 46.528C18.2406 44.8819 19.2273 42.8192 19.4918 42.3214C19.5833 42.1791 19.6342 42.0165 19.6342 41.8336C19.6342 41.3459 19.2375 40.9395 18.739 40.9395C18.2406 40.9395 18.5661 40.9496 18.4847 40.9801C18.3016 41.0309 18.1389 41.1528 18.0168 41.3053C17.9965 41.3459 17.9659 41.3764 17.9456 41.417C17.6506 41.9149 16.552 44.0182 17.1115 46.8735C17.5387 49.048 19.2883 49.8507 20.7226 50.1555C21.6279 50.3486 22.4111 50.3282 22.6451 50.3282L22.5535 50.1047L22.5637 50.0945Z" fill="url(#paint58_radial_4158_139017)" />
          <path d="M22.5637 50.0945L21.933 48.4993C21.933 48.4993 21.6787 48.5094 21.3125 48.4586C20.57 48.3468 19.3392 47.9404 18.8306 46.528C18.2406 44.8819 19.2273 42.8192 19.4918 42.3214C19.5833 42.1791 19.6342 42.0165 19.6342 41.8336C19.6342 41.3459 19.2375 40.9395 18.739 40.9395C18.2406 40.9395 18.5661 40.9496 18.4847 40.9801C18.3016 41.0309 18.1389 41.1528 18.0168 41.3053C17.9965 41.3459 17.9659 41.3764 17.9456 41.417C17.6506 41.9149 16.552 44.0182 17.1115 46.8735C17.5387 49.048 19.2883 49.8507 20.7226 50.1555C21.6279 50.3486 22.4111 50.3282 22.6451 50.3282L22.5535 50.1047L22.5637 50.0945Z" fill="url(#paint59_radial_4158_139017)" />
          <path d="M22.645 50.3281C22.411 50.3281 21.638 50.3485 20.7225 50.1554V50.1046C20.6208 49.4746 20.8547 48.8548 21.3125 48.4585C21.6787 48.5093 21.933 48.4991 21.933 48.4991L22.6552 50.318L22.645 50.3281Z" fill="#121547" />
          <path opacity="0.9" d="M32.9899 48.3466C32.7458 46.67 31.9523 45.9893 31.9523 45.9893L26.1746 47.1375H26.1034C25.9712 47.2695 25.8593 47.422 25.7678 47.615L25.605 47.6557H25.544C25.544 47.6658 23.8859 48.0418 23.8859 48.0418C23.5909 47.8386 23.2349 47.7065 22.8484 47.7065C21.8617 47.7065 21.0581 48.5092 21.0581 49.4948C21.0581 50.4804 21.8649 51.3623 22.8484 51.2832C23.5575 51.2261 23.4892 51.1917 23.7537 51.0393L25.2591 51.5372L25.7169 51.6896C25.8288 52.0249 25.961 52.2586 26.0932 52.3399L31.9523 53.4576C31.9523 53.4576 32.9187 52.7159 33.0611 49.7184C33.0916 49.0884 33.0407 48.6514 33 48.3365L32.9899 48.3466Z" fill="#59FFD9" />
          <path d="M36.9265 49.8303C36.9468 49.7389 36.9468 49.6373 36.9265 49.5356C36.8451 49.2511 36.5603 49.0886 36.2958 49.1699C36.2144 49.1902 36.1534 49.2308 36.1026 49.2918C36.072 49.3223 36.0415 49.3629 36.0212 49.3934C35.9703 49.4645 35.8381 49.6982 35.7262 49.8405C35.4922 50.125 35.0752 50.4705 35.0752 50.4705C35.0752 50.4705 35.299 50.5416 36.1127 51.4662C36.3772 51.1716 36.7536 50.2774 36.8756 49.9624C36.896 49.9218 36.9163 49.8811 36.9265 49.8303Z" fill="url(#paint60_linear_4158_139017)" />
          <path d="M35.899 51.4102C36.145 51.233 36.1527 50.8236 35.9161 50.4959C35.6795 50.1682 35.2882 50.0462 35.0422 50.2235C34.7961 50.4007 34.7885 50.8101 35.0251 51.1378C35.2617 51.4655 35.653 51.5875 35.899 51.4102Z" fill="url(#paint61_linear_4158_139017)" />
          <path d="M35.4006 51.7101C35.7208 51.7101 35.9804 51.4508 35.9804 51.1309C35.9804 50.8111 35.7208 50.5518 35.4006 50.5518C35.0804 50.5518 34.8208 50.8111 34.8208 51.1309C34.8208 51.4508 35.0804 51.7101 35.4006 51.7101Z" fill="url(#paint62_radial_4158_139017)" />
          <path d="M32.0337 51.019L34.8513 50.4398C35.4108 50.328 36.0821 51.3238 35.4718 51.9132C35.0649 52.2383 32.2066 52.9394 32.2066 52.9394L32.0337 51.0088V51.019Z" fill="url(#paint63_linear_4158_139017)" />
          <path d="M22.8281 51.3545C23.8169 51.3545 24.6184 50.5539 24.6184 49.5662C24.6184 48.5785 23.8169 47.7778 22.8281 47.7778C21.8394 47.7778 21.0378 48.5785 21.0378 49.5662C21.0378 50.5539 21.8394 51.3545 22.8281 51.3545Z" fill="url(#paint64_radial_4158_139017)" />
          <path d="M27.2225 47.3407V52.2688L25.2288 51.6084L23.0723 50.8971C22.6146 50.7955 22.2585 50.2468 22.2585 49.5863C22.2585 48.9258 22.6146 48.3873 23.0723 48.2755L25.5746 47.7065L27.2225 47.3306V47.3407Z" fill="url(#paint65_linear_4158_139017)" />
          <path d="M27.2225 47.3407V52.2688L25.2288 51.6084L23.0723 50.8971C22.6044 50.7955 22.2585 50.2468 22.2585 49.5863C22.2585 48.9258 22.2789 49.2408 22.3297 49.0884C22.4518 48.6718 22.7366 48.3568 23.0723 48.2755L25.5136 47.7167H25.5746C25.5746 47.7065 27.2225 47.3306 27.2225 47.3306V47.3407Z" fill="url(#paint66_linear_4158_139017)" />
          <path opacity="0.9" d="M36.8551 45.9589C36.906 45.8573 36.9365 45.7456 36.9365 45.6236C36.9263 45.2883 36.652 45.0193 36.3262 45.0343C36.1656 45.0417 36.1431 45.0648 36.0617 45.1156C36.021 45.1461 35.9803 45.1765 35.9396 45.207C35.8583 45.268 35.7362 45.3594 35.5734 45.4814C35.2276 45.7354 34.6274 46.0504 34.6274 46.0504C34.6274 46.0504 34.8716 46.152 35.5327 47.4221C35.9193 47.1681 36.5194 46.406 36.7636 46.1012C36.8042 46.0606 36.8348 46.0199 36.8653 45.9691L36.8551 45.9589Z" fill="#87FFEB" />
          <path d="M36.8449 46.0098C36.8958 45.9081 36.9263 45.7964 36.9263 45.6744C36.9161 45.3391 36.6405 45.0521 36.316 45.0851C36.1132 45.1057 36.1329 45.1156 36.0515 45.1664C36.0108 45.1969 35.9701 45.2274 35.9295 45.2578C35.8481 45.3188 35.726 45.4103 35.5633 45.5322C35.2174 45.7862 34.6274 46.0504 34.6274 46.0504C34.6274 46.0504 34.8614 46.2028 35.5124 47.4729C35.8989 47.2189 36.4991 46.4568 36.7432 46.152C36.7839 46.1114 36.8144 46.0707 36.8449 46.0199V46.0098Z" fill="url(#paint67_linear_4158_139017)" />
          <path d="M35.3649 47.6058C35.6978 47.4701 35.8231 47.0063 35.6448 46.5698C35.4665 46.1334 35.0521 45.8896 34.7192 46.0253C34.3863 46.161 34.261 46.6248 34.4393 47.0613C34.6176 47.4977 35.032 47.7415 35.3649 47.6058Z" fill="url(#paint68_linear_4158_139017)" />
          <path d="M34.8718 47.4728C35.192 47.4728 35.4516 47.2135 35.4516 46.8936C35.4516 46.5738 35.192 46.3145 34.8718 46.3145C34.5516 46.3145 34.292 46.5738 34.292 46.8936C34.292 47.2135 34.5516 47.4728 34.8718 47.4728Z" fill="url(#paint69_radial_4158_139017)" />
          <path d="M31.9421 46.6295L34.2105 46.1519C34.6479 45.9588 35.4515 47.2798 34.831 47.9402C34.4139 48.2959 32.5016 48.6921 32.5016 48.6921L31.9421 46.6295Z" fill="url(#paint70_linear_4158_139017)" />
          <path d="M27.2224 47.3408V52.2689L25.2287 51.6085C24.9642 50.3891 25.1168 48.489 25.7373 47.6761L27.2326 47.3408H27.2224Z" fill="url(#paint71_linear_4158_139017)" />
          <path d="M37.7197 48.1741C37.7807 48.0827 37.8112 47.9607 37.8112 47.8388C37.8112 47.4933 37.5466 47.2094 37.2314 47.219C37.0537 47.2244 37.0483 47.2393 36.9669 47.2901C36.9263 47.3104 36.8754 47.3409 36.8449 47.3816C36.7635 47.4425 36.5397 47.6661 36.3668 47.7778C36.0108 48.0217 35.4106 48.2554 35.4106 48.2554C35.4106 48.2554 35.6344 48.4078 36.2447 49.7084C36.6414 49.4747 37.3738 48.6009 37.6281 48.2961C37.6688 48.2554 37.6993 48.2148 37.7299 48.164L37.7197 48.1741Z" fill="url(#paint72_linear_4158_139017)" />
          <path d="M36.0624 49.8094C36.4003 49.6866 36.5433 49.2279 36.3819 48.785C36.2205 48.342 35.8158 48.0825 35.4779 48.2053C35.1401 48.3282 34.9971 48.7868 35.1584 49.2298C35.3198 49.6727 35.7246 49.9323 36.0624 49.8094Z" fill="url(#paint73_linear_4158_139017)" />
          <path d="M35.6142 49.7086C35.9344 49.7086 36.194 49.4493 36.194 49.1295C36.194 48.8096 35.9344 48.5503 35.6142 48.5503C35.294 48.5503 35.0344 48.8096 35.0344 49.1295C35.0344 49.4493 35.294 49.7086 35.6142 49.7086Z" fill="url(#paint74_radial_4158_139017)" />
          <path d="M32.2676 48.8242L35.0751 48.3467C35.5226 48.1739 36.2754 49.5253 35.6243 50.1553C35.1971 50.4906 32.5015 50.9377 32.5015 50.9377L32.2676 48.8242Z" fill="url(#paint75_linear_4158_139017)" />
          <path d="M32.0236 53.4376L26.1645 52.3199C25.5033 51.9541 25.0558 48.0929 26.1645 47.1276L32.0134 45.9692C32.0134 45.9692 32.9289 47.0768 32.878 49.7695C32.7458 52.7771 32.0134 53.4478 32.0134 53.4478L32.0236 53.4376Z" fill="url(#paint76_linear_4158_139017)" />
          <path d="M32.7762 49.8608C32.7355 50.7346 32.644 51.4154 32.5423 51.9336C32.4812 52.2486 32.41 52.5026 32.3388 52.716C32.1252 53.346 31.9218 53.5391 31.9218 53.5391L26.0627 52.4214C25.4015 52.0556 24.9539 48.1944 26.0627 47.2291H26.1339C26.1339 47.2189 31.9116 46.0605 31.9116 46.0605C31.9421 46.1012 32.5117 46.8226 32.705 48.4484C32.7559 48.8548 32.7864 49.3222 32.766 49.8608H32.7762Z" fill="url(#paint77_linear_4158_139017)" />
          <path d="M33.0205 49.7998C32.8882 52.8075 31.9118 53.5391 31.9118 53.5391L26.0527 52.4214C25.3915 52.0556 24.9439 48.1944 26.0527 47.2291H26.1239C26.1239 47.2189 31.9016 46.0605 31.9016 46.0605C31.9016 46.0605 32.695 46.7312 32.9391 48.4179C33.0001 48.8243 33.0205 49.2816 33.0001 49.7998H33.0205Z" fill="url(#paint78_linear_4158_139017)" />
          <path d="M32.7153 48.4484C32.0541 48.55 29.9994 48.7735 28.3922 48.8243C25.5237 48.9158 26.0222 47.6253 26.1442 47.2087L31.9219 46.0605C31.9524 46.1012 32.522 46.8226 32.7153 48.4484Z" fill="url(#paint79_linear_4158_139017)" />
          <path d="M25.5236 47.727C25.0964 48.2553 25.0658 49.0072 25.0658 49.0072C25.0658 49.0072 23.3976 49.2511 22.3296 49.0885C22.4516 48.6719 22.7365 48.3569 23.0722 48.2757L25.5134 47.7168L25.5236 47.727Z" fill="url(#paint80_linear_4158_139017)" />
          <path d="M32.5423 51.9338C32.4812 52.2488 32.41 52.5029 32.3388 52.7162C29.3483 52.3708 27.2427 51.9237 27.5275 51.7509C27.8021 51.5782 31.5352 51.8221 32.5423 51.9338Z" fill="url(#paint81_linear_4158_139017)" />
          <path d="M45.8066 41.1528C46.1774 41.1528 46.478 40.8525 46.478 40.4822C46.478 40.1118 46.1774 39.8115 45.8066 39.8115C45.4358 39.8115 45.1353 40.1118 45.1353 40.4822C45.1353 40.8525 45.4358 41.1528 45.8066 41.1528Z" fill="url(#paint82_radial_4158_139017)" />
          <path d="M46.2236 43.3476C46.5944 43.3476 46.895 43.0474 46.895 42.677C46.895 42.3066 46.5944 42.0063 46.2236 42.0063C45.8528 42.0063 45.5522 42.3066 45.5522 42.677C45.5522 43.0474 45.8528 43.3476 46.2236 43.3476Z" fill="url(#paint83_radial_4158_139017)" />
          <path d="M46.0101 45.1255C46.3191 45.1255 46.5696 44.8753 46.5696 44.5667C46.5696 44.258 46.3191 44.0078 46.0101 44.0078C45.7012 44.0078 45.4507 44.258 45.4507 44.5667C45.4507 44.8753 45.7012 45.1255 46.0101 45.1255Z" fill="url(#paint84_radial_4158_139017)" />
          <path d="M46.7832 42.6263C46.7425 42.1792 46.3967 41.8337 46.0203 41.8642C45.9796 41.8642 45.9389 41.8743 45.9084 41.8845L43.9961 42.4738C43.7824 42.5348 43.64 42.7482 43.6604 42.9921C43.6807 43.2562 43.8943 43.4595 44.1385 43.4493L46.1322 43.5001H46.1729C46.5594 43.4696 46.834 43.0835 46.7934 42.6364L46.7832 42.6263Z" fill="url(#paint85_linear_4158_139017)" />
          <path d="M46.3537 40.2304C46.1604 39.8341 45.7457 39.6287 45.41 39.7913C45.41 39.7913 45.3897 39.7913 45.3795 39.8015L45.3591 39.8116C45.3591 39.8116 45.349 39.8116 45.3388 39.8218L43.7316 40.8481C43.5485 40.97 43.4773 41.2342 43.579 41.478C43.6909 41.7321 43.9554 41.8743 44.1792 41.793H44.2097C44.2097 41.793 46.0915 41.2545 46.1017 41.2545C46.4374 41.0919 46.5492 40.6347 46.356 40.2384L46.3537 40.2304Z" fill="url(#paint86_linear_4158_139017)" />
          <path d="M45.8169 43.8965L44.3623 43.9371C44.108 43.9371 43.9045 44.1403 43.9045 44.3944C43.9045 44.6484 44.108 44.8516 44.3623 44.8516L45.6948 45.1361C45.7253 45.1361 45.7559 45.1463 45.7864 45.1463H45.8067C46.1119 45.1463 46.3458 44.8719 46.3458 44.5265C46.3458 44.181 46.1017 43.9066 45.8067 43.9066L45.8169 43.8965Z" fill="url(#paint87_linear_4158_139017)" />
          <path d="M42.3073 42.6059C42.6781 42.6059 42.9787 42.3057 42.9787 41.9353C42.9787 41.5649 42.6781 41.2646 42.3073 41.2646C41.9366 41.2646 41.636 41.5649 41.636 41.9353C41.636 42.3057 41.9366 42.6059 42.3073 42.6059Z" fill="url(#paint88_radial_4158_139017)" />
          <path d="M43.1801 42.5895C43.1486 42.626 43.1119 42.6616 43.0702 42.6972C42.8454 42.8872 42.4863 43.0559 42.2412 43.1138C42.2249 42.9065 42.2056 42.6982 42.1751 42.4838C42.1649 42.4025 42.1548 42.3212 42.1436 42.2409C42.3806 42.1221 42.7091 42.0032 42.9889 42.0875C43.0356 42.1027 43.0834 42.1251 43.1302 42.1536C43.1445 42.1607 43.1577 42.1698 43.172 42.179C43.3337 42.2999 43.3021 42.4472 43.1801 42.5895Z" fill="#06088A" />
          <path d="M42.0023 41.1196C42.3075 40.9977 42.7245 41.3025 43.0704 41.4143C43.3247 41.4956 43.6095 42.1662 42.8059 42.4913C42.5211 42.6031 42.3176 42.6438 42.1244 42.6031C41.453 42.471 41.3106 41.4041 42.0125 41.1196H42.0023Z" fill="url(#paint89_radial_4158_139017)" />
          <path d="M26.2459 41.1529C25.778 41.0614 25.3305 40.9497 24.9032 40.8176L24.7405 39.9844C25.1677 40.1266 25.6356 40.2384 26.1137 40.34L26.2459 41.1529Z" fill="url(#paint90_linear_4158_139017)" />
          <path d="M29.7856 36.1233C29.7652 36.1436 29.7449 36.1639 29.7245 36.1843C29.4804 36.3976 29.2465 36.0826 29.1346 35.8794C29.0837 35.788 29.0532 35.7168 29.0532 35.7168C29.0532 35.7168 29.694 34.1317 28.4734 33.5525C27.2527 32.9734 27.6291 31.5407 28.1784 30.8497C27.7715 30.2705 26.7441 28.5838 26.7441 28.5838C26.7441 28.5838 31.0469 28.4314 30.9859 29.4475C30.9248 30.4534 30.1111 31.4086 30.1111 31.4086C30.1111 31.4086 29.7856 32.0589 29.4702 33.075C30.7824 34.1114 30.1721 35.6762 29.7957 36.1233H29.7856Z" fill="url(#paint91_linear_4158_139017)" />
          <path d="M27.2326 29.1527C27.5072 29.803 28.1277 30.7886 28.2701 30.8801C28.4125 30.9715 28.911 30.6972 29.328 31.1341C29.745 31.571 30.3859 30.6464 30.4266 30.1993C30.4673 29.7522 30.3554 29.5083 30.3554 29.5083L27.0698 28.604C27.0698 28.604 27.1003 28.8275 27.2326 29.1527Z" fill="url(#paint92_linear_4158_139017)" />
          <path d="M37.5673 23.8585C36.8756 21.9279 35.6041 22.7103 35.2176 22.9643C35.5024 22.751 36.0517 22.1819 35.7567 21.3284C35.4007 20.292 34.5361 19.0219 33.3256 19.8347C33.3765 19.1743 33.2137 18.2293 31.22 17.8737C29.6027 17.579 28.8906 18.2395 28.7685 18.5646C28.4023 18.3106 27.8225 18.1277 26.9274 18.1175C24.0691 18.0871 24.2216 20.3428 24.2216 20.3428C24.2216 20.3428 23.2044 20.0583 22.3296 20.607C21.3226 21.237 21.0887 22.7611 21.8312 23.574C20.3156 24.9864 21.0175 27.8823 23.296 28.0652C23.9368 29.4674 26.5612 28.9289 27.4055 28.5021C28.1785 29.4268 29.2364 29.6198 30.7215 29.376C31.6777 29.2235 32.3897 28.7765 32.7966 28.1262C33.3154 28.4107 34.831 29.1016 36.0822 28.5631C37.7504 27.8518 38.3607 26.0736 37.5571 23.8484L37.5673 23.8585Z" fill="#4A192A" />
          <path d="M35.7568 28.736C34.6684 29.2034 33.641 28.8986 33.0409 28.6242C32.7154 28.4718 32.5323 28.3397 32.5323 28.3397L26.7749 23.249L28.7076 19.0322C28.7076 19.0322 28.7788 18.8798 28.9721 18.707C29.2976 18.4225 29.9689 18.0872 31.2099 18.3108C33.2036 18.6664 33.0409 19.5809 33.1222 20.221C34.3937 19.3878 35.2278 20.536 35.594 21.5725C35.889 22.4361 35.0854 23.1271 34.8108 23.3303C35.1973 23.0763 36.6926 22.2228 37.3843 24.1534C38.1777 26.3786 37.425 28.0146 35.7568 28.7258L35.7568 28.736Z" fill="url(#paint93_radial_4158_139017)" />
          <path d="M32.8982 28.1262C33.1729 28.807 33.7934 28.8984 34.5766 28.9493C35.3599 29.0001 36.4279 28.492 36.5703 27.8722C36.7128 27.2524 35.9193 26.724 35.3395 26.9882C34.7597 27.2524 34.2511 27.3032 34.0273 27.1203C33.8036 26.9374 32.5931 27.3743 32.8881 28.1364L32.8982 28.1262Z" fill="url(#paint94_linear_4158_139017)" />
          <path d="M33.8443 27.2626C33.7832 27.8316 33.4985 28.5124 33.0509 28.6242C32.7254 28.4718 32.5423 28.3397 32.5423 28.3397L26.7849 23.249L28.7176 19.0322C28.7176 19.0322 28.7888 18.8798 28.9821 18.707C30.0196 19.2456 29.8264 19.9975 29.8264 19.9975C29.8264 19.9975 30.1722 19.7536 31.342 20.3125C32.3083 20.7697 33.3458 22.4565 32.9186 23.9501C32.6948 25.0374 33.9663 25.9722 33.8443 27.2525V27.2626Z" fill="#99437A" />
          <path d="M33.2136 27.2523C33.0814 28.5834 32.2066 29.508 30.7215 29.7519C29.2363 29.9856 28.1785 29.8027 27.4054 28.878C26.5611 29.3048 24.3029 29.7519 23.6621 28.3497C21.3937 28.1668 20.3053 25.2709 22.177 23.767C22.177 23.767 22.1973 23.7569 22.2075 23.7467C21.8311 23.3301 21.6887 22.7916 21.7294 22.2835C21.7701 21.7958 21.9837 21.3182 22.3397 20.9829C23.0823 20.2717 24.2317 20.7187 24.2317 20.7187C24.2317 20.7187 24.0792 18.463 26.9375 18.4935C29.8162 18.524 29.3788 20.3733 29.3788 20.3733C29.3788 20.3733 29.7755 19.8652 30.9452 20.4241C31.9116 20.8813 32.9491 22.568 32.5219 24.0617C32.4304 24.3665 32.2879 24.6714 32.054 24.9559C32.0743 24.9559 32.0947 24.9559 32.1252 24.9762C32.6745 25.1388 33.356 25.9822 33.2238 27.2624L33.2136 27.2523Z" fill="url(#paint95_linear_4158_139017)" />
          <path d="M34.4037 32.5161C34.4037 32.7498 34.2002 32.9429 33.9561 32.9429C33.712 32.9429 33.5085 32.7498 33.5085 32.5161C33.5085 32.2824 33.5797 32.2418 33.6916 32.1706C33.7628 32.1198 33.8544 32.0894 33.9561 32.0894C34.0578 32.0894 34.0172 32.0894 34.0477 32.0894C34.2511 32.13 34.3935 32.3027 34.3935 32.506L34.4037 32.5161Z" fill="url(#paint96_linear_4158_139017)" />
          <path d="M34.4037 32.5161C34.4037 32.7499 34.2002 32.9429 33.9561 32.9429C33.712 32.9429 33.5085 32.7499 33.5085 32.5161C33.5085 32.2824 33.5085 32.4552 33.5085 32.4247C33.5492 32.6178 33.7323 32.76 33.9459 32.76C34.1596 32.76 34.3325 32.6178 34.3833 32.4247V32.5161H34.4037Z" fill="url(#paint97_radial_4158_139017)" />
          <path d="M34.1198 32.2161C34.1244 32.1999 34.1107 32.1818 34.0891 32.1756C34.0674 32.1695 34.0462 32.1776 34.0416 32.1938C34.0369 32.21 34.0507 32.2281 34.0723 32.2343C34.0939 32.2404 34.1152 32.2323 34.1198 32.2161Z" fill="#65F4E8" />
          <path d="M34.3936 32.4146C34.3427 32.2825 34.2206 32.1504 34.0681 32.1199H33.9765C33.8748 32.1199 33.8036 32.1504 33.7324 32.2114C33.6205 32.2927 33.5391 32.4349 33.5391 32.5873C33.5391 32.7398 33.5391 32.6585 33.5493 32.6889C33.529 32.6381 33.5188 32.5873 33.5188 32.5264C33.5188 32.3841 33.59 32.252 33.7019 32.1809C33.7731 32.1301 33.8647 32.0996 33.9664 32.0996C34.0681 32.0996 34.0274 32.0996 34.0579 32.0996C34.2207 32.1301 34.3528 32.2622 34.3936 32.4146Z" fill="url(#paint98_radial_4158_139017)" />
          <path d="M33.0204 30.6669C33.0204 30.7888 32.9187 30.8904 32.7865 30.8904C32.6542 30.8904 32.5525 30.7888 32.5525 30.6669C32.5525 30.545 32.5932 30.5246 32.644 30.484C32.6847 30.4535 32.7254 30.4434 32.7763 30.4434H32.8271C32.9288 30.4637 33.0102 30.5551 33.0102 30.6669H33.0204Z" fill="url(#paint99_linear_4158_139017)" />
          <path d="M33.0204 30.6669C33.0204 30.7888 32.9187 30.8904 32.7865 30.8904C32.6542 30.8904 32.5525 30.7888 32.5525 30.6669C32.5525 30.5449 32.5525 30.6364 32.5525 30.616C32.5728 30.7177 32.6644 30.7888 32.7763 30.7888C32.8881 30.7888 32.9797 30.7177 33 30.616V30.6669H33.0204Z" fill="url(#paint100_radial_4158_139017)" />
          <path d="M32.872 30.4999C32.8735 30.4945 32.866 30.4876 32.8552 30.4845C32.8444 30.4815 32.8344 30.4834 32.8329 30.4888C32.8313 30.4942 32.8388 30.501 32.8496 30.5041C32.8604 30.5072 32.8705 30.5053 32.872 30.4999Z" fill="#65F4E8" />
          <path d="M33.0102 30.6058C32.9796 30.5347 32.9186 30.4738 32.8474 30.4534H32.7965C32.7457 30.4534 32.705 30.4738 32.6643 30.4941C32.6033 30.5347 32.5626 30.616 32.5626 30.6871C32.5626 30.7583 32.5626 30.7278 32.5626 30.7379C32.5524 30.7176 32.5422 30.6871 32.5422 30.6566C32.5422 30.5855 32.5829 30.5144 32.6338 30.4737C32.6745 30.4433 32.7152 30.4331 32.766 30.4331H32.8169C32.8983 30.4534 32.9695 30.5144 32.9898 30.5957L33.0102 30.6058Z" fill="url(#paint101_radial_4158_139017)" />
          <path d="M24.5369 32.089C24.5369 32.2516 24.3945 32.3938 24.2216 32.3938C24.0486 32.3938 23.9062 32.2617 23.9062 32.089C23.9062 31.9163 23.9571 31.896 24.0385 31.8451C24.0894 31.8045 24.1504 31.7842 24.2216 31.7842H24.2928C24.4352 31.8147 24.5369 31.9366 24.5369 32.0788L24.5369 32.089Z" fill="url(#paint102_linear_4158_139017)" />
          <path d="M24.5369 32.0888C24.5369 32.2514 24.3945 32.3936 24.2216 32.3936C24.0486 32.3936 23.9062 32.2615 23.9062 32.0888C23.9062 31.9161 23.9062 32.0481 23.9062 32.0278C23.9368 32.1599 24.0588 32.2615 24.2114 32.2615C24.364 32.2615 24.486 32.1599 24.5166 32.0278V32.0888H24.5369Z" fill="url(#paint103_radial_4158_139017)" />
          <path d="M24.3375 31.8702C24.3405 31.8594 24.3299 31.8469 24.3137 31.8423C24.2975 31.8377 24.2818 31.8427 24.2788 31.8535C24.2757 31.8643 24.2863 31.8768 24.3025 31.8814C24.3188 31.886 24.3344 31.881 24.3375 31.8702Z" fill="#65F4E8" />
          <path d="M24.5267 32.018C24.486 31.9265 24.4047 31.8351 24.3029 31.8148H24.2317C24.1605 31.8148 24.1097 31.8351 24.0588 31.8757C23.9774 31.9265 23.9266 32.0383 23.9266 32.1399C23.9266 32.2415 23.9266 32.1907 23.9266 32.211C23.9164 32.1806 23.9062 32.1399 23.9062 32.0993C23.9062 31.9977 23.9571 31.9062 24.0385 31.8554C24.0894 31.8148 24.1504 31.7944 24.2216 31.7944H24.2928C24.4047 31.8148 24.5064 31.9062 24.5267 32.018Z" fill="url(#paint104_radial_4158_139017)" />
          <path d="M31.1792 26.2468C31.1792 26.2468 31.6471 25.9318 31.9116 26.1147C32.176 26.2976 32.1557 26.6736 32.0743 26.6736C31.993 26.6736 31.4437 26.8768 31.3826 27.0292C31.3216 27.1816 31.1894 26.2468 31.1894 26.2468H31.1792Z" fill="url(#paint105_linear_4158_139017)" />
          <path d="M32.7965 16.624C32.7965 17.0101 32.471 17.3251 32.0743 17.3251C31.6775 17.3251 31.3521 17.0101 31.3521 16.624C31.3521 16.2378 31.4741 16.187 31.6572 16.0549C31.7793 15.9737 31.9217 15.9229 32.0844 15.9229C32.2472 15.9229 32.1861 15.9229 32.237 15.9432C32.5625 16.0143 32.8067 16.2886 32.8067 16.624L32.7965 16.624Z" fill="url(#paint106_linear_4158_139017)" />
          <path d="M32.7965 16.6236C32.7965 17.0097 32.471 17.3247 32.0743 17.3247C31.6775 17.3247 31.3521 17.0097 31.3521 16.6236C31.3521 16.2375 31.3521 16.522 31.3724 16.4712C31.4436 16.7862 31.7284 17.0199 32.0844 17.0199C32.4404 17.0199 32.7253 16.7862 32.7965 16.4712C32.8067 16.522 32.8168 16.5728 32.8168 16.6236H32.7965Z" fill="url(#paint107_radial_4158_139017)" />
          <path d="M32.3526 16.1219C32.3602 16.095 32.3358 16.0644 32.298 16.0536C32.2602 16.0428 32.2233 16.056 32.2156 16.083C32.2079 16.11 32.2323 16.1406 32.2701 16.1513C32.308 16.1621 32.3449 16.1489 32.3526 16.1219Z" fill="#65F4E8" />
          <path d="M32.7762 16.4613C32.6846 16.2479 32.4913 16.0345 32.2574 15.9837C32.2065 15.9736 32.1557 15.9634 32.1048 15.9634C31.942 15.9634 31.82 16.0244 31.6979 16.1057C31.5148 16.2276 31.3826 16.4715 31.3826 16.7052C31.3826 16.9389 31.3826 16.8169 31.4029 16.8677C31.3724 16.7864 31.3521 16.7052 31.3521 16.6137C31.3521 16.38 31.4741 16.1768 31.6572 16.0447C31.7793 15.9634 31.9217 15.9126 32.0844 15.9126C32.2472 15.9126 32.1861 15.9126 32.237 15.9329C32.5015 15.9939 32.7253 16.1869 32.7863 16.4511L32.7762 16.4613Z" fill="url(#paint108_radial_4158_139017)" />
          <path d="M36.8857 21.1051C36.8857 21.2982 36.723 21.4506 36.5195 21.4506C36.3161 21.4506 36.1533 21.2982 36.1533 21.1051C36.1533 20.9121 36.2144 20.8816 36.3059 20.8206C36.3669 20.78 36.4381 20.7495 36.5195 20.7495H36.6009C36.7637 20.78 36.8857 20.9222 36.8857 21.095V21.1051Z" fill="url(#paint109_linear_4158_139017)" />
          <path d="M36.8857 21.1051C36.8857 21.2982 36.723 21.4506 36.5195 21.4506C36.3161 21.4506 36.1533 21.2982 36.1533 21.1051C36.1533 20.9121 36.1533 21.0543 36.1533 21.034C36.1838 21.1966 36.3364 21.3084 36.5093 21.3084C36.6823 21.3084 36.8247 21.1864 36.8654 21.034V21.1051H36.8857Z" fill="url(#paint110_radial_4158_139017)" />
          <path d="M36.658 20.8492C36.6611 20.8384 36.6505 20.8259 36.6342 20.8213C36.618 20.8167 36.6024 20.8217 36.5993 20.8325C36.5962 20.8433 36.6069 20.8558 36.6231 20.8604C36.6393 20.865 36.6549 20.86 36.658 20.8492Z" fill="#65F4E8" />
          <path d="M36.8755 21.024C36.8349 20.9122 36.7331 20.8106 36.6111 20.7902H36.5297C36.4483 20.7902 36.3873 20.8207 36.3262 20.8614C36.2347 20.9223 36.1635 21.0443 36.1635 21.1662C36.1635 21.2881 36.1635 21.2272 36.1737 21.2475C36.1533 21.2069 36.1533 21.1662 36.1533 21.1154C36.1533 20.9935 36.2144 20.8919 36.3059 20.8309C36.3669 20.7902 36.4381 20.7598 36.5195 20.7598H36.6009C36.7331 20.7902 36.845 20.8919 36.8755 21.0138V21.024Z" fill="url(#paint111_radial_4158_139017)" />
          <path d="M33.1221 16.4614C33.1221 16.5834 33.0204 16.6748 32.8983 16.6748C32.7763 16.6748 32.6746 16.5834 32.6746 16.4614C32.6746 16.3395 32.7152 16.3293 32.7661 16.2887C32.8068 16.2582 32.8475 16.248 32.8983 16.248H32.9492C33.0509 16.2684 33.1221 16.3497 33.1221 16.4513V16.4614Z" fill="url(#paint112_linear_4158_139017)" />
          <path d="M33.1221 16.4615C33.1221 16.5835 33.0204 16.6749 32.8983 16.6749C32.7763 16.6749 32.6746 16.5835 32.6746 16.4615C32.6746 16.3396 32.6746 16.4311 32.6746 16.4209C32.6949 16.5123 32.7865 16.5835 32.8882 16.5835C32.9899 16.5835 33.0814 16.5123 33.1018 16.4209V16.4615H33.1221Z" fill="url(#paint113_radial_4158_139017)" />
          <path d="M32.9784 16.3114C32.98 16.306 32.9725 16.2991 32.9617 16.2961C32.9509 16.293 32.9409 16.2949 32.9393 16.3003C32.9378 16.3057 32.9453 16.3126 32.9561 16.3156C32.9669 16.3187 32.9769 16.3168 32.9784 16.3114Z" fill="#65F4E8" />
          <path d="M33.1221 16.4106C33.0916 16.3497 33.0407 16.2785 32.9594 16.2684H32.9085C32.8576 16.2684 32.817 16.2887 32.7864 16.309C32.7356 16.3497 32.6949 16.4208 32.6949 16.4919C32.6949 16.563 32.6949 16.5224 32.6949 16.5427C32.6949 16.5224 32.6746 16.4919 32.6746 16.4614C32.6746 16.3903 32.7152 16.3293 32.7661 16.2887C32.8068 16.2582 32.8475 16.248 32.8983 16.248H32.9492C33.0306 16.2684 33.0916 16.3293 33.112 16.4005L33.1221 16.4106Z" fill="url(#paint114_radial_4158_139017)" />
          <path opacity="0.5" d="M22.7873 23.1375C22.7873 24.4889 23.0822 25.7488 23.6417 26.9377C24.2011 28.4822 25.076 31.5711 24.9335 35.1986C24.9132 36.3874 25.9609 37.241 27.4765 37.749C24.9742 37.4036 22.8991 36.4078 22.9195 34.7414C23.072 31.1139 22.1871 28.0351 21.6276 26.4804C21.058 25.2916 20.7732 24.0316 20.7732 22.6802C20.8647 15.537 25.8389 13.9011 28.982 15.7606C25.5947 15.0696 22.8686 16.8986 22.7873 23.1375Z" fill="url(#paint115_linear_4158_139017)" />
          <path d="M21.8618 24.0621C21.7398 24.3974 22.0449 25.3525 22.8688 25.4541C23.6928 25.5557 23.9369 25.7691 23.9369 25.7691C23.9369 25.7691 24.059 25.3627 24.7812 25.5049C25.5034 25.6472 25.9001 26.1959 25.9001 26.1959C25.9001 26.1959 27.0699 25.6878 27.5276 26.1959C27.9854 26.7039 28.0057 26.8055 28.0057 26.8055C28.0057 26.8055 28.9415 25.9317 29.8875 26.3991C30.1622 26.1451 30.8335 25.4338 30.8335 25.4338C30.8335 25.4338 29.9486 25.4948 29.2569 25.3627C28.7686 25.5659 28.2905 25.6573 27.3445 25.5659C26.3985 25.4744 25.4525 24.9969 25.2491 24.8851C24.8015 24.9461 23.5198 24.6108 22.94 24.3059C22.3602 24.0011 22.2077 23.7471 22.2077 23.7471L21.8618 24.0621Z" fill="url(#paint116_radial_4158_139017)" />
          <path d="M33.1526 26.094C33.0001 25.5758 32.7152 24.9458 31.9218 24.9458C30.7826 24.9458 29.806 25.8603 29.7552 26.724C29.745 26.8967 29.7552 27.0593 29.7755 27.2015C29.7755 27.4657 29.2262 27.801 28.6769 27.8925C28.1277 27.9839 28.7278 28.0957 29.1347 27.9738C29.5416 27.8519 29.7552 27.7706 29.8874 27.5572C30.1926 28.2177 30.9758 28.3193 31.5556 28.177C32.3287 27.9941 32.8068 27.1406 32.4914 26.7748C32.2269 26.4598 31.7183 26.5614 31.4539 26.8358C31.4742 26.4598 31.281 26.1042 31.0877 26.155C30.986 26.1753 30.9656 26.2464 31.0673 26.3379C30.9758 26.3277 30.8029 26.3582 30.8029 26.4395C30.8029 26.5208 31.1894 26.4192 31.2403 26.5919C31.2911 26.7646 31.2911 27.0491 31.2911 27.1507C31.2911 27.2524 31.3522 27.232 31.3725 27.1507C31.3827 27.1304 31.403 27.1101 31.4132 27.0796C31.5759 26.7849 32.1761 26.6427 32.2778 26.8866C32.3795 27.1304 32.0845 27.8011 31.4946 27.9738C30.9046 28.1364 29.9382 27.5673 30.04 26.7849C30.1213 26.155 30.6096 25.464 31.7794 25.3319C33.1933 25.1592 33.234 26.9069 33.234 26.9069C33.234 26.9069 33.3052 26.6427 33.1526 26.1245L33.1526 26.094Z" fill="url(#paint117_radial_4158_139017)" />
          <path d="M30.9555 25.7893C30.8334 26.084 31.3217 26.1348 31.4437 26.1449C31.5658 26.1551 32.4609 25.8503 32.6237 26.3583C32.7864 26.8664 33.0611 27.0086 33.1018 26.7648C33.1424 26.5209 32.8576 25.5353 32.1252 25.454C31.4641 25.3829 31.0572 25.5759 30.9555 25.7893Z" fill="url(#paint118_linear_4158_139017)" />
          <path d="M32.0542 24.9561C32.0745 24.9561 31.6473 25.3625 31.3523 25.4337C30.1215 25.7283 29.2467 25.4337 29.2467 25.4337C29.2467 25.4337 27.4971 26.0636 25.4424 24.9459C24.8321 24.9662 23.1435 24.5801 22.1772 23.7672C22.1772 23.7672 22.1975 23.7571 22.2077 23.7469C21.8313 23.3303 21.6889 22.7918 21.7296 22.2837C23.0113 23.7469 24.4659 24.2245 24.7304 23.9603C25.0253 23.6656 25.1271 22.1516 26.3375 22.2736C27.548 22.3955 25.4831 22.5276 25.5645 23.9197C25.6458 25.3117 28.3923 25.393 28.6364 24.6919C28.8703 24.0009 29.0839 23.4218 29.796 23.4624C30.508 23.5031 29.5417 24.0822 29.4705 24.6004C29.4094 25.1187 31.7083 25.3117 32.5221 24.0721C32.4305 24.3769 32.2881 24.6817 32.0542 24.9662V24.9561Z" fill="url(#paint119_radial_4158_139017)" />
          <path d="M22.2076 23.747C22.2076 23.747 22.9603 24.7428 25.4423 25.0781C26.6324 26.0535 29.3381 25.5048 29.3381 25.5048C29.3381 25.5048 31.281 25.9215 32.0846 24.946L32.3389 24.5192C32.3389 24.5192 32.0642 25.0476 31.5556 25.2305C31.0267 25.4236 29.7857 25.5557 29.4093 25.2305C29.033 24.9053 29.2161 23.7775 29.7857 23.747C29.4602 23.6149 28.8092 24.1128 29.0838 24.9866C29.1347 25.1797 29.0533 25.2102 29.0126 25.2305C29.0126 25.2305 28.3413 25.5557 27.2631 25.4236C26.1849 25.2915 25.7576 25.1289 25.5847 24.8952C25.4118 24.6615 24.8523 23.0154 26.2764 22.487C26.0424 22.3549 25.0049 22.9239 25.127 24.0823V24.1636C25.1778 24.5091 24.8625 24.8037 24.5166 24.7428C24.2013 24.692 23.8249 24.6005 23.4588 24.4481C22.6145 24.0925 22.1873 23.7572 22.1873 23.7572L22.2076 23.747Z" fill="url(#paint120_radial_4158_139017)" />
          <path d="M26.8765 20.902C26.8765 20.902 26.8154 20.2415 26.9578 20.0282C27.1003 19.8148 26.7341 19.6725 26.4696 19.8249C26.2051 19.9774 26.0424 20.587 26.3068 20.902C26.5713 21.217 26.8663 20.902 26.8663 20.902H26.8765Z" fill="url(#paint121_radial_4158_139017)" />
          <path d="M28.0056 26.8055C27.9242 27.2526 27.7614 27.6895 27.7411 27.6489V27.6285V27.3948C27.7004 27.1002 27.5478 26.714 27.3037 26.4397C26.9782 26.0841 26.378 26.4499 26.317 26.5921C26.256 26.7344 26.0017 27.2119 25.7372 27.2119C25.4727 27.2119 25.3812 27.029 25.3608 26.4397C25.3405 25.8504 24.7607 25.6878 24.4047 25.6776C24.4047 25.6675 24.364 25.4947 24.364 25.4947C24.5471 25.4439 24.7404 25.4338 24.9235 25.4439C25.2592 25.4642 25.5541 25.6471 25.7271 25.891C25.7271 25.6268 25.8288 25.3728 25.8694 25.2915C25.9508 25.3118 26.0424 25.3423 26.1339 25.3626C26.1848 25.383 26.2153 25.4134 26.195 25.4338C26.1645 25.4642 26.1339 25.6878 26.1034 25.8707C26.8053 25.5862 27.9039 25.8707 28.0056 26.8157L28.0056 26.8055Z" fill="url(#paint122_linear_4158_139017)" />
          <path d="M29.3687 20.3636C29.3687 20.3636 29.1652 20.394 29.0838 20.8513C29.7247 21.1256 30.7622 21.2577 30.986 21.7861C31.2098 22.3145 31.0979 22.66 30.8334 22.7717C30.5689 22.8835 31.047 22.2942 30.7825 21.9487C30.5181 21.6032 29.8162 21.1866 28.6261 21.0342C27.436 20.8818 26.6222 21.1053 26.6222 21.1053C26.6222 21.1053 25.6152 20.9224 24.9947 21.0748C24.3742 21.2273 24.0792 21.654 23.9775 21.9893C23.8758 22.3246 23.7944 22.599 23.6825 22.4669C23.5706 22.3348 23.9165 21.2476 24.2827 20.9935L24.2115 20.6989V20.5363C24.2115 20.5363 24.4047 20.8411 24.6794 20.9021C24.954 20.9631 25.605 20.6684 26.5714 20.8208C27.8327 20.5465 28.7685 20.6887 28.7685 20.6887C28.7685 20.6887 28.9516 20.3229 29.389 20.1807C29.3687 20.262 29.3687 20.3432 29.3687 20.3432L29.3687 20.3636Z" fill="url(#paint123_radial_4158_139017)" />
          <path d="M24.5369 21.4609C24.191 21.7455 23.6214 22.853 24.1503 23.2696C24.6793 23.6862 25.0353 22.1011 25.9304 21.9182C26.8256 21.7353 27.2019 22.1925 26.968 22.5685C26.734 22.9445 26.0525 24.1638 27.2731 24.306C28.4938 24.4483 28.8396 22.6904 29.0939 22.5787C29.3482 22.4669 29.8059 23.3204 30.284 22.8835C30.7621 22.4466 30.4468 21.6134 29.338 21.2984C28.2293 20.9834 27.0595 21.0951 26.2254 21.3593C25.8287 21.1155 25.1777 20.9224 24.5369 21.4609Z" fill="url(#paint124_linear_4158_139017)" />
          <path d="M25.5237 18.9914C25.1372 19.2048 24.7202 19.7637 25.5644 19.9364C26.0832 20.038 26.368 19.6011 26.7851 19.6417C27.2021 19.6824 27.375 20.353 28.1888 20.2819C29.0026 20.2107 29.1246 19.5401 28.6364 19.093C28.1481 18.6459 26.5105 18.4529 25.5237 18.9914Z" fill="url(#paint125_linear_4158_139017)" />
          <path d="M29.8264 20.4141C29.511 20.6174 30.9656 21.0238 31.3623 21.5827C31.7591 22.1415 31.4641 22.5683 31.2098 22.8731C30.9555 23.178 29.7552 24.001 30.2841 24.4481C30.813 24.8952 32.3388 24.1941 32.5321 23.5437C32.7254 22.8934 32.2676 21.8773 31.8506 21.3591C31.4336 20.8409 30.2943 20.1093 29.8366 20.4141H29.8264Z" fill="url(#paint126_linear_4158_139017)" />
          <path d="M27.7513 27.6286V27.3847C27.7411 27.1916 27.7106 26.9884 27.6394 26.7852C27.2833 25.6878 25.9203 26.3584 25.9203 26.3584C25.9203 26.4296 25.9203 26.5109 25.9 26.582C25.7576 27.0595 25.7372 26.9173 25.7372 26.9173C25.9 26.521 25.8491 25.7793 24.8726 25.6065C24.6997 25.576 24.5471 25.5862 24.3945 25.6472C23.6926 25.9114 23.2756 27.0494 23.6519 28.35C23.5095 28.4211 23.2145 27.6082 23.418 26.6125C23.5604 25.9114 23.9266 25.5964 24.3436 25.4744C24.5267 25.4236 24.72 25.4135 24.9031 25.4236C25.2388 25.4439 25.5338 25.6268 25.7067 25.8707C25.7067 25.6065 25.8084 25.3525 25.8491 25.2712C25.8491 25.2509 25.8593 25.2407 25.8593 25.2407C25.8593 25.2407 26.0118 25.2915 26.1034 25.3423C26.1542 25.3627 26.1848 25.3931 26.1644 25.4135C26.1339 25.4439 26.1034 25.6675 26.0729 25.8504C26.7747 25.5659 27.8733 25.8504 27.975 26.7954C28.0869 27.9334 27.4156 28.8072 27.3749 28.8581C27.3952 28.8174 27.6801 28.3094 27.7207 27.6387V27.6184L27.7513 27.6286Z" fill="url(#paint127_radial_4158_139017)" />
          <path d="M31.5861 27.0087C31.4539 27.1713 31.7692 27.2729 31.9726 27.1306C32.1761 26.9884 32.3083 26.8664 32.1557 26.7953C31.8913 26.6632 31.6777 26.8868 31.5759 27.0087H31.5861Z" fill="url(#paint128_linear_4158_139017)" />
          <path d="M23.9978 28.4208C24.2724 29.1016 24.7912 29.2235 25.5846 29.2743C26.378 29.3251 27.1918 28.939 27.3342 28.309C27.4766 27.6791 26.6832 27.1608 26.1034 27.425C25.5235 27.6892 25.0658 27.6994 24.8522 27.5266C24.6386 27.3539 23.7129 27.6689 24.0079 28.4208H23.9978Z" fill="url(#paint129_linear_4158_139017)" />
          <path d="M28.0666 28.6651C27.8225 28.8581 28.0361 29.6202 29.0329 29.7828C30.0298 29.9454 31.7488 29.7828 32.3083 29.0004C32.8678 28.218 32.0947 28.4314 32.0947 28.4314C32.0947 28.4314 31.2402 29.0817 30.8028 29.0817H30.3654C30.3654 29.0817 30.7011 28.6143 30.4977 28.5736C30.2942 28.533 30.1417 29.0004 29.7755 29.041C29.4093 29.0817 28.8498 28.8683 28.6972 28.6854C28.5446 28.5025 28.1784 28.5838 28.0666 28.6854L28.0666 28.6651Z" fill="url(#paint130_linear_4158_139017)" />
          <path d="M26.4392 21.0135C26.4392 21.0135 26.307 19.977 26.602 19.9974C26.897 20.0177 26.5613 20.4445 26.724 20.9017C26.8258 21.1862 26.4291 21.0135 26.4291 21.0135H26.4392Z" fill="#99273F" />
          <path d="M30.1315 18.5341C29.9586 18.7577 31.047 19.4283 31.4335 19.7027C31.8201 19.977 32.3999 20.1599 32.6847 19.7839C32.9695 19.408 32.5524 18.8898 31.993 18.6459C31.4335 18.402 30.3756 18.209 30.1315 18.5341Z" fill="url(#paint131_linear_4158_139017)" />
          <path d="M33.7427 20.0687C33.4884 20.1907 33.2545 20.4853 33.8038 21.1255C34.4751 21.8977 33.875 22.0908 34.17 22.3956C34.465 22.7004 35.1973 22.6293 35.3499 21.918C35.5025 21.2068 34.5667 19.6826 33.7427 20.0789L33.7427 20.0687Z" fill="url(#paint132_linear_4158_139017)" />
          <path d="M33.0815 22.4159C32.8883 22.6191 32.4102 23.4828 33.1324 23.8893C33.8546 24.2957 35.0244 23.1475 35.0244 23.1475C35.0244 23.1475 34.0072 24.0722 33.3663 23.6556C32.7255 23.239 33.1629 22.6801 33.3663 22.6293C33.5698 22.5785 33.7325 22.6293 33.8139 22.6293C33.8953 22.6293 34.0987 22.4566 33.7936 22.294C33.4884 22.1314 33.2036 22.294 33.0917 22.4159L33.0815 22.4159Z" fill="#99437A" />
          <path d="M35.8482 24.8646C36.428 24.4784 37.2926 24.1838 37.7605 25.0678C38.2284 25.9518 37.852 26.6732 37.5469 26.8764C37.2417 27.0797 36.4992 27.3337 36.1025 26.7037C35.7159 26.094 36.5297 25.4437 36.6517 25.6571C35.6956 26.1957 35.1971 25.3015 35.8482 24.8544V24.8646Z" fill="url(#paint133_radial_4158_139017)" />
          <path d="M33.1324 20.2211C33.1324 20.2211 32.8883 20.7292 32.6543 20.8612C32.4814 20.9527 32.3085 20.9019 32.339 20.7901C32.3695 20.6783 33.0001 20.4446 33.1121 19.9976C33.1324 20.1297 33.1324 20.2211 33.1324 20.2211Z" fill="#99437A" />
          <path d="M36.8857 25.3929C36.6111 25.3116 35.777 25.5961 35.9397 26.5309C36.1025 27.4658 37.374 27.1203 37.6283 26.8967C37.8826 26.6732 38.2589 26.2261 37.9741 25.3929C37.6893 24.5597 36.9569 24.1126 36.0821 24.6207C35.2073 25.1287 35.1259 25.4335 36.1126 24.8442C37.0891 24.2549 37.5977 24.8036 37.8419 25.3929C38.086 25.9822 37.8724 26.5309 37.4248 26.8358C36.9772 27.1406 36.0923 27.0187 36.1126 26.5309C36.133 26.0432 36.5195 25.8197 36.7331 25.7384C36.9467 25.6571 37.2824 26.1651 37.2621 25.8603C37.2417 25.5555 37.2214 25.4844 36.8857 25.3929Z" fill="#AD538D" />
          <path d="M34.3327 24.8647C34.3327 24.8647 33.6613 25.3321 33.7326 26.3991C33.5495 26.2568 33.4986 26.0434 33.4986 26.0434C33.4986 26.0434 33.2341 25.3525 34.1598 24.6717C34.7803 24.2043 34.3327 24.8647 34.3327 24.8647Z" fill="#99437A" />
          <path d="M33.6918 27.4458C33.9156 27.8319 34.3835 27.9945 34.5971 27.9945C34.8107 27.9945 34.6988 28.2282 34.4954 28.218C34.2919 28.2079 33.7833 28.1063 33.5901 27.8319C33.3968 27.5576 33.6918 27.4458 33.6918 27.4458Z" fill="#99437A" />
          <path d="M33.0917 23.1577C33.0205 23.3202 33.1934 23.7775 33.8139 23.7673C34.4344 23.7572 35.4923 22.924 35.6042 22.1009C35.7161 21.2779 34.8413 22.863 34.3123 23.1272C33.7834 23.3914 33.5698 23.0561 33.4172 23.0053C33.2646 22.9545 33.1629 22.9849 33.0815 23.1678L33.0917 23.1577Z" fill="url(#paint134_linear_4158_139017)" />
          <path d="M27.8326 32.7189C27.9445 33.2067 28.3819 33.5217 28.7481 33.6029C29.1143 33.6842 29.45 32.7697 29.4703 32.5767C29.5619 31.8552 28.9617 31.5098 28.6362 31.4894C28.3108 31.4691 27.6394 31.906 27.8326 32.7189Z" fill="url(#paint135_linear_4158_139017)" />
          <path d="M30.1213 35.1681C30.0704 35.5034 29.8975 35.9301 29.7348 36.1842C29.4906 36.3975 29.2567 36.0826 29.1448 35.8793C29.3279 35.5948 29.6839 35.0664 29.5313 34.2434C29.4499 33.7557 29.8772 33.715 29.9891 33.9284C30.0908 34.1418 30.1925 34.7108 30.1213 35.1681Z" fill="url(#paint136_linear_4158_139017)" />
          <path d="M43.0093 45.7964L45.5726 45.7456L45.2166 46.4061L43.4467 46.5382L43.0093 45.7964Z" fill="#06088A" />
          <path d="M30.3758 16.9897C30.6199 17.01 30.6504 17.0811 31.037 16.4715C31.4235 15.8618 31.8609 15.6484 32.4 15.6891C32.9391 15.7297 31.7286 14.9981 31.0878 16.1158C30.8539 16.4816 30.7827 16.6645 30.6606 16.7357C30.5385 16.8068 30.2435 16.7357 30.2029 16.8576C30.1622 16.9795 30.3148 16.9897 30.3656 16.9998L30.3758 16.9897Z" fill="url(#paint137_linear_4158_139017)" />
          <path d="M37.9131 23.1068C38.3098 25.0374 38.1064 29.5997 35.421 36.1638C35.0039 37.3221 33.946 37.6371 33.946 37.6371C33.946 37.6371 35.9194 33.1256 36.4687 29.4778C37.018 25.83 36.6009 23.6047 36.6009 23.6047L37.9131 23.0967V23.1068Z" fill="url(#paint138_linear_4158_139017)" />
        </g>
      </g>
      <defs>
        <filter id="filter0_f_4158_139017" x="-12" y="-12" width="88" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_4158_139017" />
        </filter>
        <linearGradient id="paint0_linear_4158_139017" x1="42.9421" y1="38.4852" x2="43.3279" y2="37.2758" gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#265894" />
          <stop offset="1" stopColor="#191C61" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_4158_139017" x1="43.8309" y1="36.6033" x2="43.5364" y2="37.264" gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#3240C2" />
          <stop offset="1" stopColor="#191C61" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint2_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(42.8539 38.7161) scale(3.47401 3.47027)">
          <stop stopColor="#31BDA1" />
          <stop offset="0.9" stopColor="#2E68B3" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint3_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.3213 40.1569) scale(7.76123 7.75287)">
          <stop stopColor="#63F9DB" />
          <stop offset="0.7" stopColor="#63F9DB" />
        </radialGradient>
        <linearGradient id="paint4_linear_4158_139017" x1="45.4615" y1="39.4662" x2="45.3397" y2="46.8228" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.173262" stopColor="#61AACE" />
          <stop offset="0.305348" stopColor="#5498C7" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.810695" stopColor="#4173AE" />
          <stop offset="1" stopColor="#4A88E0" />
        </linearGradient>
        <linearGradient id="paint5_linear_4158_139017" x1="44.547" y1="39.6086" x2="44.4252" y2="46.8026" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3250A6" />
          <stop offset="1" stopColor="#204AB3" />
        </linearGradient>
        <linearGradient id="paint6_linear_4158_139017" x1="28.6023" y1="46.2965" x2="29.1514" y2="47.8813" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4C89BD" />
          <stop offset="0.2" stopColor="#4173AE" />
        </linearGradient>
        <linearGradient id="paint7_linear_4158_139017" x1="31.1175" y1="45.3876" x2="31.5324" y2="46.5744" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5498C7" />
          <stop offset="0.7" stopColor="#4173AE" />
        </linearGradient>
        <linearGradient id="paint8_linear_4158_139017" x1="32.6308" y1="31.4062" x2="31.4046" y2="32.3529" gradientUnits="userSpaceOnUse">
          <stop stopColor="#353DA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <radialGradient id="paint9_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.1805 45.1987) rotate(-1.99785) scale(0.793414 0.792562)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <radialGradient id="paint10_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.4854 50.279) rotate(22.8779) scale(2.83752 1.13416)">
          <stop stopColor="#76C7D9" />
          <stop offset="0.3" stopColor="#61AACE" />
          <stop offset="0.4" stopColor="#5498C7" />
          <stop offset="0.6" stopColor="#4173AE" />
        </radialGradient>
        <radialGradient id="paint11_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(42.1407 41.2062) rotate(84.6943) scale(3.30237 2.31411)">
          <stop offset="0.3" stopColor="#7FD3DE" />
          <stop offset="0.8" stopColor="#4189D4" />
        </radialGradient>
        <linearGradient id="paint12_linear_4158_139017" x1="42.35" y1="39.7814" x2="42.3349" y2="36.8875" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <linearGradient id="paint13_linear_4158_139017" x1="42.3056" y1="40.9882" x2="41.4019" y2="43.0313" gradientUnits="userSpaceOnUse">
          <stop stopColor="#76C7D9" />
          <stop offset="0.2" stopColor="#61AACE" />
          <stop offset="0.3" stopColor="#5498C7" />
          <stop offset="0.6" stopColor="#337DAB" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint14_linear_4158_139017" x1="43.2971" y1="42.608" x2="42.0672" y2="41.7634" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#3B1CAB" />
        </linearGradient>
        <radialGradient id="paint15_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.71 41.2441) scale(2.10357 1.91028)">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.1" stopColor="#76C7D9" />
          <stop offset="0.3" stopColor="#61AACE" />
          <stop offset="0.4" stopColor="#5498C7" />
          <stop offset="0.8" stopColor="#4173AE" />
          <stop offset="1" stopColor="#4A88E0" />
        </radialGradient>
        <linearGradient id="paint16_linear_4158_139017" x1="45.8332" y1="39.9082" x2="46.3095" y2="41.4202" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#445DA8" />
        </linearGradient>
        <radialGradient id="paint17_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.3395 39.8273) rotate(17.2825) scale(1.96301 1.96127)">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.1" stopColor="#76C7D9" />
          <stop offset="0.3" stopColor="#61AACE" />
          <stop offset="0.4" stopColor="#5498C7" />
          <stop offset="0.8" stopColor="#4173AE" />
          <stop offset="1" stopColor="#4A88E0" />
        </radialGradient>
        <linearGradient id="paint18_linear_4158_139017" x1="47.0238" y1="41.8507" x2="47.0435" y2="43.268" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <radialGradient id="paint19_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(46.8057 44.067) scale(1.22064 1.21932)">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.1" stopColor="#76C7D9" />
          <stop offset="0.3" stopColor="#61AACE" />
          <stop offset="0.4" stopColor="#5498C7" />
          <stop offset="0.8" stopColor="#4173AE" />
          <stop offset="1" stopColor="#4A88E0" />
        </radialGradient>
        <linearGradient id="paint20_linear_4158_139017" x1="46.8238" y1="46.3784" x2="46.8371" y2="47.3294" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <linearGradient id="paint21_linear_4158_139017" x1="24.3234" y1="40.5127" x2="33.7935" y2="40.5127" gradientUnits="userSpaceOnUse">
          <stop stopColor="#767BE9" />
          <stop offset="0.3" stopColor="#555ACE" />
          <stop offset="0.5" stopColor="#474BC2" />
          <stop offset="0.7" stopColor="#7073EF" />
          <stop offset="0.8" stopColor="#787BF8" />
          <stop offset="1" stopColor="#A6A9F6" />
        </linearGradient>
        <linearGradient id="paint22_linear_4158_139017" x1="24.3234" y1="40.1978" x2="33.7935" y2="40.1978" gradientUnits="userSpaceOnUse">
          <stop offset="0.3" stopColor="#5CC3E5" />
          <stop offset="1" stopColor="#61FFC2" />
        </linearGradient>
        <radialGradient id="paint23_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.0534 39.3542) scale(4.47568 4.47085)">
          <stop stopColor="#FF59F9" />
          <stop offset="1" stopColor="#A71ADB" />
        </radialGradient>
        <radialGradient id="paint24_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.8201 34.1527) rotate(51.97) scale(5.93342 6.3915)">
          <stop stopColor="#6C6FD9" />
          <stop offset="0.4" stopColor="#3A3BB2" />
          <stop offset="0.7" stopColor="#29299C" />
          <stop offset="0.9" stopColor="#2A29A3" />
          <stop offset="1" stopColor="#403DD4" />
        </radialGradient>
        <linearGradient id="paint25_linear_4158_139017" x1="38.6904" y1="30.4885" x2="36.4891" y2="39.7214" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4034C8" />
          <stop offset="0.1" stopColor="#8085FF" />
          <stop offset="0.3" stopColor="#B8BAF8" />
          <stop offset="0.5" stopColor="#7075F0" />
          <stop offset="0.7" stopColor="#373BB0" />
          <stop offset="1" stopColor="#464BE0" />
        </linearGradient>
        <linearGradient id="paint26_linear_4158_139017" x1="29.5907" y1="33.5873" x2="27.8324" y2="41.3821" gradientUnits="userSpaceOnUse">
          <stop stopColor="#23DBD6" />
          <stop offset="1" stopColor="#469CE8" />
        </linearGradient>
        <linearGradient id="paint27_linear_4158_139017" x1="21.2209" y1="37.6527" x2="36.896" y2="37.6527" gradientUnits="userSpaceOnUse">
          <stop stopColor="#50DEC4" />
          <stop offset="0.3" stopColor="#2D9EB0" />
          <stop offset="0.5" stopColor="#127C96" />
          <stop offset="0.7" stopColor="#36AAB9" />
          <stop offset="1" stopColor="#43DEB7" />
        </linearGradient>
        <linearGradient id="paint28_linear_4158_139017" x1="29.0933" y1="37.4341" x2="29.408" y2="41.1531" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6BDECF" />
          <stop offset="0.8" stopColor="#47E9FF" />
        </linearGradient>
        <linearGradient id="paint29_linear_4158_139017" x1="29.3459" y1="37.647" x2="29.3459" y2="14.3884" gradientUnits="userSpaceOnUse">
          <stop stopColor="#22F5C4" />
          <stop offset="1" stopColor="#91FFF3" stopOpacity="0.3" />
        </linearGradient>
        <radialGradient id="paint30_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.9223 16.0326) rotate(0.399569) scale(0.823932 0.57613)">
          <stop stopColor="#9AFBE5" />
          <stop offset="1" stopColor="#22F5C4" stopOpacity="0.3" />
        </radialGradient>
        <linearGradient id="paint31_linear_4158_139017" x1="29.033" y1="39.5167" x2="29.033" y2="33.2271" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#BAFFF1" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint32_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.6917 17.408) rotate(0.399569) scale(4.87238 3.40699)">
          <stop stopColor="#9AFBE5" />
          <stop offset="1" stopColor="#22F5C4" stopOpacity="0.3" />
        </radialGradient>
        <linearGradient id="paint33_linear_4158_139017" x1="29.2629" y1="13.0229" x2="29.2629" y2="39.7013" gradientUnits="userSpaceOnUse">
          <stop stopColor="#59FFD9" />
          <stop offset="1" stopColor="#C9FFF9" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint34_linear_4158_139017" x1="29.2629" y1="38.1759" x2="29.2629" y2="13.83" gradientUnits="userSpaceOnUse">
          <stop stopColor="#59FFD9" />
          <stop offset="1" stopColor="#C9FFF9" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint35_linear_4158_139017" x1="28.2711" y1="13.5385" x2="29.1864" y2="13.284" gradientUnits="userSpaceOnUse">
          <stop stopColor="#456BE6" />
          <stop offset="0.9" stopColor="#1A1D64" />
        </linearGradient>
        <radialGradient id="paint36_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.8021 11.5841) scale(1.63769 1.63593)">
          <stop stopColor="#398EC7" />
          <stop offset="0.6" stopColor="#191C61" />
          <stop offset="0.9" stopColor="#1A1D64" />
          <stop offset="1" stopColor="#343CCF" />
        </radialGradient>
        <linearGradient id="paint37_linear_4158_139017" x1="39.1593" y1="50.4377" x2="39.0339" y2="53.7784" gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#094DC7" />
          <stop offset="1" stopColor="#1C2BC2" />
        </linearGradient>
        <linearGradient id="paint38_linear_4158_139017" x1="32.9537" y1="43.7593" x2="32.804" y2="49.7535" gradientUnits="userSpaceOnUse">
          <stop stopColor="#072299" />
          <stop offset="1" stopColor="#084DBA" />
        </linearGradient>
        <linearGradient id="paint39_linear_4158_139017" x1="43.0092" y1="46.0015" x2="43.0092" y2="46.0096" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0061F2" />
          <stop offset="1" stopColor="#051A75" />
        </linearGradient>
        <linearGradient id="paint40_linear_4158_139017" x1="42.2478" y1="39.8593" x2="42.2478" y2="46.0238" gradientUnits="userSpaceOnUse">
          <stop stopColor="#051A75" />
          <stop offset="1" stopColor="#0061F2" />
        </linearGradient>
        <linearGradient id="paint41_linear_4158_139017" x1="41.3277" y1="39.4027" x2="45.1894" y2="39.5715" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0956D1" />
          <stop offset="1" stopColor="#072299" />
        </linearGradient>
        <linearGradient id="paint42_linear_4158_139017" x1="38.1681" y1="50.1955" x2="40.7123" y2="48.5547" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D6FF0" />
          <stop offset="1" stopColor="#072299" />
        </linearGradient>
        <linearGradient id="paint43_linear_4158_139017" x1="39.917" y1="49.7687" x2="39.7952" y2="52.5604" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00095E" />
          <stop offset="1" stopColor="#0818CC" />
        </linearGradient>
        <linearGradient id="paint44_linear_4158_139017" x1="33.6536" y1="42.2494" x2="31.5778" y2="44.1342" gradientUnits="userSpaceOnUse">
          <stop stopColor="#084FBF" />
          <stop offset="0.8" stopColor="#0843B8" />
        </linearGradient>
        <linearGradient id="paint45_linear_4158_139017" x1="18.7156" y1="35.3725" x2="22.5538" y2="43.5452" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4034C8" />
          <stop offset="0.2" stopColor="#8085FF" />
          <stop offset="0.3" stopColor="#B8E4F8" />
          <stop offset="0.5" stopColor="#7075F0" />
          <stop offset="0.7" stopColor="#373BB0" />
          <stop offset="1" stopColor="#3B3FBC" />
        </linearGradient>
        <radialGradient id="paint46_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.3836 37.4539) scale(6.74403 6.73677)">
          <stop stopColor="#B3B5FF" />
          <stop offset="0.1" stopColor="#7578ED" />
          <stop offset="0.5" stopColor="#3332AB" />
          <stop offset="0.8" stopColor="#271E7D" />
        </radialGradient>
        <radialGradient id="paint47_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.817 38.8259) scale(4.30275 4.29812)">
          <stop stopColor="#231B86" />
          <stop offset="1" stopColor="#2A29A3" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint48_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.8649 39.6894) scale(4.52654 4.52166)">
          <stop offset="0.7" stopColor="#29299C" />
          <stop offset="0.9" stopColor="#343CD2" />
        </radialGradient>
        <radialGradient id="paint49_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.8242 39.6285) scale(4.34344 4.33876)">
          <stop stopColor="#7E83FF" />
          <stop offset="0.4" stopColor="#4254CC" />
          <stop offset="0.7" stopColor="#2C249C" />
          <stop offset="0.9" stopColor="#261380" />
        </radialGradient>
        <linearGradient id="paint50_linear_4158_139017" x1="17.9915" y1="40.9244" x2="19.8" y2="42.9179" gradientUnits="userSpaceOnUse">
          <stop stopColor="#14064A" />
          <stop offset="1" stopColor="#2B1B87" />
        </linearGradient>
        <linearGradient id="paint51_linear_4158_139017" x1="18.4616" y1="40.4845" x2="20.2599" y2="42.4679" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4A47FF" />
          <stop offset="1" stopColor="#3E2EEB" />
        </linearGradient>
        <linearGradient id="paint52_linear_4158_139017" x1="17.5731" y1="41.3119" x2="19.3816" y2="43.2952" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4434FF" />
          <stop offset="1" stopColor="#3E2EEB" />
        </linearGradient>
        <radialGradient id="paint53_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.9829 41.4172) scale(1.27139 1.27002)">
          <stop stopColor="#0A0B26" />
          <stop offset="0.3" stopColor="#1C1C63" />
          <stop offset="0.8" stopColor="#04052E" />
        </radialGradient>
        <radialGradient id="paint54_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.5526 40.7465) scale(2.20732 2.20494)">
          <stop stopColor="#222275" />
          <stop offset="0.3" stopColor="#1C1C63" />
          <stop offset="0.8" stopColor="#04052E" />
        </radialGradient>
        <linearGradient id="paint55_linear_4158_139017" x1="17.549" y1="43.3577" x2="21.1447" y2="48.4622" gradientUnits="userSpaceOnUse">
          <stop stopColor="#191C61" />
          <stop offset="1" stopColor="#262A91" />
        </linearGradient>
        <linearGradient id="paint56_linear_4158_139017" x1="18.4595" y1="48.2438" x2="17.8805" y2="48.9963" gradientUnits="userSpaceOnUse">
          <stop stopColor="#462EB3" />
          <stop offset="1" stopColor="#3451BF" />
        </linearGradient>
        <linearGradient id="paint57_linear_4158_139017" x1="17.9388" y1="45.0424" x2="16.9828" y2="44.7777" gradientUnits="userSpaceOnUse">
          <stop stopColor="#462EB3" stopOpacity="0" />
          <stop offset="1" stopColor="#373DD4" />
        </linearGradient>
        <radialGradient id="paint58_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.822 46.5089) scale(2.25622 2.25379)">
          <stop stopColor="#3ADECD" />
          <stop offset="0.8" stopColor="#35AECF" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint59_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.4057 47.726) scale(3.05897 3.05568)">
          <stop stopColor="#31BDA1" />
          <stop offset="0.9" stopColor="#2E68B3" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint60_linear_4158_139017" x1="35.7842" y1="49.9781" x2="36.7434" y2="50.5479" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.794652" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint61_linear_4158_139017" x1="35.3424" y1="50.1012" x2="35.6269" y2="51.6931" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <radialGradient id="paint62_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.2387 50.6501) rotate(-28.3742) scale(0.8339 0.833408)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <linearGradient id="paint63_linear_4158_139017" x1="33.5343" y1="50.6094" x2="34.0715" y2="52.4748" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.794652" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <radialGradient id="paint64_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.296 48.042) scale(3.40762 3.40395)">
          <stop stopColor="#395DC7" />
          <stop offset="0.694652" stopColor="#191C61" />
          <stop offset="0.9" stopColor="#1A1D64" />
          <stop offset="1" stopColor="#23288A" />
        </radialGradient>
        <linearGradient id="paint65_linear_4158_139017" x1="24.7967" y1="47.0968" x2="24.7155" y2="52.6346" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.2" stopColor="#61AACE" />
          <stop offset="0.305348" stopColor="#5498C7" />
          <stop offset="0.5" stopColor="#3F6AA6" />
          <stop offset="0.8" stopColor="#4165AE" />
        </linearGradient>
        <linearGradient id="paint66_linear_4158_139017" x1="24.8004" y1="50.4357" x2="24.638" y2="51.2791" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F6AA6" stopOpacity="0" />
          <stop offset="1" stopColor="#4F7BD4" />
        </linearGradient>
        <linearGradient id="paint67_linear_4158_139017" x1="35.5032" y1="45.6232" x2="36.3977" y2="46.6778" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.794652" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint68_linear_4158_139017" x1="35.1382" y1="46.1429" x2="34.8581" y2="48.1061" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <radialGradient id="paint69_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.0305 46.4282) rotate(-2.19763) scale(0.793414 0.792562)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <linearGradient id="paint70_linear_4158_139017" x1="33.3705" y1="46.5516" x2="33.9183" y2="48.3815" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.794652" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint71_linear_4158_139017" x1="26.1685" y1="48.1537" x2="26.1685" y2="52.1368" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3763AC" />
          <stop offset="1" stopColor="#2D44A1" />
        </linearGradient>
        <linearGradient id="paint72_linear_4158_139017" x1="36.3031" y1="47.8624" x2="37.187" y2="48.8185" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.794652" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint73_linear_4158_139017" x1="35.8506" y1="48.3077" x2="35.6374" y2="50.1673" gradientUnits="userSpaceOnUse">
          <stop stopColor="#465EA6" />
          <stop offset="1" stopColor="#2C4EBF" />
        </linearGradient>
        <radialGradient id="paint74_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.7159 48.723) scale(0.834103 0.833205)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <linearGradient id="paint75_linear_4158_139017" x1="33.8625" y1="48.5808" x2="34.299" y2="50.6843" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.5" stopColor="#4276AB" />
          <stop offset="0.794652" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint76_linear_4158_139017" x1="29.232" y1="46.6296" x2="29.1204" y2="53.6408" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B6EEF9" />
          <stop offset="0.2" stopColor="#99D4EA" />
          <stop offset="0.4" stopColor="#69AAD2" />
          <stop offset="0.5" stopColor="#5498C7" />
          <stop offset="0.8" stopColor="#4DA5E8" />
          <stop offset="1" stopColor="#4AAEFF" />
        </linearGradient>
        <linearGradient id="paint77_linear_4158_139017" x1="29.1301" y1="46.7311" x2="29.0185" y2="53.7422" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.189305" stopColor="#61AACE" />
          <stop offset="0.316043" stopColor="#5498C7" />
          <stop offset="0.5" stopColor="#4476AE" />
          <stop offset="0.805348" stopColor="#4165AE" />
        </linearGradient>
        <linearGradient id="paint78_linear_4158_139017" x1="29.0034" y1="51.2212" x2="28.7902" y2="52.9181" gradientUnits="userSpaceOnUse">
          <stop offset="0.5" stopColor="#3F6AA6" stopOpacity="0" />
          <stop offset="1" stopColor="#4F7BD4" />
        </linearGradient>
        <linearGradient id="paint79_linear_4158_139017" x1="30.6486" y1="44.0786" x2="29.1258" y2="48.7739" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#BDE1FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint80_linear_4158_139017" x1="23.3766" y1="45.7965" x2="24.1177" y2="49.3227" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFFFF9" />
          <stop offset="1" stopColor="#9CD2FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint81_linear_4158_139017" x1="25.209" y1="51.2278" x2="34.1294" y2="52.6126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4F7BD4" stopOpacity="0" />
          <stop offset="1" stopColor="#4F7BD4" />
        </linearGradient>
        <radialGradient id="paint82_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(45.9999 39.9233) scale(0.915479 0.914493)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <radialGradient id="paint83_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(46.4169 42.1181) scale(0.915479 0.914493)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <radialGradient id="paint84_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(46.1729 44.0993) scale(0.762899 0.762077)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <linearGradient id="paint85_linear_4158_139017" x1="45.2121" y1="42.0671" x2="45.2426" y2="43.5506" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.337433" stopColor="#5498C7" />
          <stop offset="0.553476" stopColor="#4276AB" />
          <stop offset="0.816043" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint86_linear_4158_139017" x1="44.8092" y1="40.3229" x2="45.195" y2="41.5933" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.337433" stopColor="#5498C7" />
          <stop offset="0.553476" stopColor="#4276AB" />
          <stop offset="0.816043" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <linearGradient id="paint87_linear_4158_139017" x1="45.2372" y1="43.9249" x2="45.0139" y2="45.0224" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.337433" stopColor="#5498C7" />
          <stop offset="0.553476" stopColor="#4276AB" />
          <stop offset="0.816043" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </linearGradient>
        <radialGradient id="paint88_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(42.4904 41.3764) scale(0.915479 0.914493)">
          <stop stopColor="#2A2B93" />
          <stop offset="0.3" stopColor="#242682" />
          <stop offset="0.8" stopColor="#191C61" />
        </radialGradient>
        <radialGradient id="paint89_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(42.3075 41.079) scale(1.75975 1.75786)">
          <stop stopColor="#76C7D9" />
          <stop offset="0.2" stopColor="#61AACE" />
          <stop offset="0.3" stopColor="#5498C7" />
          <stop offset="0.5" stopColor="#3C6BAB" />
          <stop offset="0.8" stopColor="#4173AE" />
          <stop offset="1" stopColor="#5195F5" />
        </radialGradient>
        <linearGradient id="paint90_linear_4158_139017" x1="24.7467" y1="39.5605" x2="26.1282" y2="41.4113" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7FD3DE" />
          <stop offset="0.9" stopColor="#7FD3DE" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint91_linear_4158_139017" x1="30.7001" y1="25.4539" x2="26.761" y2="36.9385" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E07881" />
          <stop offset="1" stopColor="#72C7AA" />
        </linearGradient>
        <linearGradient id="paint92_linear_4158_139017" x1="28.7527" y1="31.825" x2="28.7527" y2="29.4067" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B99490" stopOpacity="0" />
          <stop offset="1" stopColor="#22F5C4" stopOpacity="0.5" />
        </linearGradient>
        <radialGradient id="paint93_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.6073 29.1526) scale(5.9913 5.98485)">
          <stop stopColor="#8CDED2" stopOpacity="0.8" />
          <stop offset="0.6" stopColor="#DE8CC3" stopOpacity="0.8" />
          <stop offset="1" stopColor="#C75B98" stopOpacity="0.8" />
        </radialGradient>
        <linearGradient id="paint94_linear_4158_139017" x1="34.7827" y1="28.8278" x2="34.6507" y2="26.8566" gradientUnits="userSpaceOnUse">
          <stop stopColor="#95F0E5" />
          <stop offset="1" stopColor="#AFAEA4" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint95_linear_4158_139017" x1="27.2318" y1="19.032" x2="27.2318" y2="29.0711" gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#BE4A62" />
          <stop offset="0.4" stopColor="#FE8E9D" />
          <stop offset="0.8" stopColor="#D49FA1" />
          <stop offset="1" stopColor="#72C7AA" />
        </linearGradient>
        <linearGradient id="paint96_linear_4158_139017" x1="34.0321" y1="32.3006" x2="33.7884" y2="32.9206" gradientUnits="userSpaceOnUse">
          <stop stopColor="#297D6B" />
          <stop offset="0.8" stopColor="#79FFEA" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint97_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.8035 32.8921) scale(0.478084 0.477568)">
          <stop stopColor="#F5FFFE" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient id="paint98_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.773 34.8939) scale(0.447568 0.491794)">
          <stop offset="0.2" stopColor="#67D9C7" stopOpacity="0.5" />
          <stop offset="1" stopColor="#2C675C" stopOpacity="0.1" />
        </radialGradient>
        <linearGradient id="paint99_linear_4158_139017" x1="32.8304" y1="30.5522" x2="32.6984" y2="30.8673" gradientUnits="userSpaceOnUse">
          <stop stopColor="#297D6B" />
          <stop offset="0.8" stopColor="#79FFEA" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint100_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.7051 30.8599) scale(0.2543 0.254026)">
          <stop stopColor="#F5FFFE" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient id="paint101_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.6948 29.0106) scale(0.233956 0.233704)">
          <stop offset="0.2" stopColor="#67D9C7" stopOpacity="0.5" />
          <stop offset="1" stopColor="#2C675C" stopOpacity="0.1" />
        </radialGradient>
        <linearGradient id="paint102_linear_4158_139017" x1="24.2761" y1="31.934" x2="24.1035" y2="32.3711" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4EB79F" stopOpacity="0.3" />
          <stop offset="0.8" stopColor="#79FFEA" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint103_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.1199 32.353) scale(0.335676 0.335314)">
          <stop stopColor="#F5FFFE" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient id="paint104_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.0893 30.2093) scale(0.315332 0.314992)">
          <stop offset="0.2" stopColor="#67D9C7" stopOpacity="0.5" />
          <stop offset="1" stopColor="#2C675C" stopOpacity="0.1" />
        </radialGradient>
        <linearGradient id="paint105_linear_4158_139017" x1="31.545" y1="26.9987" x2="31.6871" y2="26.2264" gradientUnits="userSpaceOnUse">
          <stop offset="0.2" stopColor="#AD4353" />
          <stop offset="1" stopColor="#EF7E86" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint106_linear_4158_139017" x1="32.2016" y1="16.2725" x2="31.8056" y2="17.2686" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C675C" stopOpacity="0.1" />
          <stop offset="0.8" stopColor="#79FFEA" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint107_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.8302 17.2231) scale(0.783243 0.7824)">
          <stop stopColor="#F5FFFE" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient id="paint108_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.7793 16.2174) scale(0.732383 0.731594)">
          <stop offset="0.2" stopColor="#67D9C7" stopOpacity="0.5" />
          <stop offset="1" stopColor="#2C675C" stopOpacity="0.1" />
        </radialGradient>
        <linearGradient id="paint109_linear_4158_139017" x1="36.5814" y1="20.9288" x2="36.3885" y2="21.4268" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C675C" stopOpacity="0.1" />
          <stop offset="0.8" stopColor="#79FFEA" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint110_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.3975 21.41) scale(0.386536 0.386119)">
          <stop stopColor="#F5FFFE" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient id="paint111_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.3771 20.4753) scale(0.366192 0.365797)">
          <stop offset="0.2" stopColor="#67D9C7" stopOpacity="0.5" />
          <stop offset="1" stopColor="#2C675C" stopOpacity="0.1" />
        </radialGradient>
        <linearGradient id="paint112_linear_4158_139017" x1="32.9373" y1="16.3551" x2="32.8154" y2="16.66" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C675C" stopOpacity="0.1" />
          <stop offset="0.8" stopColor="#79FFEA" stopOpacity="0.8" />
        </linearGradient>
        <radialGradient id="paint113_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.8271 16.6444) scale(0.233956 0.233704)">
          <stop stopColor="#F5FFFE" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0.1" />
        </radialGradient>
        <radialGradient id="paint114_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.817 16.4817) scale(0.223784 0.223543)">
          <stop offset="0.2" stopColor="#67D9C7" stopOpacity="0.5" />
          <stop offset="1" stopColor="#2C675C" stopOpacity="0.1" />
        </radialGradient>
        <linearGradient id="paint115_linear_4158_139017" x1="24.8776" y1="36.4891" x2="24.8776" y2="15.6996" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#91FFF3" stopOpacity="0.3" />
        </linearGradient>
        <radialGradient id="paint116_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.0292 25.4033) scale(6.74403 6.73677)">
          <stop offset="0.2" stopColor="#D6676F" />
          <stop offset="1" stopColor="#EF7E86" stopOpacity="0.2" />
        </radialGradient>
        <radialGradient id="paint117_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.7661 25.0474) scale(3.73312 3.7291)">
          <stop stopColor="#B55160" />
          <stop offset="1" stopColor="#A73C53" />
        </radialGradient>
        <linearGradient id="paint118_linear_4158_139017" x1="32.2543" y1="25.4661" x2="31.9802" y2="26.4519" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEC1C9" />
          <stop offset="0.8" stopColor="#FE8E9D" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint119_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.9987 22.1313) scale(6.53042 6.52338)">
          <stop offset="0.3" stopColor="#CE6D7F" />
          <stop offset="0.7" stopColor="#94B5AE" />
        </radialGradient>
        <radialGradient id="paint120_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2606 24.4481) scale(7.62899 7.62078)">
          <stop stopColor="#A94056" />
          <stop offset="1" stopColor="#911A33" />
        </radialGradient>
        <radialGradient id="paint121_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.673 20.8715) scale(1.12909 1.12787)">
          <stop offset="0.2" stopColor="#AF4C61" stopOpacity="0.8" />
          <stop offset="1" stopColor="#B9576B" stopOpacity="0.6" />
        </radialGradient>
        <linearGradient id="paint122_linear_4158_139017" x1="26.3968" y1="25.4232" x2="25.9502" y2="27.0289" gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#BA5B62" />
          <stop offset="1" stopColor="#EF7E86" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint123_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.1919 19.3576) scale(4.6486 4.64359)">
          <stop stopColor="#911A33" />
          <stop offset="1" stopColor="#A94056" />
        </radialGradient>
        <linearGradient id="paint124_linear_4158_139017" x1="28.1044" y1="20.1491" x2="26.1348" y2="25.4342" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEC1C9" />
          <stop offset="0.8" stopColor="#FE8E9D" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint125_linear_4158_139017" x1="27.6537" y1="17.7328" x2="26.6182" y2="20.7412" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEC1C9" />
          <stop offset="0.8" stopColor="#FEAFBA" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint126_linear_4158_139017" x1="32.1655" y1="19.0762" x2="30.0335" y2="25.286" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEC1C9" />
          <stop offset="0.8" stopColor="#FE8E9D" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint127_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.9813 26.2771) scale(4.17052 4.16602)">
          <stop offset="0.2" stopColor="#97233B" />
          <stop offset="1" stopColor="#C2869A" />
        </radialGradient>
        <linearGradient id="paint128_linear_4158_139017" x1="31.9348" y1="26.7451" x2="31.8435" y2="27.2024" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EDC0C4" />
          <stop offset="1" stopColor="#AFAEA4" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint129_linear_4158_139017" x1="25.723" y1="29.1723" x2="25.5911" y2="27.3026" gradientUnits="userSpaceOnUse">
          <stop stopColor="#95F0E5" />
          <stop offset="1" stopColor="#AFAEA4" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint130_linear_4158_139017" x1="30.3096" y1="29.7323" x2="30.2183" y2="28.3606" gradientUnits="userSpaceOnUse">
          <stop stopColor="#95F0E5" />
          <stop offset="0.5" stopColor="#79C4A9" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint131_linear_4158_139017" x1="31.6684" y1="18.5345" x2="31.1709" y2="19.7644" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DB97C3" stopOpacity="0.6" />
          <stop offset="1" stopColor="#B25086" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint132_linear_4158_139017" x1="34.7736" y1="20.2835" x2="33.8598" y2="22.5095" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DB97C3" stopOpacity="0.6" />
          <stop offset="1" stopColor="#B25086" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint133_radial_4158_139017" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(36.9671 27.039) scale(1.64786 1.64609)">
          <stop stopColor="#8CDED2" stopOpacity="0.8" />
          <stop offset="1" stopColor="#C578A9" />
        </radialGradient>
        <linearGradient id="paint134_linear_4158_139017" x1="34.8798" y1="23.9816" x2="34.4229" y2="22.8127" gradientUnits="userSpaceOnUse">
          <stop stopColor="#95F0E5" />
          <stop offset="1" stopColor="#AFAEA4" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint135_linear_4158_139017" x1="28.4248" y1="34.03" x2="28.6785" y2="32.14" gradientUnits="userSpaceOnUse">
          <stop stopColor="#95ECE2" />
          <stop offset="1" stopColor="#72C7AA" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint136_linear_4158_139017" x1="30.4466" y1="32.8344" x2="29.1369" y2="36.666" gradientUnits="userSpaceOnUse">
          <stop stopColor="#95ECE2" />
          <stop offset="1" stopColor="#72C7AA" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint137_linear_4158_139017" x1="31.4993" y1="16.1302" x2="30.4323" y2="17.1169" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A9DFD5" stopOpacity="0.2" />
          <stop offset="1" stopColor="#4FB8A4" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint138_linear_4158_139017" x1="35.5109" y1="36.7225" x2="37.0943" y2="20.6575" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.7" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_4158_139017">
          <rect width="64" height="64" rx="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_4158_139017">
          <rect width="32.4" height="45.168" fill="white" transform="translate(15.824 9.43994)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BrainBotIcon;
