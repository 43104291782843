import { WSEvents } from '../constants/ws';
import { AviatorGameWSMessages } from '../providers/WebSocketProvider/types';

class WebSocketServer {
  server: WebSocket | null;

  pingInterval: NodeJS.Timeout;

  constructor() {
    this.connect();
  }

  async connect() {
    if (this.server?.readyState === WebSocket.OPEN) {
      return true;
    }

    try {
      await new Promise((resolve, reject) => {
        window.dispatchEvent(new Event(WSEvents.CONNECTING, { bubbles: false, cancelable: true }));

        const token = localStorage.getItem('jwtToken');
        const protocol = process.env.REACT_APP_ENV === 'local' ? 'ws' : 'wss';

        this.server = new WebSocket(`${protocol}://${process.env.REACT_APP_WS_PATH}?token=${token}`);

        this.server.onopen = () => {
          resolve(null);

          this.processOpen();
        };

        this.server.onmessage = this.processMessage.bind(this);

        this.server.onclose = (event: CloseEvent) => {
          reject(new Error('Failed to connect to WS'));

          this.processClose(event);
        };

        this.server.onerror = this.processError;
      });

      return true;
    } catch {
      return false;
    }
  }

  private processOpen() {
    window.console.log('Connected to WebSocket server');

    window.dispatchEvent(new Event(WSEvents.CONNECTED, { bubbles: false, cancelable: true }));

    this.pingInterval = setInterval(() => {
      if (this.server?.readyState === WebSocket.OPEN) {
        this.server.send(JSON.stringify({ type: 'ping' }));
      }
    }, 50000);
  }

  // eslint-disable-next-line class-methods-use-this
  private processMessage(event: MessageEvent<string>) {
    const message: AviatorGameWSMessages = JSON.parse(event.data);

    if (message.type === 'start') {
      window.console.log('Game started:', message);
    } else if (message.type === 'progress') {
      window.console.log('Game progress:', message.data.currentValue);
    } else if (message.type === 'result') {
      window.console.log('Game result:', message);
    } else if (message.type === 'error') {
      window.console.log('Game error:', message);
    }
  }

  private processClose(event: CloseEvent) {
    window.console.log(`WebSocket closed: Code=${event.code}, Reason=${event.reason}`);

    if (this.pingInterval) clearInterval(this.pingInterval);

    if (this.server?.CONNECTING !== this.server?.readyState && WebSocket.OPEN !== this.server?.readyState) {
      setTimeout(() => {
        window.console.log('reconnect ws');

        this.connect();
      }, 1000);
    }
  }

  private processError(error: ErrorEvent) {
    window.console.error('WebSocket error:', JSON.stringify(error));

    this.server?.close();
  }

  cleanup() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  }
}

export default WebSocketServer;
