// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Phaser from 'phaser';
import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { useEffect } from 'react';
import { slotMachinePixiApp } from '../../../apps/slotMachine';
import eventEmitter, { emitGameEvent, GameEvents } from '../../../pages/Home/eventEmiter';
import { SlotMachineSpinResponse } from '../../../types';
import { SlotTypes } from '../../../types/constants';
import { slotFileList } from '../../../PIXIHelpers/pixiConstants';
import { loadSpineAtlas } from '../../../PIXIHelpers';

type SpineDataListT = {
  [key in typeof slotFileList[number]['name']]?: Spine
}
const app = slotMachinePixiApp;
const columns = [[], [], []];
const folderPath = '../spine/slot-machine/';
const numberOfDisplayedRows = 4;

function getOrCreateSpineAnimation(spineName: keyof SpineDataListT) {
  const animationFile = slotFileList.find((el) => el.name === spineName);
  if (animationFile) {
    const path = folderPath + animationFile.jsonPath;
    const { spineData } = PIXI.Assets.get(path);
    if (spineData) {
      const spineObject: Spine = new Spine(spineData);
      slotMachinePixiApp.stage.addChild(spineObject);
      return spineObject;
    }
    window.console.error('Slot cache not found');
  }
  return null;
}
const addAnimation = (fileName: keyof SpineDataListT, colIndex, rowIndex) => {
  const animation = getOrCreateSpineAnimation(fileName);
  const file = slotFileList.find((el) => el.name === fileName);
  // add the animation to the scene and render...
  if (animation.state.hasAnimation(file.animations[0])) {
    animation.state.setAnimation(0, file.animations[0], true);
    animation.scale.set(calculateScale(animation, 75, 64) * file.scale);
    if (typeof colIndex === 'number' && typeof rowIndex === 'number') {
      const position = calculateAnimationPosition(colIndex, rowIndex);
      animation.x = position.x + file.customOffset.x;
      animation.y = position.y + file.customOffset.y;
    }
    animation.state.timeScale = 0;
    animation.autoUpdate = true;
    animation.name = file.name;
    animation.animations = file.animations;
    animation.customOffset = file.customOffset;
    animation.fileData = file;
  }
  return animation;
};
const intialRenderAnimations = () => {
  for (let colIndex = 0; colIndex < 3; colIndex++) {
    // eslint-disable-next-line no-plusplus
    for (let rowIndex = 0; rowIndex < slotFileList.length; rowIndex++) {
      const file = slotFileList[Phaser.Math.Between(0, slotFileList.length - 1)];
      const animation = addAnimation(file.name, colIndex, rowIndex);
      animation.visible = (rowIndex < numberOfDisplayedRows && rowIndex !== 0);
      columns[colIndex].push(animation);
    }
  }
};
export async function preloadSlot() {
  await Promise.all(
    slotFileList.map(async (animationFile) => {
      const path = folderPath + animationFile.jsonPath;

      try {
        const spineAtlas = await loadSpineAtlas(path);

        PIXI.Assets.add({
          src: path,
          alias: path,
          data: {
            spineAtlas,
          },
        });

        await PIXI.Assets.load(path);
      } catch (error) {
        window.console.error(`Preload error ${path}:`, error);
      }
    }),
  );
  intialRenderAnimations();
}

const calculateScale = (animation: Spine, targetWidth: number, targetHeight: number) => {
  const originalWidth = animation.width;
  const originalHeight = animation.height;
  const scaleX = targetWidth / originalWidth;
  const scaleY = targetHeight / originalHeight;
  return Math.min(scaleX, scaleY);
};
const calculateAnimationPosition = (colIndex: number, rowIndex: number): { x: number; y: number } => {
  const sceneWidth = 335;
  const sceneHeight = 258;
  const baseXOffset = sceneWidth * (1 / 5);
  const baseYOffset = sceneHeight * (1 / 5);
  const baseXSpacing = sceneWidth * (1 / 3.2);
  const baseYSpacing = sceneHeight * (1 / 3.15);
  const xPosition = (baseXSpacing * colIndex) + (baseXOffset);
  const yPosition = (baseYSpacing * (rowIndex - 1)) + (baseYOffset);
  return { x: xPosition, y: yPosition };
};

const increment = 2;
const initialSpeed = 30;
const maxSpeed = 100;
const reverseSpeed = 250;
const reverseOffset = 10;
export const getMiddleElementPosition = () => {
  const sceneWidth = 335;
  const sceneHeight = 258;
  const baseYOffset = sceneHeight / 7;
  const baseXOffset = sceneWidth / 6;
  const calculatedPosition = calculateAnimationPosition(1, 2);
  const getGlobalPosition = (animation) => ({
    elementWidth: animation.width,
    elementHeight: animation.height,
    elementX: animation.x,
    elementY: animation.y,
    elementOffset: animation.customOffset,
    baseYOffset,
    baseXOffset,
    calculatedPosition,
    elementScale: columns[1][2].scale,
  });
  return getGlobalPosition(columns[1][2]);
};
export const getSlotCanvasRect = () => app.view.getBoundingClientRect() as DOMRect;
function SlotMachinePixi() {
  let animationFrameIds = [];
  const updateDisplay = (columnIndex: number): void => {
    const column = columns[columnIndex];
    // eslint-disable-next-line no-plusplus
    for (let rowIndex = 0; rowIndex < column.length; rowIndex++) {
      const spineObject = column[rowIndex];
      spineObject.visible = (rowIndex < numberOfDisplayedRows && rowIndex !== 0); // Display top 4
      const position = calculateAnimationPosition(columnIndex, rowIndex);
      spineObject.x = position.x + spineObject.customOffset.x;
      spineObject.y = position.y + spineObject.customOffset.y;
    }
  };

  const animateReverse = ({
    spineObject, currentTime, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, columnIndex, targetValues,
  }) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / reverseSpeed, 1);

    try {
      spineObject.y = startYPosition + (endYPosition - startYPosition) * progress;
    } catch (e) {
      window.console.warn(e);
    }

    if (progress < 1) {
      animationFrameIds.push(requestAnimationFrame((t) => animateReverse({
        currentTime: t, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, columnIndex, targetValues, spineObject,
      })));
    } else {
      animationsCompleted[rowIndex] = true;

      if (animationsCompleted.every((done) => done)) {
        if (columns.length - 1 === columnIndex) {
          if (shouldAnimateMiddleRow(targetValues)) {
            columns.forEach((col) => {
              col[2].state.timeScale = 1;
              col[2].state.setAnimation(0, col[2].spineData.animations[0].name, false);
            });
          }
          if (shouldRunCoinExplosion(targetValues)) {
            setTimeout(() => {
              emitGameEvent({ event: GameEvents.SLOT_COINS_EXPLOSION, animation: explosionAnimation(targetValues) });
            }, 100);
          }
          setTimeout(() => {
            emitGameEvent({ event: GameEvents.SLOT_STOP_SPIN });
          }, 100);
        }
      }
    }
  };

  const startSpinAnimation = (columnIndex: number, cycles: number, targetValues: string[]): void => {
    const column = columns[columnIndex];
    let completedCycles = 0;
    let currentIndex = 0;
    let currentDuration = initialSpeed;
    const reverseAnimation = (reverseColumnIndex: number): void => {
      const reverseColumn = columns[reverseColumnIndex];
      const animationsCompleted = new Array(reverseColumn.length).fill(false);

      for (let rowIndex = 0; rowIndex < reverseColumn.length; rowIndex++) {
        const spineObject = reverseColumn[rowIndex];
        spineObject.visible = true;

        const startYPosition = spineObject.y + reverseOffset;
        const endYPosition = spineObject.y;
        const startTime = performance.now();

        animationFrameIds.push(requestAnimationFrame((currentTime) => animateReverse({
          currentTime, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, columnIndex, targetValues, spineObject,
        })));
      }
    };
    const animate = ({
      spineObject, currentTime, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, targetValuesAnimate,
    }) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / currentDuration, 1);

      try {
        spineObject.y = startYPosition + (endYPosition - startYPosition) * progress;
      } catch { /* empty */ }
      if (progress < 1) {
        animationFrameIds.push(requestAnimationFrame((t) => animate({
          currentTime: t, spineObject, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, targetValuesAnimate: targetValues,
        })));
      } else {
        animationsCompleted[rowIndex] = true;
        if (rowIndex === 0 && completedCycles <= cycles) {
          const targetSymbols = Phaser.Utils.Array.Shuffle([...slotFileList]);
          const file = targetSymbols[currentIndex % targetSymbols.length];
          const newSpineObject = addAnimation(file.name, columnIndex, 0);
          column.unshift(newSpineObject);
          column[column.length - 1].destroy();
          column.pop();
        }

        if (animationsCompleted.every((done) => done)) {
          updateDisplay(columnIndex);
          if (completedCycles < cycles) {
            completedCycles++;
            currentIndex++;
            currentDuration = Math.min(currentDuration + (increment * columnIndex), maxSpeed);
            animateColumn(); // Restart animation for next cycle
          } else { // all cycles completed
            const targetValue = targetValuesAnimate[columnIndex];
            if ((column[2] && column[2].name !== targetValue)
            ) {
              currentIndex++;
              currentDuration = Math.min(currentDuration + increment * columnIndex, maxSpeed);
              animateColumn(); // loop while not reached targetValue
            } else {
              reverseAnimation(columnIndex);
            }
          }
        }
      }
    };

    const animateColumn = () => {
      const animationsCompleted = new Array(column.length).fill(false);
      for (let rowIndex = 0; rowIndex < column.length; rowIndex++) {
        const spineObject = column[rowIndex];
        spineObject.visible = true;

        const startYPosition = calculateAnimationPosition(columnIndex, rowIndex).y + spineObject.customOffset.y;
        const endYPosition = calculateAnimationPosition(columnIndex, rowIndex + 1).y + spineObject.customOffset.y + reverseOffset;

        const startTime = performance.now();
        animationFrameIds.push(requestAnimationFrame((t) => animate({
          currentTime: t, spineObject, startYPosition, endYPosition, startTime, animationsCompleted, rowIndex, targetValuesAnimate: targetValues,
        })));
      }
    };

    animateColumn();
  };
  const shouldAnimateMiddleRow = (slotsArr): boolean => {
    const slotTypesToAnimate = [SlotTypes.coin_bag, SlotTypes.treasure];
    if (slotTypesToAnimate.includes(slotsArr[0])) {
      const allEqual = (arr: string[]) => arr.every((v) => v === arr[0]);
      return allEqual(slotsArr);
    }
    return false;
  };
  const shouldRunCoinExplosion = (slotsArr): boolean => {
    const slotTypesToAnimate = [SlotTypes.coin_bag, SlotTypes.treasure];
    return !!slotsArr.some((el) => slotTypesToAnimate.includes(el));
  };
  const explosionAnimation = (slotsArr): boolean => {
    const slotTypesToAnimate = [SlotTypes.coin_bag, SlotTypes.treasure];
    const animationArr = ['coins_small', 'coins_mid', 'coins_big'];
    const animationPower = slotsArr.reduce((acc, slot) => acc + (slotTypesToAnimate.includes(slot) ? 1 : 0), 0);
    return animationArr[animationPower - 1];
  };

  const clearAllAnimationsFrames = () => { // clear requestAnimationFrame timers
    animationFrameIds.forEach((id) => cancelAnimationFrame(id));
    animationFrameIds = [];
  };
  useEffect(() => () => clearAllAnimationsFrames(), []);
  const startSpin = (targetSlots: SlotMachineSpinResponse['slots'][number]['type']) => {
    clearAllAnimationsFrames();
    columns.forEach((column, colIndex) => {
      const spins = [1, 5, 20];
      startSpinAnimation(colIndex, spins[colIndex], targetSlots);
    });
  };
  useEffect(() => {
    const slot = document.getElementById('slot-test');
    slot.appendChild(app.view);
    app.resize();
  }, []);
  useEffect(() => {
    const handler = (data) => {
      if (data?.targetSlots) {
        startSpin(data.targetSlots);
      }
    };
    const hideMiddle = () => {
      if (columns[1][2]) {
        columns[1][2].visible = false;
      }
    };
    const showMiddle = () => {
      if (columns[1][2]) {
        columns[1][2].visible = true;
      }
    };
    eventEmitter.on(GameEvents.SLOT_START_SPIN, handler);
    eventEmitter.on(GameEvents.SLOT_HIDE_MIDDLE, hideMiddle);
    eventEmitter.on(GameEvents.SLOT_SHOW_MIDDLE, showMiddle);

    return () => {
      eventEmitter.off(GameEvents.SLOT_START_SPIN, handler);
      eventEmitter.off(GameEvents.SLOT_HIDE_MIDDLE, hideMiddle);
      eventEmitter.off(GameEvents.SLOT_SHOW_MIDDLE, showMiddle);
    };
  }, []);

  return (
    <div id="slot-test" />
  );
}

export default SlotMachinePixi;
