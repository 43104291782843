import {
  memo,
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import { ReactComponent as Coin } from '../../assets/images/coin-size.svg';
import { ReactComponent as Info } from '../../assets/svg/info.svg';
import Button from '../../Components/Button';
import LeaderboardCurrentUserCard from '../../Components/LeaderboardCurrentUserCard';
import LeaderboardUserCard from '../../Components/LeaderboardUserCard';
import LeagueIcon from '../../Components/LeagueIcon';
import LinearProgressBar from '../../Components/LinearProgressBar';
import { getNextLeagueCoins, numberWithCommas } from '../../helpers';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { useAppDispatch, useAppSelector } from '../../store';
import { getTopPlayers } from '../../store/reducers/leaderboard/asyncActions';
import { SettingsResponse } from '../../store/reducers/settings/types';
import { LeaguesUnion, ProfileResponseT } from '../../types';
import LeaguesModal from './LeaguesModal';

const Leaderboard = memo(() => {
  const dispatch = useAppDispatch();

  const {
    leaderboard: topPlayers,
  } = useAppSelector(({ leaderboard }) => leaderboard);
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const leaguesList: LeaguesUnion[] = ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Challenger', 'Legendary', 'Master'];
  const currentLeague: LeaguesUnion = userData?.league.name as LeaguesUnion;
  const nextLeague: LeaguesUnion = leaguesList[leaguesList.indexOf(currentLeague) + 1];
  const statsRef = useRef<HTMLDivElement>(null);

  const [activeLeague, setActiveLeague] = useState<LeaguesUnion>(currentLeague);
  const [openLeaguesModal, setOpenLeaguesModal] = useState<boolean>(false);
  const goldFrom = useMemo(() => userSettings?.leagues[userSettings?.leagues?.findIndex((league) => league.name === activeLeague)].from, [userSettings, userData, activeLeague]);
  const goldToNextLeague: number = useMemo(() => getNextLeagueCoins((userSettings as SettingsResponse).leagues, (userData as ProfileResponseT).league.name), [userSettings, userData]);

  const topPlayersContainerRef = useRef<HTMLDivElement>(null);

  const rect = useResizeObserver(topPlayersContainerRef);

  useEffect(() => {
    dispatch(getTopPlayers());
  }, []);

  useEffect(() => {
    if (!topPlayersContainerRef.current || !rect) {
      return;
    }

    const isUserLeague = activeLeague === currentLeague;

    topPlayersContainerRef.current.style.maxHeight = `${window.innerHeight - (isUserLeague ? 155 : 70) - rect.y}px`;
  }, [rect, activeLeague, currentLeague]);

  const getStarBg = (league: LeaguesUnion) => {
    switch (league) {
      case 'Bronze':
        return 'before:bg-leaderboardBronzeBg';
      case 'Silver':
        return 'before:bg-leaderboardSilverBg';
      case 'Gold':
        return 'before:bg-leaderboardGoldBg';
      case 'Platinum':
        return 'before:bg-leaderboardPlatinumBg';
      case 'Diamond':
        return 'before:bg-leaderboardDiamondBg';
      case 'Challenger':
        return 'before:bg-leaderboardChallengerBg';
      case 'Legendary':
        return 'before:bg-leaderboardLegendaryBg';
      case 'Master':
        return 'before:bg-leaderboardMasterBg';
      default:
        return 'before:bg-leaderboardBronzeBg';
    }
  };

  return (
    <div className="absolute h-screen w-screen  bg-no-repeat  top-0 combined-page-bg">
      <div>
        <div className={`mt-[10vh] flex flex-col items-center before:content-[''] 
             ${getStarBg(currentLeague)} 
             before:w-screen before:left-0 before:bg-center0 before:h-[50vh] before:absolute before:bg-no-repeat before:top-[-3rem] before:z-[1]`}
        >
          <div className="z-[1] h-fit flex flex-col items-center min-h-[12rem]">
            <div className={{
              Bronze: 'h-[7.115rem] w-[6.938rem] mt-6',
              Silver: 'h-[7.115rem] w-[6.938rem] mt-6',
              Gold: 'h-[7.115rem] w-[6.938rem] mt-6',
              Platinum: 'h-[7.715rem] w-[6.938rem] mt-6 translate-y-[-5px]',
              Diamond: 'h-[7.615rem] w-[6.938rem] mt-6 translate-y-[-5px]',
              Challenger: 'h-[7.115rem] w-[6.938rem] mt-6',
              Legendary: 'h-[8.215rem] w-[6.938rem] mt-6 translate-y-[-10px]',
              Master: 'h-[9.715rem] w-[6.938rem] mt-0 translate-y-[6px]',
            }[currentLeague]}
            >
              <LeagueIcon league={currentLeague} />
            </div>
            <div className={`text-white mt-[0] ml-[-.15rem] text-22 font-bold font-Bangers
            ${{
              Bronze: 'translate-y-[-5px]',
              Silver: 'translate-y-[-5px]',
              Gold: 'translate-y-[-6px]',
              Platinum: 'translate-y-[-6px]',
              Diamond: 'translate-y-[-6px]',
              Challenger: 'translate-y-[-6px]',
              Legendary: 'translate-y-[-13px]',
              Master: 'translate-y-[-16px]',
            }[currentLeague]}`}
            >
              {currentLeague}
            </div>
          </div>
          <div className="pb-[.4rem] w-full px-4" ref={statsRef}>
            <div className="max-w-[450px] mx-auto my-0">
              <div>
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <div className={`h-[20px] ${currentLeague === 'Legendary' ? 'max-w-[20px]' : 'max-w-[25px]'}`}>
                      <LeagueIcon league={currentLeague} />
                    </div>
                    <span className="text-xs font-Bangers font-normal tracking-[0.3px]">{currentLeague}</span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <span className="text-xs font-Bangers font-normal tracking-[0.3px]">{nextLeague}</span>
                    <div className={`h-[20px] ${nextLeague === 'Legendary' ? 'max-w-[20px]' : 'max-w-[21px]'}`}>
                      {nextLeague ? (<LeagueIcon league={nextLeague} />) : ''}
                    </div>
                  </div>
                </div>
                <div className="mt-[.3rem] h-[17px]">
                  <LinearProgressBar currentValue={35} goal={100} progressClass={currentLeague} />
                </div>
                <div className="flex justify-center items-center mt-2">
                  <span className="">
                    <Coin width="1.2rem" height="1.2rem" />
                  </span>
                  <span className="text-14 font-Bangers">
                    {numberWithCommas(userData?.total_earned_coins || 0)}
                  </span>
                  <span className="text-gray-2 mx-[8px] font-Barlow text-14">/</span>
                  <span className="text-14">
                    <Coin width="1.2rem" height="1.2rem" />
                  </span>
                  <span className="text-14 font-Bangers">
                    {nextLeague ? numberWithCommas(goldToNextLeague) : <span className="text-xl"> &#8734;</span>}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4">
            <div className="">
              <div className="py-[0.625rem] px-5 bg-leagueCardBg leagues-list rounded-10 flex justify-between items-start">
                {userSettings?.leagues.map((league) => (
                  <div
                    onClick={() => setActiveLeague(league.name)}
                    className={`py-[.115rem] flex flex-col text-sm w-[10%] min-w-[39px] max-w-[50px] items-center rounded-10 ${activeLeague === league.name && 'bg-activeLeagueBG text-white'}`}
                    key={league.name}
                  >
                    <div className={`p-0 my-0 mx-auto h-[20px] ${league.name === 'Legendary' ? 'max-w-[20px]' : 'max-w-[21px]'}`}>
                      <LeagueIcon league={league.name} />
                    </div>
                    <div className="text-center w-full truncate text-[10px] pt-[.14rem] font-Bangers">
                      {league.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between pt-2">
              <div className="flex flex-col items-baseline">
                <div className="text-base font-normal font-Bangers">
                  {activeLeague}
                </div>
                <div className="flex items-center text-gray-2 text-xs tracking-[0.3px]">
                  from
                  {' '}
                  <span className="ml-[0.6rem]">
                    {numberWithCommas(goldFrom || 0)}
                  </span>
                  <Coin width="16px" className="ml-[0.4rem]" />
                </div>
              </div>
              <Button
                onClick={() => setOpenLeaguesModal(true)}
                className="rounded-10 focus:outline-none transform active:scale-75 transition-transform"
                pureClass
              >
                <Info height="24px" width="24px" />
              </Button>
            </div>
            <div
              className="mt-4 flex flex-col gap-[.7rem] mb-[11rem] overflow-y-auto"
              ref={topPlayersContainerRef}
            >
              {topPlayers?.[activeLeague].map((leader) => (
                leader.telegram_id !== userData?.telegram_id && (
                <LeaderboardUserCard
                  id={`leader_board_user_card_${leader.telegram_id}`}
                  key={leader.telegram_id}
                  {...leader}
                />
                )
              ))}
            </div>
          </div>

          <LeaguesModal
            open={openLeaguesModal}
            onClose={() => setOpenLeaguesModal(false)}
            leagues={(userSettings as SettingsResponse)?.leagues}
          />
          {userData && userData.league.name === activeLeague
          && (
            <LeaderboardCurrentUserCard
              username={userData?.username || ''}
              league={userData?.league}
              total_earned_coins={userData.total_earned_coins}
            />
          )}
        </div>
      </div>
    </div>
  );
});
Leaderboard.displayName = 'Leaderboard';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default Leaderboard;
