import { TextureAtlas } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { slotFileList } from './pixiConstants';

export const assetsPreload = (): Promise<void> => new Promise((resolve) => {
  const filesToWait = slotFileList.map((file) => {
    const { pngFileName } = getFilePaths(file.jsonPath);
    return pngFileName;
  });
  const requiredList = filesToWait.reduce((acc, fileName) => {
    acc[fileName] = false;
    return acc;
  }, {} as Record<string, boolean>);

  const observer = new PerformanceObserver((list) => {
    const entries = list.getEntries();
    entries.forEach((entry) => {
      const readyKey = Object.keys(requiredList).find((key) => entry.name.includes(key));
      if (readyKey) {
        requiredList[readyKey] = true;
        if (!Object.values(requiredList).includes(false)) {
          observer.disconnect();
          resolve();
        }
      }
    });
  });
  observer.observe({ entryTypes: ['resource'] });
});
const getFilePaths = (jsonPath: string) => {
  const pathArray = jsonPath.split('/');
  const folder = pathArray.slice(0, pathArray.length - 1).join('/');
  const name = jsonPath.split('/').pop()?.replace('.json', '');
  const atlasPath = `${folder}/${name}.atlas`;
  const pngPath = `${folder}/${name}.png`;
  const pngFileName = `${name}.png`;
  return {
    folder, atlasPath, name, pngPath, pngFileName,
  };
};
export async function loadSpineAtlas(path: string) {
  const { atlasPath, folder } = getFilePaths(path);
  const rawAtlas = await fetch(atlasPath).then((res) => res.text());
  return new TextureAtlas(rawAtlas, ((line, callback) => {
    callback(PIXI.BaseTexture.from(`${folder}/${line}`));
  }));
}
